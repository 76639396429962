import BootstrapTable from "react-bootstrap-table-next"
import useGetData from "../../hooks/use-get-data"
import { createScrap, getScrap } from "../../data/api-requests"
import { scrap } from "../../data/types/scrap"
import { Button, Container, Form } from "react-bootstrap"
import { Plus } from "react-bootstrap-icons"
import { Demodal } from "demodal"
import ItemModal from "../shared/modal/modal-item"
import { scrapFormFields, scrapFormSchema } from "../shared/form/form-scrap"

const Scrap = () => {

  const fetchData = async () => {
    const result = await getScrap()
    if (result) return result
  }

  const [scrapData, refresh] = useGetData<scrap>({fetchDataFn: fetchData})

  const onAddScrapClick = async () => {
    const modalResult: any = await Demodal.open(ItemModal, {
      titleVerb: "Add",
      itemName: "Scrap",
      formData: {product_id: 0, location_id: 0, scrap_qty: 0},
      formFields: scrapFormFields,
      formSchema: scrapFormSchema,
      onFormSubmitCallback: createScrap
    })

    if (modalResult) {
      refresh()
    }
  }

  const productFormatter = (cell: any, row: scrap) => (
    <>
      {row.product_id.default_code ? `[${row.product_id.default_code}]` : ''} {row.product_id.name}
    </>
  )

  const productUnitOfMeasureFormatter = (cell: any, row: scrap) => (
    <>
      {row.product_uom_id.name}
    </>
  )

  const scrappedByFormatter = (cell: any, row: scrap) => (
    <>
      {row.tk_scrapped_by !== "False" ? row.tk_scrapped_by : "-"}
    </>
  )

  const tableColumns = [
    {
        dataField: 'product_id',
        text: 'Product',
        sort: true,
        formatter: productFormatter,
    },
    {
      dataField: 'scrap_qty',
      text: 'Qty'
    },
    {
      dataField: 'product_uom_id',
      text: 'UoM',
      formatter: productUnitOfMeasureFormatter,
    },
    {
      dataField: 'tk_scrapped_by',
      text: 'Scrapped By',
      formatter: scrappedByFormatter,
    },
    {
      dataField: 'date_done',
      text: 'Scrapped On',
    },
  ]

  return <>
    <Container fluid className="p-3">
      <div className="d-flex align-items-center users-header">
        <h1 className="m-0 users-title">Scrap</h1>
        <Button variant="secondary" onClick={onAddScrapClick} className="ml-3"><Plus/></Button>
      </div>

      <div className="mt-3">
        <BootstrapTable
          keyField="id"
          data={ scrapData }
          columns={ tableColumns }
          defaultSorted={[{dataField: 'full_name', order: 'asc'}]}
          bootstrap4
        />
      </div>
    </Container>
  </>
}

export default Scrap