import { Demodal, useModal } from "demodal";
import { useState } from "react";
import { Button, Modal, Pagination } from "react-bootstrap";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { odooDocument, emptyDocument } from "../../../data/types/product-drawing";

interface ProductDrawingsModalProps {
    productDrawingData: odooDocument
}

const ProductDrawingViewerModal = Demodal.create(({
    productDrawingData = emptyDocument
}: ProductDrawingsModalProps) => {
    const [numPages, setNumPages] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    const modal = useModal();

    const resolve = (value: boolean) => () => {
        modal.resolve(value)
        modal.close()
        modal.remove()
    }

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setPageNumber(1)
        setNumPages(numPages)
    }

    const onPrevClick = () => {
        if (pageNumber > 1) setPageNumber(pageNumber - 1)
    }

    const onNextClick = () => {
        if (pageNumber < numPages) setPageNumber(pageNumber + 1)
    }

    return (
        <Modal
            show={modal.isOpen}
            onHide={resolve(false)}
            className="product-drawing-viewer-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Document - {productDrawingData.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    {productDrawingData.mimetype === 'application/pdf' ? (
                        <>
                            <Document
                                file={`data:application/pdf;base64,${productDrawingData.datas}`}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} width={1600} />
                            </Document>
                            <Pagination className="justify-content-center">
                                <Pagination.Prev onClick={onPrevClick} disabled={pageNumber === 1} />
                                <Pagination.Item disabled>{pageNumber} of {numPages}</Pagination.Item>
                                <Pagination.Next onClick={onNextClick} disabled={pageNumber > numPages - 1} />
                            </Pagination>
                        </>
                    ) : (
                        <div className="attachment-viewer-image-container">
                            <img alt="" className="attachment-image" src={`data:image/*;base64,${productDrawingData.datas}`}/>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={resolve(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
})

export default ProductDrawingViewerModal
