import Cookies from 'universal-cookie';
import { BaseSyntheticEvent, useEffect, useState } from "react"
import { Button, Container, Form } from "react-bootstrap"
import LocationDropdownCombo from '../shared/location-dropdown-combo';
import { workCenter, workCenterGroup } from '../../data/types/work-order';
import createNotification from '../../utils/notification';
import useCheckPermission from '../../hooks/use-check-permission';
import useGetStaticData from '../../hooks/use-get-static-data';

const Settings = () => {
  useCheckPermission('WO Administrator', true)

  const staticResources = useGetStaticData()

  const cookies = new Cookies()
  const defaultWorkCenterGroupId = cookies.get('tk_shop_floor_default_work_center_group_id')
  const defaultWorkCenterId = cookies.get('tk_shop_floor_default_work_center_id')
  const [currentWorkCenterGroupId, setCurrentWorkCenterGroupId] = useState<number>(defaultWorkCenterGroupId || 0)
  const [currentWorkCenterId, setCurrentWorkCenterId] = useState<number>(defaultWorkCenterId || 0)

  const [workCenterGroups, setWorkCenterGroups] = useState<workCenterGroup[]>([])
  const [workCenters, setWorkCenters] = useState<workCenter[]>([])
  const [workCenterGroupsWithPlaceholder, setWorkCenterGroupsWithPlaceholder] = useState<workCenterGroup[]>([])
  const [workCentersWithPlaceholder, setWorkCentersWithPlaceholder] = useState<workCenter[]>([])

  // Set WCG when available
  useEffect(() => {
    if (staticResources && staticResources.work_center_groups) {
      setWorkCenterGroups(staticResources.work_center_groups)
    }
  }, [staticResources])

  // Set WC when current WCG changes
  useEffect(() => {
    if (workCenterGroups) {
      const relatedWorkCenters = workCenterGroups.find((wcg: workCenterGroup) => wcg.id === Number(currentWorkCenterGroupId))
      if (relatedWorkCenters) setWorkCenters(relatedWorkCenters.work_centers)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkCenterGroupId, workCenterGroups])

  // Add "Select..." option to work center groups and work centers
  useEffect(() => {
    if (workCenterGroups && workCenterGroups.length > 0) {
      // The work center is no longer valid when the group changes
      setCurrentWorkCenterId(0)

      setWorkCenterGroupsWithPlaceholder([
        {
          id: 0,
          work_center_group_name: 'Select...',
          has_work_centers: false,
          has_paint_booth: false,
          paint_booth_name: '',
          sequence: 0,
          work_centers: [],
        },
        ...workCenterGroups
      ])
    }

    if (workCenters && workCenters.length > 0) {
      setWorkCentersWithPlaceholder([
        {
          id: 0,
          work_center_name: 'Select...',
          sequence: 0,
        },
        ...workCenters
      ])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workCenterGroups, workCenters])

  const onWorkCenterGroupChange = (workCenterGroupId: number) => {
    setCurrentWorkCenterGroupId(workCenterGroupId)
  }

  const onWorkCenterChange = (workCenterId: number) => {
    setCurrentWorkCenterId(workCenterId)
  }

  const onFormSubmit = (event: BaseSyntheticEvent) => {
    event.preventDefault()

    if (Number(currentWorkCenterGroupId) === 0) {
      cookies.remove('tk_shop_floor_default_work_center_group_id', {path: '/'})
    } else {
      cookies.set('tk_shop_floor_default_work_center_group_id', currentWorkCenterGroupId, {path: '/'})
    }

    if (Number(currentWorkCenterId) === 0) {
      cookies.remove('tk_shop_floor_default_work_center_id', {path: '/'})
    } else {
      cookies.set('tk_shop_floor_default_work_center_id', currentWorkCenterId, {path: '/'})
    }

    createNotification('Saved', 'Saved application settings.')
  }

  return (
      <Container className="p-3">
        <div className="d-flex align-items-center users-header">
          <h1 className="m-0 users-title">Application Settings</h1>
        </div>

        <div className="mt-3">
          <Form onSubmit={onFormSubmit} className="settings-form" autoComplete="off">
            <Form.Group>
              <Form.Label>Shop Floor - Default Location</Form.Label>
              <LocationDropdownCombo
                initialWorkCenterGroupId={currentWorkCenterGroupId}
                initialWorkCenterId={defaultWorkCenterId}
                onWorkCenterChange={onWorkCenterChange}
                onWorkCenterGroupChange={onWorkCenterGroupChange}
                workCenters={workCentersWithPlaceholder}
                workCenterGroups={workCenterGroupsWithPlaceholder}
              />
            </Form.Group>
            <Button type="submit" className="mb-2">
              Save Changes
            </Button>
          </Form>
        </div>
    </Container>
  )
}

export default Settings
