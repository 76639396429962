import { Demodal, useModal } from "demodal"
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { Badge, Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { PlusCircle } from "react-bootstrap-icons";
import { cancelDelivery, deleteDelivery, updateDeliveryNote, updateDeliveryProductQtys, validateDelivery } from "../../../data/api-requests";
import { emptypackingSlipProduct, packingSlipProduct } from "../../../data/types/deliveries";
import createNotification from "../../../utils/notification";
import ConfirmModal from "./modal-confirm";
import DeliveryItemsLine from "./modal-delivery-items-line";
import TextareaAutosize from 'react-textarea-autosize';

/**
 * Modal to add/edit items
 */

interface modalDeliveryItemsProps {
    deliveryOrderId: number;
    workOrderId: number;
    odooStockPickingId: number;
    odooWorkOrderId: number;
    deliveryState: string;
    deliveryName: string;
    deliveryNote: string;
    updateDeliveryOrder: Function;
    productsList: packingSlipProduct[];
}

const DeliveryItemsModal = Demodal.create((props: modalDeliveryItemsProps) => {
    const [productsList, setProductsList] = useState<packingSlipProduct[]>([])
    const [originalProductsList, setOriginalProductsList] = useState<packingSlipProduct[]>([])
    const [changedProducts, setChangedProducts] = useState<packingSlipProduct[]>([])
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)
    const [allProductsComplete, setAllProductsComplete] = useState<boolean>(false)
    const [anyProductsComplete, setAnyProductsComplete] = useState<boolean>(false)
    const [anyQtyEntered, setAnyQtyEntered] = useState<boolean>(false)
    const [anyOdooQtyEntered, setAnyOdooQtyEntered] = useState<boolean>(false)
    const [showDeliveryNote, setShowDeliveryNote] = useState<boolean>(false)
    const [editMode, setEditMode] = useState({active: false, value: ''})
    const [readOnly, setReadOnly] = useState<boolean>(false)
    const modal = useModal()

    // On line items change
    useEffect(() => {
        const allDone = productsList.every((line: packingSlipProduct) => {
            return (line.qty >= line.qty_total) ? true : false
        })
        const anyDone = productsList.some((line: packingSlipProduct) => {
            return (line.qty >= line.qty_total) ? true : false
        })
        const hasPartialQty = productsList.some((line: packingSlipProduct) => {
            return (line.qty > 0) ? true : false
        })
        const hasPartialOdooQty = productsList.some((line: packingSlipProduct) => {
            return (line.qty > 0 && line.product_id) ? true : false
        })
        setAllProductsComplete(allDone)
        setAnyProductsComplete(anyDone)
        setAnyQtyEntered(hasPartialQty)
        setAnyOdooQtyEntered(hasPartialOdooQty)
    }, [productsList])

    const closeModal = (triggerRefresh: boolean = false) => {
        modal.resolve(triggerRefresh)
        modal.close()
        modal.remove()
    }

    const handleValidateClick = async () => {
        // No quantities
        if (!anyQtyEntered) {
            const warningMessage = 'You must enter some quantities to process this order.'
            createNotification('No Quantities Entered', warningMessage, 'error')
            return false
        }

        // Odoo delivery order, but no Odoo line items have quantities
        if (props.odooStockPickingId && !anyOdooQtyEntered) {
            const warningMessage = 'This delivery order is from Odoo, but no Odoo line items have quantities entered.'
            createNotification('No Odoo Quantities Entered', warningMessage, 'error')
            return false
        }

        // Backorder needed
        if (!allProductsComplete) {
            const warningMessage = 'Note: a backorder will be created for incomplete line items.'
            const modalResult = await Demodal.open(ConfirmModal, {
                title: 'Create backorder?',
                description: warningMessage,
            })

            if (!modalResult) return false
        }

        performValidate()
    }

    const performValidate = async () => {
        const deliveryOrderId = props.deliveryOrderId ?? 0
        const odooStockPickingId = props.odooStockPickingId ?? 0

        if (changedProducts.length > 0) {
            await updateDeliveryProductQtys(deliveryOrderId, odooStockPickingId, changedProducts)
        }

        await validateDelivery(deliveryOrderId, odooStockPickingId)

        setChangedProducts([])

        modal.resolve(true)
        modal.close()
        modal.remove()
    }

    const handleCancelClick = async () => {
        const modalResult = await Demodal.open(ConfirmModal, {
            title: 'Cancel delivery order?',
            description: 'This will lock the delivery order, preventing it from being edited in the future.',
        })

        if (!modalResult) return false

        cancelDelivery(props.deliveryOrderId).then(() => {
            createNotification('Cancelled', `Cancelled delivery order.`)
            closeModal(true)
        })
    }

    const handleDeleteClick = async () => {
        const modalResult = await Demodal.open(ConfirmModal, {
            title: 'Delete delivery order?',
            description: 'This will remove the delivery order. This action can not be undone.',
        })

        if (!modalResult) return false

        deleteDelivery(props.deliveryOrderId).then(() => {
            createNotification('Deleted', `Removed delivery order.`)
            closeModal(true)
        })
    }

    const performClose = () => {
        setChangedProducts([])
        setProductsList([...originalProductsList])
        setHasUnsavedChanges(false)
        closeModal()
    }

    const handleSaveProductsClick = async () => {
        const deliveryOrderId = props.deliveryOrderId ?? 0
        const odooStockPickingId = props.odooStockPickingId ?? 0

        if (hasUnsavedChanges) {
            await updateDeliveryProductQtys(deliveryOrderId, odooStockPickingId, changedProducts)
            setChangedProducts([])
        }

        if (showDeliveryNote && props.deliveryNote !== editMode.value) {
            await updateDeliveryNote(deliveryOrderId, odooStockPickingId, editMode.value)
            setEditMode({active: false, value: ''})
        }
        createNotification('Saved', `Saved delivery order lines.`)
        closeModal()
    }

    const addLine = () => {
        const randomNegativeInt: number = (Math.floor(Math.random() * 1000)) * -1;
        const newProduct: packingSlipProduct = {...emptypackingSlipProduct, id: (randomNegativeInt - 1)}
        setProductsList([...productsList, newProduct])
    }

    useEffect(() => {
        if (props.deliveryNote) {
            setEditMode({active: false, value: props.deliveryNote})
            setShowDeliveryNote(true)
        }
    }, [props, props.deliveryNote])

    const addPackingSlipNote = () => {
        setShowDeliveryNote(true)
        setEditMode({active: true, value: editMode.value})
    }

    const handleDeliveryNoteChange = (event: BaseSyntheticEvent) => {
        let formValue = event.target.value
        setEditMode({active: true, value: formValue})

    }

    const handleDeliveryNoteClick = (event: BaseSyntheticEvent) => {
        let value = event.target.value
        setEditMode({active: true, value: value})
    }

    useEffect(() => {
        // Set read-only mode if component is already completed
        if (['done', 'cancel'].includes(props.deliveryState)) {
            setReadOnly(true)
        } else {
            setReadOnly(false)
        }
    }, [props.deliveryState])

    useEffect(() => {
        setProductsList(props.productsList)
        setOriginalProductsList(props.productsList)
    }, [props.productsList])

    const getTitle = (deliveryOrderName: string) => (
        <><span className="component-type">Delivery Order</span> {
            deliveryOrderName
                ? deliveryOrderName
                : <>CUSTOM/OUT/{String(props.deliveryOrderId).padStart(5, '0')}</>
        }</>
    )

    return (
        <Modal
            show={modal.isOpen}
            onHide={performClose}
            backdrop="static"
            className="delivery-items-modal"
        >
            <Modal.Header closeButton>
                <div>
                    <Row>
                        <Col>
                            <Modal.Title className="text-nowrap">
                                { getTitle(props.deliveryName) }
                                { props.deliveryState === 'done' && (<Badge className="ml-2 component-status-badge" variant="success">Completed</Badge>)}
                                { props.deliveryState === 'cancel' && (<Badge className="ml-2 component-status-badge" variant="secondary">Cancelled</Badge>)}
                            </Modal.Title>
                        </Col>
                        {!showDeliveryNote && (
                            <Col className="w-100">
                                <div className="mt-1 w-100 d-flex justify-content-end">
                                    <Button onClick={addPackingSlipNote} variant="primary" className="btn-lg">Add Note</Button>
                                </div>
                            </Col>
                        )}
                    </Row>
                </div>
            </Modal.Header>
            <Modal.Body>
                {showDeliveryNote && (
                    <Row className="w-100 border-bottom ml-0">
                        <Col className="mt-2 mb-3">
                            <small className="d-flex text-nowrap">Packing Slip Note</small>
                            <Form className="w-100" onSubmit={e => e.preventDefault()}>
                                <InputGroup className="w-100">
                                    <TextareaAutosize
                                        className="form-control"
                                        autoFocus={editMode.active}
                                        maxRows={3}
                                        onFocusCapture={handleDeliveryNoteClick}
                                        onChange={handleDeliveryNoteChange}
                                        data-value={editMode.value}
                                        value={editMode.active ? editMode.value : props.deliveryNote}
                                    />
                                </InputGroup>
                            </Form>
                        </Col>
                    </Row>
                )}
                {productsList && (
                    productsList.map((product: packingSlipProduct) => (
                        <DeliveryItemsLine
                            key={product.stock_move_line_id ?? product.stock_move_id ?? product.id}
                            product={product}
                            products={productsList}
                            changedProducts={changedProducts}
                            readOnly={readOnly}
                            setChangedProducts={setChangedProducts}
                            setProducts={setProductsList}
                            setHasUnsavedChanges={setHasUnsavedChanges}
                        />
                    ))
                )}
                {(!readOnly) && (
                    <Button
                        variant="outline-primary"
                        className="delivery-add-line-button"
                        onClick={addLine}
                    >
                        <PlusCircle className="mr-2"/> <span>Add Line</span>
                    </Button>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button className="mr-auto" variant="secondary" onClick={performClose}>
                    Close
                </Button>
                {readOnly && (
                    <small className="text-muted">This delivery order is in {props.deliveryState} state, the line items are now read-only.</small>
                )}
                {!readOnly && props.deliveryOrderId > 0 && (
                    <Button
                        variant="warning"
                        onClick={handleCancelClick}
                        disabled={readOnly || props.deliveryState === 'cancel'}
                        className="mr-5"
                    >
                        Cancel
                    </Button>
                )}
                <Button
                    variant="primary"
                    onClick={handleSaveProductsClick}
                    disabled={(
                        (
                            (props.deliveryNote === null && editMode.value === '')
                            || props.deliveryNote === editMode.value
                        )
                        && !hasUnsavedChanges
                    )}
                >
                    Save
                </Button>
                {!readOnly && (
                    <Button
                        variant="success"
                        onClick={handleValidateClick}
                        disabled={readOnly}
                    >
                        Validate
                    </Button>
                )}
                {(props.deliveryOrderId > 0 && props.deliveryState === 'cancel') && (
                    <Button
                        variant="danger"
                        onClick={handleDeleteClick}
                    >
                        Delete
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    )
})

export default DeliveryItemsModal