import { Demodal, useModal } from "demodal"

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Container, Row, Col, Form, Table } from "react-bootstrap";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { createMoQueue, getSerialNumberStatus } from "../../../data/api-requests";
import { Trash } from "react-bootstrap-icons";
import { moQueue } from "../../../data/types/mo-queue";
import { serialNumber } from "../../../data/types/serial-number";
import createNotification from "../../../utils/notification";

interface modalSerialNumberProps {
  qty: number;
  moQueue?: moQueue[];
  odooWorkOrderId: number;
  moId: number;
  partId: number;
}

const SerialNumberModal = Demodal.create(({
  qty = 0,
  moQueue = [],
  odooWorkOrderId = 0,
  moId = 0,
  partId = 0,
}: modalSerialNumberProps) => {
  const getCreateSerialNumber = (serialNumber: string, readOnly: boolean, stage?: string) => {
    let result: serialNumber = {
      serial_number: serialNumber,
      read_only: readOnly,
      stage: stage ? stage : '',
    }
    return result
  } 

  const [serialNumbers, setSerialNumbers] = useState<serialNumber[]>(moQueue && moQueue.length > 0 ? moQueue.map((mo: moQueue) => getCreateSerialNumber(mo.serial_number, true, mo.queue_stage_name)) : [])
  const [errorIndexes, setErrorIndexes] = useState<Array<{index: number, errorMessage: string}>>([])
  const [fieldValues, setFieldValues] = useState({lastValue: '', currentValue: ''})
  const [editMode, setEditMode] = useState({status: false, index: 0, fieldName: ''})
  const [submitFailedSerialNumbers, setSubmitFailedSerialNumbers] = useState<Boolean>(false)
  const modal = useModal();

  const startingIndex = serialNumbers.filter(item => item.read_only).length

  const resolve = (value: string[]) => () => {
    modal.resolve(value)
    modal.close()
    modal.remove()
  }

  const onFormSubmit = async () => {
    let isValid = await checkSerialNumbersValidity(serialNumbers)
    const serialNumbersFiltered = serialNumbers.filter(item => (!item.read_only && item.serial_number !== "")).map(item => item.serial_number)
    if (!isValid) return false

    await createMoQueue(odooWorkOrderId, moId, partId, 1, serialNumbersFiltered)
      .then((result: string[]) => {
        if (result.length > 0) {
          createNotification('Failed', `Serial numbers were not added to queue.`)
          checkSerialNumbersValidity(serialNumbers, true)
          isValid = false
        } else {
          createNotification('Success', 'Serial numbers added to queue.')
        }
      })
      .catch((error) => {
        console.error('request error', error)
        createNotification('Failed', 'Failed to add serial numbers to queue.')
      })

    if (isValid) {
      setSerialNumbers([])
      setErrorIndexes([])
      modal.resolve(serialNumbersFiltered)
      modal.close()
      modal.remove()
    }
  }

  const handleInputClick = (event: BaseSyntheticEvent) => {
    const target = event.target
    const initialValue = target.value
    setEditMode({'status': true, index: parseInt(target.dataset.index, 10), fieldName: target.name})
    setFieldValues({lastValue: initialValue, currentValue: initialValue})
  }

  const handleInputChange = (event: BaseSyntheticEvent) => {
    const fieldIndex = editMode.index
    const fieldName = event.target.name
    let fieldValue = String(event.target.value)
    const newValue = fieldValue.replace(/[^a-zA-Z0-9]{1,30}$/gi, '')

    const invalidQty = fieldName === 'qty' && Number(newValue) < 1

    if ((fieldName === 'length' && !newValue.match(/[^a-zA-Z0-9]{1,30}$/gi)) || invalidQty) {
      if (!event.target.className.includes(' custom-project-error')) event.target.className += ' custom-project-error'
      setErrorIndexes([{index: editMode.index, errorMessage: `${fieldName} is not a valid serial number.`}])
    }

    if (!fieldValue.trim()) { fieldValue = '' }
    // Update value in interface
    const editedBars = serialNumbers.map((item: serialNumber, index: number) => {
      if (index === fieldIndex) {
        item.serial_number = fieldValue
      }
      return item
    })
    setSerialNumbers([...editedBars])
  }

  const handleInputKeyDown = async (event: any) => {
    if (event.key === 'Enter') {
      // Submit
      const fieldIndex = editMode.index
      const fieldName = editMode.fieldName
      let fieldValue = String(fieldValues)
      if (!fieldValue.trim()) { fieldValue = '' }
      // Update value in interface
      const editedBars = serialNumbers.map((item: serialNumber, index: number) => {
        if (index === fieldIndex) {
          item.serial_number = fieldValue
        }
        return item
      })
      setSerialNumbers([...editedBars])
    }
    if (
      event.key === 'Escape' || event.key === 'Esc'
      || event.key === 'Enter'
    ) {
      cancelEdit(event)
    }
  }

  const cancelEdit = (event: any) => {
    setEditMode({'status': false, index: 0, fieldName: ''})
    setFieldValues({lastValue: '', currentValue: ''})
    event.target.blur()
    handleInputBlur(event)
  }

  const handleInputBlur = async (event: any) => {
    const fieldIndex = editMode.index
    const fieldName = editMode.fieldName

    // Update value in interface
    const editedSerialNumbers = serialNumbers.map((item: serialNumber, index: number) => {
      if (index === fieldIndex) {
        item.serial_number = event.target.value
      }
      return item
    })

    let newSerialNumbers = [...editedSerialNumbers]
    if (event.type === 'keydown' || (newSerialNumbers.filter(item => item.serial_number === '').length === 0 && newSerialNumbers.length < qty)) {
      let newSerialNumber: serialNumber = getCreateSerialNumber('', false)
      newSerialNumbers = serialNumbers.length < qty ? [...editedSerialNumbers, newSerialNumber] : [...editedSerialNumbers]
    }
    setSerialNumbers(newSerialNumbers)
    await checkSerialNumbersValidity(newSerialNumbers)
  }

  const checkSerialNumbersValidity = async (newSerialNumbers: serialNumber[], submittingSerialNumbers: boolean = false) => {
    let indexes = []
    const validSerialNumbers = newSerialNumbers.filter((item: serialNumber) => !item.read_only).map(item => item.serial_number)
    const filteredSerialNumbers = newSerialNumbers.filter(item => !item.read_only)
    const result = await getSerialNumberStatus(validSerialNumbers)
    if (result && result.length > 0) {
      indexes = result.map((serialNumber: string, index: number) => {
        // find index of each item in result, add those indexes to errorIndexes
        if (serialNumber) {
          const serialNumberIndex = filteredSerialNumbers.map(item => item.serial_number).indexOf(serialNumber) + startingIndex
          const errorMessage = submittingSerialNumbers ? 'Serial number has already been used.' : 'Failed to submit serial number.'
          return {index: serialNumberIndex, errorMessage: errorMessage}
        }
      })
    }

    indexes = [...indexes, ...validSerialNumbers.map((item, index) => (item !== "" && validSerialNumbers.indexOf(item) !== index) ? {index: index + startingIndex, errorMessage: 'Serial number has already been used.'} : false).filter(item => item !== false)]

    setErrorIndexes(indexes)
    return indexes.length === 0
  }

  // Necessary for autofocus to work.
  useEffect(() => {
    onAddClick()
  }, [])

  const hasError = (index: number) => {
    return errorIndexes.filter((item) => item.index === index).length > 0
  }

  const getError = (index: number) => {
    return errorIndexes.filter((item) => item.index === index)
  }

  const editableInput = (item: string, fieldName: string, index: number, readOnly: boolean | undefined, stage: string) => (
    <>
    <input
      type="text"
      className={`form-control form-control-xl ${hasError(index) ? 'serial-number-row-error' : ''}`}
      name={fieldName}
      onFocusCapture={handleInputClick}
      onChange={handleInputChange}
      onKeyDown={handleInputKeyDown}
      onBlur={handleInputBlur}
      data-index={index}
      readOnly={readOnly}
      disabled={readOnly}
      autoFocus={index === serialNumbers.length-1}
      value={
          item + (readOnly ? ` (${stage})` : '')
      }
    />
    <small className="text-danger">{hasError(index) ? getError(index)[0].errorMessage : ''}</small>
    </>
  )

  const onDeleteClick = async (index: number) => {
    let editedSerialNumbers = serialNumbers.filter((item: serialNumber, itemIndex: number) => itemIndex !== index)
    setSerialNumbers([...editedSerialNumbers])

    let editedErrorIndexes: any[] = []
    const validSerialNumbers = editedSerialNumbers.filter((item: serialNumber) => !item.read_only).map(item => item.serial_number)
    editedErrorIndexes = [...validSerialNumbers.map((item, index) => (item !== "" && validSerialNumbers.indexOf(item) !== index) ? {index: index + startingIndex, errorMessage: 'Serial number has already been used.'} : false).filter(item => item !== false)]
    setErrorIndexes(editedErrorIndexes)
    await checkSerialNumbersValidity(editedSerialNumbers)
  }

  const onAddClick = () => {
    let newSerialNumber = getCreateSerialNumber('', false)
    if (serialNumbers.length < qty) {
      setSerialNumbers([...serialNumbers, newSerialNumber])
    }
  }

  return (
    <>
      <Modal
        show={modal.isOpen}
        onHide={resolve([])}
        className="confirm-modal"
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <Container className="p-0">
            <Row>
              <Col key={1}>
                <Form.Group key={''} controlId={''}>
                  <Row>
                    <Col>
                      <Table className="table table-bordered serial-number-table text-center m-auto w-100">
                        <thead>
                          <tr>
                            <th>Enter Serial Number</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {serialNumbers.map((value: serialNumber, index: number) => (
                          <tr>
                            <td>
                              <>{editableInput(value.serial_number, '', index, value.read_only, value.stage)}
                              </>
                            </td>
                            <td>
                              {value.read_only || serialNumbers.length-1 === index ? (
                                <>-</>
                              ) : (
                              <Button variant="outline-danger" data-index={index} onClick={() => onDeleteClick(index)}><Trash/></Button>
                              )}
                            </td>
                          </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button className="mr-auto" variant="secondary" onClick={resolve([])}>
            Close
          </Button>
          <Button variant="primary" onClick={onFormSubmit} disabled={serialNumbers.filter(item => item.read_only).length >= qty}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
})

export default SerialNumberModal