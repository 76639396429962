import Cookies from 'universal-cookie';
import { useState as useGlobalState } from '@hookstate/core'
import globalState from '../../common/context'
import { appEnvironment, useMockData } from '../../common/config'

import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Badge from 'react-bootstrap/Badge';

import { ArrowRepeat, PersonCircle, Trash } from 'react-bootstrap-icons';
import { Dropdown } from 'react-bootstrap';
import HeaderReceivingButton from './header-receiving-button';
import WorkOrderDropdown from './field/field-work-order';
import { useHistory } from 'react-router-dom';
import HeaderTimeTracking from './header-time-tracking';

const Header = () => {
  const cookies = new Cookies()
  const globals = useGlobalState(globalState)
  const history = useHistory()
  const username = useMockData ? 'Demo User' : cookies.get('tk_user_display_name')
  const userRules = globals.userRules.get()

  const handleRefreshClick = () => {
    document.location.reload()
  }

  const handleSyncClick = () => {
    globals.isLoading.set(true)
    globals.triggerSync.set(true)
  }

  const handleWorkOrderClick = (fieldName: string, id: number) => {
    const windowLocation = window.location.href

    if (windowLocation.includes('work_order/shop_floor')) {
      goToWorkOrder(id)
    } else if (windowLocation.includes('/report')) {
      history.push(`/work_order/${String(id)}/report`)
      window.location.reload()
    } else {
      history.push(`/work_order/${String(id)}`)
    }
  }

  const goToWorkOrder = (workOrderId: number) => {
    cookies.set('tk_shop_floor_work_order_id', workOrderId, {path: '/'})
    history.push(`/work_order/shop_floor/${String(workOrderId)}`)
  }

  const onLogoutClick = () => {
    cookies.set('tk_current_url', window.location.href, {path: '/'})
    history.push('/logout')
  }

  return <>
    <Navbar bg="dark" variant="dark" expand="lg" sticky="top" className={appEnvironment === 'staging' ? 'header-staging' : ''}>
      <Navbar.Brand className="d-none d-md-flex">
        Production App

        {!useMockData && process.env.NODE_ENV !== 'production' && (
          <Badge className="ml-2 d-none d-xl-inline-block" variant="secondary">Using Localhost API</Badge>
        )}
        {useMockData && (
          <Badge className="ml-2 d-none d-xl-inline-block" variant="warning">Using Mock Data</Badge>
        )}
      </Navbar.Brand>
      {username && (
        <Nav className="mr-auto">
          <WorkOrderDropdown
            initialIdValue={0}
            initialFilterValue={''}
            formikSetFieldValue={handleWorkOrderClick}
            fieldName={''}
            staticDropdownTitle={'Search WOs'}
          />
        </Nav>
      )}
      <Navbar.Collapse id="basic-navbar-nav">
        {username && (
          <Nav className="ml-auto tk-primary-nav">
            <Button href="/work_order_overview" className="d-flex align-items-center">
              Work Orders
            </Button>

            <Button href="/work_order/shop_floor/0" className="d-flex align-items-center">
              Shop Floor
            </Button>

            {userRules.includes('Receive Parts') && (
              <HeaderReceivingButton/>
            )}
          </Nav>
        )}
      </Navbar.Collapse>
      {username && (
        <Nav className="ml-auto tk-utilities-nav">
          <HeaderTimeTracking/>

          <Button href="/scrap" className="d-flex align-items-center mx-2">
            <Trash/>
          </Button>

          <Dropdown alignRight={true} className="mr-2">
            <Dropdown.Toggle>
              <ArrowRepeat className="mx-2"/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={handleRefreshClick}>Refresh</Dropdown.Item>
                <Dropdown.Item onClick={handleSyncClick}>Sync WO's from Odoo</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown alignRight={true}>
            <Dropdown.Toggle variant="dark" className="dropdown-basic">
              <PersonCircle className="mr-2"/>
              <span className="d-none d-md-inline-block align-middle mr-2">{username}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Header className="d-inline-block d-md-none">{username}</Dropdown.Header>
              <Dropdown.Divider className="d-block d-md-none"/>
              {userRules.includes('User Administrator') && (
                <>
                <Dropdown.Item href={`/users`}>Manage Users</Dropdown.Item>
                <Dropdown.Item href={`/settings`}>App Settings</Dropdown.Item>
                </>
              )}
              {userRules.includes('MO Queue') && (
                <Dropdown.Item href={`/queue`}>MO Queue</Dropdown.Item>
              )}
              {(userRules.includes('User Administrator') || userRules.includes('MO Queue')) && (
                <Dropdown.Divider className=""/>
              )}
              <Dropdown.Item onClick={onLogoutClick}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      )}
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="ml-2" />
    </Navbar>
  </>
}

export default Header