import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useIdleTimer } from 'react-idle-timer'
import Cookies from 'universal-cookie';
import { useState as useGlobalState } from '@hookstate/core'
import globalState from '../../common/context'
import { extendSessionRequest, putUserCurrentUrl } from '../../data/api-requests';
import { sessionTimeoutMinutes } from '../../common/config';

const SessionManager = (props: any) => {
  const cookies = new Cookies()
  const globals = useGlobalState(globalState)

  // Modal open state
  const [open, setOpen] = useState(false)

  // Time before idle
  const [remaining, setRemaining] = useState(0)

  const timeout = 1000 * 60 * sessionTimeoutMinutes // idle time before the user will be prompted, then logged out.
  const promptTimeout = 1000 * 30 // 30 seconds
  const refreshTokenInterval = 1000 * 60 * 2.5

  const logOutUser = () => {
    cookies.remove('tk_auth_token', {path: '/'})
    cookies.remove('tk_auth_token_expiry', {path: '/'})
    cookies.remove('tk_user_id', {path: '/'})
    cookies.remove('tk_user_display_name', {path: '/'})
    cookies.remove('tk_user_rules', {path: '/'})
    window.location.href = '/'
  }

  const extendSession = () => {
    // Don't extend session while logging out
    if (window.location.href.includes('logout')) return false

    extendSessionRequest()
      .then((result) => {
        // Update session info
        cookies.set('tk_auth_token', result['access_token'], {path: '/'})
        cookies.set('tk_auth_token_expiry', result['expiry'], {path: '/'})
        cookies.set('tk_user_display_name', result['display_name'], {path: '/'})
        cookies.set('tk_user_rules', result['rules'], {path: '/'})
        globals.userRules.set(result['rules'])
      }).catch(() => {
        logOutUser()
      })
  }

  const onPrompt = () => {
    setOpen(true)
    setRemaining(promptTimeout)
  }

  // Log out the user when they have been idle for too long
  // also save their last visited URL
  const onIdle = () => {
    putUserCurrentUrl({
      current_url: window.location.href
    }).then(() => {
      logOutUser()
      setOpen(false)
      setRemaining(0)
    })
  }

  // When user becomes active, reset the timer
  const onActive = () => {
    setOpen(false)
    setRemaining(0)
  }

  // Called while the user is performing actions. Refreshes the session.
  const onAction = () => {
    extendSession()
  }

  const { getRemainingTime, isPrompted, reset } = useIdleTimer({
    timeout,
    promptTimeout,
    onPrompt,
    onIdle,
    onActive,
    onAction,
    eventsThrottle: refreshTokenInterval,
  })

  // Upon clicking the modal button, extend the session
  const handleStillHere = () => {
    extendSession()
    setOpen(false)
    reset()
  }

  // Update the counter when the modal is showing
  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime, isPrompted])

  return (
    <>
      <Modal show={open}>
        <Modal.Header closeButton>
          <Modal.Title>Your session is about to expire.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Logging you out in {remaining} seconds</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleStillHere}>
            I'm Still Here
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SessionManager