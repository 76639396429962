import apiRequest from "../utils/api"
import { convertTimestampsToDates } from "../utils/helpers"
import { applicablePaintMockData } from "./types/applicable-paint"
import { paintCuresMockData } from "./types/paint-curing"
import { paintUsagesMockData } from "./types/paint-usage"
import { partsMockData } from "./types/parts"
import { positectorTestsMockData } from "./types/positector"
import { shopFloorTabsMockData } from "./types/shop-floor"
import { timeEntriesMockData } from "./types/time-tracking"
import { workOrderMockData } from "./types/work-order"

export async function loginRequest(data: URLSearchParams) {
  return await apiRequest(
    {
      loginMode: true,
      method: 'post',
      url: `token`,
      data: data,
      blockUi: false,
    }
  )
}

export async function extendSessionRequest() {
  return await apiRequest(
    {
      method: 'post',
      url: `refresh_token`,
      data: {},
      blockUi: false,
    }
  )
}

export async function getStaticResources() {
  return await apiRequest(
    {
      method: 'get',
      url: `static/`,
      blockUi: false,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getUsers() {
  return await apiRequest(
    {
      method: 'get',
      url: `admin/users`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getUser(userId: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `admin/user/${userId}`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function putUser(userId: number, data: any) {
  return await apiRequest(
    {
      method: 'put',
      url: `admin/user/${userId}`,
      data: data,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function syncUsers() {
  return await apiRequest(
    {
      method: 'post',
      url: `user/sync`,
      data: {},
      blockUi: true,
    }
  )
}

export async function syncData() {
  return await apiRequest(
    {
      method: 'post',
      url: `work_order/sync`,
      timeout: 120000, // 2 minutes
      data: {},
      blockUi: false,
      errorMessage: 'Could not complete work order/applicable paint sync.'
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getMoQueue(odooWorkOrderId: number | null = null, isCompleted: boolean = false) {
  let queryParams: any = {}

  if (odooWorkOrderId) queryParams['odoo_work_order_id'] = odooWorkOrderId
  if (isCompleted) queryParams['is_completed'] = isCompleted

  return await apiRequest(
    {
      method: 'get',
      url: `work_order/mo/queue`,
      blockUi: true,
      params: queryParams
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function createMoQueue(odooWorkOrderId: number, moId: number, productId: number, qty: number, serialNumbers: string[] | null = null) {
  return await apiRequest(
    {
      method: 'post',
      url: `work_order/${odooWorkOrderId}/mo/queue`,
      data: {
        serial_numbers: serialNumbers,
        mo_id: moId,
        qty: qty,
        product_id: productId,
      },
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function cancelQueuedMo(moQueueId: number) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/mo/queue/${moQueueId}/cancel`,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function markMoQueueAsDone() {
  return await apiRequest(
    {
      method: 'post',
      url: `work_order/mo/queue/mark_as_done`,
      timeout: 1800000,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getWorkOrders(stage: string = '', searchTerm: string = '', limit: number = 9999) {
  let queryParams: any = {}

  if (stage) queryParams['stage'] = stage
  if (searchTerm) queryParams['search_term'] = searchTerm
  if (limit) queryParams['limit'] = Number(limit)

  return await apiRequest(
    {
      method: 'get',
      url: `work_order/`,
      params: queryParams,
      mockData: workOrderMockData,
      blockUi: true,
    }
  )
  .then((result) => convertTimestampsToDates(result))
  .catch((error) => console.error('request error', error))
}

export async function getWorkOrder(id: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/${id}`,
      mockData: workOrderMockData[0],
      blockUi: true,
    }
  )
  .then((result) => convertTimestampsToDates(result))
  .catch((error) => console.error('request error', error))
}

export async function getWorkOrderIdFromOdooId(id: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `/work_order/get_local_id_from_odoo_id/${id}`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getWorkCentreGroupLocation(workOrderId: number, workCenterGroupId: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/${workOrderId}/work_center_group_location/${workCenterGroupId}`,
      blockUi: false,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function toggleWorkCentreLocationAssignment(odooWorkOrderId: number, workOrderId: number, workCenterGroupId: number, databaseId: number = 0) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/${workOrderId}/work_center_group_location/${workCenterGroupId}`,
      data: {id: databaseId, odoo_work_order_id: odooWorkOrderId},
      blockUi: true,
      errorMessage: 'Could not assign work center.',
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function completeWorkOrderReview(workOrderId: number) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/${workOrderId}/review_completed`,
      blockUi: false,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function completeWorkOrder(workOrderId: number, odooWorkOrderId: number) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/${workOrderId}/completed`,
      data: {odoo_work_order_id: odooWorkOrderId},
      blockUi: false,
      errorDuration: 15000,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function completeShopFloorWorkOrder(workOrderId: number, odooWorkOrderId: number) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/${workOrderId}/shop_floor_completed`,
      data: {odoo_work_order_id: odooWorkOrderId},
      blockUi: true,
      errorDuration: 15000,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function completeWorkOrderDelivery(workOrderId: number) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/${workOrderId}/delivery_completed`,
      blockUi: false,
      errorMessage: 'Could not complete work order delivery.'
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function updateWorkCenterInstructions(workOrderId: number, workCenterId: number, instructionText: string) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/${workOrderId}/work_center/${workCenterId}/instruction`,
      data: {instruction_text: instructionText},
      blockUi: false,
      errorMessage: 'Could not update instructions for work center.'
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function updateMekTest(workOrderId: number, pass: boolean | null) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/${workOrderId}/mek_test`,
      data: {passed: pass},
      blockUi: true,
      errorMessage: 'Could not update mek test.'
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getShopFloorTabs(workCenterGroupId: number = 0, workCenterId: number = 0) {
  return await apiRequest(
    {
      method: 'get',
      url: `shop_floor/${workCenterGroupId}/tabs/${workCenterId}`,
      mockData: shopFloorTabsMockData,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getApplicablePaints(workOrderId: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/${workOrderId}/applicable_paint`,
      mockData: applicablePaintMockData,
      blockUi: false,
      errorMessage: 'Could not get applicable paints.'
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getPaintUsages(workOrderId: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/${workOrderId}/paint_usage`,
      mockData: paintUsagesMockData,
      blockUi: false,
      errorMessage: 'Could not get paint usage.'
    }
  )
}

export async function postPaintUsage(workOrderId: number, data: any) {
  return await apiRequest(
    {
      method: 'post',
      url: `work_order/${workOrderId}/paint_usage`,
      data: data,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function putPaintUsage(paintUsageId: number, data: any) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/paint_usage/${paintUsageId}`,
      data: data,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function deletePaintUsage(id: number) {
  return await apiRequest(
    {
      method: 'delete',
      url: `work_order/paint_usage/${id}`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getPaintCuring(workOrderId: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/${workOrderId}/paint_curing`,
      mockData: paintCuresMockData,
      blockUi: true,
    }
  )
  .then((result) => convertTimestampsToDates(result))
  .catch((error) => console.error('request error', error))
}

export async function postPaintCuring(workOrderId: number, data: any) {
  return await apiRequest(
    {
      method: 'post',
      url: `work_order/${workOrderId}/paint_curing`,
      data: data,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function putPaintCuring(paintCuringId: number, data: any) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/paint_curing/${paintCuringId}`,
      data: data,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getParts(workOrderId: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/${workOrderId}/parts`,
      mockData: partsMockData,
      blockUi: false,
    }
  )
  .then((result) => convertTimestampsToDates(result))
  .catch((error) => console.error('request error', error))
}

export async function deletePaintCuring(paintCuringId: number) {
  return await apiRequest(
    {
      method: 'delete',
      url: `work_order/paint_curing/${paintCuringId}`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getAttendance(userId: number, daysLimit: number = 0) {
  return await apiRequest(
    {
      method: 'get',
      url: `user/attendance`,
      params: {
        user_id: userId,
        days_limit: daysLimit,
      },
      blockUi: true,
      errorMessage: 'Could not get attendances.'
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getRunningTimeEntries() {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/time_tracking/running`,
      mockData: timeEntriesMockData,
      errorMessage: 'Could not get time entries.',
      blockUi: false,
    }
  )
  .then((result) => convertTimestampsToDates(result))
  .catch((error) => console.error('request error', error))
}

export async function getTimeEntries(
  workOrderId: number,
  workCenterId: number,
  userId: number = 0,
  daysLimit: number = 0,
) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/${workOrderId}/time_tracking`,
      params: {
        work_center_id: workCenterId,
        user_id: userId,
        days_limit: daysLimit,
      },
      mockData: timeEntriesMockData,
      blockUi: true,
      errorMessage: 'Could not get time entries.'
    }
  )
  .then((result) => convertTimestampsToDates(result))
  .catch((error) => console.error('request error', error))
}

export async function postTimeEntry(workOrderId: number, data: any) {
  return await apiRequest(
    {
      method: 'post',
      url: `work_order/${workOrderId}/time_tracking`,
      data: data,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function putTimeEntry(timeTrackingId: number, data: any) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/time_tracking/${timeTrackingId}`,
      data: data,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function deleteTimeEntry(id: number) {
  return await apiRequest(
    {
      method: 'delete',
      url: `work_order/time_tracking/${id}`,
      blockUi: true,
    }
  )
}

export async function getTitration(workOrderId: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/${workOrderId}/titration`,
      blockUi: true,
    }
  )
  .then((result) => convertTimestampsToDates(result))
  .catch((error) => console.error('request error', error))
}

export async function postTitration(workOrderId: number, data: any) {
  return await apiRequest(
    {
      method: 'post',
      url: `work_order/${workOrderId}/titration`,
      data: data,
      blockUi: true,
    }
  )
}

export async function putTitration(titrationId: number, data: any) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/titration/${titrationId}`,
      data: data,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function deleteTitration(titrationId: number) {
  return await apiRequest(
    {
      method: 'delete',
      url: `work_order/titration/${titrationId}`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function postApplicablePaintUsage(workOrderId: number, data: any) {
  return await apiRequest(
    {
      method: 'post',
      url: `work_order/${workOrderId}/applicable_paint`,
      data: data,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function deleteApplicablePaint(id: number) {
  return await apiRequest(
    {
      method: 'delete',
      url: `work_order/applicable_paint/${id}`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getDeliveries(odooWorkOrderId: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/${odooWorkOrderId}/deliveries`,
      blockUi: true,
    }
  )
  .then((result) => convertTimestampsToDates(result))
  .catch((error) => console.error('request error', error))
}

export async function postDelivery(workOrderId: number) {
  return await apiRequest(
    {
      method: 'post',
      url: `work_order/${workOrderId}/delivery`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function cancelDelivery(deliveryId: number) {
  return await apiRequest(
    {
      method: 'post',
      url: `work_order/delivery/${deliveryId}/cancel`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function deleteDelivery(deliveryId: number) {
  return await apiRequest(
    {
      method: 'delete',
      url: `work_order/delivery/${deliveryId}`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getPackingSlip(deliveryOrderId: number, odooStockPickingId: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/delivery/packing_slip/?delivery_id=${deliveryOrderId}&odoo_stock_picking_id=${odooStockPickingId}`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function printPackingSlipPdf(data: any) {
  return await apiRequest(
    {
      method: 'post',
      url: `work_order/delivery/packing_slip_pdf`,
      data: data,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function validateDelivery(deliveryOrderId: number, odooStockPickingId: number) {
  return await apiRequest(
    {
      method: 'post',
      url: `work_order/delivery/validate/?delivery_id=${deliveryOrderId}&odoo_stock_picking_id=${odooStockPickingId}`,
      blockUi: true,
      errorMessage: 'Could not validate delivery order.',
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function updateDeliveryProductQtys(deliveryOrderId: number, odooStockPickingId: number, data: any) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/delivery/lines/?delivery_id=${deliveryOrderId}&odoo_stock_picking_id=${odooStockPickingId}`,
      data: data,
      blockUi: true,
      errorMessage: 'Could not update delivery line items.',
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function updateDeliveryNote(deliveryOrderId: number, odooStockPickingId: number, deliveryOrderNote: string) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/delivery/note?delivery_id=${deliveryOrderId}&odoo_stock_picking_id=${odooStockPickingId}`,
      data: {'note': deliveryOrderNote},
      blockUi: true,
      errorMessage: 'Could not update packing slip note.',
    }
  )
}

export async function createComment(workOrderId: number, workCenterId: number, data: any) {
  return await apiRequest(
    {
      method: 'post',
      url: `work_order/${workOrderId}/work_center/${workCenterId}/comment`,
      data: data,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function updateComment(commentId: number, data: any) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/comment/${commentId}`,
      data: data,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function deleteComment(commentId: number) {
  return await apiRequest(
    {
      method: 'delete',
      url: `work_order/comment/${commentId}`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getPositector(workOrderId: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/${workOrderId}/positector`,
      mockData: positectorTestsMockData,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function postPositector(workOrderId: number, data: any) {
  return await apiRequest(
    {
      method: 'post',
      url: `work_order/${workOrderId}/positector`,
      data: data,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function putPositector(positectorId: number, data: any) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/positector/${positectorId}`,
      data: data,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function deletePositector(positectorId: number) {
  return await apiRequest(
    {
      method: 'delete',
      url: `work_order/positector/${positectorId}`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getReceivedParts(workOrderId?: number | undefined, unassigned?: boolean | undefined) {
  let params: any = {}

  if (workOrderId) params['work_order_id'] = workOrderId
  if (unassigned) params['unassigned'] = unassigned

  return await apiRequest(
    {
      method: 'get',
      url: `work_order/received_part/`,
      params: params,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function postReceivedPart() {
  return await apiRequest(
    {
      method: 'post',
      url: `work_order/received_part`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function putReceivedPart(receivedPartId: number, data: any) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/received_part/${receivedPartId}`,
      data: data,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function markMoAsDone(workOrderId: number, moId: number, qty: number, serialNumbers: Array<string> | null = null) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/${workOrderId}/mo/${moId}`,
      data: {'qty': qty, 'serial_numbers': serialNumbers},
      timeout: 180000,
    }
  )
}

export async function markMoAsNotDone(moId: number, moNotDone: boolean) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/mo_not_done/${moId}`,
      data: {'mo_not_done': moNotDone},
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function markAllMosAsDone(workOrderId: number, moIds: Array<any>) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/${workOrderId}/all_mo`,
      data: {'mo_ids': moIds},
      blockUi: true,
      timeout: 180000,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function deleteReceivedPart(receivedPartId: number) {
  return await apiRequest(
    {
      method: 'delete',
      url: `work_order/received_part/${receivedPartId}`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getMoQueueByMoId(workOrderId: number, moId: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/${workOrderId}/mo/queue/${moId}`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getMrpProductionStatus(odooMrpProductionId: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/mrp_production_status/${odooMrpProductionId}`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getSerialNumberStatus(serialNumbers: Array<string>) {
  return await apiRequest(
    {
      method: 'post',
      url: `work_order/serial_number_status`,
      data: {
        serial_numbers: serialNumbers,
      },
    }
  )
}

export async function getAttachment(attachmentId: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/attachment/${attachmentId}`,
      responseType: 'blob',
      silenceError: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function postAttachment(fileData: string, fileName: string, fileExtension: string, workOrderId: number = 0, receivedPartId: number = 0) {
  return await apiRequest(
    {
      method: 'post',
      url: `work_order/attachment/`,
      data: {
        "work_order_id": workOrderId,
        "received_part_id": receivedPartId,
        "file_data": fileData,
        "file_name": fileName,
        "file_extension": fileExtension,
      },
      blockUi: false,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getProductDrawings(productId: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/product_drawings/product/${productId}`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function deleteAttachment(attachmentId: number) {
  return await apiRequest(
    {
      method: 'delete',
      url: `work_order/attachment/${attachmentId}`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getProductDrawing(documentId: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/product_drawing/${documentId}`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getReceivedPartsCounts() {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/received_parts_counts/`,
      blockUi: false,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getPaintSpecifications(productId: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/paint_specifications/product/${productId}`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getPrepTasks(workOrderId: number, workCenterId: number) {
  return await apiRequest(
    {
      method: 'get',
      url: `work_order/${workOrderId}/work_center/${workCenterId}/prep_task`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function putPrepTask(data: any) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/prep_task`,
      data: data,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getUserCurrentUrl() {
  return await apiRequest(
    {
      method: 'get',
      url: `user/current_url`,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function putUserCurrentUrl(data: any) {
  return await apiRequest(
    {
      method: 'put',
      url: `user/current_url`,
      data: data,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function putPalletsUsed(workOrderId: number, palletCount: number) {
  return await apiRequest(
    {
      method: 'put',
      url: `work_order/${workOrderId}/pallet_count`,
      blockUi: true,
      data: {
        pallet_count: palletCount,
      },
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getScrap() {
  return await apiRequest(
    {
      method: 'get',
      url: `scrap/`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getScrapProducts(searchTerm: string) {
  let queryParams: any = {}

  if (searchTerm) queryParams['search_term'] = searchTerm

  return await apiRequest(
    {
      method: 'get',
      url: `scrap/products`,
      params: queryParams,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function getScrapLocations() {
  return await apiRequest(
    {
      method: 'get',
      url: `scrap/locations`,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}

export async function createScrap(data: any) {
  return await apiRequest(
    {
      method: 'post',
      url: `scrap/`,
      data: data,
      blockUi: true,
    }
  )
  .then((result) => result)
  .catch((error) => console.error('request error', error))
}
