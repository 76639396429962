import { Alert, Container } from "react-bootstrap"

const PermissionError = () => (
  <Container className="pt-3">
    <Alert variant="warning">
      <h3>Permission Error</h3>
      <p>Your user does not have permission for this page.</p>
    </Alert>
  </Container>
)

export default PermissionError