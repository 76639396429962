export interface paintUsage {
  id: number;
  work_order_id: number;
  applicable_paint_id: number;
  batch: string;
  start_weight_in_kg: number;
  end_weight_in_kg: number;
  user_name?: string;
  user_id?: number;
}

export const emptyPaintUsage: paintUsage = {
  id: 0,
  work_order_id: 0,
  applicable_paint_id: 0,
  batch: '',
  start_weight_in_kg: 0,
  end_weight_in_kg: 0,
  user_name: '',
  user_id: 0,
}

export const newPaintUsage: paintUsage = {
  id: 0,
  work_order_id: 0,
  applicable_paint_id: 0,
  batch: '',
  start_weight_in_kg: 0,
  end_weight_in_kg: 0,
}

export const paintUsagesMockData: paintUsage[] = [
  {
    id: 1,
    work_order_id: 1,
    applicable_paint_id: 3,
    batch: '1233',
    start_weight_in_kg: 12.2,
    end_weight_in_kg: 0.0,
    user_name: 'Peter Smith',
    user_id: 1,
  },
  {
    id: 2,
    work_order_id: 1,
    applicable_paint_id: 2,
    batch: '1234',
    start_weight_in_kg: 20.0,
    end_weight_in_kg: 18.3,
    user_name: 'Peter Smith',
    user_id: 1,
  },
]
