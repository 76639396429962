import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { updateMekTest } from "../../data/api-requests";
import { workOrder } from "../../data/types/work-order";

interface WorkOrderMekTestProps {
  workOrder: workOrder;
}

const WorkOrderMekTest = (props: WorkOrderMekTestProps) => {
  const [mekTestPass, setMekTestPass] = useState<boolean|null>(null)

  const onChangeMekTestPass = (pass: boolean | null) => {
    updateMekTest(props.workOrder.id, pass)
      .then(() => {
        setMekTestPass(pass)
        props.workOrder.mek_test_passed = pass
      })
  }

  useEffect(() => {
    if (props.workOrder && props.workOrder.id > 0) {
      setMekTestPass(props.workOrder.mek_test_passed)
    }
  }, [props.workOrder])

  return (
    <div className="work-order-section overflow-unset work-order-mek-test">
      <h2>MEK Test</h2>

      <h3 className="my-0 mr-3 d-inline-block">Pass</h3>
      <Dropdown className="mr-2 d-inline-block">
        <Dropdown.Toggle className="work-order-mek-test-btn">
          {mekTestPass ? (
            <>Yes</>
          ) : (
            mekTestPass === null ? (<>-</>) : (<>No</>)
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="work-order-mek-test-dropdown">
          <Dropdown.Item onClick={() => onChangeMekTestPass(null)} className="text-center">-</Dropdown.Item>
          <Dropdown.Item onClick={() => onChangeMekTestPass(true)} >Yes</Dropdown.Item>
          <Dropdown.Item onClick={() => onChangeMekTestPass(false)} >No</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default WorkOrderMekTest