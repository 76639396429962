import * as Yup from 'yup';
import { formField } from '../modal/modal-item';
import { formFieldTypes } from '../../../data/types/form-field-type';
import { floatNumTest } from './custom-validators';

// Form Fields
export const scrapFormFields: formField[][] = [
  [
    {
      name: 'product_id',
      label: 'Product',
      type: formFieldTypes.scrapProduct,
    },
    {
      name: 'location_id',
      label: 'Location',
      type: formFieldTypes.scrapLocation,
    },
    {
      name: 'scrap_qty',
      label: 'Quantity',
      type: formFieldTypes.number,
    },
  ]
]

// Validation Rules
export const scrapFormSchema = Yup.object().shape({
    product_id: Yup.number().required('Please select a product.').test('Is positive?', 'Please select a product.', (value) => value === undefined || value > 0),
    location_id: Yup.number().required('Please select a location.').test('Is positive?','Please select a location.', (value) => value === undefined || value > 0),
    scrap_qty: floatNumTest.positive('Please enter a quantity greater than 0.').required('Required'),
})
