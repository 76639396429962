export interface timeEntry {
  id: number;
  active_prep_task_types: number[];
  description?: string;
  end_time?: Date | undefined;
  is_billable: boolean;
  odoo_work_order_id?: number;
  odoo_work_order_name?: string;
  quantity?: number;
  start_time: Date;
  type_id?: number;
  type_name?: string;
  user_display_name?: string;
  user_id: number;
  work_center_name?: string;
  work_center_id?: number;
  work_center_group_name?: string;
  work_center_group_id?: number;
  work_order_id?: number;
}

export interface attendance {
  id: number;
  employee_id: number;
  check_in: false | string;
  check_out: false | string;
  tk_lunch_in: false | string;
  tk_lunch_out: false | string;
  worked_hours: number;
}

export interface putTimeEntryType {
  id: number;
  active_prep_task_types?: number[];
  description?: string;
  end_time?: Date | undefined;
  is_billable?: boolean;
  odoo_work_order_id?: number;
  odoo_work_order_name?: string;
  quantity?: number;
  start_time?: Date;
  type_id?: number;
  type_name?: string;
  user_display_name?: string;
  user_id?: number;
  work_center_name?: string;
  work_center_id?: number;
  work_center_group_name?: string;
  work_center_group_id?: number;
  work_order_id?: number;
}

export interface timeEntriesGroupedByDate {
  date: string;
  total_minutes: number;
  total_attendance_minutes: number;
  rows: timeEntry[];
}

export interface timeEntriesGroupedByWorkCenter {
  work_center_id: number;
  work_center_name: string;
  total_minutes: number;
  rows_grouped_by_date: timeEntriesGroupedByDate[];
}

export const emptyTimeEntry: timeEntry = {
  id: 0,
  active_prep_task_types: [],
  end_time: undefined,
  is_billable: true,
  quantity: 0,
  start_time: new Date(),
  user_display_name: '',
  user_id: 0,
  work_center_id: 0,
  work_center_group_id: 0,
  work_order_id: 0,
}

export const newTimeEntry: timeEntry = {
  id: 0,
  active_prep_task_types: [],
  end_time: undefined,
  is_billable: true,
  quantity: undefined,
  start_time: new Date(),
  type_id: undefined,
  user_id: 0,
}

export const newNonBillableTimeEntry: timeEntry = {
  id: 0,
  active_prep_task_types: [],
  end_time: undefined,
  is_billable: true,
  quantity: undefined,
  start_time: new Date(),
  type_id: undefined,
  user_id: 0,
  work_order_id: undefined,
  work_center_group_id: undefined,
  work_center_id: undefined,
}

export const timeEntriesMockData: timeEntry[] = [
  {
    id: 1,
    active_prep_task_types: [],
    end_time: new Date('2022-06-22 13:24:01.000000'),
    is_billable: true,
    quantity: 23,
    start_time: new Date('2022-06-22 13:10:23.000000'),
    user_display_name: 'Mark Goerzen',
    user_id: 1,
    work_order_id: 1,
    work_center_id: 1,
    work_center_group_id: 1,
  },
]
