import { Demodal } from "demodal";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { ListGroup, Button, OverlayTrigger, Tooltip, Badge, Spinner } from "react-bootstrap";
import { CheckCircle, DashCircle, Hourglass, XCircle } from "react-bootstrap-icons";
import { useState as useGlobalState } from '@hookstate/core'
import globalState from '../../common/context'
import {
  getProductDrawings,
  getPaintSpecifications,
  createMoQueue,
  markMoQueueAsDone,
  markMoAsNotDone,
  markAllMosAsDone,
  getMoQueueByMoId,
  getMrpProductionStatus
} from "../../data/api-requests";
import { part } from "../../data/types/parts";
import createNotification from "../../utils/notification";
import ConfirmModal from "../shared/modal/modal-confirm";
import PaintSpecificationModal from "../shared/modal/modal-paint-specification";
import { triggerPartsRefresh } from "../../utils/helpers";
import { workOrder } from "../../data/types/work-order";
import ItemModal from "../shared/modal/modal-item";
import { partQtyFormFields, partQtyFormSchema } from "../shared/form/form-part-quantity";
import SerialNumberModal from "../shared/modal/modal-serial-number";
import { moQueue, mrpProduction } from "../../data/types/mo-queue";

interface WorkOrderPartsProps {
  workOrder: workOrder;
  parts: part[];
  showCompletionStatus?: boolean;
  showMarkAsDoneButton?: boolean;
}

const WorkOrderParts = (props: WorkOrderPartsProps) => {
  const [parts, setParts] = useState<part[]>([])
  const [disableMarkAsDoneButtons, setDisableMarkAsDoneButtons] = useState<number[]>([])
  const globals = useGlobalState(globalState)
  const pendingRequestNames = globals.pendingRequestNames.get()

  useEffect(() => {
    setParts([])
    if (props.parts) {
      setParts(props.parts)
      setDisableMarkAsDoneButtons([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.parts])

  const onPaintSpecificationsClick = async (part: part) => {
    const paintSpecification = await getPaintSpecifications(part.id)
    const productDrawings = await getProductDrawings(part.id)

    await Demodal.open(PaintSpecificationModal, {
      part: part,
      paintSpecifications: paintSpecification,
      productDrawings: productDrawings,
    })
  }

  const onMarkAllAsDoneClick = async (event: BaseSyntheticEvent) => {
    event.stopPropagation()

    const unfinishedMoIds = parts
      .filter((item: part) => (!item.done || item.mo_not_done))
      .map(item => {return {'id': item.mo_id, 'name': item.mo_name, 'qty': item.qty, 'product_id': item.id, 'done': item.done}})
    const finishedMoIds = parts
      .filter((item: part) => (item.done && !item.mo_not_done))
      .map(item => {return {'id': item.mo_id, 'name': item.mo_name, 'qty': item.qty, 'product_id': item.id, 'done': item.done}})

    const moNotDone = finishedMoIds.length === parts.length

    const titleExtra = moNotDone ? 'NOT' : ''
    const description = moNotDone ? 'Please confirm marking Manufacturing Order as not done.' : 'Plase confirm completion.'

    const confirmModalResult = await Demodal.open(ConfirmModal, {
      title: `Mark MOs as ${titleExtra} done?`,
      description: description,
    })

    if (confirmModalResult) {
      const moIds = moNotDone ? finishedMoIds : unfinishedMoIds

      await markAllMosAsDone(props.workOrder.odoo_work_order_id, moIds)
      .finally(() => {
        triggerPartsRefresh()
        markMoQueueAsDone()
      })
    }
  }

  const markAsNotDone = async (part: part) => {
    const titleExtra = !part.mo_not_done ? 'NOT' : ''
    const description = !part.mo_not_done ? 'Please confirm marking Manufacturing Order as not done.' : 'Plase confirm completion.'

    const confirmModalResult = await Demodal.open(ConfirmModal, {
      title: `Mark manufacturing order as ${titleExtra} done?`,
      description: description,
    })

    if (confirmModalResult) {
      setDisableMarkAsDoneButtons([...disableMarkAsDoneButtons, part.mo_id])
      const result = await markMoAsNotDone(part.mo_id, !part.mo_not_done)
      if (result) {
        createNotification('Success', `MO marked as ${titleExtra} done.`)
      }
      triggerPartsRefresh()
    }
  }

  const markSerialNumbersAsDone = async (part: part, moQueue: moQueue[]) => {
    const serialModalResult: Array<string> = await Demodal.open(SerialNumberModal, {
      qty: part.qty,
      moQueue: moQueue,
      odooWorkOrderId: props.workOrder.odoo_work_order_id,
      moId: part.mo_id,
      partId: part.id,

    })

    if (serialModalResult.length > 0) {
      triggerPartsRefresh()
      markMoQueueAsDone()
    }
  }

  const markMrpProductionAsDone = async (part: part, moQueue: moQueue[]) => {
    const moQueueQty = moQueue.reduce((a,v) => a = a + v.qty, 0)
    if (moQueueQty === part.qty) {
      const confirmModalResult = await Demodal.open(ConfirmModal, {
        title: `Mark manufacturing order as done`,
        description: `Manufacturing order is already queued or is completed.`,
        confirmLabel: 'OK',
      })

      if (confirmModalResult) {
        triggerPartsRefresh()
      }
    } else {
      let newPart = {...part}
      newPart.qty_to_produce = newPart.qty - moQueueQty
      newPart.qty = newPart.qty_to_produce
      const modalResult: part = await Demodal.open(ItemModal, {
        titleVerb: 'Add',
        itemName: 'manufacturing order to queue?',
        saveLabel: 'Confirm',
        formData: newPart,
        formFields: partQtyFormFields,
        formSchema: partQtyFormSchema,
      })

      if (modalResult) {
        createMoQueue(props.workOrder.odoo_work_order_id, part.mo_id, part.id, modalResult.qty)
        .then(() => {
          createNotification('Success', 'Manufacturing Order added to queue.')
          triggerPartsRefresh()
        })
        .catch((error) => console.error('request error', error))
        .finally(() => {
          triggerPartsRefresh()
          markMoQueueAsDone()
        })
      }
    }
  }

  const onMarkAsDoneClick = async (event: BaseSyntheticEvent, part: part) => {
    event.stopPropagation()

    if (part.done) {
      await markAsNotDone(part)
    }
    else {
      const odooMrpProductionResult: mrpProduction = await getMrpProductionStatus(part.mo_id)

      if (odooMrpProductionResult && odooMrpProductionResult.state === 'done') {
        const confirmModalResult = await Demodal.open(ConfirmModal, {
          title: `Manufacturing state changed.`,
          description: 'This manufacturing order has been completed, please refresh the page.',
          confirmLabel: 'Refresh',
          cancelLabel: 'Close',
        })

        if (confirmModalResult) {
          window.location.reload()
        }
      } else {
        const moQueueResult: moQueue[] = await getMoQueueByMoId(props.workOrder.odoo_work_order_id, part.mo_id)

        if (part.serial_required) {
          await markSerialNumbersAsDone(part, moQueueResult)
        } else {
          await markMrpProductionAsDone(part, moQueueResult)
        }
      }
    }
  }

  const partsAreLoading = (pendingRequestNames: string[]) => {
    return pendingRequestNames.some((value: string) => value.includes("/parts"))
  }

  const getPartTitle = (part: part) => part.description ? `${part.name} - ${part.description}` : part.name

  const getPickComponentStates = (part: part) => {
    let state = 'success'
    const pickComponents = part.pick_components ?? []

    if (pickComponents.length > 0) {
      const readyPickComponents = pickComponents.filter(value => value.state === 'done')
      const waitingPickComponents = pickComponents.filter(value => ['confirmed', 'assigned'].includes(value.state))

      if (readyPickComponents.length === 0) {
        state = 'danger'
      } else if (readyPickComponents.length > 0 && waitingPickComponents.length > 0) {
        state = 'waiting'
      } else {
        state = 'success'
      }
    }

    return <>
    {pickComponents.length > 0 ? (
      <OverlayTrigger
        placement={'bottom'}
        overlay={
          <Tooltip id='tooltip'>
            <div>
              <span className="text-nowrap">
                {state === 'danger' ? (
                  'Parts not ready'
                ) : state === 'waiting' ? (
                  'Parts on Backorder'
                ) : (
                  'Ready to manufacture'
                )}
              </span>
            </div>
          </Tooltip>
        }
      >
        <span className={`dot ${state} mr-2 mb-1`}></span>
      </OverlayTrigger>
    ) : <></>}
    </>
  }

  const hasQueuedMOs = (part: part) => {
    return part.mo_queue.filter((mo: moQueue) => mo.odoo_mrp_production_id === part.mo_id && mo.odoo_product_id === part.id).length > 0
  }

  const getMarkAllAsDoneAdverb = (moId: number | null = null) => {
    const finishedMoIds = parts.filter((item: part) => (item.done && !item.mo_not_done))
    return (moId && parts.filter(item => (item.done && !item.mo_not_done) && moId === item.mo_id).length === 1) || finishedMoIds.length === parts.length ? 'NOT' : ''
  }

  const getTooltip = (part: part) => (
    props.workOrder.shop_floor_completed || (part.done && part.serial_required) ?
      <>MO Marked as done</>
    : <>
        Mark MO as {!part.serial_required && getMarkAllAsDoneAdverb(part.mo_id)} done {
          part.mo_not_done ?
            <><br/>(MO was previously marked as NOT done)</>
          : <></>
        }
    </>
  )

  return (
    <>
    {(partsAreLoading(pendingRequestNames) || parts.length > 0) && (
      <div className="work-order-section work-order-parts">
        <header className="work-order-section-header work-order-parts-header">
          <h2>Parts</h2>

          {partsAreLoading(pendingRequestNames) && (
            <div className="mt-1 ml-2 mr-auto">
              <Spinner animation="border" variant="info" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}

          {(
            parts.length > 0
            && !props.workOrder.shop_floor_completed
            && props.showMarkAsDoneButton
            && (parts.filter((item: part) => item.serial_required).length === 0)
          ) && (
            <Button onClick={onMarkAllAsDoneClick}>Mark all MOs as {getMarkAllAsDoneAdverb()} done</Button>
          )}
        </header>

        <ListGroup>
          {parts.map((part: part) => (
            <ListGroup.Item key={part.mo_id} className={"work-order-part" + (part.done && !part.mo_not_done ? ' completed' : '')} onClick={() => onPaintSpecificationsClick(part)}>
              <div className="part-info-section">
                <h3 className="part-info-name text-truncate" title={getPartTitle(part)}>
                  {getPickComponentStates(part)}
                  {getPartTitle(part)}
                </h3>
                <div className="d-flex mb-1">
                  <Badge variant="primary">{part.mo_name}</Badge>
                </div>
                <ul className="part-info-list">
                  <li className="part-info-item d-flex justify-content-between">
                    <div className="text-muted">{part.default_code}</div>
                    {part.serial_required && part.serial_number !== '' && part.qty === 1 && (
                      <div className="text-muted">S/N: <strong>{part.serial_number}</strong></div>
                    )}
                    <div className="text-muted">Qty: <strong>{part.qty}</strong></div>
                  </li>
                </ul>
              </div>
              {props.showCompletionStatus && (
                <div className="ml-2" title={part.done ? 'Done' : 'Waiting'}>
                  {part.done ? (
                    <CheckCircle size={45} className="m-2 text-success" />
                  ) : (
                    <DashCircle size={45} className="m-2 text-secondary" />
                  )}
                </div>
              )}
              {(part.thumbnail || props.showMarkAsDoneButton) && (
                <div className="part-button-section ml-2">
                  {part.thumbnail && (
                    <div className="tk-thumbnail-wrapper part-drawings ml-2">
                      <img className="tk-thumbnail part-thumbnail" src={`data:image/*;base64,${part.thumbnail}`} alt="" />
                    </div>
                  )}
                  {props.showMarkAsDoneButton && (
                    <OverlayTrigger delay={{ show: 500, hide: 0 }} overlay={
                      <Tooltip id="mo-button-tooltip">{getTooltip(part)}</Tooltip>}>
                      <Button
                        onClick={(event) => onMarkAsDoneClick(event, part)}
                        disabled={props.workOrder.shop_floor_completed || (part.done && part.serial_required) || disableMarkAsDoneButtons.includes(part.mo_id)}
                        variant={!part.serial_required && part.done && !part.mo_not_done ? "warning" : "success"}
                        className={part.mo_not_done ? "part-mark-as-done-button ml-2 mo-not-done" : "part-mark-as-done-button ml-2"}
                      >
                        {(!props.workOrder.shop_floor_completed && !part.serial_required && part.done && !part.mo_not_done) ? (
                          <XCircle size={45} />
                        ) : !part.done && hasQueuedMOs(part) ? (
                          <Hourglass size={45} />
                        ) : (
                          <CheckCircle size={45} />
                        )}
                      </Button>
                    </OverlayTrigger>
                  )}
                </div>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    )}
    </>
  )
}

export default WorkOrderParts
