import { useEffect, useState } from "react";
import { Alert, ListGroup } from "react-bootstrap";
import { instruction } from "../../data/types/instruction";
import { workCenter, workOrder } from "../../data/types/work-order";
import useApplicableWorkCenterIds from "../../hooks/use-applicable-work-center-ids";

interface WorkOrderInstructionsReportProps {
  workOrder: workOrder;
  workCenters: workCenter[];
}

const WorkOrderInstructions = (props: WorkOrderInstructionsReportProps) => {
  const [filteredWorkCenters, setFilteredWorkCenters] = useState<workCenter[]>([])
  const applicableWorkCenterIds = useApplicableWorkCenterIds(props.workOrder)
  const [workCenterInstructions, setWorkCenterInstructions] = useState<instruction[]>([])

  useEffect(() => {
    if (props.workOrder && props.workOrder.id > 0) {
      setWorkCenterInstructions(props.workOrder.instructions)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workOrder])

  useEffect(() => {
    if (
      props.workCenters
      && props.workOrder
      && props.workOrder.id > 0
      && workCenterInstructions.length > 0
    ) {
      // Only include work centers that have instructions
      const workCenters = [...props.workCenters].filter((workCenter: workCenter) => (
        workCenterHasInstructions(workCenter.id)
      ))

      setFilteredWorkCenters(workCenters)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicableWorkCenterIds, props.workCenters, props.workOrder, workCenterInstructions])

  const workCenterHasInstructions = (workCenterId: number) => {
    const instruction = workCenterInstructions
      .filter((instruction: instruction) => instruction.work_center_id === workCenterId)
    return instruction.length > 0
  }

  const getInstructionByWorkCenter = (workCenterId: number) => {
    const instruction = workCenterInstructions
      .filter((instruction: instruction) => instruction.work_center_id === workCenterId)
    return (instruction.length > 0) ? instruction[0].instruction_text : ''
  }

  return (
    <div className="work-order-section work-order-instructions">
      <header className="work-order-section-header work-order-instructions-header">
        <h2>Instructions</h2>
      </header>

      {filteredWorkCenters.length === 0 && (
        <Alert variant="info">No instructions set.</Alert>
      )}

      <ListGroup>
        {filteredWorkCenters.map((workCenter: workCenter) => (
          <ListGroup.Item key={workCenter.id} className="bg-light">
            <h3 className="instructions-report-title">{workCenter.work_center_name}</h3>
            <small>{getInstructionByWorkCenter(workCenter.id)}</small>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  )
}

export default WorkOrderInstructions