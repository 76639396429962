import { useEffect } from "react"
import { useState as useGlobalState } from '@hookstate/core'
import { globalRefreshTriggers } from '../../common/context'
import { Badge, Button } from "react-bootstrap"
import { getReceivedPartsCounts } from "../../data/api-requests"
import { receivedPartsCounts } from "../../data/types/received-parts"
import useGetData from "../../hooks/use-get-data"

const HeaderReceivingButton = () => {
  const refreshTriggers = useGlobalState(globalRefreshTriggers)
  const triggerCountsRefresh = refreshTriggers.receivedPartsCounts.get()

  const fetchData = async () => {
    const result = await getReceivedPartsCounts()
    if (result) return [result]
  }

  const [data, refresh] = useGetData<receivedPartsCounts>({
    autoLoad: false,
    fetchDataFn: fetchData,
  })

  useEffect(() => {
    if (triggerCountsRefresh > 0) refresh()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerCountsRefresh])

  // Delay initial load to give other API requests priority
  useEffect(() => {
    setTimeout(() => {
      refresh()
    }, 2000) // 2 seconds
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Button href="/receiving" className="header-receiving-button d-flex align-items-center">
      <span>Receiving</span>
      <Badge variant="warning" className="ml-2" title="Unassigned Parts">
        {data.length > 0 ? data[0].unassigned_count : <>&mdash;</>}
      </Badge>
      <Badge variant="secondary" className="ml-2" title="Untagged Parts">
        {data.length > 0 ? data[0].untagged_count : <>&mdash;</>}
      </Badge>
    </Button>
  )
}

export default HeaderReceivingButton
