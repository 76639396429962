import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useState as useGlobalState } from '@hookstate/core'
import { globalRefreshTriggers } from '../../common/context'
import { Badge, Button, ButtonGroup, Dropdown, Spinner } from "react-bootstrap"
import { timeEntry } from "../../data/types/time-tracking"
import useGetData from "../../hooks/use-get-data"
import { getRunningTimeEntries, postTimeEntry } from "../../data/api-requests"
import HeaderTimeTrackingRow from "./header-time-tracking-row"
import { Stopwatch } from "react-bootstrap-icons"
import { confirmDuration, onAddTimeEntry } from "./time-tracking/time-tracking-utils"
import useCheckPermission from "../../hooks/use-check-permission"
import Cookies from "universal-cookie"
import createNotification from "../../utils/notification"
import { triggerGlobalRefresh } from "../../utils/helpers"
import { timeTrackingType } from "../../data/types/time-tracking-type"
import { useDropdownHeight } from "../../utils/hooks"

const HeaderTimeTracking = () => {
  const cookies = new Cookies()
  const currentUserId = cookies.get('tk_user_id') ? Number(cookies.get('tk_user_id')) : 0
  const hasAdminPermission = useCheckPermission('WO Administrator')
  const refreshTriggers = useGlobalState(globalRefreshTriggers)
  const triggerRunningTimersRefresh = refreshTriggers.runningTimers.get()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const dropdownMaxHeight = useDropdownHeight(dropdownRef, isOpen)
  const [timeTrackingTypes, refreshTimeTrackingTypes] = useGetData<timeTrackingType>({
    autoLoad: false,
    interfaceName: 'timeTrackingType',
    sortByField: 'time_tracking_type_name'
  })

  const fetchData = async () => {
    const result = await getRunningTimeEntries()
    if (result) return result
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [runningTimeEntries, refreshTimeEntries, setData, isLoading] = useGetData<timeEntry>({
    autoLoad: false,
    fetchDataFn: fetchData,
    sortByField: 'start_time',
  })

  useEffect(() => {
    if (triggerRunningTimersRefresh > 0) refreshTimeEntries()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRunningTimersRefresh])

  // Delay initial load to give other API requests priority
  useEffect(() => {
    setTimeout(() => {
      refreshTimeEntries()
    }, 2000) // 2 seconds
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Set maximum dropdown height so it doesn't cause page scrolling
  useLayoutEffect(() => {
    if (dropdownRef.current && isOpen) {
      if (timeTrackingTypes.length === 0) refreshTimeTrackingTypes()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const onDropdownToggle = (isOpen:boolean) => setIsOpen(isOpen)

  const onAddTimeEntryClick = async (type_id: number) => {
    if (!type_id) return false
    const matchingType = timeTrackingTypes.find((type: timeTrackingType) => type.id === Number(type_id))
    if (!matchingType) return false

    let workOrderId = 0

    const modalResult = await onAddTimeEntry({
      hasAdminPermission: hasAdminPermission,
      workCenterId: 0,
      currentUserId: currentUserId,
      title: `${matchingType.time_tracking_type_name} Time Entry`,
      isBillable: false,
      timeTrackingType: matchingType,
    })

    if (modalResult) {
      if (modalResult.end_time) {
        const confirmModalResult = await confirmDuration(modalResult.start_time, modalResult.end_time)
        if (!confirmModalResult) return false
      }

      if ('id' in modalResult) modalResult['id'] = 0
      if ('work_order_id' in modalResult && modalResult['work_order_id']) {
        workOrderId = modalResult['work_order_id']
        delete modalResult['work_order_id']
      }
      const result = await postTimeEntry(workOrderId, {...modalResult, 'type_id': matchingType.id})
      if (result) {
        createNotification('Submitted', `Added time entry.`)
        triggerGlobalRefresh()
      }
    }
  }

  return (
    <Dropdown onToggle={onDropdownToggle}>
      <Dropdown.Toggle variant="primary" className="header-time-tracking-dropdown-toggle ml-2" id="header-time-tracking-menu">
        <span className="d-none d-md-block mr-1">Time Tracking</span>
        <Stopwatch className="d-md-none mr-1" />
        <Badge variant="secondary" className="d-none d-md-block" title="Number of running timers">
          {(!isLoading && runningTimeEntries.length > 0) ? runningTimeEntries.length : <>&mdash;</>}
        </Badge>
      </Dropdown.Toggle>

      <Dropdown.Menu
        ref={dropdownRef}
        className="header-time-tracking-dropdown"
        style={{overflowY: 'scroll', maxHeight: dropdownMaxHeight}}
      >
        <div className="px-3 py-2">
          {isLoading && (
            <div className="text-center p-3">
              <Spinner animation={"border"} variant="primary" />
            </div>
          )}
          {!isLoading && runningTimeEntries.map((timeEntry: timeEntry) => (
            <HeaderTimeTrackingRow key={timeEntry.id} timeEntry={timeEntry}/>
          ))}
          {(!isLoading && runningTimeEntries.length === 0) && (
            <>There are no running timers for your user.</>
          )}
        </div>
        <div className="p-2">
          <h3 className="text-center">Add Non-billable Time</h3>
          <ButtonGroup vertical className="w-100 ml-auto mb-2">
            {timeTrackingTypes.map((type: timeTrackingType) =>
              <Button
                variant='outline-primary'
                key={type.id}
                onClick={() => {onAddTimeEntryClick(type.id)}}
              >
                {type.time_tracking_type_name}
              </Button>
            )}
          </ButtonGroup>

          <Button
            href="/time_tracking"
            variant="secondary"
            className="w-100 d-flex align-items-center justify-content-center"
          >
            <span>View All Time Entries</span>
          </Button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
export default HeaderTimeTracking