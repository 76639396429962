import { BaseSyntheticEvent } from "react";
import { Form } from "react-bootstrap"
import { dropdownData } from "../../data/types/general";

interface LocationDropdownProps {
  name?: string;
  data: dropdownData[];
  currentValue: number;
  callback: Function;
}

const LocationDropdown = (props: LocationDropdownProps) => {
  const onDropdownChange = (event: BaseSyntheticEvent) => {
    props.callback(Number(event.target.value))
  }

  return (
    <div className="location-dropdown">
      <Form.Control
        className="location-dropdown-input"
        custom
        as="select"
        onChange={onDropdownChange}
        disabled={props.data.length === 0}
        value={props.currentValue}
        name={props.name ? props.name : ''}
      >
        {props.data.map((row: dropdownData) => (
          <option key={row.id} value={row.id}>{row.name}</option>
        ))}
      </Form.Control>
    </div>
  )
}

export default LocationDropdown