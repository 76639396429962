import { Demodal, useModal } from "demodal"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AttachmentImage from "../attachment-image";
import { useEffect, useState } from "react";
import { workOrderAttachment } from "../../../data/types/work-order";
import AttachmentPdf from "../attachment-pdf";

/**
 * Modal to view attachments
 */

export interface AttachmentsModalResult {
  action: string;
  id: number;
}

interface AttachmentsModalProps {
  attachments: workOrderAttachment[];
  initialAttachmentId?: number;
  showDelete: boolean;
}

const AttachmentsModal = Demodal.create(({
  attachments = [],
  initialAttachmentId = undefined,
  showDelete = false,
}: AttachmentsModalProps) => {
  const modal = useModal();
  const [currentAttachmentId, setCurrentAttachmentId] = useState<number>(initialAttachmentId ? initialAttachmentId : 0)
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  useEffect(() => {
    if (attachments.length > 0 && !initialAttachmentId) {
      setCurrentAttachmentId(attachments[0].id)
    } else if (initialAttachmentId) {
      const index = attachments.findIndex((attachment: workOrderAttachment) => attachment.id === initialAttachmentId)
      if (index > -1) setCurrentIndex(index)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goToPrev = () => {
    const newIndex = (currentIndex > 0)
      ? currentIndex - 1
      : attachments.length - 1

    setCurrentIndex(newIndex)
    setCurrentAttachmentId(attachments[newIndex].id)
  }
  const goToNext = () => {
    const newIndex = (currentIndex < attachments.length - 1)
      ? currentIndex + 1
      : 0

      setCurrentIndex(newIndex)
      setCurrentAttachmentId(attachments[newIndex].id)
  }

  const resolve = (value: any) => () => {
    modal.resolve(value)
    modal.close()
    modal.remove()
  }

  return (
    <>
      <Modal
        show={modal.isOpen}
        onHide={resolve(false)}
        className="attachment-viewer-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{attachments[currentIndex].file_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentAttachmentId > 0 && attachments.length > 0 && (attachments[currentIndex].file_name.includes('pdf') === false) && (
            <div className="attachment-viewer-image-container">
              <AttachmentImage attachmentId={currentAttachmentId} />
            </div>
          )}
          {currentAttachmentId > 0 && attachments.length > 0 && (attachments[currentIndex].file_name.includes('pdf')) && (
            <div className="attachment-viewer-pdf-container">
            <AttachmentPdf attachmentId={currentAttachmentId} />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="mr-auto" variant="secondary" onClick={resolve(false)}>Close</Button>
          {showDelete && (
            <Button className="ml-auto mr-3" variant="danger" onClick={resolve({action: 'delete', id: currentAttachmentId})}>Delete</Button>
          )}
          <div className="attachment-viewer-count text-muted mr-3">{currentIndex + 1}/{attachments.length}</div>
          <Button
            className=""
            variant="primary"
            disabled={attachments.length <= 1}
            onClick={() => { goToPrev() }}
          >&larr;</Button>
          <Button
            className=""
            variant="primary"
            disabled={attachments.length <= 1}
            onClick={() => { goToNext() }}
          >&rarr;</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
})

export default AttachmentsModal