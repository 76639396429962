import { BaseSyntheticEvent, useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap"
import { prepTaskType } from "../../../data/types/prep-task-type";
import useGetStaticData from "../../../hooks/use-get-static-data";

export interface PrepTasksFieldProps {
  fieldName: string;
  formikSetFieldValue: Function;
  initialValue: number[];
}

const PrepTasksField = (props: PrepTasksFieldProps) => {
    const [selectedPrepTasks, setSelectedPrepTasks] = useState<Array<number>>([])
    const staticResources = useGetStaticData()
    const [prepTaskTypes, setPrepTaskTypes] = useState<prepTaskType[]>([])

    useEffect(() => {
      if (staticResources && staticResources.prep_task_types) {
        setPrepTaskTypes(staticResources.prep_task_types)
      }
    }, [staticResources])

    useEffect(() => {
      if (props.initialValue) {
        setSelectedPrepTasks(props.initialValue)
      }
    }, [props.initialValue])

    const onUpdateClick = (e: BaseSyntheticEvent) => {
      const ruleId = parseInt(e.target.id, 10)
      if (e.target.checked) {
        // add it
        selectedPrepTasks.push(ruleId)
      } else {
        // remove it
        const index = selectedPrepTasks.indexOf(ruleId)
        if (index > -1) {
            selectedPrepTasks.splice(index, 1)
        }
      }
      setSelectedPrepTasks([...selectedPrepTasks])
      props.formikSetFieldValue(props.fieldName, selectedPrepTasks)
    }

    return (
      <Container className="bg-light p-3">
        {prepTaskTypes.map((type: prepTaskType) => (
          <Form.Check
            key={type.id}
            inline
            custom
            className="d-inline-block mr-4 mb-0"
            type="checkbox"
            id={String(type.id)}
            label={type.completed_task_name}
            onChange={onUpdateClick}
            checked={selectedPrepTasks.includes(type.id)}
            value={type.id}
          />
        ))}
      </Container>
    )
}

export default PrepTasksField