import * as Yup from 'yup';
import { formField } from '../modal/modal-item';
import { formFieldTypes } from '../../../data/types/form-field-type';

// Form Fields
export const applicablePaintFormFields: formField[][] = [
  [
    {
      name: 'odoo_product_product_id',
      label: 'Product',
      type: formFieldTypes.paintProduct,
    },
  ]
]

// Validation Rules
export const applicablePaintFormSchema = Yup.object().shape({
  odoo_product_product_id: Yup.number().positive('Required').required('Required'),
})
