import { useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { getWorkOrderIdFromOdooId } from "../../data/api-requests"

const WorkOrderOdooRedirect = () => {
  /**
   * Redirect to the local work order ID given an Odoo work order ID.
   */
  const history = useHistory()
  const { odooWorkOrderId } = useParams<{odooWorkOrderId: string}>()

  useEffect(() => {
    if (odooWorkOrderId) goToWorkOrder()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odooWorkOrderId])

  const goToWorkOrder = async () => {
    if (odooWorkOrderId) {
      const result = await getWorkOrderIdFromOdooId(Number(odooWorkOrderId))
      if (result) history.push(`/work_order/${String(result.id)}/review`)
    }
  }

  return (<></>)
}

export default WorkOrderOdooRedirect
