import { BaseSyntheticEvent, useState } from 'react'
import Cookies from 'universal-cookie';
import { useState as useGlobalState } from '@hookstate/core'
import globalState from '../../common/context'

import { getUserCurrentUrl, loginRequest } from '../../data/api-requests';
import createNotification from '../../utils/notification';

import { Button, Container, Form } from 'react-bootstrap';
import { ArrowReturnLeft, BackspaceFill } from 'react-bootstrap-icons';
import TriKoatingLogo from '../../assets/tri-koating-logo.svg'

const LoginForm = (props: any) => {
  const cookies = new Cookies()
  const globals = useGlobalState(globalState)
  const [pinValue, setPinValue] = useState<string>('')

  const onPinValueChange = (event: BaseSyntheticEvent) => {
    setPinValue(event.target.value)
  }

  const onNumberClick = (event: BaseSyntheticEvent) => {
    setPinValue(pinValue + event.target.innerText)
  }

  const onDeleteClick = (event: BaseSyntheticEvent) => {
    setPinValue(pinValue.slice(0, -1))
  }

  const onLoginSubmit = (event: BaseSyntheticEvent) => {
    event.preventDefault()

    // Prepare

    const target = event.target as typeof event.target & {
      pin: { value: string }
    }

    // Validate

    if (target.pin.value === '') {
      createNotification('Error', 'PIN cannot be empty.', 'error', 5000)
      return false
    }

    if (target.pin.value === '0' || isNaN(Number(target.pin.value))) {
      createNotification('Error', 'Please enter a valid PIN.', 'error', 5000)
      setPinValue('')
      return false
    }

    // Submit

    const params = new URLSearchParams()
    params.append('username', 'None')
    params.append('password', target.pin.value)

    loginRequest(params)
      .then((loginResult) => {
        // Set user data & refresh page
        cookies.set('tk_auth_token', loginResult['access_token'], {path: '/'})
        cookies.set('tk_auth_token_expiry', loginResult['expiry'], {path: '/'})
        cookies.set('tk_user_id', loginResult['user_id'], {path: '/'})
        cookies.set('tk_user_display_name', loginResult['display_name'], {path: '/'})
        cookies.set('tk_user_rules', loginResult['rules'], {path: '/'})
        globals.userRules.set(loginResult['rules'])

        // --------------------------------------
        // Redirects
        // --------------------------------------

        // If there are default shop floor work centers set, send them directly to shop floor
        if (cookies.get('tk_shop_floor_default_work_center_group_id')) {
          window.location.href = `/work_order/shop_floor/`
          return false
        }

        // Exception: if they're trying to access a specific work order via Odoo
        if (window.location.href.includes('odoo_work_order_redirect')) {
          window.location.reload()
          return false
        }

        // Go to user's last visited page, if available.
        getUserCurrentUrl().then((value) => {
          if (value && 'current_url' in value && value.current_url) {
            window.location.href = value.current_url
          } else {
            window.location.reload()
          }
        })
      })
      .catch(() => {
        setPinValue('')
      })
  }

  return (
    <Container className="login-container p-5">
      <div>
        <h1 className="mb-5"><img className="login-tri-koating-logo d-block mx-auto" src={TriKoatingLogo} alt="Tri Koating"/></h1>
        <div id="app-switcher-login">
          <Form onSubmit={onLoginSubmit} className="login-form" autoComplete="off">
            <Form.Control
              autoFocus
              id="login-pin-input"
              name="pin"
              className="mx-auto login-pin-input"
              type="password"
              placeholder="PIN"
              maxLength={8}
              autoComplete="off"
              onChange={onPinValueChange}
              value={pinValue}
            />
            <div className="login-input-numpad">
              <Button onClick={onNumberClick} variant="light" className="login-keypad-button">1</Button>
              <Button onClick={onNumberClick} variant="light" className="login-keypad-button">2</Button>
              <Button onClick={onNumberClick} variant="light" className="login-keypad-button">3</Button>
              <Button onClick={onNumberClick} variant="light" className="login-keypad-button">4</Button>
              <Button onClick={onNumberClick} variant="light" className="login-keypad-button">5</Button>
              <Button onClick={onNumberClick} variant="light" className="login-keypad-button">6</Button>
              <Button onClick={onNumberClick} variant="light" className="login-keypad-button">7</Button>
              <Button onClick={onNumberClick} variant="light" className="login-keypad-button">8</Button>
              <Button onClick={onNumberClick} variant="light" className="login-keypad-button">9</Button>
              <Button onClick={onDeleteClick} variant="danger" className="login-keypad-button text-white" data-button-type="backspace">
                <BackspaceFill />
              </Button>
              <Button onClick={onNumberClick} variant="light" className="login-keypad-button">0</Button>
              <Button
                type="submit"
                variant="primary"
                className="login-keypad-button text-white"
                data-button-type="submit">
                <ArrowReturnLeft />
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Container>
  )
}

export default LoginForm
