import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useState as useGlobalState } from '@hookstate/core'
import globalState, { globalRefreshTriggers } from '../../common/context'
import { Alert, Button, OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap"
import { getShopFloorTabs } from "../../data/api-requests"
import { shopFloorTab } from "../../data/types/shop-floor"
import Cookies from "universal-cookie"
import { workCenter, workCenterGroup, workOrder } from "../../data/types/work-order"
import useGetData from "../../hooks/use-get-data"
import { XLg, List } from "react-bootstrap-icons"
import LocationDropdownComboNav from "../shared/location-dropdown-combo-nav"

interface WorkOrderShopFloorTabsProps {
  currentWorkOrder: workOrder;
  workCenters: workCenter[];
  workCenterGroups: workCenterGroup[];
}

const WorkOrderShopFloorTabs = (props: WorkOrderShopFloorTabsProps) => {
  const globals = useGlobalState(globalState)
  const refreshTriggers = useGlobalState(globalRefreshTriggers)
  const history = useHistory()
  const cookies = new Cookies()
  const { workOrderId } = useParams<{workOrderId: string}>()
  const triggerShopFloorTabsRefresh = refreshTriggers.shopFloorTabs.get()
  const currentWorkCenterGroupId = globals.shopFloorWorkCenterGroupId.get()
  const currentWorkCenterId = globals.shopFloorWorkCenterId.get()
  const [show, setShow] = useState<boolean>(false)

  const fetchData = async () => {
    if (currentWorkCenterGroupId > 0 && currentWorkCenterId > 0) {
      const result = await getShopFloorTabs(currentWorkCenterGroupId, currentWorkCenterId)
      if (result) return result
    }
  }

  const [tabs, refresh] = useGetData<shopFloorTab>({fetchDataFn: fetchData})

  // Select the first work order in the list if no ID specified
  useEffect(() => {
    if (tabs && tabs.length > 0) {
      if (Number(workOrderId) === 0) {
        goToWorkOrder(tabs[0].id)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs])

  // Load data when work center and workcenter group IDs change
  useEffect(() => {
    refresh()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkCenterGroupId, currentWorkCenterId])

  // Watch for refresh event
  useEffect(() => {
    if (triggerShopFloorTabsRefresh) {
      refresh()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerShopFloorTabsRefresh])

  const goToWorkOrder = (workOrderId: number) => {
    cookies.set('tk_shop_floor_work_order_id', workOrderId, {path: '/'})
    history.push(`/work_order/shop_floor/${String(workOrderId)}`)
  }

  const onTabChange = (newWorkOrder: string|null) => {
    if (newWorkOrder) {
      goToWorkOrder(Number(newWorkOrder))
    }
  }

  const onToggleClick = () => setShow(!show)

  return (
    <div className={`work-order-list-tabs-component ${show ? 'is-visible' : ''}`}>
      <div className="work-order-list-tabs-header">
        <Button variant="light" className="mr-3 work-order-list-tabs-toggle" onClick={onToggleClick}>
          {show ? <XLg/> : <List/>}
        </Button>
        <h1 className="shop-floor-title d-none d-sm-block">Shop Floor</h1>
        <LocationDropdownComboNav workCenters={props.workCenters} workCenterGroups={props.workCenterGroups} />
      </div>

      <Tabs
        activeKey={workOrderId}
        onSelect={(newWorkOrder) => onTabChange(newWorkOrder)}
        variant="pills"
        className={`work-order-list-tabs flex-column mt-4 ${show ? 'is-visible' : ''}`}
      >
        {tabs.map((tab: shopFloorTab) => (
          <Tab key={tab.id} eventKey={tab.id} title={
            <div className="d-flex align-items-center">
              {tab.tracking_time ? (
                <OverlayTrigger overlay={<Tooltip id="indicator-tooltip" className="indicator-tooltip">Time is currently being tracked</Tooltip>}>
                  <div className="dot success mr-2"></div>
                </OverlayTrigger>
              ) : (
                <div className="dot mr-2"></div>
              )}
              <span>{tab.odoo_work_order_name}</span>
            </div>
          } />
        ))}
      </Tabs>
      {tabs.length === 0 && (
        <Alert variant="info" className="mt-4">No work orders found with the specified work center group/work center.</Alert>
      )}
    </div>
  )
}

export default WorkOrderShopFloorTabs