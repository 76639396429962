import { BaseSyntheticEvent, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import BootstrapTable, { ColumnDescription, SortOrder } from "react-bootstrap-table-next";
import { currentLocation, workCenterGroup, workOrder } from "../../data/types/work-order";
import CurrentLocationWidget from "./current-location-widget";
import useCheckPermission from "../../hooks/use-check-permission"

interface WorkOrderOverviewTableProps {
  tab: 'queue' | 'paint' | 'shipping' | 'closed';
  workOrders: workOrder[];
  workCenterGroups: workCenterGroup[];
  filteredWorkCenterGroups: number[];
}

const WorkOrderOverviewTable = (props: WorkOrderOverviewTableProps) => {
  const history = useHistory()
  const hasAdminPermission = useCheckPermission('WO Administrator')
  const [workOrders, setWorkOrders] = useState<workOrder[]>([])
  const [workCenterGroups, setWorkCenterGroups] = useState<workCenterGroup[]>([])
  const [tableColumns, setTableColumns] = useState<ColumnDescription<any>[]>([])
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc')

  const onWorkCenterGroupsChange = (workOrderId: number, newCurrentLocations: currentLocation[]) => {
    // Update work center groups for the specified work order ID
    const matchingWorkOrder = workOrders.find((workOrder: workOrder) => workOrder.id === workOrderId)
    if (matchingWorkOrder) {
      matchingWorkOrder.current_locations = newCurrentLocations
      setWorkOrders([...workOrders])
    }
  }

  const getWorkCenterTab = () => {
    let workCenterTab: string = ''
    switch (props.tab) {
      case 'paint':
        workCenterTab = 'paint'
        break;
      case 'shipping':
        workCenterTab = 'delivery'
        break;
      case 'closed':
        workCenterTab = 'delivery'
        break;
      default:
        workCenterTab = hasAdminPermission ? 'review' : 'blast'
        break;
    }

    return workCenterTab
  }

  const WoInfoFormatter = (cell: any, row: workOrder) => {
    return <>
      <div>{row.odoo_customer_name}</div>
      {row.odoo_description && (
        <div className="text-muted"><small>{row.odoo_description}</small></div>
      )}
      {row.odoo_colours && (
        <div><small>{row.odoo_colours.join(', ')}</small></div>
      )}
    </>
  }

  const CurrentLocationFormatter = (cell: any, row: workOrder) => {
    return <div className="d-flex justify-content-end">
      <CurrentLocationWidget workOrder={row} workCenterGroups={props.workCenterGroups} onWorkCenterGroupsChange={onWorkCenterGroupsChange} />
    </div>
  }

  const ArrayOfStringsFormatter = (cell: any, row: workOrder) => {
    return cell.join(', ')
  }

  const reviewColumns = [
    {
      dataField: 'odoo_work_order_name',
      text: 'WO #',
      sort: true,
    },
    {
      dataField: 'none',
      text: 'Info',
      formatter: WoInfoFormatter,
      headerClasses: 'd-table-cell d-md-none',
      classes: 'd-table-cell d-md-none',
    },
    {
      dataField: 'odoo_customer_name',
      text: 'Customer',
      headerClasses: 'd-none d-md-table-cell',
      classes: 'd-none d-md-table-cell',
    },
    {
      dataField: 'odoo_description',
      text: 'Description',
      headerClasses: 'd-none d-md-table-cell',
      classes: 'd-none d-md-table-cell',
    },
    {
      dataField: 'odoo_colours',
      text: 'Colour',
      formatter: ArrayOfStringsFormatter,
      headerClasses: 'd-none d-md-table-cell',
      classes: 'd-none d-md-table-cell',
    },
  ]

  const paintColumns = [
    {
      dataField: 'odoo_work_order_name',
      text: 'WO #',
      sort: true,
    },
    {
      dataField: 'none',
      text: 'Info',
      formatter: WoInfoFormatter,
      headerClasses: 'd-table-cell d-md-none',
      classes: 'd-table-cell d-md-none',
    },
    {
      dataField: 'odoo_customer_name',
      text: 'Customer',
      headerClasses: 'd-none d-md-table-cell',
      classes: 'd-none d-md-table-cell',
    },
    {
      dataField: 'odoo_description',
      text: 'Description',
      headerClasses: 'd-none d-md-table-cell',
      classes: 'd-none d-md-table-cell',
    },
    {
      dataField: 'odoo_colours',
      text: 'Colour',
      headerClasses: 'd-none d-md-table-cell',
      classes: 'd-none d-md-table-cell',
      formatter: ArrayOfStringsFormatter,
    },
    {
      dataField: 'none',
      text: 'Current Location',
      headerClasses: 'header-current-location',
      formatter: CurrentLocationFormatter,
    }
  ]

  // Tab Change
  useEffect(() => {
    switch (props.tab) {
      case 'paint':
        setTableColumns(paintColumns)
        break;
      case 'shipping':
        setTableColumns(paintColumns)
        break;
      case 'closed':
        setTableColumns(paintColumns)
        break;
      default:
        setTableColumns(reviewColumns)
        break;
    }

    setSortOrder(props.tab === 'closed' ? 'desc' : 'asc')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tab])

  // Work Orders/Filters Change
  useEffect(() => {
    let filteredWorkOrders: workOrder[] = []

    if (props.workOrders && props.workOrders.length > 0) {
      filteredWorkOrders = [...props.workOrders]

      if (
        props.tab === 'paint'
        && props.filteredWorkCenterGroups && props.filteredWorkCenterGroups.length > 0
      ) {
        filteredWorkOrders = filteredWorkOrders.filter((workOrder: workOrder) => workOrder.current_locations
          .filter((location: currentLocation) => location.is_completed === false)
          .map((location: currentLocation) => location.work_center_group_id)
          .some((id: number) => props.filteredWorkCenterGroups.includes(id))
        )
      }

      setWorkOrders(filteredWorkOrders)
    }
  }, [props.workOrders, props.filteredWorkCenterGroups, props.tab])

  // Work Center Groups Change
  useEffect(() => {
    if (props.workCenterGroups && props.workCenterGroups.length > 0) {
      setWorkCenterGroups([...props.workCenterGroups])
    }
  }, [props.workCenterGroups])

  const rowEvents = {
    onClick: (e: BaseSyntheticEvent, row: workOrder, rowIndex: number) => {
      const workCenterTab: string = getWorkCenterTab()
      history.push(`/work_order/${row.id}/${workCenterTab}`)
    }
  }

  return (
    <Container fluid className="w-100 p-3 work-order-overview-table-container">
      {tableColumns.length > 0 && workCenterGroups.length > 0 && workOrders.length > 0 && (
        <>
          <BootstrapTable
            keyField="id"
            data={ workOrders }
            columns={ tableColumns }
            sort={{ dataField: 'odoo_work_order_name', order: sortOrder }}
            hover
            rowEvents={ rowEvents }
            bootstrap4
          />
        </>
      )}
    </Container>
  )
}

export default WorkOrderOverviewTable