import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Badge, Dropdown, Form, Nav, Spinner } from "react-bootstrap";
import { paintProduct } from "../../../data/types/paint-product";
import { debounce } from "lodash";
import { getScrapProducts } from "../../../data/api-requests";
import { useDropdownHeight } from "../../../utils/hooks";


interface ScrapProductDropdownProps {
  initialIdValue: number;
  formikSetFieldValue: Function;
  fieldName: string;
}

const ScrapProductDropdown = (props: ScrapProductDropdownProps) => {
  const [data, setData] = useState<paintProduct[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataFiltered, setDataFiltered] = useState<any>([])
  const [filterValue, setFilterValue] = useState<string>('')
  const [currentValue, setCurrentValue] = useState<paintProduct>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownMaxHeight = useDropdownHeight(dropdownRef, isOpen)

  const sortProducts = (productData: paintProduct[], filterStr: string) => {
    return productData.sort((a: paintProduct, b: paintProduct) =>
      String(a.default_code).toLowerCase().includes(filterStr.toLowerCase()) ? -1 : 0
    )
  }

  const debouncedSearch = useRef(
    debounce(async (searchTerm) => {
      let result = []
      if (searchTerm.length > 2) {
        setIsLoading(true)
        result = await getScrapProducts(searchTerm)
        setIsLoading(false)
      }
      setData(result)
    }, 500)
  ).current

  const onFilterInputChange = (event: any) => {
    const fieldValue = event.target.value
    const newValue = fieldValue.replace(/([^a-zA-Z0-9/" ]+)/gi, '')
    setFilterValue(newValue)
  }

  // Focus on filter input when opening dropdown
  useLayoutEffect(() => {
    if (isOpen) {
      inputRef.current!.focus()
    }
  }, [isOpen])

  // Set selected product
  useEffect(() => {
    const initialValue = data.find((item: paintProduct) => item.id === props.initialIdValue)
    if (initialValue) setCurrentValue(initialValue)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() =>  {
    filterValue ? debouncedSearch(filterValue) : setData([])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue])

  useEffect(() =>  {
    let productsData = [...data]
    if (filterValue) {
      productsData = sortProducts(productsData, filterValue)
    }
    setDataFiltered([...productsData])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // Cancel the debounced function when component is unmounted
  useEffect(() => (
    () => { debouncedSearch.cancel() }
  ), [debouncedSearch])

  const onDropdownToggle = (isOpen:boolean) => {
    setIsOpen(isOpen)
  }

  const onSelectItem = (value: string | null) => {
    if (typeof value === 'string') {
      const newId = Number(value)
      const matchingRow = data.find((paint: paintProduct) => paint.id === newId)
      if (matchingRow) {
        setCurrentValue(matchingRow)
        props.formikSetFieldValue(props.fieldName, matchingRow.id)
      }
      setFilterValue('')
    }
  }

  const getNoun = () => (dataFiltered.length === 1) ? 'result' : 'results'

  return (
    <>
      <Dropdown className="mb-3" onToggle={onDropdownToggle}>
        <Dropdown.Toggle variant="primary" className="applicable-paints-dropdown-toggle" id="paint-products-menu">
          {currentValue ? (
            <>
              {currentValue.default_code && (
                <>[{currentValue.default_code}]</>
              )}
              {" "}
              {currentValue.name}
            </>
          ) : 'Select...'}
        </Dropdown.Toggle>

        <Dropdown.Menu ref={dropdownRef} className="tk-dropdown-field scrap-dropdown" style={{overflowY: 'scroll', maxHeight: dropdownMaxHeight, overflowX: 'hidden', maxWidth: '466px'}}>
          <div className="">
            <div className="tk-dropdown-field__header">
              <Form.Control
                ref={inputRef}
                type="text"
                value={filterValue}
                onChange={onFilterInputChange}
                placeholder="Filter..."
              />
              {((!isLoading && filterValue && filterValue.length > 2) || data.length > 0) && (
                <div className="tk-dropdown-field__status-bar px-3">
                  <strong>{dataFiltered.length}</strong>{" "}{getNoun()} found.
                </div>
              )}
              {(isLoading) && (
                <div className="text-center my-3">
                  <Spinner animation="border" variant="info" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )}
            </div>
            <Nav
              className="flex-column tk-dropdown-field__menu-items"
              onSelect={onSelectItem}
            >
              {dataFiltered.map((paint: paintProduct) => (
                <Nav.Link key={paint.id} eventKey={paint.id}>
                  <div className="tk-dropdown-field-title text-truncate">
                    {paint.default_code && (
                      <>[{paint.default_code}]</>
                    )}
                    {" "}
                    {paint.name}
                  </div>
                  <div className="tk-dropdown-field__meta">
                    <div><Badge variant="info">{paint.categ_id.name}</Badge></div>
                    <div className="ml-auto">Vendor: {paint.tk_vendor_name}</div>
                  </div>
                </Nav.Link>
              ))}
            </Nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <Form.Label className="">Unit of Measure</Form.Label>
      <div className="form-control">{currentValue?.uom_id.name}</div>
    </>
  )
}

export default ScrapProductDropdown
