import { Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';
import { Demodal, useModal } from "demodal"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DateTimeField from '../field/field-datetime';
import ApplicablePaintDropdown from '../field/field-applicable-paint';
import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import UserField from '../field/field-user';
import PaintProductDropdown from '../field/field-paint-product';
import RulesField from '../field/field-rules';
import NumberField from '../field/field-number';
import SwitchField from '../field/field-switch';
import AttachmentsField from '../field/field-attachments';
import WorkOrderDropdown from '../field/field-work-order';
import PrepTasksField from '../field/field-prep-tasks';
import DateTimeRangeField from '../field/field-datetime-range';
import LocationDropdownComboField from '../field/field-location-combo';
import ScrapProductDropdown from '../field/field-scrap-product';
import ScrapLocationDropdown from '../field/field-scrap-location';
import { formFieldTypes } from '../../../data/types/form-field-type';

export interface formField {
  name: string; // must match the object key in formData
  label: string;
  type: formFieldTypes;
  readonly?: boolean;
}

/**
 * Modal to add/edit items
 */

interface modalConfirmProps {
  itemName: string;
  titleVerb: string;
  saveLabel: string;
  formData: any;
  formFields: formField[][];
  formSchema: Yup.ObjectSchema<any>;
  canDelete: boolean;
  onFormSubmitCallback: Function | false;
}

const ItemModal = Demodal.create(({
  itemName = 'Confirmation',
  titleVerb = '',
  saveLabel = '',
  formData = {},
  formFields = [],
  formSchema,
  canDelete = false,
  onFormSubmitCallback = false,
}: modalConfirmProps) => {
  const modal = useModal();

  const resolve = (value: any) => () => {
    modal.resolve(value)
    modal.close()
    modal.remove()
  }

  const modalTitleVerb = !titleVerb ? (formData.id > 0 ? 'Edit' : 'Add') : titleVerb
  const saveButtonLabel = !saveLabel ? (formData.id > 0 ? 'Save' : 'Submit') : saveLabel

  const onFormSubmit = async (values: any) => {
    let result = undefined

    if (onFormSubmitCallback) {
      const castedValues = formSchema.cast(values, {stripUnknown: true})
      result = await onFormSubmitCallback(castedValues)
    }

    if (!onFormSubmitCallback || result) {
      const castedValues = formSchema.cast(values)
      modal.resolve(castedValues)
      modal.close()
      modal.remove()
    }
  }

  return (
    <>
      <Modal
        show={modal.isOpen}
        onHide={resolve(false)}
        backdrop='static'
        className={`item-modal num-cols-${formFields.length}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitleVerb} {itemName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Formik
          initialValues={formData}
          validationSchema={formSchema}
          onSubmit={values => onFormSubmit(values)}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <FormikForm id="modal-form">
              <Container className="p-0">
                  <Row>
                    {formFields.map((fieldGroup: formField[], index: number) => (
                      <Col key={index}>
                        {fieldGroup.map((field: formField) => (
                          <Form.Group key={field.name} controlId={field.name}>
                            {(
                              field.label !== '' && field.type !== formFieldTypes.switch
                            ) && (
                              <Form.Label>{field.label}</Form.Label>
                            )}

                            {field.type === formFieldTypes.text && (
                              <Field
                                name={field.name}
                                id={field.name}
                                value={values[field.name] ?? ""}
                                className="form-control"
                                disabled={field.readonly}
                              />
                            )}

                            {field.type === formFieldTypes.number &&  (
                              <NumberField
                                formikSetFieldValue={setFieldValue}
                                fieldName={field.name}
                                initialValue={values[field.name] ?? ""}
                                readonly={field.readonly}
                              />
                            )}

                            {field.type === formFieldTypes.textarea && (
                              <Field
                                name={field.name}
                                id={field.name}
                                as={'textarea'}
                                rows={3}
                                value={values[field.name] ?? ""}
                                className="form-control"
                                disabled={field.readonly}
                              />
                            )}

                            {field.type === formFieldTypes.switch && (
                              <SwitchField
                                formikSetFieldValue={setFieldValue}
                                fieldName={field.name}
                                fieldLabel={field.label}
                                initialValue={formData[field.name]}
                                readonly={field.readonly}
                              />
                            )}

                            {field.type === formFieldTypes.datetime && (
                              <div>
                                <DateTimeField
                                  formikSetFieldValue={setFieldValue}
                                  fieldName={field.name}
                                  initialValue={formData[field.name]}
                                />
                              </div>
                            )}

                            {field.type === formFieldTypes.datetimeRange && (
                              <div>
                                <DateTimeRangeField
                                  formikSetFieldValue={setFieldValue}
                                  fieldName={field.name}
                                  initialStartDateValue={formData['start_time']}
                                  initialEndDateValue={formData['end_time']}
                                />
                              </div>
                            )}

                            {field.type === formFieldTypes.user && (
                              <div>
                                <UserField
                                  formikSetFieldValue={setFieldValue}
                                  fieldName={field.name}
                                  initialValue={formData[field.name]}
                                />
                              </div>
                            )}

                            {field.type === formFieldTypes.rule && (
                              <div>
                                <RulesField
                                user={formData}
                                fieldName={field.name}
                                formikSetFieldValue={setFieldValue}
                                />
                              </div>
                            )}

                            {field.type === formFieldTypes.attachments && (
                              <div>
                                <AttachmentsField
                                  receivedPartId={formData.id}
                                  initialValue={formData[field.name]}
                                  formikSetFieldValue={setFieldValue}
                                  fieldName={field.name}
                                />
                              </div>
                            )}

                            {field.type === formFieldTypes.applicablePaint && (
                              <div>
                                <ApplicablePaintDropdown
                                  workOrderId={formData.work_order_id}
                                  initialIdValue={formData.applicable_paint_id}
                                  formikSetFieldValue={setFieldValue}
                                  fieldName={field.name}
                                />
                              </div>
                            )}

                            {field.type === formFieldTypes.paintProduct && (
                              <div>
                                <PaintProductDropdown
                                  initialIdValue={formData.odoo_product_product_id}
                                  formikSetFieldValue={setFieldValue}
                                  fieldName={field.name}
                                />
                              </div>
                            )}

                            {field.type === formFieldTypes.prepTasks && (
                              <div>
                                <PrepTasksField
                                  initialValue={formData[field.name]}
                                  formikSetFieldValue={setFieldValue}
                                  fieldName={field.name}
                                />
                              </div>
                            )}

                            {field.type === formFieldTypes.workOrder && (
                              <div>
                                <WorkOrderDropdown
                                  initialIdValue={formData[field.name]}
                                  initialWorkOrderNameValue={formData.odoo_work_order_name}
                                  initialFilterValue={formData.customer_po_number}
                                  formikSetFieldValue={setFieldValue}
                                  fieldName={field.name}
                                />
                              </div>
                            )}

                            {field.type === formFieldTypes.locationCombo && (
                              <div>
                                <LocationDropdownComboField
                                  initialWorkCenterGroupIdValue={formData.work_center_group_id}
                                  initialWorkCenterIdValue={formData.work_center_id}
                                  formikSetFieldValue={setFieldValue}
                                />
                              </div>
                            )}

                            {field.type === formFieldTypes.scrapProduct && (
                              <div>
                                <ScrapProductDropdown
                                  initialIdValue={0}
                                  formikSetFieldValue={setFieldValue}
                                  fieldName={field.name}
                                />
                              </div>
                            )}

                            {field.type === formFieldTypes.scrapLocation && (
                              <div>
                                <ScrapLocationDropdown
                                  initialIdValue={0}
                                  formikSetFieldValue={setFieldValue}
                                  fieldName={field.name}
                                />
                              </div>
                            )}

                            {field.type === formFieldTypes.description && (
                              <div>{formData[field.name]}</div>
                            )}

                            {field.type === formFieldTypes.info && (
                              <Alert variant="info">{formData[field.name]}</Alert>
                            )}

                            {errors[field.name] && touched[field.name] ? (<div className="text-danger">{errors[field.name]}</div>) : null}
                          </Form.Group>
                        ))}
                      </Col>
                    ))}
                  </Row>
              </Container>
            </FormikForm>
          )}
        </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button className="mr-auto" variant="secondary" onClick={resolve(false)}>Close</Button>
          <div>
            {formData.id > 0 && canDelete && (
              <Button className="mr-2" variant="danger" onClick={resolve('delete')}>Delete</Button>
            )}
            <Button variant="primary" type="submit" form="modal-form">{saveButtonLabel}</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
})

export default ItemModal