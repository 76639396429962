import { useParams } from "react-router-dom";
import { getWorkOrders } from "../../data/api-requests";
import { workCenterGroup, workOrder } from "../../data/types/work-order";
import WorkOrderOverviewTable from "./work-order-overview-table";
import WorkOrderOverviewTabs from "./work-order-overview-tabs";
import useGetData from "../../hooks/use-get-data";
import { Container, Row, Col } from "react-bootstrap";
import WorkOrderOverviewFilters from "./work-order-overview-filters";
import { useEffect, useState } from "react";
import useGetStaticData from "../../hooks/use-get-static-data";

const WorkOrderOverview = () => {
  const { tab } = useParams<{tab: any}>()
  const [filteredWorkCenterGroups, setFilteredWorkCenterGroups] = useState<number[]>([])
  const [workCenterGroups, setWorkCenterGroups] = useState<workCenterGroup[]>([])
  const staticResources = useGetStaticData()

  const fetchData = async () => {
    const limit = tab === 'closed' ? 100 : 300
    const result = await getWorkOrders(tab, '', limit)
    if (result) return result
  }

  const [workOrders, refreshWorkOrders] = useGetData<workOrder>({fetchDataFn: fetchData, autoLoad: false})

  useEffect(() => {
    refreshWorkOrders()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  // Set WCG when available
  useEffect(() => {
    if (staticResources && staticResources.work_center_groups) {
      setWorkCenterGroups(staticResources.work_center_groups)
    }
  }, [staticResources])

  const onFilteredWorkCenterGroupsChange = (workCenterGroupIds: number[]) => {
    setFilteredWorkCenterGroups(workCenterGroupIds)
  }

  return (
    <>
      <Container fluid className="w-100 py-3">
        <Row>
          <Col>
            <WorkOrderOverviewTabs />
          </Col>
          {tab === 'paint' && (
            <Col className="d-flex align-items-center justify-content-end">
              <WorkOrderOverviewFilters
                workCenterGroups={workCenterGroups}
                initialValue={filteredWorkCenterGroups}
                onFilteredWorkCenterGroupsChange={onFilteredWorkCenterGroupsChange}
              />
            </Col>
          )}
        </Row>
      </Container>

      {workOrders.length > 0 && workCenterGroups.length > 0 && (
        <WorkOrderOverviewTable
          tab={tab}
          workOrders={workOrders}
          workCenterGroups={workCenterGroups}
          filteredWorkCenterGroups={filteredWorkCenterGroups}
        />
      )}
    </>
  )
}

export default WorkOrderOverview