import { Demodal } from "demodal"
import { BaseSyntheticEvent, useEffect, useState } from "react"
import { Button, Container, Form } from "react-bootstrap"
import { ArrowRepeat, Check, Dash } from "react-bootstrap-icons"
import BootstrapTable from "react-bootstrap-table-next"
import { userAdministrationRuleId } from "../../common/config"
import { getUsers, putUser, syncUsers } from "../../data/api-requests"
import { user } from "../../data/types/user"
import useGetData from "../../hooks/use-get-data"
import createNotification from "../../utils/notification"
import { putUserFormSchema, userFormFields } from "../shared/form/form-users"
import ItemModal from "../shared/modal/modal-item"

const Users = () => {
    const [userDataFiltered, setUserDataFiltered] = useState<user[]>([])
    const [showInactiveUsers, setShowInactiveUsers] = useState<boolean>(false)

    const fetchData = async () => {
        const result = await getUsers()
        if (result) return result
    }

    const [userData, refresh] = useGetData<user>({fetchDataFn: fetchData})

    useEffect(() => {
        const filtered = getFilteredUsers(userData)
        setUserDataFiltered([...filtered])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, showInactiveUsers])

    const getFilteredUsers = (users: user[]) => {
        const filtered = users.filter((user: user) => {
            if (showInactiveUsers) {
                return true
            } else {
                if (user.is_active) {
                    return true
                } else {
                    return false
                }
            }
        })
        return filtered
    }

    const onSyncUsersClick = () => {
        syncUsers().then(() => {
            createNotification('User sync', 'Sync completed.')
            refresh()
        }).catch((error) => {})
    }

    const onEditUserClick = async (user: user) => {
        const editUser: user = {
            id: user.id,
            display_name: user.display_name,
            full_name: user.full_name,
            pin: undefined,
            user_rules: [],
            is_active: user.is_active,
        }
        const modalResult: user = await Demodal.open(ItemModal, {
            itemName: 'User',
            formData: {...editUser, info: 'Note: the above fields are synced directly from Odoo employee data, and are not editable in this interface.'},
            formFields: userFormFields,
            formSchema: putUserFormSchema,
        })

        if (modalResult) {
            const editedUser: user = {
                id: modalResult.id,
                display_name: modalResult.display_name,
                full_name: modalResult.full_name,
                pin: modalResult.pin !== undefined && modalResult.pin !== user.pin ? modalResult.pin : user.pin,
                user_rules: modalResult.user_rules,
                is_active: modalResult.is_active,
            }
            await putUser(user.id, editedUser)
            createNotification('Edited', 'Edited user.')
            refresh()
        }
    }

    const handleShowInactiveUsersClick = (event: BaseSyntheticEvent) => {
        setShowInactiveUsers(!showInactiveUsers)
    }

    const isAdminFormatter = (cell: any, row: user) => (
        <>
            {row.user_rules && row.user_rules.includes(userAdministrationRuleId) ? (
                <div className="text-success mb-1">
                    <Check/>
                </div>
            ) : (
                <div className="text-muted mb-1">
                    <Dash/>
                </div>
            )}
        </>
    )

    const isActiveFormatter = (cell: any, row: user) => (
        <>
            {cell ? (
                <div className="text-success mb-1">
                    <Check/>
                </div>
            ) : (
                <div className="text-muted mb-1">
                    <Dash/>
                </div>
            )}
        </>
    )

    const rowEvents = {
        onClick: (e: BaseSyntheticEvent, row: user, rowIndex: number) => {
            onEditUserClick(row)
        }
    }

    const tableColumns = [
        {
            dataField: 'display_name',
            text: 'Display Name',
        },
        {
            dataField: 'full_name',
            text: 'Full Name',
            sort: true,
        },
        {
            dataField: 'user_rules',
            text: 'Admin',
            formatter: isAdminFormatter,
        },
        {
            dataField: 'is_active',
            text: 'Active',
            sort: true,
            formatter: isActiveFormatter,
        }
    ]

    return (
        <Container fluid className="p-3">
            <div className="d-flex align-items-center users-header">
                <h1 className="m-0 users-title">Users</h1>
                <div className="ml-auto d-flex align-items-center">
                    <Form.Check
                        type="switch"
                        className="mr-4 inactive-users-switch"
                        id="inactiveUsersSwitch"
                        label="Show Inactive Users"
                        onChange={handleShowInactiveUsersClick}
                        checked={showInactiveUsers}
                    />
                    <Button variant="secondary" onClick={onSyncUsersClick}>
                        <ArrowRepeat className="d-inline-block align-middle mr-2" />
                        <span className="d-inline-block align-middle">Sync<span className="d-none d-xl-inline"> Users from Odoo</span></span>
                    </Button>
                </div>
            </div>

            <div className="mt-3">
                <BootstrapTable
                    keyField="id"
                    data={ userDataFiltered }
                    columns={ tableColumns }
                    defaultSorted={[{dataField: 'full_name', order: 'asc'}]}
                    hover
                    rowEvents={ rowEvents }
                    bootstrap4
                />
            </div>
        </Container>
    )
}

export default Users