import { Badge } from "react-bootstrap"
import { prepTaskType } from "../../data/types/prep-task-type"

interface TimeTrackingPrepTaskBadgesProps {
  prepTaskTypes: prepTaskType[];
  activePrepTaskTypes: number[];
}

export const TimeTrackingPrepTaskBadges = (props: TimeTrackingPrepTaskBadgesProps) => {
  const completedTasks = props.prepTaskTypes.filter((type: prepTaskType) => props.activePrepTaskTypes.includes(type.id))

  return (
    <div className="time-entry-tasks">
      <span className="time-entry-tasks-label mr-2">Tasks:</span>
      {completedTasks.map((type: prepTaskType) => (
          <Badge key={type.id} variant="info" className="mr-2">{type.completed_task_name}</Badge>
      ))}
      {(completedTasks.length === 0) && (
        <>&mdash;</>
      )}
    </div>
  )
}

export default TimeTrackingPrepTaskBadges