import { Field } from "formik";
import { BaseSyntheticEvent, useState } from "react";

interface NumberFieldProps {
  formikSetFieldValue: Function;
  fieldName: string;
  initialValue: string;
  readonly?: boolean;
}

const NumberField = (props: NumberFieldProps) => {
  const [value, setValue] = useState(props.initialValue);

  const onChange = (event: BaseSyntheticEvent) => {
    const value = event.target.value.replace(/[^0-9.]/g, '')
    setValue(value)
    props.formikSetFieldValue(props.fieldName, value)
  }

  return (
    <Field
      name={props.fieldName}
      value={value}
      onChange={onChange}
      className="form-control"
      disabled={props.readonly}
    />
  )
}

export default NumberField
