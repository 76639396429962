export interface applicablePaint {
  id: number;
  odoo_product_product_id: number;
  product_name: string;
  default_code: string;
  vendor_name: string;
  category: string;
}

export const emptyApplicablePaint: applicablePaint = {
  "id": 0,
  "odoo_product_product_id": 0,
  "product_name": "",
  "default_code": "",
  "vendor_name": "",
  "category": ""
}

export const applicablePaintMockData: applicablePaint[] = [
  {
    "id": 2,
    "odoo_product_product_id": 552,
    "product_name": "OGF primer",
    "default_code": "09/73841",
    "vendor_name": "Tiger",
    "category": "Primer"
  },
  {
    "id": 3,
    "odoo_product_product_id": 690,
    "product_name": "marrone 3601",
    "default_code": "68/15185",
    "vendor_name": "Tiger",
    "category": "Top Coat"
  },
  {
    "id": 4,
    "odoo_product_product_id": 615,
    "product_name": "flat clear",
    "default_code": "9030-01311 SD",
    "vendor_name": "TCI",
    "category": "Clear Coat"
  }
]
