import { Alert, Button, Container } from "react-bootstrap";

const RouteNotFound = () => (
  <Container className="pt-3">
    <Alert variant="warning">
      <h3>Page Not Found</h3>
      <p>This URL is not valid. Please go back to the previous page, or contact technical support if you did not enter this URL manually.</p>
      <hr />
      <Button variant="warning" onClick={() => { window.history.back() }}>Go Back</Button>
    </Alert>
  </Container>
)

export default RouteNotFound