import { useEffect, useState } from "react"
import { getAttachment } from "../../data/api-requests"
import { toBase64 } from "../../utils/attachments"
import PdfViewer from "./pdf-viewer"

interface AttachmentPdfProps {
  attachmentId: number
}

const AttachmentPdf = (props: AttachmentPdfProps) => {
  const [pdfData, setPdfData] = useState<string>()

  const getPdf = async () => {
    const result = await getAttachment(props.attachmentId)
    if (result) {
      const b64: string = String(await toBase64(result)).replace('data:application/pdf;base64,', '')
      setPdfData(b64)
    }
  }

  useEffect(() => {
    if (props.attachmentId) getPdf()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.attachmentId])

  return pdfData ? <PdfViewer pdfData={pdfData} /> : <></>
}

export default AttachmentPdf
