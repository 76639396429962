import { BaseSyntheticEvent, useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import TextareaAutosize from 'react-textarea-autosize';
import { allApplicableWorkCenters } from "../../common/config";
import { updateWorkCenterInstructions } from "../../data/api-requests";
import { instruction } from "../../data/types/instruction";
import { workCenter, workOrder } from "../../data/types/work-order";
import useApplicableWorkCenterIds from "../../hooks/use-applicable-work-center-ids";
import { convertArrayToObject } from "../../utils/helpers";

interface WorkOrderInstructionsProps {
  workOrder: workOrder;
  workCenter: string;
  workCenters: workCenter[];
  workCenterId?: number;
}

const WorkOrderInstructions = (props: WorkOrderInstructionsProps) => {
  const [fieldValues, setFieldValues] = useState<any>({})
  const [workCenterInstructionText, setWorkCenterInstructionText] = useState<string>('')
  const [filteredWorkCenters, setFilteredWorkCenters] = useState<workCenter[]>([])
  const applicableWorkCenterIds = useApplicableWorkCenterIds(props.workOrder)
  const [workCenterInstructions, setWorkCenterInstructions] = useState<instruction[]>([])

  useEffect(() => {
    if (props.workOrder && props.workOrder.id > 0) {
      setWorkCenterInstructions(props.workOrder.instructions)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workOrder])

  useEffect(() => {
    if (props.workCenters) {
      // Only include work centers if:
      // - they are applicable work centers list assigned to this WO
      // - they are standard work centers
      setFilteredWorkCenters(
        [...props.workCenters].filter((wc: workCenter) => (
          (allApplicableWorkCenters.includes(wc.id) && applicableWorkCenterIds.includes(wc.id))
          || allApplicableWorkCenters.includes(wc.id) === false
        ))
      )
    }
  }, [applicableWorkCenterIds, props.workCenters, props.workOrder])

  const onInstructionFocusOut = (workCenterId: number, instructionText: string) => {
    // Trim string immediately upon focus out, instead of after a short delay from the request
    const lastValue = getFieldValue(workCenterId).lastValue.trim()
    const currentValue = getFieldValue(workCenterId).currentValue.trim()
    updateFieldValues(lastValue, currentValue, workCenterId)

    updateWorkCenterInstructions(props.workOrder.id, workCenterId, instructionText)
    .then(() => {
      updateFieldValues(instructionText, instructionText, workCenterId)
      // Insert or update instructions in already loaded workOrder object
      const matchingInstruction = props.workOrder.instructions
        .find((instruction: instruction) => instruction.work_center_id === workCenterId)
      if (matchingInstruction) {
        matchingInstruction.instruction_text = instructionText
      } else {
        props.workOrder.instructions = [
          ...workCenterInstructions,
          {
            id: Math.floor(Math.random() * 9999),
            work_order_id: 1,
            work_center_id: workCenterId,
            created_by: 1,
            created_on: new Date(),
            instruction_text: instructionText
          }
        ]
      }
    })
    .catch(() => {
      const fieldValue = getFieldValue(workCenterId).lastValue.trim()
      updateFieldValues(undefined, fieldValue, workCenterId)
    })
  }

  // Activate edit mode on table fields
  const handleInputClick = (event: BaseSyntheticEvent) => {
    const target = event.target
    const initialValue = target.value

    updateFieldValues(initialValue, initialValue, event.target.dataset.rowId)
  }

  // Handle updating table field values
  const handleInputChange = (event: BaseSyntheticEvent) => {
    const fieldValue = event.target.value
    updateFieldValues(undefined, fieldValue, event.target.dataset.rowId)
  }

  const getFieldValue = (workCenterId: number) => {
    return fieldValues && workCenterId in fieldValues ? fieldValues[workCenterId] : {lastValue: '', currentValue: ''}
  }

  const updateFieldValues = (lastValue: string | undefined, currentValue: string | undefined, workCenterId: number) => {
    if (lastValue !== undefined) {
      fieldValues[workCenterId].lastValue = lastValue
    }
    if (currentValue !== undefined) {
      fieldValues[workCenterId].currentValue = currentValue
    }

    let newFieldValues = {}
    Object.assign(newFieldValues, fieldValues)
    setFieldValues(newFieldValues)
  }

  useEffect(() => {
    if (props.workCenters && props.workCenters.length > 0 && workCenterInstructions) {
      const fieldValues = convertArrayToObject(props.workCenters, 'id', workCenterInstructions)
      setFieldValues(fieldValues)
    }
  }, [props.workCenters, workCenterInstructions])

  useEffect(() => {
    if (props.workCenterId && workCenterInstructions) {
      const matchingInstructions = workCenterInstructions.find((instruction: instruction) => instruction.work_center_id === props.workCenterId)
      setWorkCenterInstructionText(matchingInstructions ? matchingInstructions.instruction_text : '')
    }
  }, [props.workCenterId, workCenterInstructions])

  const inputField = (workCenter: workCenter) => (
    <TextareaAutosize
      className="form-control"
      maxRows={10}
      onFocusCapture={handleInputClick}
      onChange={handleInputChange}
      onBlurCapture={(event: BaseSyntheticEvent) => onInstructionFocusOut(workCenter.id, event.target.value.trim())}
      data-row-id={workCenter.id}
      disabled={false}
      value={getFieldValue(workCenter.id).currentValue}
    />
  )

  return (
    props.workCenter ? (
      props.workCenter === 'review' ? (
        <div className="work-order-section work-order-instructions">
          <header className="work-order-section-header work-order-instructions-header">
            <h2>Instructions</h2>
          </header>

          <Form>
            {filteredWorkCenters.map((workCenter: workCenter) => (
              <Form.Group key={workCenter.id} controlId={`instructionsForm.${workCenter.id}`}>
                <Form.Label>{workCenter.work_center_name}</Form.Label>
                {inputField(workCenter)}
              </Form.Group>
            ))}
          </Form>
        </div>
      ) : (
        workCenterInstructionText ? (
          <div className="work-order-section work-order-instructions">
            <header className="work-order-section-header work-order-instructions-header">
              <h2>Instructions</h2>
            </header>

            <Alert variant="info" className="work-order-instruction white-space-pre-line" value={workCenterInstructionText}>
              {workCenterInstructionText}
            </Alert>
          </div>
        ) : (
          <></>
        )
      )
    ) : (
      <></>
    )
  )
}

export default WorkOrderInstructions