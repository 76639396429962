import { useState as useGlobalState } from '@hookstate/core'
import { useEffect, useState } from 'react'
import { globalDataCache, globalRefreshTriggers } from '../common/context'

import { staticResources } from '../data/types/static-resources'
import { getStaticResources } from '../data/api-requests'


const useGetStaticData = () => {
  const refreshTriggers = useGlobalState(globalRefreshTriggers)
  const dataCache = useGlobalState(globalDataCache)
  const triggerGlobalRefresh = refreshTriggers.all.get()
  const [data, setData] = useState<staticResources>()

  const getData = async (noCache: boolean = false) => {
    // first check cache
    if (noCache === false) {
      const cacheData = dataCache['staticResources'].get()
      if (cacheData) {
        setData(cacheData)
        return true
      }
    }

    const result = await getStaticResources()
    if (result) {
      setData(result)
      dataCache['staticResources'].set(result)
    }
  }

  // Init
  useEffect(() => {
    getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Watch for refresh event
  useEffect(() => {
    if (triggerGlobalRefresh > 0) getData(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerGlobalRefresh])

  return data
}

export default useGetStaticData