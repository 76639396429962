import { useEffect, useRef, useState } from "react"
import { useState as useGlobalState } from '@hookstate/core'
import { moQueue } from "../../data/types/mo-queue"
import { cancelQueuedMo, getMoQueue } from "../../data/api-requests"
import useGetData from "../../hooks/use-get-data"
import { Button, Container, Form } from "react-bootstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator";
import { Demodal } from "demodal"
import ConfirmModal from "../shared/modal/modal-confirm"
import { moQueueStageQueued } from "../../common/config"
import createNotification from "../../utils/notification"
import globalState from "../../common/context"
import Cookies from "universal-cookie"
import { format } from "date-fns"
import useCheckPermission from "../../hooks/use-check-permission"
import { Trash } from "react-bootstrap-icons"

const Queue = () => {
  const cookies = new Cookies()
  const globals = useGlobalState(globalState)
  const hasMoQueuePermission = useCheckPermission('MO Queue', true)

  const defaultShowCompleted = cookies.get('tk_mo_queue_show_completed') === 'true' ? true : false
  const [showCompleted, setShowCompleted] = useState<boolean>(defaultShowCompleted || false)

  const fetchData = async () => {
    if (hasMoQueuePermission) {
      const result = await getMoQueue(null, showCompleted)
      if (result) return result
    }
  }

  const [moData, refresh] = useGetData<moQueue>({fetchDataFn: fetchData})
  const [moDataFiltered, setMoDataFiltered] = useState<moQueue[]>([])
  const [filterValue, setFilterValue] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() =>  {
    // Filter by search string
    const filtered = [...moData].filter((mo: moQueue) => {
      if (
        String(mo.serial_number).toLowerCase().includes(filterValue.toLowerCase())
        || String(mo.odoo_work_order_name).toLowerCase().includes(filterValue.toLowerCase())
        || String(mo.odoo_mrp_production_name).toLowerCase().includes(filterValue.toLowerCase())
        || String(mo.created_by_name).toLowerCase().includes(filterValue.toLowerCase())
      ) {
        return true
      }
      return false
    })

    setMoDataFiltered(filtered)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moData, filterValue])

  const onFilterInputChange = (event: any) => {
    const fieldValue = event.target.value
    const newValue = fieldValue.replace(/([^a-zA-Z0-9/]+)/gi, '')
    setFilterValue(newValue)
  }

  const handleShowCompletedClick = () => {
    cookies.set('tk_mo_queue_show_completed', !showCompleted, {path: '/'})
    setShowCompleted(!showCompleted)
  }

  useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCompleted])

  useEffect(() => {
    if (hasMoQueuePermission) refresh()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMoQueuePermission])


  const onDeleteItemClick = async (item: moQueue) => {
    const modalResult: boolean = await Demodal.open(ConfirmModal, {
      title: "Cancel Queued Item?",
      description: <>Please confirm cancellation.</>,
    })

    if (modalResult) {
      globals.isLoading.set(true)
      const result = await cancelQueuedMo(item.id)
      if (result) {
        createNotification('Success', 'Queued MO has been cancelled.')
      }
      refresh()
    }
  }

  const moNameFormatter = (cell: any, row: moQueue) => (
    cell ? cell : row.odoo_mrp_production_id
  )

  const emptyColumnFormatter = (cell: any) => (
    cell ? cell : '-'
  )

  const dateFormatter = (cell: any) => (
    cell ? format(new Date(cell), "MMM d, yyyy 'at' h:mm aaaa") : '-'
  )

  const deleteRowFormatter = (cell: any, row: moQueue) => {
    return row.queue_stage_id === moQueueStageQueued ? <Button variant='outline-danger pe-auto' onClick={() => onDeleteItemClick(row)}><Trash/></Button> : ''
  }

  const tableColumns = [
    {
      dataField: 'odoo_work_order_name',
      text: 'WO #',
      sort: true,
    },
    {
      dataField: 'odoo_mrp_production_name',
      text: 'MO #',
      sort: true,
      formatter: moNameFormatter,
    },
    {
      dataField: 'serial_number',
      text: 'Serial Number',
      sort: true,
      formatter: emptyColumnFormatter,
    },
    {
      dataField: 'queue_stage_name',
      text: 'Stage',
      sort: true,
    },
    {
      dataField: 'created_by_name',
      text: 'Created By',
    },
    {
      dataField: 'created_on',
      text: 'Created On',
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: 'completed_on',
      text: 'Completed On',
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: '',
      text: '',
      headerStyle: {width: 70},
      formatter: deleteRowFormatter,
    },
  ]

  return (
    <Container fluid className="p-3">
      <div className="d-flex align-items-center users-header">
        <h1 className="m-0 users-title">Manufacturing Order Queue</h1>
        <div className="ml-auto d-flex align-items-center">
          <Form.Check
            type="switch"
            className="mr-4 text-nowrap"
            id="showCompletedSwitch"
            label="Show Completed Items"
            onChange={handleShowCompletedClick}
            checked={showCompleted}
          />
          <Form.Control
            ref={inputRef}
            type="text"
            value={filterValue}
            onChange={onFilterInputChange}
            placeholder="Search..."
          />
        </div>
      </div>

      <div className="mt-3">
        <BootstrapTable
          keyField="id"
          data={ moDataFiltered }
          columns={ tableColumns }
          pagination={ paginationFactory({
            alwaysShowAllBtns: true,
            showTotal: true,
            sizePerPageList: [{
              text: '15', value: 15
            }, {
              text: '20', value: 20
            }, {
              text: 'All', value: moDataFiltered.length
            }]
          }) }
          defaultSorted={[{dataField: 'created_on', order: 'desc'}]}
          bootstrap4
        />
      </div>
    </Container>
  )
}

export default Queue
