import { useEffect, useState } from "react";
import { Alert, ListGroup } from "react-bootstrap";
import { comment } from "../../data/types/comments";
import { staticResources } from "../../data/types/static-resources";
import { workCenter, workOrder } from "../../data/types/work-order";
import { getDateStringFromDate } from "../../utils/helpers";

interface WorkOrderCommentsReportProps {
  workOrder: workOrder;
  staticResources?: staticResources;
}

const WorkOrderCommentsReport = (props: WorkOrderCommentsReportProps) => {
  const [comments, setComments] = useState<comment[]>([])
  const [workCenters, setWorkCenters] = useState<workCenter[]>([])
  const [filteredWorkCenters, setFilteredWorkCenters] = useState<workCenter[]>([])

  useEffect(() => {
    if (props.workOrder) setComments([...props.workOrder.comments])
  }, [props.workOrder])

  useEffect(() => {
    if (props.staticResources && props.staticResources.work_centers) {
      setWorkCenters(props.staticResources.work_centers)
    }
  }, [props.staticResources])

  useEffect(() => {
    // Only include work centers that have comments
    if (workCenters && workCenters.length > 0 && comments && comments.length > 0) {
      setFilteredWorkCenters(
        [...workCenters.filter((workCenter: workCenter) => workCenterHasComments(workCenter.id))]
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workCenters, comments])

  const getCommentsByWorkCenter = (workCenterId: number) =>
    comments.filter((comment: comment) => comment.work_center_id === workCenterId)

  const workCenterHasComments = (workCenterId: number) =>
    comments.some((comment: comment) => comment.work_center_id === workCenterId)

  return (
    <div className="work-order-section work-order-comments-report">
      <header className="work-order-section-header work-order-comments-report-header">
        <h2>Comments</h2>
      </header>

      {filteredWorkCenters.length === 0 && (
        <Alert variant="info">No comments posted.</Alert>
      )}

      <ListGroup className="comments">
        {filteredWorkCenters.map((workCenter: workCenter) => (
          <>
            <ListGroup.Item className="bg-light" key={workCenter.work_center_name}>
              <strong>{workCenter.work_center_name}</strong>
            </ListGroup.Item>
            {getCommentsByWorkCenter(workCenter.id).map((comment: comment) => (
              <ListGroup.Item key={comment.id} className="comment">
                <header className="comment-header text-muted mb-2">
                  <div className="comment-user">{comment.user_name}</div>
                  <div className="comment-date ml-auto">{getDateStringFromDate(comment.created_on, 'd MMM yyyy')}</div>
                </header>
                <p className="m-0 white-space-pre-line">{comment.comment_text}</p>
              </ListGroup.Item>
            ))}
          </>
        ))}
      </ListGroup>
    </div>
  )
}

export default WorkOrderCommentsReport
