import { BaseSyntheticEvent } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { deleteTitration, getTitration, postTitration, putTitration } from '../../data/api-requests'
import { emptyTitration, titration } from '../../data/types/titration'
import useGetData from '../../hooks/use-get-data'
import { Plus } from 'react-bootstrap-icons'
import { Button } from 'react-bootstrap'
import { Demodal } from 'demodal'
import createNotification from '../../utils/notification'
import { putTitrationFormSchema, titrationFormSchema } from '../shared/form/form-titration'
import TitrationModal from '../shared/modal/modal-titration'
import { format } from 'date-fns'

interface WorkOrderTitrationProps {
  workOrderId: number;
  readOnly: boolean;
}

const WorkOrderTitration = (props: WorkOrderTitrationProps) => {
  const fetchData = async () => {
    const result = await getTitration(props.workOrderId)
    if (result) return result
  }

  const [titrations, refresh] = useGetData<titration>({
    fetchDataFn: fetchData
  })

  const rowEvents = {
    onClick: (e: BaseSyntheticEvent, row: titration, rowIndex: number) => {
        onEditItemClick(row)
    }
  }

  const createdOnFormatter = (cell: any, row: titration) => (
    cell ? format(cell, "MMM d, yyyy 'at' h:mm aaaa") : ''
  )

  const tableColumns = [
    {
      dataField: 'created_on',
      text: 'Date',
      formatter: createdOnFormatter,
      sort: true,
    },
    {
      dataField: 'created_by_name',
      text: 'Measured By',
    },
  ]

  const onDeleteRow = async (titrationId: number) => {
    await deleteTitration(titrationId)
    createNotification('Deleted', `Removed Titration entry.`)
    refresh()
  }

  const onEditItemClick = async (item: titration) => {
    const editTitration: titration = item

    const modalResult: 'delete' | titration = await Demodal.open(TitrationModal, {
        titration: editTitration,
        formSchema: putTitrationFormSchema,
        readOnly: props.readOnly || props.workOrderId !== item.work_order_id,
    })

    if (props.readOnly) return false

    if (modalResult === 'delete') {
      onDeleteRow(item.id)
    } else if (modalResult) {
      const editedTitration: titration = modalResult
      await putTitration(item.id, editedTitration)
      createNotification('Edited', 'Edited titration.')
      refresh()
    }
  }

  const onAddItemClick = async (event: BaseSyntheticEvent) => {
    const modalResult: any = await Demodal.open(TitrationModal, {
      titration: emptyTitration,
      formSchema: titrationFormSchema
    })

    if (modalResult) {
      const result = await postTitration(props.workOrderId, modalResult)
      if (result) {
        createNotification('Submitted', `Added titration entry.`)
        refresh()
      }
    }
  }

  return (
    <div className="work-order-section work-order-titration">
      <header className="work-order-section-header work-order-titration-header">
        <h2>Titration</h2>
        {(!props.readOnly) && (<Button onClick={onAddItemClick}><Plus/></Button>)}
      </header>

      <div className="mt-3">
        {tableColumns && (
          <BootstrapTable
            keyField="id"
            data={ titrations }
            columns={ tableColumns }
            rowEvents={ rowEvents }
            defaultSorted={[{dataField: 'created_on', order: 'asc'}]}
            bootstrap4
            hover
          />
        )}
      </div>
    </div>
  )
}

export default WorkOrderTitration