interface paintProductCategId {
  id: number;
  name: string;
}

interface paintProductUoMId {
  id: number;
  name: string;
}

export interface paintProduct {
  id: number;
  name: string;
  categ_id: paintProductCategId;
  uom_id: paintProductUoMId;
  tk_vendor_name: string;
  default_code: string;
}

export const paintProductsMockData: paintProduct[] = [
  {
    "id": 569,
    "name": "gray epoxy",
    "categ_id": {
      "id": 7,
      "name": "Top Coat"
    },
    "uom_id": {
      "id": 1,
      "name": "Units",
    },
    "tk_vendor_name": "Forrest",
    "default_code": "(729)1049bx50"
  },
  {
    "id": 753,
    "name": "malachite",
    "categ_id": {
      "id": 7,
      "name": "Top Coat"
    },
    "uom_id": {
      "id": 1,
      "name": "Units",
    },
    "tk_vendor_name": "Tiger",
    "default_code": "039/40003"
  },
  {
    "id": 540,
    "name": "black fine texture",
    "categ_id": {
      "id": 7,
      "name": "Top Coat"
    },
    "uom_id": {
      "id": 1,
      "name": "Units",
    },
    "tk_vendor_name": "Tiger",
    "default_code": "039/80200"
  },
]
