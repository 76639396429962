import { Demodal, useModal } from "demodal";

import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { emptyTitration, titration } from "../../../data/types/titration";
import { Alert, Card, Col, Form, Row } from "react-bootstrap";
import { Formik, Form as FormikForm, Field } from 'formik';
import { phosphateConcentrationSpec, phosphatePHSpec, phosphateTempSpec, preCleanerConcentrationSpec, preCleanerTempSpec, rinseTDSSpec, rinseTempSpec, rustInhibConcentrationSpec, rustInhibPHSpec, rustInhibTempSpec } from "../../../common/config";
import ConfirmModal from "./modal-confirm";
import { useState } from "react";
import { format } from "date-fns";

interface titrationTypeSectionField {
  title: string;
  spec: string;
  value: any;
  name: string;
  type?: 'text' | 'input';
}

interface modalTitrationProps {
  titration: titration;
  formSchema: Yup.ObjectSchema<any>;
  readOnly: boolean;
}

const TitrationModal = Demodal.create(({
  titration = emptyTitration,
  formSchema,
  readOnly,
}: modalTitrationProps) => {
    const modal = useModal();

    const [modalErrorMessage, setModalErrorMessage] = useState<string>('')

    const resolve = (value: any) => () => {
        modal.resolve(value)
        modal.close()
        modal.remove()
    }

    const titrationTypeSection = (title: string, fields: titrationTypeSectionField[], errors: any = {}, touched: any = {}, colSpan: number = 6) => {
      return (
        <>
        {(!readOnly || (readOnly && fields.every(element => element.value !== null))) && (
          <Col md={colSpan}>
            <Card className="mb-4">
              <Card.Header>
                  <strong>{title}</strong>
              </Card.Header>
              <Card.Body>
                <div className="card-info">
                  {fields.map((field: titrationTypeSectionField) => (
                    <Form.Group key={field.name} controlId={field.name}>
                      <div key={field.title} className={`spec-field-${field.type ? field.type : 'text'}`}>
                        {(!field.type || (field.type && field.type === 'text')) && (
                          <span>
                            <strong className="text-muted card-info__label">{field.title}</strong>
                            {field.value ? String(field.value) : ''}
                          </span>
                        )}
                        {(field.type && field.type === 'input') && (
                            <span>
                              <strong className="text-muted card-info__label">{field.title}&nbsp;<small>({field.spec})</small></strong>
                              <Field
                                name={field.name}
                                id={field.name}
                                value={field.value}
                                className="form-control"
                              />
                            </span>
                        )}
                        {errors[field.name] && touched[field.name] ? (<div className="text-danger">{errors[field.name]}</div>) : null}
                      </div>
                    </Form.Group>
                  ))}
                </div>
              </Card.Body>
            </Card>
          </Col>
        )}
        </>
      )
    }

    const onFormSubmit = (values: titration) => {
      if (
        (values.pre_cleaner_concentration && values.pre_cleaner_temp) ||
        (values.rust_inhib_concentration && values.rust_inhib_temp && values.rust_inhib_ph) ||
        (values.phosphate_concentration && values.phosphate_temp && values.phosphate_ph) ||
        (values.rinse_tds && values.rinse_temp)
      ) {
        setModalErrorMessage('')

        const castedValues = formSchema.cast(values)
        modal.resolve(castedValues)
        modal.close()
        modal.remove()
      } else {
        setModalErrorMessage('Please enter measurements for at least one section.')
      }
    }

    const handleDeleteClick = async () => {
      const modalResult = await Demodal.open(ConfirmModal, {
        title: 'Delete titration?',
        description: 'This will remove the titration record. This action can not be undone.',
      })

      if (!modalResult) return false

      modal.resolve('delete')
      modal.close()
      modal.remove()
    }

    const modalTitleVerb = titration.id > 0 ? 'Edit' : 'Add'

    const modalTitrationInputType = readOnly ? 'text' : 'input'

    return (
        <>
        <Modal
          show={modal.isOpen}
          onHide={resolve(false)}
          backdrop='static'
          className="titration-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalTitleVerb} Titration</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
                initialValues={titration}
                validationSchema={formSchema}
                onSubmit={values => onFormSubmit(values)}
              >
                {({ values, errors, touched, setFieldValue }) => (
                  <FormikForm id="modal-form">
                    <Row>
                      {titrationTypeSection('Pre Cleaner', [{
                        title: 'Titration',
                        spec: preCleanerConcentrationSpec,
                        value: values.pre_cleaner_concentration,
                        name: 'pre_cleaner_concentration',
                        type: modalTitrationInputType,
                      }, {
                        title: 'Temperature',
                        spec: preCleanerTempSpec,
                        value: values.pre_cleaner_temp,
                        name: 'pre_cleaner_temp',
                        type: modalTitrationInputType,
                      }], errors, touched)}
                      {titrationTypeSection('R/O Rinse', [{
                        title: 'TDS Temperature',
                        spec: rinseTDSSpec,
                        value: values.rinse_tds,
                        name: 'rinse_tds',
                        type: modalTitrationInputType,
                      }, {
                        title: 'Temperature',
                        spec: rinseTempSpec,
                        value: values.rinse_temp,
                        name: 'rinse_temp',
                        type: modalTitrationInputType,
                      }], errors, touched)}
                      {titrationTypeSection('621 Phosphate', [{
                        title: 'Titration',
                        spec: phosphateConcentrationSpec,
                        value: values.phosphate_concentration,
                        name: 'phosphate_concentration',
                        type: modalTitrationInputType,
                      }, {
                        title: 'Temperature',
                        spec: phosphateTempSpec,
                        value: values.phosphate_temp,
                        name: 'phosphate_temp',
                        type: modalTitrationInputType,
                      }, {
                        title: 'pH',
                        spec: phosphatePHSpec,
                        value: values.phosphate_ph,
                        name: 'phosphate_ph',
                        type: modalTitrationInputType,
                      }], errors, touched)}
                      {titrationTypeSection('Rust Inhib', [{
                        title: 'Titration',
                        spec: rustInhibConcentrationSpec,
                        value: values.rust_inhib_concentration,
                        name: 'rust_inhib_concentration',
                        type: modalTitrationInputType,
                      }, {
                        title: 'Temperature',
                        spec: rustInhibTempSpec,
                        value: values.rust_inhib_temp,
                        name: 'rust_inhib_temp',
                        type: modalTitrationInputType,
                      }, {
                        title: 'pH',
                        spec: rustInhibPHSpec,
                        value: values.rust_inhib_ph,
                        name: 'rust_inhib_ph',
                        type: modalTitrationInputType,
                      }], errors, touched)}
                    </Row>
                  </FormikForm>
                )}
              </Formik>
              {modalErrorMessage !== '' && (
                <Alert variant="danger">{modalErrorMessage}</Alert>
              )}
              <Row>
                <Col>
                  {titration.created_on && titration.created_by_name && (
                    <>Created by {titration.created_by_name} on {format(titration.created_on, "MMM d, yyyy 'at' h:mm aaaa")}</>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {titration.updated_on && titration.updated_by_name && (
                    <>Updated by {titration.updated_by_name} on {format(titration.updated_on, "MMM d, yyyy 'at' h:mm aaaa")}</>
                  )}
                </Col>
              </Row>
          </Modal.Body>
          <Modal.Footer>
            {titration.id > 0 && !readOnly && (
              <Button className="mr-2" variant="danger" onClick={handleDeleteClick}>Delete</Button>
            )}
            <Button variant="primary" type="submit" form="modal-form">Confirm</Button>
          </Modal.Footer>
        </Modal>
        </>
    )
})

export default TitrationModal