import { BaseSyntheticEvent, useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap"
import { getUser } from "../../../data/api-requests";
import { rule } from "../../../data/types/rule"
import { user } from "../../../data/types/user";
import useGetData from "../../../hooks/use-get-data"

export interface RulesFieldProps {
    user: user;
    fieldName: string;
    formikSetFieldValue: Function;
}

const RulesField = (props: RulesFieldProps) => {
    const [selectedUserRules, setSelectedUserRules] = useState<Array<number>>([])
    const [rules] = useGetData<rule>({interfaceName: 'rule'})

    const fetchUser = () => {
        getUser(props.user.id)
        .then((result: user) => {
            if (result.user_rules) {
                setSelectedUserRules([...result.user_rules])
                props.formikSetFieldValue(props.fieldName, result.user_rules)
            }
        })
    }

    useEffect(() => {
        if (props.user.id > 0) {
            fetchUser()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user])

    const onUpdateClick = (e: BaseSyntheticEvent) => {
        const ruleId = parseInt(e.target.id, 10)
        if (e.target.checked) {
            // add it
            selectedUserRules.push(ruleId)
        } else {
            // remove it
            const index = selectedUserRules.indexOf(ruleId)
            if (index > -1) {
                selectedUserRules.splice(index, 1)
            }
        }
        setSelectedUserRules([...selectedUserRules])
        props.formikSetFieldValue(props.fieldName, selectedUserRules)
    }

    return (
        <Container className="bg-light p-4 permission-list text-secondary">
        {rules.map((rule: rule) => (
            <Form.Check
                key={rule.id}
                custom
                id={String(rule.id)}
                label={rule.rule_name}
                type="checkbox"
                className="permissions-list-item"
                onChange={onUpdateClick}
                checked={selectedUserRules.includes(rule.id)}
            />
        ))}
        </Container>
    )
}

export default RulesField