export interface prepTaskType {
  id: number;
  completed_task_name: string;
  sequence: number;
}

export const emptyPrepTaskType: prepTaskType = {
  id: 0,
  completed_task_name: '',
  sequence: 0,
}

export const prepTaskTypesMockData: prepTaskType[] = [
  {
    id: 2,
    completed_task_name: 'Blow Off',
    sequence: 2,
  },
  {
    id: 1,
    completed_task_name: 'Scuff',
    sequence: 1,
  },
  {
    id: 3,
    completed_task_name: 'Plug',
    sequence: 3,
  },
]
