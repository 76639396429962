import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { completeWorkOrderDelivery } from "../../data/api-requests";
import { triggerGlobalRefresh } from "../../utils/helpers";

interface WorkOrderDeliveriesCompletedProps {
  workOrderId: number;
  workOrderCompleted: boolean;
  deliveriesCompleted: boolean;
}

const WorkOrderDeliveriesCompleted = (props: WorkOrderDeliveriesCompletedProps) => {
  const [workOrderCompleted, setWorkOrderCompleted] = useState<boolean>(false)
  const [workOrderDeliveryCompleted, setWorkOrderDeliveryCompleted] = useState<boolean>(false)

  useEffect(() => {
    setWorkOrderCompleted(props.workOrderCompleted)
    setWorkOrderDeliveryCompleted(props.deliveriesCompleted)
  }, [props])

  const onDeliveryCompletedClick = () => {
    completeWorkOrderDelivery(props.workOrderId)
    .then(() => {
      setWorkOrderDeliveryCompleted(true)
      triggerGlobalRefresh()
    })
  }

  return (
    <div className="work-order-section work-order-deliveries-completed">
      <Form>
          <Form.Check
            custom
            type='checkbox'
            id='deliveries-completed-checkbox'
            label='Deliveries Completed'
            onChange={onDeliveryCompletedClick}
            checked={workOrderDeliveryCompleted}
            disabled={workOrderDeliveryCompleted || !workOrderCompleted}
          />
      </Form>
    </div>
  )
}

export default WorkOrderDeliveriesCompleted