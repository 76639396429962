import { BaseSyntheticEvent, useState } from "react";
import { Form } from "react-bootstrap";
import { user } from "../../../data/types/user";
import useGetData from "../../../hooks/use-get-data";

interface UserFieldProps {
  formikSetFieldValue: Function;
  fieldName: string;
  initialValue: number;
}

const UserField = (props: UserFieldProps) => {
  const [value, setValue] = useState(props.initialValue);
  const [users] = useGetData<user>({interfaceName: 'user', sortByField: 'full_name'})

  const onChange = (event: BaseSyntheticEvent) => {
    setValue(event.target.value)
    props.formikSetFieldValue(props.fieldName, Number(event.target.value))
  }

  return (
    <Form.Control
      as="select"
      custom
      name={props.fieldName}
      value={value}
      onChange={onChange}
      disabled={users.length === 0}
    >
      <option key='select' value={0}>Select...</option>
      {users.map((user: user) => (
        <option key={user.id} value={user.id}>{user.full_name}</option>
      ))}
    </Form.Control>
  )
}

export default UserField
