import React from "react";
import { Button } from "react-bootstrap";

type CustomToggleProps = {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
  customClasses?: string | undefined
};

export const CustomToggle = React.forwardRef(
  (props: CustomToggleProps, ref: React.Ref<HTMLButtonElement>) => (
  <Button
    className={props.customClasses}
    variant="none"
    ref={ref}
    onClick={(e: any) => {
      e.preventDefault();
      if (props.onClick) props.onClick(e);
    }}
  >
    {props.children}
  </Button>
));