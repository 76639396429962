import * as Yup from 'yup';
import { formField } from '../modal/modal-item';
import { formFieldTypes } from '../../../data/types/form-field-type';
import { floatNumTest } from './custom-validators';

// Form Fields
export const paintUsageFormFields: formField[][] = [
  [
    {
      name: 'applicable_paint_id',
      label: 'Product',
      type: formFieldTypes.applicablePaint,
    },
    {
      name: 'batch',
      label: 'Batch',
      type: formFieldTypes.text,
    },
    {
      name: 'start_weight_in_kg',
      label: 'Start (kg)',
      type: formFieldTypes.text,
    },
    {
      name: 'end_weight_in_kg',
      label: 'End (kg)',
      type: formFieldTypes.text,
    },
  ]
]

// Validation Rules
export const paintUsageFormSchema = Yup.object().shape({
  applicable_paint_id: Yup.number().positive('Required').required('Required'),
  batch: Yup.string()
    .max(100, 'Too Long!')
    .required('Required'),
  start_weight_in_kg: floatNumTest
    .positive('Starting weight must be a positive number.')
    .required('Required'),
  end_weight_in_kg: floatNumTest
    .positive('Ending weight must be a positive number.')
    .lessThan(Yup.ref('start_weight_in_kg'), 'Ending weight must be less than starting weight.')
    .required('Required'),
})
