import { useLayoutEffect, useRef } from "react";
import { Badge, Form, Tab, Tabs } from "react-bootstrap"

interface ReceivingNavProps {
  currentTab: string;
  onTabChange: Function;
  currentFilterValue: string;
  onFilterValueChange: Function;
  unassignedCount: number;
  untaggedCount: number;
}

const ReceivingNav = (props: ReceivingNavProps) => {
  const onTabChange = (newTab: string) => props.onTabChange(newTab)
  const inputRef = useRef<HTMLInputElement>(null);


  // Focus on filter input when opening dropdown
  useLayoutEffect(() => {
    inputRef.current!.focus()
  }, [])

  const onFilterInputChange = (event: any) => {
    const inputValue = event.target.value
    props.onFilterValueChange(inputValue)
  }

  return (
    <div className="d-flex align-items-center mb-4 receiving-nav">
      <div>
        <Tabs
          className="receiving-nav-tabs"
          activeKey={props.currentTab}
          onSelect={(newTab) => onTabChange(String(newTab))}
          variant="pills"
        >
          <Tab eventKey="unassigned" title={
            <>
              Unassigned
              <Badge variant="warning" className="ml-2" title="Unassigned Parts">{props.unassignedCount}</Badge>
            </>
            } />
          <Tab eventKey="untagged" title={
            <>
              Untagged
              <Badge variant="secondary" className="ml-2" title="Untagged Parts">{props.untaggedCount}</Badge>
            </>
          } />
        </Tabs>
      </div>
      <Form.Control
        className="ml-auto received-parts-filter-input"
        ref={inputRef}
        type="text"
        value={props.currentFilterValue}
        onChange={onFilterInputChange}
        placeholder="Filter..."
      />
    </div>
  )
}

export default ReceivingNav