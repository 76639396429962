import * as Yup from 'yup';
import { formField } from "../modal/modal-item"
import { formFieldTypes } from '../../../data/types/form-field-type';

// Form Fields
export const receivedPartFormFields: formField[][] = [
  [
    {
      name: 'customer_name',
      label: 'Customer Name',
      type: formFieldTypes.text,
    },
    {
      name: 'customer_po_number',
      label: 'Customer PO Number',
      type: formFieldTypes.text,
    },
    {
      name: 'note',
      label: 'Note',
      type: formFieldTypes.textarea,
    },
    {
      name: 'attachments',
      label: 'Attachments',
      type: formFieldTypes.attachments,
    },
  ]
]

export const assignWorkOrderFormFields: formField[][] = [
  [
    {
      name: 'work_order_id',
      label: 'Work Order',
      type: formFieldTypes.workOrder,
    },
  ]
]

// Validation Rules
export const receivedPartFormSchema = Yup.object().shape({
  customer_name: Yup.string().nullable(true),
  customer_po_number: Yup.string().nullable(true),
  is_tagged: Yup.bool().required(),
  note: Yup.string().nullable(true),
  new_attachments: Yup.mixed().nullable(true),
  deleted_attachments: Yup.array(),
  attachments: Yup.array(),
  work_order_id: Yup.number().nullable(true),
})

export const assignWorkOrderSchema = Yup.object().shape({
  work_order_id: Yup.number().required().typeError('Required'),
})
