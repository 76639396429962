import { useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { completeShopFloorWorkOrder, completeWorkOrder } from "../../data/api-requests";
import { Demodal } from "demodal"
import ConfirmModal from "../shared/modal/modal-confirm";
import { triggerGlobalRefresh } from "../../utils/helpers";
import { workOrder } from "../../data/types/work-order";
import useCheckPermission from "../../hooks/use-check-permission"

interface WorkOrderCompletedProps {
  workOrder: workOrder;
}

const WorkOrderCompleted = (props: WorkOrderCompletedProps) => {
  const hasWorkOrderAdminPermission = useCheckPermission('WO Administrator')
  const [workOrderPartsCompleted, setWorkOrderPartsCompleted] = useState<boolean>(false)
  const [workOrderReviewCompleted, setWorkOrderReviewCompleted] = useState<boolean>(false)
  const [workOrderCompleted, setWorkOrderCompleted] = useState<boolean>(false)
  const [shopFloorCompleted, setShopFloorCompleted] = useState<boolean>(false)

  useEffect(() => {
    setWorkOrderPartsCompleted(props.workOrder.odoo_all_parts_completed)
    setWorkOrderReviewCompleted(props.workOrder.odoo_review_completed)
    setWorkOrderCompleted(props.workOrder.odoo_work_order_completed || props.workOrder.work_order_completed)
    setShopFloorCompleted(props.workOrder.shop_floor_completed)
  }, [props])

  const onShopFloorCompletedClick = async () => {
    let modalResult = true

    if (!shopFloorCompleted) {
      modalResult = await Demodal.open(ConfirmModal, {
        title: "Confirmation",
        description: <>Please confirm that the shop floor has completed this work order.</>,
      })
    }

    if (modalResult) {
      completeShopFloorWorkOrder(props.workOrder.id, props.workOrder.odoo_work_order_id)
      .then((result) => {
        setShopFloorCompleted(result)
        triggerGlobalRefresh()
      })
    }
  }

  const onCompletedClick = async () => {
    const modalResult = await Demodal.open(ConfirmModal, {
      title: "Confirmation",
      description: <>Please confirm that the current work order is completed. Once confirmed, the time entry and paint usage will not be editable anymore.</>,
    })

    if (modalResult) {
      completeWorkOrder(props.workOrder.id, props.workOrder.odoo_work_order_id)
      .then(() => {
        setWorkOrderCompleted(true)
        triggerGlobalRefresh()
      })
    }
  }

  return (
    <div className="work-order-section work-order-completed">
      <Form>
          <Form.Check
            custom
            type='checkbox'
            id='shop-floor-completed-checkbox'
            className='mb-2'
            label='Shop Floor Completed'
            onChange={onShopFloorCompletedClick}
            checked={shopFloorCompleted}
            disabled={(shopFloorCompleted && !hasWorkOrderAdminPermission) || !workOrderPartsCompleted}
          />
          <Form.Check
            custom
            type='checkbox'
            id='completed-checkbox'
            label='Work Order Completed'
            onChange={onCompletedClick}
            checked={workOrderCompleted}
            disabled={!shopFloorCompleted || workOrderCompleted || !workOrderPartsCompleted || !workOrderReviewCompleted}
          />
      </Form>
      {!workOrderPartsCompleted && (
        <Alert variant="info" className="mt-4 mb-0">
          All part manufacturing orders must be completed first.
        </Alert>
      )}
      {!workOrderReviewCompleted && (
        <Alert variant="info" className="mt-4 mb-0">
          The review must be completed before marking this work order as completed.
        </Alert>
      )}
      {(props.workOrder.work_order_completed && props.workOrder.odoo_work_order_completed === false) && (
        <Alert variant="warning" className="mt-4 mb-0">
          This work order is marked as completed in the apps database but is not in the correct Odoo stage. Please move the Odoo work order into the Shipping or Closed stages.
        </Alert>
      )}
    </div>
  )
}

export default WorkOrderCompleted