import { useState as useGlobalState } from '@hookstate/core'
import globalState from '../../common/context'
import Spinner from 'react-bootstrap/Spinner'

const LoadingIndicator = () => {
  const globals = useGlobalState(globalState)

  return (
    <>
      {globals.isLoading.get() && (
        <div className="app-loading-indicator">
          <Spinner animation="border" variant="info" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
          {globals.loadingMessage.get() && (
            <div className="pt-3">{globals.loadingMessage.get()}</div>
          )}
        </div>
      )}
    </>
  )
}

export default LoadingIndicator