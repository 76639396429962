import * as Yup from 'yup';
import { formField } from '../modal/modal-item';
import { formFieldTypes } from '../../../data/types/form-field-type';

// Form Fields
export const commentFormFields: formField[][] = [
    [
        {
            name: 'comment_text',
            label: '',
            type: formFieldTypes.textarea,
        }
    ]
]

// Validation Rules
export const putCommentFormSchema = Yup.object().shape({
    id: Yup.number(),
    comment_text: Yup.string().required('Required'),
})

export const commentFormSchema = Yup.object().shape({
    comment_text: Yup.string().required('Required'),
})
