import { BaseSyntheticEvent, useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { putPalletsUsed } from "../../data/api-requests";
import { workOrder } from "../../data/types/work-order"

interface WorkOrderPalletsUsedProps {
  workOrder: workOrder;
}

const WorkOrderPalletsUsed = (props: WorkOrderPalletsUsedProps) => {
  const [fieldValue, setFieldValue] = useState<any>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const palletCount = props.workOrder.pallet_count ? props.workOrder.pallet_count.toString() : '0'
    updateFieldValues(palletCount, palletCount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const onPalletsUsedChange = (event: BaseSyntheticEvent) => {
    const regex = /([^0-9]+)/gi
    let palletsUsed: string = event.target.value.replace(regex, '')
    updateFieldValues('', palletsUsed)
  }

  const handleInputClick = (event: BaseSyntheticEvent) => {
    const target = event.target
    const initialValue = target.value

    updateFieldValues(initialValue, initialValue)
  }

  const updateFieldValues = (lastValue: string, currentValue: string) => {
    fieldValue.lastValue = lastValue
    fieldValue.currentValue = currentValue

    let newFieldValues = {}
    Object.assign(newFieldValues, fieldValue)
    setFieldValue(newFieldValues)
  }

  const onPalletsUsedKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        onPalletsUsedFocusOut()
      }
  }

  const onPalletsUsedFocusOut = () => {
    setIsLoading(true)
    // Trim string immediately upon focus out, instead of after a short delay from the request
    const lastValue = fieldValue.lastValue ? fieldValue.lastValue.trim() : 0
    const currentValue = fieldValue.currentValue ? fieldValue.currentValue.trim() : 0
    updateFieldValues(lastValue, currentValue)
    putPalletsUsed(props.workOrder.id, currentValue)
    .then(() => {
      updateFieldValues(lastValue, currentValue)
    })
    .catch(() => {
      updateFieldValues('', fieldValue)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  return <>
    <div className="work-order-section work-order-pallet-count">
      <header className="work-order-section-header work-order-pallet-count-header">
        <h2 className="mr-2">Pallets Used</h2>
      </header>
      <Form.Control
        type="text"
        maxLength={5}
        className="w-50"
        onFocusCapture={handleInputClick}
        onChange={onPalletsUsedChange}
        onKeyDown={onPalletsUsedKeyDown}
        onBlurCapture={() => onPalletsUsedFocusOut()}
        data-row-id={props.workOrder.id}
        disabled={isLoading}
        value={fieldValue ? fieldValue.currentValue : ''}
      />
    </div>
  </>
}

export default WorkOrderPalletsUsed