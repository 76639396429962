import { format, parse, addDays } from "date-fns";
import { BaseSyntheticEvent, useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup, Overlay, Popover } from "react-bootstrap";
import { Calendar3 } from "react-bootstrap-icons";
import Calendar from 'react-calendar'
import { getDateStringFromDate, sanitizeTimeString, getTimeStringFromDate } from "../../../utils/helpers";

interface DateTimeRangeFieldProps {
  formikSetFieldValue: Function;
  fieldName: string;
  initialStartDateValue: Date | undefined;
  initialEndDateValue: Date | undefined;
}

const DateTimeRangeField = (props: DateTimeRangeFieldProps) => {
  const [show, setShow] = useState<boolean>(false)
  const [startDateValue, setStartDateValue] = useState(props.initialStartDateValue);
  const [endDateValue, setEndDateValue] = useState(props.initialEndDateValue);
  const [dateInputValue, setDateInputValue] = useState<string | undefined>(getDateStringFromDate(startDateValue));
  const [startTimeInputValue, setStartTimeInputValue] = useState<string | undefined>(getTimeStringFromDate(startDateValue));
  const [endTimeInputValue, setEndTimeInputValue] = useState<string | undefined>(getTimeStringFromDate(endDateValue));

  const target = useRef(null)

  const onDateChange = (date: any) => {
    setStartDateValue(date)
    setEndDateValue(date)
    setDateInputValue(format(date, 'yyyy-MM-dd'))
    setShow(false)
  }

  const onStartTimeChange = (event: BaseSyntheticEvent) => setStartTimeInputValue(event.target.value)
  const onEndTimeChange = (event: BaseSyntheticEvent) => setEndTimeInputValue(event.target.value)

  const onStartTimeBlur = (event: BaseSyntheticEvent) => setStartTimeInputValue(sanitizeTimeString(event.target.value))
  const onEndTimeBlur = (event: BaseSyntheticEvent) => setEndTimeInputValue(sanitizeTimeString(event.target.value))

  useEffect(() => {
    // When either the date or time inputs change, merge their values and update the formik field value
    let parsedStartDate = new Date()

    if (dateInputValue && startTimeInputValue) {
      const startDateString = `${dateInputValue} ${startTimeInputValue}`
      parsedStartDate = parse(startDateString, 'yyyy-MM-dd h:mm a', new Date())
      props.formikSetFieldValue('start_time', parsedStartDate)
    }
    if (dateInputValue && endTimeInputValue) {
      const endDateString = `${dateInputValue} ${endTimeInputValue}`
      let parsedEndDate = parse(endDateString, 'yyyy-MM-dd h:mm a', new Date())
      // If the end time is earlier than the start time, bump the end time one day forward
      if (parsedEndDate && parsedEndDate.getTime() < parsedStartDate.getTime()) {
        parsedEndDate = addDays(parsedEndDate, 1)
      }
      props.formikSetFieldValue('end_time', parsedEndDate)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateInputValue, startTimeInputValue, endTimeInputValue])

  return (
    <div className="d-flex align-items-center">
      <InputGroup>
        <Form.Control
          disabled={true}
          value={getDateStringFromDate(startDateValue)}
        />
        <InputGroup.Append>
          <Button
            ref={target}
            variant='primary'
            onClick={() => setShow(!show)}
          >
            <Calendar3 />
          </Button>
          <Overlay placement="bottom" target={target.current} show={show} rootClose={true} onHide={() => {setShow(false)}}>
            {(props) => (
              <Popover id="overlay-example" className="calendar-tooltip" {...props}>
                <Calendar calendarType="US" onChange={onDateChange} value={startDateValue} />
              </Popover>
            )}
          </Overlay>
        </InputGroup.Append>
      </InputGroup>

      <Form.Control
        className="ml-3 time-entry-input time-entry-time-input"
        maxLength={8}
        value={startTimeInputValue}
        onChange={onStartTimeChange}
        onBlur={onStartTimeBlur}
        placeholder="00:00 AM"
      />
      <div className="mx-2">&ndash;</div>
      <Form.Control
        className="time-entry-input time-entry-time-input"
        maxLength={8}
        value={endTimeInputValue}
        onChange={onEndTimeChange}
        onBlur={onEndTimeBlur}
        placeholder="00:00 AM"
      />
    </div>
  )
}

export default DateTimeRangeField
