import { format, parse } from "date-fns";
import { BaseSyntheticEvent, useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup, Overlay, Popover } from "react-bootstrap";
import { Calendar3 } from "react-bootstrap-icons";
import Calendar from 'react-calendar'
import { getDateStringFromDate, sanitizeTimeString, getTimeStringFromDate } from "../../../utils/helpers";

interface DateTimeFieldProps {
  formikSetFieldValue: Function;
  fieldName: string;
  initialValue: Date | undefined;
}

const DateTimeField = (props: DateTimeFieldProps) => {
  const [show, setShow] = useState<boolean>(false)
  const [value, setValue] = useState(props.initialValue);
  const [dateInputValue, setDateInputValue] = useState<string | undefined>(getDateStringFromDate(value));
  const [timeInputValue, setTimeInputValue] = useState<string | undefined>(getTimeStringFromDate(value));

  const target = useRef(null)

  const onDateChange = (date: any) => {
    setValue(date)
    setDateInputValue(format(date, 'yyyy-MM-dd'))
    setShow(false)
  }

  const onTimeChange = (event: BaseSyntheticEvent) => {
    setTimeInputValue(event.target.value)
  }

  const onTimeBlur = (event: BaseSyntheticEvent) => {
    setTimeInputValue(sanitizeTimeString(event.target.value))
  }

  useEffect(() => {
    // When either the date or time inputs change, merge their values and update the formik field value
    if (dateInputValue && timeInputValue) {
      const dateString = `${dateInputValue} ${timeInputValue}`
      const parsedDate = parse(dateString, 'yyyy-MM-dd h:mm a', new Date())
      props.formikSetFieldValue(props.fieldName, parsedDate)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateInputValue, timeInputValue])

  return (
    <div className="d-flex">
      <InputGroup>
        <Form.Control
          disabled={true}
          value={getDateStringFromDate(value)}
        />
        <InputGroup.Append>
          <Button
            ref={target}
            variant='primary'
            onClick={() => setShow(!show)}
          >
            <Calendar3 />
          </Button>
          <Overlay placement="bottom" target={target.current} show={show} rootClose={true} onHide={() => {setShow(false)}}>
            {(props) => (
              <Popover id="overlay-example" className="calendar-tooltip" {...props}>
                <Calendar calendarType="US" onChange={onDateChange} value={value} />
              </Popover>
            )}
          </Overlay>
        </InputGroup.Append>
      </InputGroup>

      <Form.Control
        className="ml-3 time-entry-input time-entry-time-input"
        maxLength={8}
        value={timeInputValue}
        onChange={onTimeChange}
        onBlur={onTimeBlur}
        placeholder="00:00 AM"
      />
    </div>
  )
}

export default DateTimeField
