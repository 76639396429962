import { useEffect } from 'react';
import { Alert, Container } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { putUserCurrentUrl } from '../../data/api-requests';

const LogoutComponent = () => {
  const cookies = new Cookies()

  const onLogout = async () => {
    // Wait for a second so any pending API requests can complete
    await new Promise(resolve => setTimeout(resolve, 1000))
    putUserCurrentUrl({
      current_url: cookies.get('tk_current_url')
    }).finally(() => {
      cookies.remove('tk_current_url', {path: '/'})
      cookies.remove('tk_auth_token', {path: '/'})
      cookies.remove('tk_auth_token_expiry', {path: '/'})
      cookies.remove('tk_user_id', {path: '/'})
      cookies.remove('tk_user_display_name', {path: '/'})
      cookies.remove('tk_user_rules', {path: '/'})
      window.location.href = '/'
    })
  }

  useEffect(() => {
    onLogout()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (<Container className="p-3"><Alert variant="info">Logging out...</Alert></Container>)
}

export default LogoutComponent
