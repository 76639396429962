// Configuration variables

export const apiBaseUrl = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : ''
export const apiKey = process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : false
export const useMockData = process.env.REACT_APP_USE_MOCK_DATA === '1' ? true : false

export const sessionTimeoutMinutes = process.env.REACT_APP_SESSION_TIMEOUT_MINUTES ?
  parseInt(process.env.REACT_APP_SESSION_TIMEOUT_MINUTES, 10)
  : 5

export const workCenterIds = {
  'blast': process.env.REACT_APP_WORK_CENTER_BLAST_ID
    ? parseInt(process.env.REACT_APP_WORK_CENTER_BLAST_ID, 10) : 1,
  'assembly': process.env.REACT_APP_WORK_CENTER_ASSEMBLY_ID
    ? parseInt(process.env.REACT_APP_WORK_CENTER_ASSEMBLY_ID, 10) : 8,
  'packaging': process.env.REACT_APP_WORK_CENTER_PACKAGING_ID
    ? parseInt(process.env.REACT_APP_WORK_CENTER_PACKAGING_ID, 10) : 6,
  'paint': process.env.REACT_APP_WORK_CENTER_PAINT_ID
    ? parseInt(process.env.REACT_APP_WORK_CENTER_PAINT_ID, 10) : 5,
  'prep': process.env.REACT_APP_WORK_CENTER_PREP_ID
    ? parseInt(process.env.REACT_APP_WORK_CENTER_PREP_ID, 10) : 4,
  'setup': process.env.REACT_APP_WORK_CENTER_SETUP_ID
    ? parseInt(process.env.REACT_APP_WORK_CENTER_SETUP_ID, 10) : 2,
  'wash': process.env.REACT_APP_WORK_CENTER_WASH_ID
    ? parseInt(process.env.REACT_APP_WORK_CENTER_WASH_ID, 10) : 3,
  'welding': process.env.REACT_APP_WORK_CENTER_WELDING_ID
    ? parseInt(process.env.REACT_APP_WORK_CENTER_WELDING_ID, 10) : 9,
}

export const allApplicableWorkCenters = [
  workCenterIds.blast,
  workCenterIds.assembly,
  workCenterIds.packaging,
  workCenterIds.paint,
  workCenterIds.prep,
  workCenterIds.setup,
  workCenterIds.wash,
  workCenterIds.welding,
]

export const userAdministrationRuleId = process.env.REACT_APP_USER_ADMINISTRATION_RULE_ID ?
  parseInt(process.env.REACT_APP_USER_ADMINISTRATION_RULE_ID, 10)
  : 3

export const workCenterGroupP1Id = process.env.REACT_APP_WORK_CENTER_GROUP_P1_ID ?
  parseInt(process.env.REACT_APP_WORK_CENTER_GROUP_P1_ID, 10)
  : 1

export const appEnvironment = process.env.REACT_APP_ENVIRONMENT ?
  process.env.REACT_APP_ENVIRONMENT
  : 'production'

export const preCleanerConcentrationSpec = process.env.REACT_APP_PRE_CLEANER_CONCENTRATION_SPEC ?
  process.env.REACT_APP_PRE_CLEANER_CONCENTRATION_SPEC
  : '9-16 ml'

export const preCleanerTempSpec = process.env.REACT_APP_PRE_CLEANER_TEMP_SPEC ?
  process.env.REACT_APP_PRE_CLEANER_TEMP_SPEC
  : '> 165 °F'

export const phosphateConcentrationSpec = process.env.REACT_APP_PHOSPHATE_CONCENTRATION_SPEC ?
  process.env.REACT_APP_PHOSPHATE_CONCENTRATION_SPEC
  : '9-18 ml'

export const phosphateTempSpec = process.env.REACT_APP_PHOSPHATE_TEMP_SPEC ?
  process.env.REACT_APP_PHOSPHATE_TEMP_SPEC
  : '> 165 °F'

export const phosphatePHSpec = process.env.REACT_APP_PHOSPHATE_PH_SPEC ?
  process.env.REACT_APP_PHOSPHATE_PH_SPEC
  : '4.5-5.5'

export const rustInhibConcentrationSpec = process.env.REACT_APP_RUST_INHIB_CONCENTRATION_SPEC ?
  process.env.REACT_APP_RUST_INHIB_CONCENTRATION_SPEC
  : '6-9 ml'

export const rustInhibTempSpec = process.env.REACT_APP_RUST_INHIB_TEMP_SPEC ?
  process.env.REACT_APP_RUST_INHIB_TEMP_SPEC
  : '> 165 °F'

export const rustInhibPHSpec = process.env.REACT_APP_RUST_INHIB_PH_SPEC ?
  process.env.REACT_APP_RUST_INHIB_PH_SPEC
  : '4.5-5.5'

export const rinseTDSSpec = process.env.REACT_APP_RINSE_TDS_SPEC ?
  process.env.REACT_APP_RINSE_TDS_SPEC
  : '< 40 ml'

export const rinseTempSpec = process.env.REACT_APP_RINSE_TEMP_SPEC ?
  process.env.REACT_APP_RINSE_TEMP_SPEC
  : '> 165 °F'

export const moQueueStageQueued = process.env.REACT_APP_MO_QUEUE_STAGE_QUEUED ?
  process.env.REACT_APP_MO_QUEUE_STAGE_QUEUED
  : 1
