import { BaseSyntheticEvent, useState, useEffect } from "react";
import { useState as useGlobalState } from '@hookstate/core'
import globalState from '../../common/context'
import Cookies from 'universal-cookie';
import { Button } from "react-bootstrap";
import { Plus, Stopwatch, XLg } from "react-bootstrap-icons";
import { useStopwatch } from "../../lib/react-timer-hook";
import { deleteTimeEntry, getTimeEntries, postTimeEntry, putTimeEntry } from "../../data/api-requests";
import { timeEntry, timeEntriesGroupedByDate, putTimeEntryType } from "../../data/types/time-tracking";
import { getDurationStringFromDates, triggerShopFloorTabsRefresh, roundDateToNearestMinute, triggerGlobalRefresh, triggerRunningTimersRefresh, getChangedData, getLocalTimeStringFromDate, getTimeDurationStringFromDates } from "../../utils/helpers";
import createNotification from "../../utils/notification";
import { Demodal } from "demodal";
import ConfirmModal from "../shared/modal/modal-confirm";
import { postTimeEntrySchema, putTimeEntrySchema } from "../shared/form/form-time-tracking";
import useCheckPermission from "../../hooks/use-check-permission";
import { prepTasks } from "../../data/types/prep-task";
import { workCenterIds } from "../../common/config";
import useGetData from "../../hooks/use-get-data";
import { prepTaskType } from "../../data/types/prep-task-type";
import { confirmDuration, groupTimeEntriesByDate, onAddTimeEntry, onDeleteTimeEntry, onEditTimeEntry } from "../shared/time-tracking/time-tracking-utils";
import TimeTrackingPrepTaskBadges from "../time-tracking/time-tracking-prep-task-badges";
import { staticResources } from "../../data/types/static-resources";


interface WorkOrderTimeTrackingProps {
  workOrderId: number;
  workCenterId: number;
  workCenterGroupId: number;
  workOrderCompleted: boolean;
  staticResources?: staticResources
  enableCreate?: boolean;
}

const WorkOrderTimeTracking = (props: WorkOrderTimeTrackingProps) => {
  const globals = useGlobalState(globalState)
  const cookies = new Cookies()
  const currentUserId = cookies.get('tk_user_id') ? Number(cookies.get('tk_user_id')) : 0
  const prepTasks: prepTasks[] = globals.prepTasks.get()
  const hasAdminPermission = useCheckPermission('WO Administrator')
  const [currentStartTime, setCurrentStartTime] = useState<Date>()
  const [currentTimeEntryId, setCurrentTimeEntryId] = useState<number>(0)
  const [prepTaskTypes, setPrepTaskTypes] = useState<prepTaskType[]>([])

  const fetchData = async () => {
    if (props.workOrderId && props.workCenterId) {
      const result = await getTimeEntries(props.workOrderId, props.workCenterId)
      if (result) return groupTimeEntriesByDate(result)
    }
  }

  const [timeEntriesByDate, refresh] = useGetData<timeEntriesGroupedByDate>({ fetchDataFn: fetchData, autoLoad: false })

  useEffect(() => {
    if (props.staticResources && props.staticResources.prep_task_types) {
      setPrepTaskTypes(props.staticResources.prep_task_types)
    }
  }, [props.staticResources])

  const {
    seconds,
    minutes,
    hours,
    isRunning,
    start,
    reset,
  } = useStopwatch({ autoStart: false });

  // If there are any incomplete time entries, populate the latest one into the running timer
  useEffect(() => {
    if (timeEntriesByDate.length === 0) {
      return
    }

    const flattenedTimeEntries = timeEntriesByDate.flatMap((value: timeEntriesGroupedByDate) => value.rows)
    const runningTimeEntry = flattenedTimeEntries.find((value: timeEntry) => !value.end_time && value.user_id === currentUserId)
    if (runningTimeEntry) {
      const startDate = runningTimeEntry.start_time
      if (startDate) {
        const offsetTimestamp = new Date()
        const offsetSeconds = (offsetTimestamp.getTime() - startDate.getTime()) / 1000
        if (offsetSeconds > 0) {
          offsetTimestamp.setSeconds(offsetSeconds)
          reset(offsetTimestamp, true)
          setCurrentStartTime(startDate)
          setCurrentTimeEntryId(runningTimeEntry.id)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeEntriesByDate])

  useEffect(() => {
    // Fetch time tracking data
    if (props.workOrderId > 0 && props.workCenterId > 0) {
      refresh()
    }

    // Clear the current timer
    reset(undefined, false)
    setCurrentStartTime(undefined)
    setCurrentTimeEntryId(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workOrderId, props.workCenterId])

  const validatePrepTasks = (time_entry_user_id: number) => {
    // Skip validation if the work center is not "Prep"
    if (props.workCenterId !== workCenterIds.prep) {
      return true
    }

    // Check if the specified user has an empty prep task row
    if (prepTasks && prepTasks.find((value: prepTasks) =>
      value.user_id === time_entry_user_id && value.active_prep_task_types.length === 0
    )) {
      Demodal.open(ConfirmModal, {
        title: "Must Complete Task",
        description: <>There is an empty task row related to this user. You must complete at least one of these tasks before creating another time entry.</>,
        confirmLabel: "OK",
        showCancel: false,
      })
      return false
    }

    return true
  }

  const getRoundedTime = () => {
    const roundedSeconds = Math.round(seconds / 60)
    return `${String(hours).padStart(2, '0')}:${String(minutes + roundedSeconds).padStart(2, '0')}`
  }

  const getFormattedTimeElement = () => {
    return <>{String(hours).padStart(2, '0')}<span className="time-separator">:</span>{String(minutes).padStart(2, '0')}</>
  }

  const onToggleTimerClick = async (event: BaseSyntheticEvent) => {
    event.preventDefault()
    if (isRunning) {
      // Stop timer
      // ====================================

      const endTime = new Date()
      let openEditAfterSaving: boolean = false

      // Validate that time is greater than 1 minute
      if (currentStartTime) {
        const diff = getDurationStringFromDates(currentStartTime, endTime)
        if (diff === '00:00') {
          createNotification('Time Duration Too Short', 'You must enter a time of at least 1 minute.')
          return false
        }
      }

      if (currentStartTime) {
        const modalResult = await confirmDuration(currentStartTime, endTime, 'Edit')
        if (!modalResult) openEditAfterSaving = true
      }

      // Update existing time tracking row with the end time
      const putData = putTimeEntrySchema.cast({
        start_time: roundDateToNearestMinute(currentStartTime),
        end_time: roundDateToNearestMinute(endTime),
      }, { stripUnknown: true }) // removes any extra keys

      putTimeEntry(currentTimeEntryId, putData).then(() => {
        const userName = cookies.get('tk_user_display_name') ? cookies.get('tk_user_display_name') : 'user'
        createNotification('Time Submitted', `${getRoundedTime()} submitted for ${userName}.`)
        reset(undefined, false)
        setCurrentStartTime(undefined)
        setCurrentTimeEntryId(0)
        refresh()
        triggerShopFloorTabsRefresh()
        triggerRunningTimersRefresh()

        if (openEditAfterSaving) {
          const timeEntries: timeEntry[] = timeEntriesByDate.flatMap((group: timeEntriesGroupedByDate) => (
            group.rows.filter((row: timeEntry) => row.id === currentTimeEntryId)
          ))
          if (timeEntries.length > 0) {
            timeEntries[0].end_time = new Date()
            onRowClick(timeEntries[0])
          }
        }
      })
    } else {
      // Start timer
      // ====================================

      if (!validatePrepTasks(currentUserId)) {
        return false
      }

      // Create new time tracking row with only the start time
      const startTime = new Date()
      setCurrentStartTime(startTime)

      const postData: any = postTimeEntrySchema.cast({
        user_id: currentUserId,
        start_time: roundDateToNearestMinute(startTime),
        is_billable: true,
      }, { stripUnknown: true }) // removes any extra keys

      postData['work_center_id'] = props.workCenterId
      postData['work_center_group_id'] = props.workCenterGroupId

      postTimeEntry(props.workOrderId, postData).then((result) => {
        if (result && 'id' in result) setCurrentTimeEntryId(result.id)
        refresh()
        triggerShopFloorTabsRefresh()
        triggerRunningTimersRefresh()
        triggerGlobalRefresh()
      })

      start()
    }
  }

  const deleteTimer = async (id: number) => {
    await deleteTimeEntry(id)
    createNotification('Deleted', `Deleted time entry.`)
    reset(undefined, false)
    setCurrentStartTime(undefined)
    setCurrentTimeEntryId(0)
    refresh()
    triggerShopFloorTabsRefresh()
    triggerRunningTimersRefresh()
    triggerGlobalRefresh()
  }

  const onCancelTimerClick = (event: BaseSyntheticEvent) => {
    event.preventDefault()
    deleteTimer(currentTimeEntryId)
  }

  const onDeleteRow = async (id: number) => {
    const modalResult = await onDeleteTimeEntry()
    if (modalResult) deleteTimer(id)
  }

  const onRowClick = async (row: timeEntry) => {
    const modalResult = await onEditTimeEntry({ timeEntry: row })
    if (!modalResult) return false

    if (modalResult === 'delete') {
      onDeleteRow(row.id)
    } else {
      let changedData: putTimeEntryType = getChangedData(row, modalResult)
      const editId = modalResult['id']
      if ('id' in changedData) changedData['id'] = 0

      const result = await putTimeEntry(editId, changedData)
      if (result) {
        createNotification('Edited', `Edited time entry.`)
        // Clear running timer if the user just entered an end time while editing the running timer
        if (
          isRunning
          && currentTimeEntryId === editId
          && modalResult['end_time']
        ) {
          reset(undefined, false)
          setCurrentStartTime(undefined)
          setCurrentTimeEntryId(0)
        }
        refresh()
        triggerShopFloorTabsRefresh()
        triggerRunningTimersRefresh()
      }
    }
  }

  const onAddItemClick = async (event: BaseSyntheticEvent) => {
    const modalResult = await onAddTimeEntry({
      hasAdminPermission: hasAdminPermission,
      workCenterId: props.workCenterId,
      currentUserId: currentUserId,
    })

    if (modalResult) {
      if (modalResult.end_time) {
        const confirmModalResult = await confirmDuration(modalResult.start_time, modalResult.end_time)
        if (!confirmModalResult) return false
      }

      if (!validatePrepTasks(modalResult['user_id'])) {
        return false
      }
      if ('id' in modalResult) modalResult['id'] = 0
      if ('work_order_id' in modalResult) delete modalResult['work_order_id']

      modalResult['work_center_id'] = props.workCenterId
      modalResult['work_center_group_id'] = props.workCenterGroupId

      const result = await postTimeEntry(props.workOrderId, modalResult)
      if (result) {
        createNotification('Submitted', `Added time entry.`)
        refresh()
        triggerShopFloorTabsRefresh()
        triggerRunningTimersRefresh()
        triggerGlobalRefresh()
      }
    }
  }

  return (
    <div className="work-order-section work-order-time-tracking">
      <header className="work-order-section-header work-order-time-tracking-header">
        <h2 className="mr-2">Time Tracking</h2>
        <div className="d-flex align-items-stretch">
          {props.enableCreate && (
            <Button onClick={onAddItemClick} disabled={props.workOrderCompleted}><Plus /></Button>
          )}
          {(props.enableCreate || isRunning) && (
            <>
              <Button
                className="ml-2"
                variant={isRunning ? 'danger' : 'primary'}
                onClick={onToggleTimerClick}
                disabled={props.workOrderCompleted}
              >
                <Stopwatch className="d-inline-block align-middle mr-2" />
                <span className="d-inline-block align-middle">{isRunning ? 'Stop' : 'Start'}</span>
                {isRunning && (
                  <span className="d-inline-block align-middle time-tracking-time-display">{getFormattedTimeElement()}</span>
                )}
              </Button>
              {isRunning && (
                <Button variant="none" onClick={onCancelTimerClick} title="Cancel">
                  <XLg />
                </Button>
              )}
            </>
          )}
        </div>
      </header>

      <table className="table table-hover table-bordered time-entries m-0 mt-3">
        <tbody>
          {timeEntriesByDate.map((group: timeEntriesGroupedByDate) => (
            [
              <tr key={group.date} className="time-entry-heading-row">
                <td colSpan={5}>
                  <div className="d-flex">
                    <div>{group.date}</div>
                    <div className="ml-auto time-entry-qty-label">QTY</div>
                  </div>
                </td>
              </tr>,
              group.rows.map((row: timeEntry) => (
                <tr key={row.id} className="time-entry-row" onClick={() => { onRowClick(row) }}>
                  <td>{row.user_display_name}</td>
                  <td className="time-entry-times-cell">
                    <div className="d-flex">
                      <div>
                        {row.start_time && getLocalTimeStringFromDate(row.start_time)}
                        <span> &ndash; </span>
                        {row.end_time && getLocalTimeStringFromDate(row.end_time)}
                      </div>
                      {row.start_time && row.end_time && (
                        <div className="ml-auto text-muted">({getTimeDurationStringFromDates(row.start_time, row.end_time)})</div>
                      )}
                    </div>
                    {row.description && (
                      <p className="time-entry-description">{row.description}</p>
                    )}
                    {props.workCenterId === workCenterIds.prep && (
                      <TimeTrackingPrepTaskBadges prepTaskTypes={prepTaskTypes} activePrepTaskTypes={row.active_prep_task_types} />
                    )}
                  </td>
                  <td className="time-entry-qty-cell">{row.quantity}</td>
                </tr>
              ))
            ]
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default WorkOrderTimeTracking