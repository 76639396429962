import { Col } from "react-bootstrap";
import { workCenter, workOrder } from "../../data/types/work-order"
import WorkOrderInstructions from "./work-order-instructions";
import WorkOrderParts from "./work-order-parts";
import WorkOrderTimeTracking from "./work-order-time-tracking";
import WorkOrderComments from "./work-order-comments";
import WorkOrderApplicablePaints from "./work-order-applicable-paints";
import WorkOrderReviewCompleted from "./work-order-review-completed";
import WorkOrderAttachments from "./work-order-upload-attachments";
import WorkOrderCuring from "./work-order-curing";
import WorkOrderPaintUsage from "./work-order-paint-usage";
import WorkOrderPositector from "./work-order-positector";
import WorkOrderMekTest from "./work-order-mek-test";
import WorkOrderDeliveries from "./work-order-deliveries";
import WorkOrderDeliveriesCompleted from "./work-order-deliveries-completed";
import WorkOrderCompleted from "./work-order-completed";
import WorkOrderReceivedParts from "./work-order-received-parts";
import WorkOrderPalletsUsed from "./work-order-pallets-used";
import { staticResources } from "../../data/types/static-resources";
import WorkOrderCompletionReport from "./work-order-completion-report";
import WorkOrderInstructionsReport from "./work-order-instructions-report";
import WorkOrderTimeTrackingReport from "./work-order-time-tracking-report";
import WorkOrderCommentsReport from "./work-order-comments-report";
import WorkOrderTitration from "./work-order-titration";
import { part } from "../../data/types/parts";

interface WorkOrderSectionsProps {
  currentTab: string;
  parts: part[];
  workOrderId: number;
  workOrder: workOrder;
  workCenterId: number;
  workCenterGroupId: number;
  workCenters: workCenter[];
  staticResources?: staticResources;
  enableTimeTrackingCreate?: boolean;
}

const WorkOrderSections = (props: WorkOrderSectionsProps) => {
  return (
    <>
      {/* Review */}

      {(props.currentTab === 'review') && (
        <>
          <Col className="work-order-col">
            <WorkOrderParts workOrder={props.workOrder} parts={props.parts} />
            <WorkOrderReceivedParts workOrder={props.workOrder} />
          </Col>

          <Col className="work-order-col">
            <WorkOrderInstructions workOrder={props.workOrder} workCenter='review' workCenters={props.workCenters} />
          </Col>

          <Col className="work-order-col">
            <WorkOrderApplicablePaints workOrderId={props.workOrderId} />
            <WorkOrderReviewCompleted workOrderId={props.workOrderId} reviewCompleted={Boolean(props.workOrder.odoo_review_completed)} />
            <WorkOrderAttachments workOrderId={props.workOrderId} />
          </Col>
        </>
      )}

      {/* Blast */}
      {/* Welding */}
      {/* Setup */}
      {/* Wash */}
      {/* Assembly */}
      {/* Prep */}

      {['blast', 'welding', 'setup', 'wash', 'prep', 'assembly'].includes(props.currentTab) && (
        <>
          <Col className="work-order-col">
            <WorkOrderInstructions workOrder={props.workOrder} workCenter={props.currentTab} workCenterId={props.workCenterId} workCenters={props.workCenters}/>
            {props.currentTab === 'setup' && (
              <WorkOrderApplicablePaints workOrderId={props.workOrderId} readOnly={true} groupByPaintCategory={true} />
            )}
            <WorkOrderParts workOrder={props.workOrder} parts={props.parts} />
            <WorkOrderReceivedParts workOrder={props.workOrder} />
          </Col>

          <Col className="work-order-col">
            <WorkOrderTimeTracking
              enableCreate={props.enableTimeTrackingCreate}
              staticResources={props.staticResources}
              workOrderId={props.workOrderId}
              workCenterId={props.workCenterId}
              workCenterGroupId={props.workCenterGroupId}
              workOrderCompleted={Boolean(props.workOrder.odoo_work_order_completed)}
            />
          </Col>

          <Col className="work-order-col">
            {props.currentTab === 'wash' && (
              <WorkOrderTitration workOrderId={props.workOrderId} readOnly={false} />
            )}
            <WorkOrderComments workOrder={props.workOrder} workCenterId={props.workCenterId} />
          </Col>
        </>
      )}

      {/* Paint */}

      {(props.currentTab === 'paint') && (
        <>
          <Col className="work-order-col">
            <WorkOrderInstructions workOrder={props.workOrder} workCenter={props.currentTab} workCenterId={props.workCenterId} workCenters={props.workCenters}/>
            <WorkOrderApplicablePaints workOrderId={props.workOrderId} readOnly={true} groupByPaintCategory={true} />
            <WorkOrderParts workOrder={props.workOrder} parts={props.parts} />
            <WorkOrderReceivedParts workOrder={props.workOrder} />
          </Col>

          <Col className="work-order-col">
            <WorkOrderTimeTracking
              enableCreate={props.enableTimeTrackingCreate}
              staticResources={props.staticResources}
              workOrderId={props.workOrderId}
              workCenterId={props.workCenterId}
              workCenterGroupId={props.workCenterGroupId}
              workOrderCompleted={Boolean(props.workOrder.odoo_work_order_completed)}
            />
          </Col>

          <Col className="work-order-col">
            <WorkOrderCuring workOrderId={props.workOrderId} />
            <WorkOrderPaintUsage workOrderId={props.workOrderId} workOrderCompleted={Boolean(props.workOrder.odoo_work_order_completed)} />
            <WorkOrderComments workOrder={props.workOrder} workCenterId={props.workCenterId} />
          </Col>
        </>
      )}

      {/* Packaging */}

      {(props.currentTab === 'packaging') && (
        <>
          <Col className="work-order-col">
            <WorkOrderInstructions workOrder={props.workOrder} workCenter={props.currentTab} workCenterId={props.workCenterId} workCenters={props.workCenters}/>
            <WorkOrderParts workOrder={props.workOrder} parts={props.parts} showMarkAsDoneButton={true}/>
            <WorkOrderReceivedParts workOrder={props.workOrder} />
          </Col>

          <Col className="work-order-col">
            <WorkOrderTimeTracking
              enableCreate={props.enableTimeTrackingCreate}
              staticResources={props.staticResources}
              workOrderId={props.workOrderId}
              workCenterId={props.workCenterId}
              workCenterGroupId={props.workCenterGroupId}
              workOrderCompleted={Boolean(props.workOrder.odoo_work_order_completed)}
            />
            <WorkOrderCompleted workOrder={props.workOrder} />
          </Col>

          <Col className="work-order-col">
            <WorkOrderPositector workOrderId={props.workOrderId} />
            <WorkOrderMekTest workOrder={props.workOrder} />
            <WorkOrderPalletsUsed workOrder={props.workOrder}/>
            <WorkOrderComments workOrder={props.workOrder} workCenterId={props.workCenterId} />
          </Col>
        </>
      )}

      {/* Delivery */}

      {(props.currentTab === 'delivery') && (
        <>
          <Col className="work-order-col">
            <WorkOrderInstructions workOrder={props.workOrder} workCenter={props.currentTab} workCenterId={props.workCenterId} workCenters={props.workCenters}/>
            <WorkOrderParts workOrder={props.workOrder} parts={props.parts} showMarkAsDoneButton={true}/>
            <WorkOrderReceivedParts workOrder={props.workOrder} />
          </Col>

          <Col className="work-order-col">
            <WorkOrderDeliveries workOrderId={props.workOrderId} odooWorkOrderId={Number(props.workOrder.odoo_work_order_id)} />
            <WorkOrderDeliveriesCompleted workOrderId={props.workOrderId} workOrderCompleted={Boolean(props.workOrder.odoo_work_order_completed)} deliveriesCompleted={Boolean(props.workOrder.odoo_delivery_completed)} />
          </Col>

          <Col className="work-order-col"></Col>
        </>
      )}

      {/* Report */}
      {(props.currentTab === 'report') && (
        <>
          <Col className="work-order-col">
            <WorkOrderCompletionReport workOrder={props.workOrder} />
            <WorkOrderInstructionsReport workOrder={props.workOrder} workCenters={props.workCenters}/>
            <WorkOrderParts workOrder={props.workOrder} parts={props.parts} showCompletionStatus={true} />
            <WorkOrderReceivedParts workOrder={props.workOrder} readOnly={true} />
          </Col>

          <Col className="work-order-col">
            <WorkOrderTimeTrackingReport workOrderId={props.workOrderId} staticResources={props.staticResources} />
          </Col>

          <Col className="work-order-col">
            <WorkOrderCommentsReport workOrder={props.workOrder} staticResources={props.staticResources} />
            {props.workCenters.find((element: workCenter) => {
              return element.work_center_name === 'Wash'
            }) && (
              <WorkOrderTitration workOrderId={props.workOrderId} readOnly={true} />
            )}
            <WorkOrderApplicablePaints workOrderId={props.workOrderId} readOnly={true} />
            <WorkOrderPaintUsage
              workOrderId={props.workOrderId}
              workOrderCompleted={Boolean(props.workOrder.odoo_work_order_completed)}
              readOnly={true}
            />
            <WorkOrderCuring workOrderId={props.workOrderId} readOnly={true} />
            <WorkOrderPositector workOrderId={props.workOrderId} readOnly={true} />
            <WorkOrderDeliveries
              workOrderId={props.workOrderId}
              odooWorkOrderId={Number(props.workOrder.odoo_work_order_id)}
              readOnly={true}
            />

          </Col>
        </>
      )}
    </>
  )
}

export default WorkOrderSections
