import { Demodal } from "demodal";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FileImage, FilePdf } from "react-bootstrap-icons";
import { deleteAttachment } from "../../data/api-requests";
import { workOrder, workOrderAttachment } from "../../data/types/work-order";
import useCheckPermission from "../../hooks/use-check-permission";
import { triggerGlobalRefresh } from "../../utils/helpers";
import AttachmentImage from "../shared/attachment-image";
import ConfirmModal from "../shared/modal/modal-confirm";
import AttachmentsModal, { AttachmentsModalResult } from "../shared/modal/modal-view-attachments";

interface WorkOrderAttachmentsListProps {
  workOrder: workOrder;
}

const WorkOrderAttachmentsList = (props: WorkOrderAttachmentsListProps) => {
  const hasAdminPermission = useCheckPermission('WO Administrator')
  const hasReviewPermission = useCheckPermission('Review WO')

  const openAttachments = async (initialAttachmentId: number) => {
    const result: AttachmentsModalResult | boolean = await Demodal.open(AttachmentsModal, {
      attachments: props.workOrder.attachments,
      initialAttachmentId: initialAttachmentId,
      showDelete: hasAdminPermission || hasReviewPermission,
    })
    if (typeof result !== 'boolean' && result.action === 'delete') {
      const modalResult = await Demodal.open(ConfirmModal, {
        title: 'Delete attachment?',
        description: 'Please confirm you would like to delete the attachment.',
      })
      if (modalResult) {
        await deleteAttachment(result.id)
        triggerGlobalRefresh()
      }
    }
  }

  const getPreview = (attachment: workOrderAttachment) => {
    let preview = <FileImage className="attachment-icon text-info" size={48} />

    if (attachment.file_name.includes('pdf')) {
      preview = <FilePdf className="attachment-icon text-danger" size={48} />
    } else {
        preview = <AttachmentImage attachmentId={attachment.id} />
    }

    return preview
  }

  return (
    <ul className="work-order-attachments-list">
      {props.workOrder.attachments.map((attachment: workOrderAttachment) => (
        <li key={attachment.id} className="work-order-attachments-item">
          <OverlayTrigger placement='bottom' overlay={<Tooltip id="attachment-tooltip">{attachment.file_name}</Tooltip>}>
            <Button
              variant="custom"
              className="tk-thumbnail-wrapper attachment-button"
              onClick={() => {openAttachments(attachment.id)}}
            >
              {getPreview(attachment)}
            </Button>
          </OverlayTrigger>
        </li>
      ))}
    </ul>
  )
}

export default WorkOrderAttachmentsList