import * as Yup from 'yup';
import { yupTestDateIsAfter, yupTestDateIsPast, yupTestEndTimeRequired, yupTestWorkCenterRequired, yupTransformDate } from '../../../utils/helpers';
import { formField } from '../modal/modal-item';
import { formFieldTypes } from '../../../data/types/form-field-type';

// Form Fields
// ------------------------------------------------------------------

// Editing
export const timeTrackingFormFieldsPut: formField[][] = [
  [
    {
      name: 'start_time',
      label: 'Time',
      type: formFieldTypes.datetimeRange,
    },
    {
      name: 'quantity',
      label: 'Quantity',
      type: formFieldTypes.number,
    },
    {
      name: 'active_prep_task_types',
      label: 'Prep Tasks',
      type: formFieldTypes.prepTasks,
    },
    {
      name: 'description',
      label: 'Description',
      type: formFieldTypes.textarea,
    },
  ]
]

// Editing - Non-billable
export const nonBillableTimeTrackingFormFieldsPut: formField[][] = [[...timeTrackingFormFieldsPut[0]]]

// - Add fields after end_time
nonBillableTimeTrackingFormFieldsPut[0].splice(
  2, 0,
  {
    name: 'work_order_id',
    label: 'Work Order',
    type: formFieldTypes.workOrder,
  },
  {
    name: 'work_center_group_id',
    label: 'Location',
    type: formFieldTypes.locationCombo,
  },
)

// - Remove quantity field
nonBillableTimeTrackingFormFieldsPut[0] = nonBillableTimeTrackingFormFieldsPut[0].filter(
  (field: formField) => field.name !== 'quantity'
)

// Adding
export const timeTrackingFormFieldsPost: formField[][] = [
  [
    {
      name: 'start_time',
      label: 'Time',
      type: formFieldTypes.datetimeRange,
    },
    {
      name: 'quantity',
      label: 'Quantity',
      type: formFieldTypes.number,
    },
    {
      name: 'active_prep_task_types',
      label: 'Prep Tasks',
      type: formFieldTypes.prepTasks,
    },
    {
      name: 'description',
      label: 'Description',
      type: formFieldTypes.textarea,
    },
    {
      name: 'user_id',
      label: 'User',
      type: formFieldTypes.user,
    },
  ]
]

// Adding - Non-billable
export const nonBillableTimeTrackingFormFieldsPost: formField[][] = [[...timeTrackingFormFieldsPost[0]]]

// - Add fields after end_time
nonBillableTimeTrackingFormFieldsPost[0].splice(
  2, 0,
  {
    name: 'work_order_id',
    label: 'Work Order',
    type: formFieldTypes.workOrder,
  },
  {
    name: 'work_center_group_id',
    label: 'Location',
    type: formFieldTypes.locationCombo,
  },
)

// - Remove quantity field
nonBillableTimeTrackingFormFieldsPost[0] = nonBillableTimeTrackingFormFieldsPut[0].filter(
  (field: formField) => field.name !== 'quantity'
)

// Validation Rules
// ------------------------------------------------------------------

export const putTimeEntrySchema = Yup.object().shape({
  id: Yup.number(),
  start_time: Yup.date()
    .required('Required')
    .typeError('Must enter a valid date and time')
    .transform(yupTransformDate)
    .test('dateIsAfter', 'The end date must be after the start date', yupTestDateIsAfter.bind(this))
    .test('dateIsPast', 'The end time must not be in the future.', yupTestDateIsPast.bind(this)),
  end_time: Yup.date()
    .typeError('Must enter a valid date and time')
    .nullable(),
  quantity: Yup.number()
    .typeError('You must specify a number')
    .nullable(true)
    .transform((v) => (v === '' || Number.isNaN(v) ? null : v)),
  active_prep_task_types: Yup.array(),
  description: Yup.string().nullable(true),
  is_billable: Yup.bool(),
})

export const postTimeEntrySchema = putTimeEntrySchema.shape({
  start_time: Yup.date()
    .required('Required')
    .typeError('Must enter a valid date and time')
    .transform(yupTransformDate)
    .test('dateIsAfter', 'The end date must be after the start date', yupTestDateIsAfter.bind(this))
    .test('dateIsPast', 'The end time must not be in the future.', yupTestDateIsPast.bind(this))
    .test('endTimeRequired', 'End time required.', yupTestEndTimeRequired.bind(this)),
  end_time: Yup.date()
    .typeError('Must enter a valid date and time')
    .transform(yupTransformDate)
    .nullable(true),
  user_id: Yup.number()
    .required('Required')
    .min(1, 'Required'),
})

export const postNonBillableWithWorkOrderSchema = postTimeEntrySchema.shape({
  work_order_id: Yup.number().required('Required').typeError('Required'),
})
export const postNonBillableWithLocationSchema = postTimeEntrySchema.shape({
  work_center_group_id: Yup.number()
    .required('Required')
    .test('workCenterRequired', 'Work center required.', yupTestWorkCenterRequired.bind(this)),
})
export const putNonBillableWithWorkOrderSchema = putTimeEntrySchema.shape({
  work_order_id: Yup.number().required('Required').typeError('Required'),
})
export const putNonBillableWithLocationSchema = putTimeEntrySchema.shape({
  work_center_group_id: Yup.number()
  .required('Required')
  .test('workCenterRequired', 'Work center required.', yupTestWorkCenterRequired.bind(this)),
})