import { Demodal } from "demodal";
import { BaseSyntheticEvent, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import BootstrapTable from "react-bootstrap-table-next";
import { deletePaintCuring, getPaintCuring, postPaintCuring, putPaintCuring } from "../../data/api-requests";
import { paintCure } from "../../data/types/paint-curing";
import useGetData from "../../hooks/use-get-data";
import createNotification from "../../utils/notification";
import { paintCuringFormFields, paintCuringFormSchema } from "../shared/form/form-paint-curing";
import ConfirmModal from "../shared/modal/modal-confirm";
import ItemModal from "../shared/modal/modal-item";
import { format } from "date-fns";

interface WorkOrderCuringProps {
  workOrderId: number;
  readOnly?: boolean;
}

const WorkOrderCuring = (props: WorkOrderCuringProps) => {
  const fetchData = async () => {
    const result = await getPaintCuring(props.workOrderId)
    if (result) return result
  }

  const [paintCures, refresh] = useGetData<paintCure>({
    autoLoad: false,
    fetchDataFn: fetchData,
  })

  // Load on work order ID change
  useEffect(() => {
    if (props.workOrderId) refresh()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workOrderId])

  const onAddItemClick = async (event: BaseSyntheticEvent) => {
    const modalResult: any = await Demodal.open(ItemModal, {
      itemName: 'Paint Cure',
      formData: {},
      formFields: paintCuringFormFields,
      formSchema: paintCuringFormSchema
    })

    if (modalResult) {
      await postPaintCuring(props.workOrderId, modalResult)
      createNotification('Curing Submitted', `Added paint cure.`)
      refresh()
    }
  }

  const onDeleteItemClick = async (paintCureId: number) => {
    const modalResult: any = await Demodal.open(ConfirmModal, {
      title: 'Delete Paint Cure?',
      description: <>Please confirm deletion.</>,
    })

    if (modalResult) {
      await deletePaintCuring(paintCureId)
      createNotification('Deleted', 'Deleted paint cure.')
      refresh()
    }
  }

  const onRowClick = async (row: paintCure) => {
    const formData = {
      ...paintCuringFormSchema.cast(row, {stripUnknown: true}),
      'description': `Created by ${row.user_name} on ${format(row.created_on, 'MMM d, yyyy')}`
    }

    const modalResult: any = await Demodal.open(ItemModal, {
      itemName: 'Paint Cure',
      formData: formData,
      formFields: paintCuringFormFields,
      formSchema: paintCuringFormSchema,
      canDelete: true,
    })

    if (modalResult) {
      if (modalResult === 'delete') {
        onDeleteItemClick(row.id)
      } else {
        await putPaintCuring(row.id, modalResult)
        createNotification('Edited', 'Edited paint cure.')
        refresh()
      }
    }
  }

  const rowEvents = {
    onClick: (e: BaseSyntheticEvent, row: paintCure, rowIndex: number) => {
      if (!props.readOnly) onRowClick(row)
    }
  }

  const tableColumns = [
    {
      dataField: 'temperature',
      text: 'Temp (F)',
      sort: true,
    },
    {
      dataField: 'time_in_minutes',
      text: 'Time (min)',
    },
  ]

  return (
    <div className="work-order-section work-order-curing">
      <header className="work-order-section-header work-order-curing-header">
        <h2>Curing</h2>
        {!props.readOnly && (<Button onClick={onAddItemClick}><Plus/></Button>)}
      </header>

      <div className="mt-3">
        <BootstrapTable
          keyField="id"
          data={ paintCures }
          columns={ tableColumns }
          defaultSorted={[{dataField: 'id', order: 'asc'}]}
          hover
          rowEvents={ rowEvents }
          bootstrap4
        />
      </div>
    </div>
  )
}

export default WorkOrderCuring