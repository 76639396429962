import { BaseSyntheticEvent, useState } from "react"
import { Button, Dropdown, Form } from "react-bootstrap"
import { workCenterGroup } from "../../data/types/work-order"

interface WorkOrderOverviewFiltersProps {
  workCenterGroups: workCenterGroup[];
  initialValue: number[];
  onFilteredWorkCenterGroupsChange: Function;
}

const WorkOrderOverviewFilters = (props: WorkOrderOverviewFiltersProps) => {
  const [selectedWorkCenterGroups, setSelectedWorkCenterGroups] = useState<number[]>(props.initialValue)

  const getSelectedWorkCenterGroupNames = () => {
    return props.workCenterGroups
      .filter((workCenterGroup: workCenterGroup) => selectedWorkCenterGroups.includes(workCenterGroup.id))
      .map((workCenterGroup: workCenterGroup) => workCenterGroup.work_center_group_name)
      .join(', ')
  }

  const onClearClick = (e: BaseSyntheticEvent) => {
    setSelectedWorkCenterGroups([])
    props.onFilteredWorkCenterGroupsChange([])
  }

  const onUpdateClick = (e: BaseSyntheticEvent) => {
    const ruleId = parseInt(e.target.id, 10)
    if (e.target.checked) {
        selectedWorkCenterGroups.push(ruleId)
    } else {
        const index = selectedWorkCenterGroups.indexOf(ruleId)
        if (index > -1) selectedWorkCenterGroups.splice(index, 1)
    }
    setSelectedWorkCenterGroups([...selectedWorkCenterGroups])
    props.onFilteredWorkCenterGroupsChange([...selectedWorkCenterGroups])
  }

  return (
    <>
      <div className="mr-3">Filter</div>
      <Dropdown alignRight={true}>
        <Dropdown.Toggle
          variant="primary"
          className="location-filter-dropdown-toggle"
          id="location-filter-dropdown-toggle"
        >
          {selectedWorkCenterGroups.length > 0 ? (
            <>{getSelectedWorkCenterGroupNames()}</>
          ) : (
            <>In-Progress Locations</>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu className="location-filter-dropdown">
          <div className="px-3">
            {props.workCenterGroups.map((workCenterGroup: workCenterGroup) => (
              <Form.Check
                  key={workCenterGroup.id}
                  custom
                  id={String(workCenterGroup.id)}
                  label={workCenterGroup.work_center_group_name}
                  type="checkbox"
                  className="location-list-item"
                  onChange={onUpdateClick}
                  checked={selectedWorkCenterGroups.includes(workCenterGroup.id)}
              />
            ))}
          </div>
          <div className="px-2">
            <Button
              variant="light"
              className="mt-2 w-100 text-muted"
              onClick={onClearClick}
            >
                Clear Selection
            </Button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default WorkOrderOverviewFilters
