import { moQueue } from "./mo-queue";

export interface pickComponent {
  id: number;
  name: string;
  state: string;
}

export interface part {
  id: number;
  mo_id: number;
  mo_name: string;
  name: string;
  description: string;
  thumbnail: string;
  default_code: string;
  qty: number;
  qty_to_produce: number | undefined;
  done: boolean;
  serial_required: boolean;
  serial_number: string;
  pick_components: pickComponent[] | undefined;
  mo_queue: moQueue[];
  mo_not_done: boolean;
}

export const emptyPart: part = {
  id: 0,
  mo_id: 0,
  mo_name: '',
  name: '',
  description: '',
  thumbnail: '',
  default_code: '',
  qty: 0,
  qty_to_produce: 0,
  done: false,
  serial_required: false,
  serial_number: '',
  pick_components: [],
  mo_queue: [],
  mo_not_done: false,
}

export const partsMockData: part[] = [
  {
    id: 1,
    mo_id: 1,
    mo_name: 'WH/MO/00001',
    name: 'Tractor attachment with loose parts',
    description: '',
    thumbnail: '',
    default_code: 'TK000001',
    qty: 2,
    qty_to_produce: 2,
    done: false,
    serial_required: false,
    serial_number: '',
    pick_components: [],
    mo_queue: [],
    mo_not_done: false,
  },
  {
    id: 2,
    mo_id: 2,
    mo_name: 'WH/MO/00002',
    name: 'Wheel Fender',
    description: '',
    thumbnail: '',
    default_code: 'TK000002',
    qty: 32,
    qty_to_produce: 32,
    done: true,
    serial_required: false,
    serial_number: '',
    pick_components: [],
    mo_queue: [],
    mo_not_done: false,
  },
]
