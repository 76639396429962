import { useState as useGlobalState } from '@hookstate/core'
import { useEffect } from 'react'
import globalState from '../../common/context'
import { syncData } from '../../data/api-requests'
import { triggerGlobalRefresh } from '../../utils/helpers'

const SyncData = () => {
  const globals = useGlobalState(globalState)
  const triggerSync = globals.triggerSync.get()

  // Watch for refresh event
  useEffect(() => {
    if (triggerSync) {
      syncData()
      .finally(() => {
        globals.isLoading.set(false)
        globals.triggerSync.set(false)
        triggerGlobalRefresh()
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSync])

  return <></>
}

export default SyncData
