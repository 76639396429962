import { debounce } from "lodash";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Badge, Dropdown, Form, Nav, Spinner } from "react-bootstrap";
import { getWorkOrders } from "../../../data/api-requests";
import { workOrder } from "../../../data/types/work-order";
import { useDropdownHeight } from "../../../utils/hooks";


interface WorkOrderDropdownProps {
  initialIdValue: number;
  initialFilterValue: string;
  formikSetFieldValue: Function;
  fieldName: string;
  initialWorkOrderNameValue?: string;
  staticDropdownTitle?: string;
}

const WorkOrderDropdown = (props: WorkOrderDropdownProps) => {
  const [data, setData] = useState<workOrder[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataFiltered, setDataFiltered] = useState<any>([])
  const [filterValue, setFilterValue] = useState<string>('')
  const [currentValue, setCurrentValue] = useState<workOrder>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownMaxHeight = useDropdownHeight(dropdownRef, isOpen)

  const sortWorkOrders = (workOrderData: workOrder[], filterStr: string) => {
    return workOrderData.sort((a: workOrder, b: workOrder) =>
      String(a.odoo_work_order_name).toLowerCase().includes(filterStr.toLowerCase()) ? -1 : 0
    )
  }

  const debouncedSearch = useRef(
    debounce(async (searchTerm) => {
      let result = []
      if (searchTerm.length > 2) {
        setIsLoading(true)
        result = await getWorkOrders('', searchTerm)
        setIsLoading(false)
      }
      setData(result)
    }, 500)
  ).current

  const onFilterInputChange = (event: any) => {
    const fieldValue = event.target.value
    const newValue = fieldValue.replace(/([^a-zA-Z0-9]+)/gi, '')
    setFilterValue(newValue)
  }

  // Focus on filter input when opening dropdown
  useLayoutEffect(() => {
    if (isOpen) {
      inputRef.current!.focus()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  // Set initial filter value
  useEffect(() => {
    setFilterValue(props.initialFilterValue)
  }, [props.initialFilterValue])

  // Set selected work order
  useEffect(() => {
    const initialValue = data.find((item: workOrder) => item.id === props.initialIdValue)
    if (initialValue) setCurrentValue(initialValue)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() =>  {
    filterValue ? debouncedSearch(filterValue) : setData([])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue])

  useEffect(() =>  {
    let workOrderData = [...data]
    if (filterValue) {
      workOrderData = sortWorkOrders(workOrderData, filterValue)
    }
    setDataFiltered([...workOrderData])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // Cancel the debounced function when component is unmounted
  useEffect(() => (
    () => { debouncedSearch.cancel() }
  ), [debouncedSearch])

  const onDropdownToggle = (isOpen:boolean) => {
    setIsOpen(isOpen)
  }

  const onSelectItem = (value: string | null) => {
    if (typeof value === 'string') {
      const newId = Number(value)
      const matchingRow = dataFiltered.find((item: workOrder) => item.id === newId)
      if (matchingRow) {
        setCurrentValue(matchingRow)
        props.formikSetFieldValue(props.fieldName, matchingRow.id)
      }
      setFilterValue('')
    }
  }

  const getDropdownTitle = () => {
    let title = 'Select...'

    if (props.staticDropdownTitle) {
      title = props.staticDropdownTitle
    } else if (props.initialWorkOrderNameValue) {
      title = props.initialWorkOrderNameValue
    } else if (currentValue) {
      title = currentValue.odoo_work_order_name
    }

    return title
  }

  const getNoun = () => (dataFiltered.length === 1) ? 'result' : 'results'

  return (
    <Dropdown onToggle={onDropdownToggle}>
      <Dropdown.Toggle variant="primary" className="work-order-dropdown-toggle" id="work-order-menu">
        <>{getDropdownTitle()}</>
      </Dropdown.Toggle>

      <Dropdown.Menu ref={dropdownRef} className="tk-dropdown-field work-order-dropdown" style={{overflowY: 'scroll', maxHeight: dropdownMaxHeight}}>
        <div className="">
          <div className="tk-dropdown-field__header">
            <Form.Control
              ref={inputRef}
              type="text"
              value={filterValue}
              onChange={onFilterInputChange}
              placeholder="Search..."
            />
            {((!isLoading && filterValue && filterValue.length > 2) || data.length > 0) && (
              <div className="tk-dropdown-field__status-bar px-3">
                <strong>{dataFiltered.length}</strong>{" "}{getNoun()} found.
              </div>
            )}
            {(isLoading) && (
              <div className="text-center my-3">
                <Spinner animation="border" variant="info" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            )}
          </div>
          <Nav
            className="flex-column tk-dropdown-field__menu-items"
            onSelect={onSelectItem}
          >
            {dataFiltered.map((item: workOrder) => (
              <Nav.Link key={item.id} eventKey={item.id}>
                <div className="tk-dropdown-field-title tk-dropdown-field-text text-truncate">
                  {item.odoo_work_order_name}
                </div>
                <div className="tk-dropdown-field__description">
                  {item.odoo_description}
                </div>
                <div className="tk-dropdown-field__meta">
                  <div><Badge variant="info" title={item.odoo_customer_name}>{item.odoo_customer_name}</Badge></div>
                  <div className="ml-auto tk-dropdown-field-text">PO: {item.odoo_customer_po_ref ? item.odoo_customer_po_ref : <>&mdash;</>}</div>
                </div>
              </Nav.Link>
            ))}
          </Nav>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default WorkOrderDropdown
