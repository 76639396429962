import { applicableWorkCenter } from "./applicable-work-center";
import { comment } from "./comments";
import { instruction } from "./instruction";
import { part } from "./parts";

export interface currentLocation {
  id: number;
  is_active: boolean;
  has_time_tracked: boolean | null;
  is_completed: boolean;
  work_center_group_id: number;
  work_order_id: number;
}
export interface assignedLocation {
  id: number;
  is_active: boolean;
  work_center_group_id: number;
  work_order_id: number;
}

export interface workOrderAttachment {
  id: number;
  file_name: string;
}

export interface workOrder {
  id: number;
  applicable_work_centers: applicableWorkCenter[];
  assigned_locations: assignedLocation[];
  attachments: workOrderAttachment[];
  comments: comment[];
  current_locations: currentLocation[];
  delivery_completed: boolean;
  instructions: instruction[];
  mek_test_passed: boolean|null;
  odoo_all_parts_completed: boolean;
  odoo_delivery_completed: boolean;
  odoo_description: string;
  odoo_colours: string[];
  odoo_customer_name: string;
  odoo_customer_po_ref: string;
  odoo_review_completed: boolean;
  odoo_stage_name: string;
  odoo_work_order_completed: boolean;
  odoo_work_order_id: number;
  odoo_work_order_name: string;
  pallet_count: number;
  parts: part[];
  received_part_count: number;
  review_completed: boolean;
  shop_floor_completed: boolean;
  work_order_completed: boolean;
}

export const emptyWorkOrder: workOrder = {
  id: 0,
  applicable_work_centers: [],
  assigned_locations: [],
  attachments: [],
  comments: [],
  current_locations: [],
  delivery_completed: false,
  instructions: [],
  mek_test_passed: false,
  odoo_all_parts_completed: false,
  odoo_work_order_id: 0,
  odoo_work_order_name: '',
  odoo_customer_name: '',
  odoo_description: '',
  odoo_colours: [],
  odoo_customer_po_ref: '',
  odoo_stage_name: '',
  odoo_review_completed: false,
  odoo_delivery_completed: false,
  odoo_work_order_completed: false,
  pallet_count: 0,
  parts: [],
  received_part_count: 0,
  review_completed: false,
  shop_floor_completed: false,
  work_order_completed: false,
}

export const workOrderMockData: workOrder[] = [
  {
    id: 1,
    odoo_all_parts_completed: false,
    odoo_work_order_id: 12345,
    odoo_work_order_name: 'TKW00007',
    odoo_customer_name: 'John Wiebe',
    odoo_description: '5 trailers to paint',
    odoo_colours: ['Jet Black'],
    odoo_customer_po_ref: '8339',
    odoo_stage_name: 'Queue',
    applicable_work_centers: [
      {
        "id": 1,
        "work_order_id": 1,
        "work_center_id": 8,
        "is_active": true,
      }
    ],
    current_locations: [
      {
        "id": 1,
        "work_order_id": 1,
        "work_center_group_id": 4,
        "is_completed": false,
        "has_time_tracked": false,
        "is_active": true
      },
      {
        "id": 2,
        "work_order_id": 1,
        "work_center_group_id": 1,
        "is_completed": false,
        "has_time_tracked": false,
        "is_active": true
      },
      {
        "id": 3,
        "work_order_id": 1,
        "work_center_group_id": 5,
        "is_completed": false,
        "has_time_tracked": false,
        "is_active": true
      }
    ],
    assigned_locations: [],
    attachments: [],
    comments: [],
    instructions: [],
    parts: [],
    received_part_count: 0,
    odoo_review_completed: false,
    review_completed: false,
    shop_floor_completed: false,
    odoo_delivery_completed: false,
    delivery_completed: false,
    odoo_work_order_completed: false,
    work_order_completed: false,
    pallet_count: 0,
    mek_test_passed: true,
  },
  {
    id: 2,
    odoo_all_parts_completed: true,
    odoo_work_order_id: 23456,
    odoo_work_order_name: 'TKW00008',
    odoo_customer_name: 'John Wiebe',
    odoo_description: '10 misc. parts',
    odoo_colours: ['Jet Black'],
    odoo_customer_po_ref: '8340',
    odoo_stage_name: 'Paint',
    applicable_work_centers: [],
    current_locations: [
      {
        "id": 1,
        "work_order_id": 1,
        "work_center_group_id": 4,
        "is_completed": false,
        "has_time_tracked": false,
        "is_active": true
      },
      {
        "id": 2,
        "work_order_id": 1,
        "work_center_group_id": 1,
        "is_completed": false,
        "has_time_tracked": false,
        "is_active": true
      },
      {
        "id": 3,
        "work_order_id": 1,
        "work_center_group_id": 5,
        "is_completed": false,
        "has_time_tracked": false,
        "is_active": true
      }
    ],
    assigned_locations: [
      {
        "id": 1,
        "work_order_id": 1,
        "work_center_group_id": 4,
        "is_active": true
      },
      {
        "id": 2,
        "work_order_id": 1,
        "work_center_group_id": 1,
        "is_active": true
      },
    ],
    attachments: [],
    comments: [],
    instructions: [],
    parts: [],
    received_part_count: 0,
    odoo_review_completed: false,
    review_completed: false,
    shop_floor_completed: true,
    odoo_delivery_completed: false,
    delivery_completed: false,
    odoo_work_order_completed: false,
    work_order_completed: false,
    pallet_count: 0,
    mek_test_passed: false,
  }
]

export interface workCenter {
  id: number;
  work_center_name: string;
  sequence: number;
}

export const workCenterMockData: workCenter[] = [
  {
    id: 1,
    work_center_name: 'Blast',
    sequence: 1
  },
  {
    id: 2,
    work_center_name: 'Setup',
    sequence: 2
  },
  {
    id: 3,
    work_center_name: 'Wash',
    sequence: 3
  },
  {
    id: 4,
    work_center_name: 'Prep',
    sequence: 4
  },
  {
    id: 5,
    work_center_name: 'Paint',
    sequence: 5
  },
  {
    id: 6,
    work_center_name: 'Packaging',
    sequence: 6
  },
  {
    id: 7,
    work_center_name: 'Delivery',
    sequence: 7
  }
]

export interface workCenterGroup {
  id: number;
  work_center_group_name: string;
  has_paint_booth: boolean;
  has_work_centers: boolean;
  paint_booth_name: string | null;
  sequence: number;
  work_centers: workCenter[];
}

export const workCenterGroupMockData: workCenterGroup[] = [
  {
    "id": 1,
    "has_paint_booth": false,
    "has_work_centers": true,
    "paint_booth_name": null,
    "sequence": 1,
    "work_center_group_name": "P1",
    "work_centers": [
      {
        "id": 1,
        "work_center_name": "Blast",
        "sequence": 1
      }
    ],
  },
  {
    "id": 3,
    "has_paint_booth": true,
    "has_work_centers": true,
    "paint_booth_name": "P2 Powder",
    "sequence": 3,
    "work_center_group_name": "P2",
    "work_centers": [
      {
        "id": 4,
        "work_center_name": "Prep",
        "sequence": 4
      },
      {
        "id": 2,
        "work_center_name": "Setup",
        "sequence": 2
      },
      {
        "id": 6,
        "work_center_name": "Packaging",
        "sequence": 7
      },
      {
        "id": 5,
        "work_center_name": "Paint",
        "sequence": 5
      },
      {
        "id": 3,
        "work_center_name": "Wash",
        "sequence": 3
      }
    ],
  },
  {
    "id": 4,
    "has_paint_booth": true,
    "has_work_centers": true,
    "paint_booth_name": "P3",
    "sequence": 4,
    "work_center_group_name": "P3",
    "work_centers": [
      {
        "id": 8,
        "work_center_name": "Assembly",
        "sequence": 6
      }
    ],
  },
  {
    "id": 5,
    "has_paint_booth": true,
    "has_work_centers": true,
    "paint_booth_name": "P4 Powder",
    "sequence": 5,
    "work_center_group_name": "P4a",
    "work_centers": [
      {
        "id": 6,
        "work_center_name": "Packaging",
        "sequence": 7
      },
      {
        "id": 8,
        "work_center_name": "Assembly",
        "sequence": 6
      },
      {
        "id": 4,
        "work_center_name": "Prep",
        "sequence": 4
      }
    ],
  },
  {
    "id": 6,
    "has_paint_booth": false,
    "has_work_centers": false,
    "paint_booth_name": null,
    "sequence": 6,
    "work_center_group_name": "Shipping",
    "work_centers": [],
  }
]
