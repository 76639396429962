import { Demodal } from "demodal";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { ListGroup, Button, Dropdown } from "react-bootstrap";
import { Plus, ThreeDotsVertical } from "react-bootstrap-icons";
import Cookies from "universal-cookie";
import { createComment, deleteComment, updateComment } from "../../data/api-requests";
import { comment } from "../../data/types/comments";
import { workOrder } from "../../data/types/work-order";
import { CustomToggle } from "../../utils/custom-toggle";
import { getDateStringFromDate } from "../../utils/helpers";
import createNotification from "../../utils/notification";
import { commentFormFields, commentFormSchema, putCommentFormSchema } from "../shared/form/form-comment";
import ConfirmModal from "../shared/modal/modal-confirm";
import ItemModal from "../shared/modal/modal-item";

interface WorkOrderCommentsProps {
  workOrder: workOrder;
  workCenterId: number;
}

const WorkOrderComments = (props: WorkOrderCommentsProps) => {
  const cookies = new Cookies()
  const username = cookies.get('tk_user_display_name')
  const [comments, setComments] = useState<comment[]>([])

  useEffect(() => {
    if (props.workOrder && props.workCenterId) {
      setComments(props.workOrder.comments.filter((comment: comment) => comment.work_center_id === props.workCenterId))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workOrder, props.workCenterId])

  const onAddItemClick = async (event: BaseSyntheticEvent) => {
    const modalResult: any = await Demodal.open(ItemModal, {
      itemName: 'Comment',
      formData: {'comment_text': '', 'work_order_id': props.workOrder.id, 'work_center_id': props.workCenterId},
      formFields: commentFormFields,
      formSchema: commentFormSchema,
    })

    if (modalResult) {
      const result = await createComment(props.workOrder.id, props.workCenterId, modalResult)
      if (result) {
        createNotification('Submitted', 'Created comment.')

        // Add to current list
        const updatedComment: comment = {
          id: result.id,
          comment_text: modalResult.comment_text,
          work_order_id: modalResult.work_order_id,
          work_center_id: modalResult.work_center_id,
          user_name: username,
          user_id: modalResult.user_id,
          created_on: new Date(),
        }
        props.workOrder.comments = [updatedComment, ...props.workOrder.comments]
        setComments([updatedComment, ...comments])
      }
    }
  }

  const onEditItemClick = async (comment: comment) => {
    const modalResult: any = await Demodal.open(ItemModal, {
      itemName: 'Comment',
      formData: putCommentFormSchema.cast(comment, {stripUnknown: true}),
      formFields: commentFormFields,
      formSchema: putCommentFormSchema,
    })

    if (modalResult) {
      const editId = modalResult['id']
      if ('id' in modalResult) delete modalResult['id']
      const result = await updateComment(editId, modalResult)
      if (result) {
        createNotification('Edited', 'Edited comment.')

        // Update in current list
        const updatedComment = comments.find((comment: comment) => comment.id === editId)
        if (updatedComment) {
          updatedComment.id = result.id
          updatedComment.comment_text = modalResult.comment_text
        }
        setComments([...comments])
      }
    }
  }

  const onDeleteCommentClick = async (commentId: number) => {
    const modalResult: any = await Demodal.open(ConfirmModal, {
      title: 'Delete Comment?',
      description: <>Please confirm deletion.</>,
    })

    if (modalResult) {
      await deleteComment(commentId)
      createNotification('Deleted', 'Deleted comment.')

      // Remove from current list
      setComments([...comments.filter((comment: comment) => comment.id !== commentId)])
    }
  }

  return (
    <div className="work-order-section work-order-comments">
      <header className="work-order-section-header work-order-comments-header">
        <h2>Comments</h2>
        <Button onClick={onAddItemClick}><Plus/></Button>
      </header>

      <ListGroup className="comments">
        {comments.map((comment: comment) => (
          <ListGroup.Item key={comment.id} className="comment bg-light">
            <header className="comment-header text-muted mb-2">
              <div className="comment-user">{comment.user_name}</div>
              <div className="comment-date ml-auto">{getDateStringFromDate(comment.created_on, 'd MMM yyyy')}</div>
              <div className="comment-actions">
                <Dropdown>
                  <Dropdown.Toggle as={CustomToggle} id={`comment-actions-${comment.id}`} customClasses="action-menu-button ml-2">
                    <ThreeDotsVertical/>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => onEditItemClick(comment)}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={() => onDeleteCommentClick(comment.id)}>Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </header>
            <p className="m-0 white-space-pre-line">{comment.comment_text}</p>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  )
}

export default WorkOrderComments
