import * as Yup from 'yup';
import { formField } from '../modal/modal-item';
import { formFieldTypes } from '../../../data/types/form-field-type';

// Form Fields
export const partQtyFormFields: formField[][] = [
  [
    {
      name: 'qty',
      label: 'Quantity',
      type: formFieldTypes.number,
    },
  ]
]

// Validation Rules
export const partQtyFormSchema = Yup.object().shape({
  qty: Yup.number().required().max(Yup.ref('qty_to_produce'), 'Quantity cannot be greater than the manufacturing order quantity.'),
})
