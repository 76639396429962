import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { useState as useGlobalState } from '@hookstate/core'
import { globalRefreshTriggers, globalState } from '../../common/context'
import { useParams } from "react-router-dom";
import { getParts, getWorkOrder } from "../../data/api-requests";
import { workCenterGroup, workOrder, emptyWorkOrder, workCenter, workCenterGroupMockData } from "../../data/types/work-order";
import WorkOrderHeader from "./work-order-header";
import { Container, Row } from "react-bootstrap";
import WorkOrderShopFloorTabs from "./work-order-shop-floor-tabs";
import useGetData from "../../hooks/use-get-data";
import WorkOrderSections from "./work-order-sections";
import useGetStaticData from "../../hooks/use-get-static-data";
import { part } from "../../data/types/parts";

const WorkOrderShopFloor = () => {
  const { workOrderId } = useParams<{workOrderId: string}>()

  const globals = useGlobalState(globalState)
  const currentWorkCenterId = globals.shopFloorWorkCenterId.get()
  const currentWorkCenterGroupId = globals.shopFloorWorkCenterGroupId.get()

  const refreshTriggers = useGlobalState(globalRefreshTriggers)
  const triggerPartsRefresh = refreshTriggers.parts.get()

  const cookies = new Cookies()
  const defaultWorkCenterGroupId = cookies.get('tk_shop_floor_default_work_center_group_id')
  const defaultWorkCenterId = cookies.get('tk_shop_floor_default_work_center_id')

  const staticResources = useGetStaticData()
  const [workCenterGroups, setWorkCenterGroups] = useState<workCenterGroup[]>([])
  const [workCenters, setWorkCenters] = useState<workCenter[]>([])
  const [currentTab, setCurrentTab] = useState<string>('')

  // Set WCG when available
  useEffect(() => {
    if (staticResources && staticResources.work_center_groups) {
      setWorkCenterGroups(staticResources.work_center_groups)
    }
  }, [staticResources])

  // Set WC when current WCG changes
  useEffect(() => {
    if (workCenterGroups) {
      const relatedWorkCenters = workCenterGroups.find((wcg: workCenterGroup) => wcg.id === currentWorkCenterGroupId)
      if (relatedWorkCenters) setWorkCenters(relatedWorkCenters.work_centers)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkCenterGroupId])

  const fetchWorkOrderData = async () => {
    const castWorkOrderId = Number(workOrderId)
    if (!isNaN(castWorkOrderId) && castWorkOrderId > 0) {
      const result = await getWorkOrder(Number(workOrderId))
      if (result) return [result]
    }
  }

  const [workOrder, refreshWorkOrder, setWorkOrder] = useGetData<workOrder>({
    autoLoad: false,
    fetchDataFn: fetchWorkOrderData,
    initialValue: [emptyWorkOrder],
  })

  const fetchPartsData = async () => {
    const castWorkOrderId = Number(workOrderId)
    if (!isNaN(castWorkOrderId) && castWorkOrderId > 0) {
      const result = await getParts(Number(workOrderId))
      if (result) return result
    }
  }

  const [parts, refreshParts] = useGetData<part>({
    autoLoad: false,
    fetchDataFn: fetchPartsData,
    initialValue: [],
  })

  useEffect(() => {
    if (triggerPartsRefresh > 0) refreshParts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerPartsRefresh])

  // Set current tab on work center change
  useEffect(() => {
    if (currentWorkCenterId > 0 && workCenters.length > 0) {
      const matchingWorkCenter = workCenters.find((wc) => wc.id === currentWorkCenterId)
      if (matchingWorkCenter) {
        setCurrentTab(matchingWorkCenter.work_center_name.toLocaleLowerCase())
      } else {
        setCurrentTab(workCenters[0].work_center_name.toLocaleLowerCase())
      }
    }
  }, [currentWorkCenterId, workCenters])

  // Load work order on work order ID change
  useEffect(() => {
    setWorkOrder([emptyWorkOrder])
    refreshWorkOrder()
    refreshParts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workOrderId])

  // Set current WC/WCG menu defaults on init
  useEffect(() => {
    if (defaultWorkCenterGroupId && defaultWorkCenterId) {
      cookies.set('tk_shop_floor_work_center_group_id', defaultWorkCenterGroupId, {path: '/'})
      cookies.set('tk_shop_floor_work_center_id', defaultWorkCenterId, {path: '/'})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultWorkCenterGroupId, defaultWorkCenterId])

  return (
    <>
      <div className="shop-floor-nav p-3">
        <WorkOrderShopFloorTabs currentWorkOrder={workOrder[0]} workCenters={workCenters} workCenterGroups={workCenterGroups} />
      </div>
      <Container fluid className="w-100 bg-light shop-floor-container">
        {(workOrder[0].id > 0) ? (
          <>
            <WorkOrderHeader workOrder={workOrder[0]} workCenterGroups={workCenterGroups} />
            <div className="p-4">
              <Row className="g-0">
                {currentTab && (
                  <WorkOrderSections
                    currentTab={currentTab}
                    parts={parts}
                    workOrderId={Number(workOrderId)}
                    workOrder={workOrder[0]}
                    workCenterId={Number(currentWorkCenterId)}
                    workCenterGroupId={Number(currentWorkCenterGroupId)}
                    workCenters={workCenters}
                    staticResources={staticResources}
                    enableTimeTrackingCreate={true}
                  />
                )}
              </Row>
            </div>
            </>
        ) : (
          <WorkOrderHeader workOrder={emptyWorkOrder} workCenterGroups={workCenterGroupMockData} />
        )}
      </Container>
    </>
  )
}

export default WorkOrderShopFloor
