import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useState as useGlobalState } from '@hookstate/core'
import globalState from '../../../common/context'
import { Dropdown, Nav } from "react-bootstrap";
import useGetData from "../../../hooks/use-get-data";
import { getScrapLocations } from "../../../data/api-requests";
import { scrapLocation } from "../../../data/types/scrap";
import { useDropdownHeight } from "../../../utils/hooks";

interface ScrapLocationDropdownProps {
  initialIdValue: number;
  formikSetFieldValue: Function;
  fieldName: string;
}

const ScrapLocationDropdown = (props: ScrapLocationDropdownProps) => {
  const globals = useGlobalState(globalState)
  const fetchData = async () => {
    const result = await getScrapLocations()
    if (result) return result
  }

  const [data] = useGetData<scrapLocation>({fetchDataFn: fetchData})
  const [dataFiltered, setDataFiltered] = useState<any>([])
  const [filterValue, setFilterValue] = useState<string>('')
  const [currentValue, setCurrentValue] = useState<scrapLocation>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownMaxHeight = useDropdownHeight(dropdownRef, isOpen)

  useEffect(() =>  {
    // Set initially selected value, if available
    if (data) {
      const initialValue = data.find((location: scrapLocation) => location.id === props.initialIdValue)
      if (initialValue) setCurrentValue(initialValue)
    }

    // Remove already added paints to prevent duplicate entries
    const addedPaintProductIds = globals.paintProductIds.get()
    let filtered = [...data].filter((paint: scrapLocation) => addedPaintProductIds.includes(paint.id) === false)

    // Filter by search string
    filtered = filtered.filter((paint: scrapLocation) => {
      if (
        String(paint.name).toLowerCase().includes(filterValue.toLowerCase())
      ) {
        return true
      }
      return false
    })

    setDataFiltered(filtered)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filterValue])

  const onDropdownToggle = (isOpen:boolean) => {
    setIsOpen(isOpen)
  }

  const onSelectItem = (value: string | null) => {
    if (typeof value === 'string') {
      const newId = Number(value)
      const matchingRow = data.find((paint: scrapLocation) => paint.id === newId)
      if (matchingRow) {
        setCurrentValue(matchingRow)
        props.formikSetFieldValue(props.fieldName, matchingRow.id)
      }
      setFilterValue('')
    }
  }

  return (
    <Dropdown onToggle={onDropdownToggle}>
      <Dropdown.Toggle variant="primary" className="applicable-paints-dropdown-toggle" id="paint-products-menu">
        {currentValue ? (
          <>
            {currentValue.location_id && (
              <>{currentValue.location_id.name}/</>
            )}
            {currentValue.name}
          </>
        ) : 'Select...'}
      </Dropdown.Toggle>

      <Dropdown.Menu ref={dropdownRef} className="tk-dropdown-field applicable-paints-dropdown" style={{overflowY: 'scroll', maxHeight: dropdownMaxHeight}}>
        <div className="">
          <Nav
            className="flex-column tk-dropdown-field__menu-items"
            onSelect={onSelectItem}
          >
            {dataFiltered.map((paint: scrapLocation) => (
              <Nav.Link key={paint.id} eventKey={paint.id}>
                <div className="tk-dropdown-field-title text-truncate">
                  {paint.location_id && (
                    <>{paint.location_id.name}/</>
                  )}
                  {paint.name}
                </div>
                <div className="tk-dropdown-field__meta">
                </div>
              </Nav.Link>
            ))}
          </Nav>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ScrapLocationDropdown
