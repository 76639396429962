import { Accordion, Badge } from "react-bootstrap"
import { Clock } from "react-bootstrap-icons"
import { workCenterIds } from "../../common/config"
import { getTimeEntries } from "../../data/api-requests"
import { prepTaskType } from "../../data/types/prep-task-type"
import { timeEntriesGroupedByDate, timeEntriesGroupedByWorkCenter, timeEntry } from "../../data/types/time-tracking"
import useGetData from "../../hooks/use-get-data"
import { getLocalTimeStringFromDate, getTimeDurationStringFromDates } from "../../utils/helpers"
import { groupTimeEntriesByWorkCenter } from "../shared/time-tracking/time-tracking-utils"
import { useEffect, useState } from 'react';
import TimeTrackingPrepTaskBadges from '../time-tracking/time-tracking-prep-task-badges';
import { staticResources } from '../../data/types/static-resources';
import { workCenter } from "../../data/types/work-order"


interface WorkOrderTimeTrackingReportProps {
  workOrderId: number;
  staticResources?: staticResources;
}

const WorkOrderTimeTrackingReport = (props: WorkOrderTimeTrackingReportProps) => {
  const [prepTaskTypes, setPrepTaskTypes] = useState<prepTaskType[]>([])
  const [workCenters, setWorkCenters] = useState<workCenter[]>([])
  const [totalMinutes, setTotalMinutes] = useState<number>(0)

  const fetchData = async () => {
    const result = await getTimeEntries(props.workOrderId, 0, 0, 0)
    if (result) return groupTimeEntriesByWorkCenter(result, workCenters)
  }

  const [timeEntriesGroupedByWorkCenter, refresh] = useGetData<timeEntriesGroupedByWorkCenter>({
    fetchDataFn: fetchData,
    autoLoad: false,
  })

  useEffect(() => {
    if (props.workOrderId > 0 && workCenters.length > 0) refresh()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workOrderId, workCenters])

  useEffect(() => {
    if (props.staticResources && props.staticResources.prep_task_types) {
      setPrepTaskTypes(props.staticResources.prep_task_types)
    }
    if (props.staticResources && props.staticResources.work_centers) {
      setWorkCenters(props.staticResources.work_centers)
    }
  }, [props.staticResources])

  useEffect(() => {
    if (timeEntriesGroupedByWorkCenter) {
      setTotalMinutes(
        timeEntriesGroupedByWorkCenter
          .flatMap((group: timeEntriesGroupedByWorkCenter) => group.total_minutes)
          .reduce((prev: number, current: number) => prev + current, 0)
      )
    }
  }, [timeEntriesGroupedByWorkCenter])

  const formatMinutes = (minutes: number) => {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60
    return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`
  }

  const getWorkCenterHeader = (groupedByWorkCenter: timeEntriesGroupedByWorkCenter) => (
    <Accordion.Toggle
      eventKey={groupedByWorkCenter.work_center_name}
      key={`${groupedByWorkCenter.work_center_name}-heading1`}
      className="time-entry-heading-row time-entry-heading-row--toggle time-entry-heading-row--primary"
    >
      <div className="d-flex align-items-center py-1 px-2">
        <div className="d-inline-block align-middle mr-2">{groupedByWorkCenter.work_center_name}</div>
        <Badge variant="secondary" className="ml-auto d-inline-flex align-items-center tabular-nums">
          <Clock className="mr-1"/>
          {formatMinutes(groupedByWorkCenter.total_minutes)}
        </Badge>
      </div>
    </Accordion.Toggle>
  )

  const getTimeEntryRow = (row: timeEntry) => (
    <tr key={row.id} className="time-entry-row">
      <td>
        <div>
          <div>{row.user_display_name}</div>
          <Badge variant="info" className="ml-auto d-inline-flex align-items-center">{row.work_center_group_name}</Badge>
        </div>
      </td>
      <td className="time-entry-times-cell">
        <div className="d-flex">
          <div>
            {row.start_time && getLocalTimeStringFromDate(row.start_time)}
            <span> &ndash; </span>
            {row.end_time && getLocalTimeStringFromDate(row.end_time)}
          </div>
          {row.start_time && row.end_time && (
            <div className="ml-auto text-muted">({getTimeDurationStringFromDates(row.start_time, row.end_time)})</div>
          )}
        </div>
        {row.description && (
          <p className="time-entry-description">{row.description}</p>
        )}
        {row.work_center_id === workCenterIds.prep && (
          <TimeTrackingPrepTaskBadges prepTaskTypes={prepTaskTypes} activePrepTaskTypes={row.active_prep_task_types}/>
        )}
      </td>
      <td className="time-entry-qty-cell">{row.quantity}</td>
    </tr>
  )

  return (
    <div className="work-order-section work-order-time-tracking-report">
      <header className="work-order-section-header work-order-time-tracking-report-header">
        <h2 className="mr-2">Time Tracking</h2>
        <Badge variant="secondary" className="ml-auto d-inline-flex align-items-center tabular-nums">
          <Clock className="mr-1"/>
          {formatMinutes(totalMinutes)}
        </Badge>
      </header>

      <div className="mt-3">
        <Accordion defaultActiveKey="0">
          {timeEntriesGroupedByWorkCenter.map((groupedByWorkCenter: timeEntriesGroupedByWorkCenter) => (
            [
              getWorkCenterHeader(groupedByWorkCenter),
              groupedByWorkCenter.rows_grouped_by_date.map((groupedByDate: timeEntriesGroupedByDate) => (
                <Accordion.Collapse key={groupedByWorkCenter.work_center_name} eventKey={groupedByWorkCenter.work_center_name}>
                  <table className="table table-hover table-bordered time-entries m-0">
                    <tbody>
                      <tr key={`${groupedByDate.date}-heading2`} className="time-entry-heading-row">
                        <td colSpan={3}>{groupedByDate.date}</td>
                      </tr>
                      {groupedByDate.rows.map((row: timeEntry) => (
                        getTimeEntryRow(row)
                      ))}
                    </tbody>
                  </table>
                </Accordion.Collapse>
              )),
            ]
          ))}
        </Accordion>
      </div>
    </div>
  )
}

export default WorkOrderTimeTrackingReport
