export interface odooDocument {
    id: number;
    name: string;
    mimetype: string;
    datas: string;
}

export interface productDrawing {
    id: number;
    name: string;
    mimetype: string;
    tk_image_256: string;
}

export const emptyDocument: odooDocument = {
    id: 0,
    name: '',
    mimetype: '',
    datas: '',
}