export enum formFieldTypes {
  'text',
  'number',
  'textarea',
  'switch',
  'datetime',
  'datetimeRange',
  'user',
  'rule',
  'attachments',
  'applicablePaint',
  'paintProduct',
  'prepTasks',
  'workOrder',
  'locationCombo',
  'scrapProduct',
  'scrapLocation',
  'description',
  'info'
}