export interface receivedPartAttachment {
  id: number;
  file_name: string;
}

export interface receivedPart {
  id: number;
  note: string;
  work_order_id: number | null;
  odoo_work_order_name: string | null;
  is_tagged: boolean;
  customer_name: string;
  customer_po_number: string;
  attachments: receivedPartAttachment[];
}

export const emptyReceivedPart: receivedPart = {
  id: 0,
  note: '',
  work_order_id: null,
  odoo_work_order_name: '',
  is_tagged: false,
  customer_name: '',
  customer_po_number: '',
  attachments: [],
}

export const newReceivedPart = {
  id: 0,
  note: '',
  is_tagged: false,
  customer_name: '',
  customer_po_number: '',
  attachments: [],
}

export const receivedPartMockData: receivedPart[] = [
  {
    id: 23,
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur laoreet lectus imperdiet, posuere nisi ac, convallis magna.',
    work_order_id: 1,
    odoo_work_order_name: 'TKW00001',
    is_tagged: false,
    attachments: [],
    customer_name: 'John Smith',
    customer_po_number: 'PO1234',
  },
  {
    id: 24,
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur laoreet lectus imperdiet, posuere nisi ac, convallis magna.',
    work_order_id: null,
    odoo_work_order_name: null,
    is_tagged: false,
    attachments: [],
    customer_name: 'John Smith',
    customer_po_number: 'PO1234',
  },
  {
    id: 25,
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur laoreet lectus imperdiet, posuere nisi ac, convallis magna.',
    work_order_id: 1,
    odoo_work_order_name: 'TKW00001',
    is_tagged: true,
    attachments: [],
    customer_name: 'John Smith',
    customer_po_number: 'PO1234',
  },
  {
    id: 26,
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur laoreet lectus imperdiet, posuere nisi ac, convallis magna.',
    work_order_id: null,
    odoo_work_order_name: null,
    is_tagged: true,
    attachments: [],
    customer_name: 'John Smith',
    customer_po_number: 'PO1234',
  },
  {
    id: 27,
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur laoreet lectus imperdiet, posuere nisi ac, convallis magna.',
    work_order_id: null,
    odoo_work_order_name: null,
    is_tagged: true,
    attachments: [],
    customer_name: 'John Smith',
    customer_po_number: 'PO1234',
  },
  {
    id: 28,
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur laoreet lectus imperdiet, posuere nisi ac, convallis magna.',
    work_order_id: 1,
    odoo_work_order_name: null,
    is_tagged: true,
    attachments: [],
    customer_name: 'John Smith',
    customer_po_number: 'PO1234',
  }
]

export interface receivedPartsCounts {
  unassigned_count: number;
  untagged_count: number;
}
