import { useEffect, useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap"
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { currentLocation, workCenterGroup, workOrder } from "../../data/types/work-order";
import WorkOrderAttachmentsList from "./work-order-attachments-list";

interface WorkOrderHeaderProps {
  workOrder: workOrder;
  workCenterGroups: workCenterGroup[];
}

const WorkOrderHeader = (props: WorkOrderHeaderProps) => {
  const [currentLocations, setCurrentLocations] = useState<string[]>([])
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    if (
      props.workCenterGroups
      && props.workCenterGroups.length > 0
      && props.workOrder && props.workOrder.current_locations
    ) {
      const currentLocationIds = props.workOrder.current_locations
        .map((location: currentLocation) => location.work_center_group_id)
      const groupNames = [...props.workCenterGroups]
        .filter((workCenterGroup: workCenterGroup) => currentLocationIds.includes(workCenterGroup.id))
        .map((workCenterGroup: workCenterGroup) => workCenterGroup.work_center_group_name)

      setCurrentLocations(groupNames)
    }
  }, [props.workCenterGroups, props.workOrder])

  const formatValue = (value: any) => value ? value : <span className="text-muted">&mdash;</span>

  const onToggleClick = () => setShow(!show)

  return (
    <Container fluid className="w-100 py-3 work-order-header">
      <div className="work-order-header-mini d-xl-none">
        <div className="d-flex align-items-center">
          <Button className="mr-3" onClick={onToggleClick}>
            {show ? <ChevronUp/> : <ChevronDown/>}
          </Button>
          <div className="work-order-header-mini__title">
            <strong>{formatValue(props.workOrder.odoo_work_order_name)}</strong> <span className="text-muted mx-1">|</span> {formatValue(props.workOrder.odoo_customer_name)}
            {props.workOrder.odoo_description && (
              <div className="work-order-header-mini__description text-muted">{formatValue(props.workOrder.odoo_description)}</div>
            )}
          </div>
        </div>
      </div>
      <Row className={`work-order-header-row ${show ? 'is-visible' : ''}`}>
        <Col xl="auto" className="work-order-header-col d-none d-xl-block">
          <strong className="work-order-header-label">WO #</strong>
          <div className="work-order-header-value font-weight-bold">{formatValue(props.workOrder.odoo_work_order_name)}</div>
        </Col>
        <Col xl="auto" className="work-order-header-col d-none d-xl-block">
          <strong className="work-order-header-label">Customer</strong>
          <div className="work-order-header-value work-order-header-value--customer">{formatValue(props.workOrder.odoo_customer_name)}</div>
        </Col>
        <Col xl="auto" className="work-order-header-col d-none d-xl-block">
          <strong className="work-order-header-label">Description</strong>
          <div className="work-order-header-value work-order-header-value--description" title={props.workOrder.odoo_description}>
            {formatValue(props.workOrder.odoo_description)}
          </div>
        </Col>
        <Col xl="auto" className="work-order-header-col">
          <strong className="work-order-header-label">Colour</strong>
          <div className="work-order-header-value work-order-header-value--colour" title={props.workOrder.odoo_colours?.join(', ')}>{formatValue(props.workOrder.odoo_colours?.join(', '))}</div>
        </Col>
        <Col xl="auto" className="work-order-header-col">
          <strong className="work-order-header-label">PO #</strong>
          <div className="work-order-header-value">{formatValue(props.workOrder.odoo_customer_po_ref)}</div>
        </Col>
        <Col xl="auto" className="work-order-header-col">
          <strong className="work-order-header-label">Current Location</strong>
          <div className="work-order-header-value">{formatValue(currentLocations?.join(', '))}</div>
        </Col>
        <Col xl="auto" className="work-order-header-col">
          <strong className="work-order-header-label">Stage</strong>
          <div className="work-order-header-value">{formatValue(props.workOrder.odoo_stage_name)}</div>
        </Col>
        <Col xl="auto" className="ml-auto work-order-attachments-col">
          <WorkOrderAttachmentsList workOrder={props.workOrder} />
        </Col>
      </Row>
    </Container>
  )
}

export default WorkOrderHeader