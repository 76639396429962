import { useLayoutEffect, useState } from "react";

export function useDropdownHeight(dropdownRef: React.RefObject<HTMLDivElement>, isOpen: boolean) {
    const [dropdownHeight, setDropdownHeight]  = useState<number>(200)
    useLayoutEffect(() => {
        if (dropdownRef.current && isOpen) {
            const dropdownTopPosition = dropdownRef.current.getBoundingClientRect().top
            setDropdownHeight(window.innerHeight - dropdownTopPosition - 60)
        }
    }, [isOpen])
    return dropdownHeight
}