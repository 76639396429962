import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie';
import { useState as useGlobalState } from '@hookstate/core'
import globalState from '../../common/context'

import LoginForm from './login';
import SessionManager from './session-manager';
import { useMockData } from '../../common/config';
import { getDateFromTimestamp } from '../../utils/helpers';

const CheckLogin = (props: any) => {
  const history = useHistory()
  const cookies = new Cookies()
  const globals = useGlobalState(globalState)
  const [content, setContent] = useState<JSX.Element>(<></>)

  const sessionIsExpired = () => {
    const tokenExpiry = cookies.get('tk_auth_token_expiry')
    if (!tokenExpiry) return true

    const tokenExpiryDate = getDateFromTimestamp(tokenExpiry)
    if (tokenExpiryDate && tokenExpiryDate < new Date()) {
      return true
    }

    return false
  }

  // Check on route change
  useEffect(() => {
    const userRules = cookies.get('tk_user_rules')
    if (userRules && userRules !== 'undefined') {
      globals.userRules.set(userRules)
    }
    if (
      (cookies.get('tk_auth_token') && !sessionIsExpired())
      || useMockData
    ) {
      setContent(<>{props.children}{!useMockData && <SessionManager/>}</>)
    } else {
      setContent(<LoginForm/>)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, props.children])

  return (<>{content}</>)
}

export default CheckLogin