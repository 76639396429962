import * as Yup from 'yup';

// Validation Rules
export const titrationFormSchema = Yup.object().shape({
    pre_cleaner_concentration: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    pre_cleaner_temp: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    rust_inhib_concentration: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    rust_inhib_temp: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    rust_inhib_ph: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    phosphate_concentration: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    phosphate_temp: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    phosphate_ph: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    rinse_tds: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    rinse_temp: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
})

export const putTitrationFormSchema = Yup.object().shape({
    id: Yup.number(),
    pre_cleaner_concentration: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    pre_cleaner_temp: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    rust_inhib_concentration: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    rust_inhib_temp: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    rust_inhib_ph: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    phosphate_concentration: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    phosphate_temp: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    phosphate_ph: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    rinse_tds: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
    rinse_temp: Yup.string().nullable().matches(/^\d{0,4}(?:[.,]\d{1})?$/, 'Please enter valid format (1.0)'),
})
