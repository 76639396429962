import { Pagination } from "react-bootstrap";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useEffect, useState } from "react";

interface PdfViewerProps {
  pdfData: string;
}

const PdfViewer = (props: PdfViewerProps) => {
  const [pdfData, setPdfData] = useState<string>('')
  const [numPages, setNumPages] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setPageNumber(1)
    setNumPages(numPages)
  }

  const onPrevClick = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1)
  }

  const onNextClick = () => {
    if (pageNumber < numPages) setPageNumber(pageNumber + 1)
  }

  // If the PDF changes, jump back to page 1
  useEffect(() => {
    setPageNumber(1)
  }, [pdfData])

  useEffect(() => {
    setPdfData(props.pdfData)
  }, [props])

  return (
    <>
      <Document
        file={`data:application/pdf;base64,${pdfData}`}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} width={1600} />
      </Document>
      <Pagination className="justify-content-center">
        <Pagination.Prev onClick={onPrevClick} disabled={pageNumber === 1} />
        <Pagination.Item disabled>{pageNumber} of {numPages}</Pagination.Item>
        <Pagination.Next onClick={onNextClick} disabled={pageNumber > numPages - 1} />
      </Pagination>
    </>
  )
}

export default PdfViewer
