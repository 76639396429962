import { applicablePaint } from "../../data/types/applicable-paint";

interface WorkOrderApplicablePaintTableGroupProps {
  applicablePaints: applicablePaint[];
  category: string;
  showHeaders: boolean;
}

const WorkOrderApplicablePaintTableGroup = (props: WorkOrderApplicablePaintTableGroupProps) => {
  return (
    <>
      {props.applicablePaints.filter((paint: applicablePaint) => paint.category === props.category).length > 0 && (
        [
          <tr key={props.category} className="time-entry-heading-row">
            <td>{props.category}</td>
            <td>Vendor</td>
          </tr>,
          props.applicablePaints.map((paint: applicablePaint) => (
            paint.category === props.category && (
              <tr key={paint.id}>
                <td>{paint.product_name} {paint.default_code && (<small>[{paint.default_code}]</small>)}</td>
                <td>{paint.vendor_name}</td>
              </tr>
            )
          ))
        ]
      )}
    </>
  )
}

export default WorkOrderApplicablePaintTableGroup