import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useState as useGlobalState } from '@hookstate/core'
import globalState from '../../../common/context'
import { Badge, Dropdown, Form, Nav } from "react-bootstrap";
import { paintProduct } from "../../../data/types/paint-product";
import useGetData from "../../../hooks/use-get-data";
import { useDropdownHeight } from "../../../utils/hooks";


interface PaintProductDropdownProps {
  initialIdValue: number;
  formikSetFieldValue: Function;
  fieldName: string;
}

const PaintProductDropdown = (props: PaintProductDropdownProps) => {
  const globals = useGlobalState(globalState)
  const [data] = useGetData<paintProduct>({interfaceName: 'paintProduct'})
  const [dataFiltered, setDataFiltered] = useState<any>([])
  const [filterValue, setFilterValue] = useState<string>('')
  const [currentValue, setCurrentValue] = useState<paintProduct>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownMaxHeight = useDropdownHeight(dropdownRef, isOpen)

  // Focus on filter input when opening dropdown
  useLayoutEffect(() => {
    if (isOpen) {
      inputRef.current!.focus()
    }
  }, [isOpen])

  useEffect(() =>  {
    // Set initially selected value, if available
    if (data) {
      const initialValue = data.find((paint: paintProduct) => paint.id === props.initialIdValue)
      if (initialValue) setCurrentValue(initialValue)
    }

    // Remove already added paints to prevent duplicate entries
    const addedPaintProductIds = globals.paintProductIds.get()
    let filtered = [...data].filter((paint: paintProduct) => addedPaintProductIds.includes(paint.id) === false)

    // Filter by search string
    filtered = filtered.filter((paint: paintProduct) => {
      if (
        String(paint.name).toLowerCase().includes(filterValue.toLowerCase())
        || String(paint.categ_id.name).toLowerCase().includes(filterValue.toLowerCase())
        || String(paint.tk_vendor_name).toLowerCase().includes(filterValue.toLowerCase())
        || String(paint.default_code).toLowerCase().includes(filterValue.toLowerCase())
      ) {
        return true
      }
      return false
    })

    setDataFiltered(filtered)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filterValue])

  const onDropdownToggle = (isOpen:boolean) => {
    setIsOpen(isOpen)
  }

  const onFilterInputChange = (event: any) => {
    const inputValue = event.target.value
    setFilterValue(inputValue)
  }

  const onSelectItem = (value: string | null) => {
    if (typeof value === 'string') {
      const newId = Number(value)
      const matchingRow = data.find((paint: paintProduct) => paint.id === newId)
      if (matchingRow) {
        setCurrentValue(matchingRow)
        props.formikSetFieldValue(props.fieldName, matchingRow.id)
      }
      setFilterValue('')
    }
  }

  const getNoun = () => (dataFiltered.length === 1) ? 'result' : 'results'

  return (
    <Dropdown onToggle={onDropdownToggle}>
      <Dropdown.Toggle variant="primary" className="applicable-paints-dropdown-toggle" id="paint-products-menu">
        {currentValue ? (
          <>
            {currentValue.default_code && (
              <>[{currentValue.default_code}]</>
            )}
            {" "}
            {currentValue.name}
          </>
        ) : 'Select...'}
      </Dropdown.Toggle>

      <Dropdown.Menu ref={dropdownRef} className="tk-dropdown-field applicable-paints-dropdown" style={{overflowY: 'scroll', maxHeight: dropdownMaxHeight}}>
        <div className="">
          <Form.Control
            ref={inputRef}
            type="text"
            value={filterValue}
            onChange={onFilterInputChange}
            placeholder="Filter..."
          />
          <div className="tk-dropdown-field__status-bar px-3">
            <strong>{dataFiltered.length}</strong>{" "}{getNoun()} found.
          </div>
          <Nav
            className="flex-column tk-dropdown-field__menu-items"
            onSelect={onSelectItem}
          >
            {dataFiltered.map((paint: paintProduct) => (
              <Nav.Link key={paint.id} eventKey={paint.id}>
                <div className="tk-dropdown-field-title text-truncate">
                  {paint.default_code && (
                    <>[{paint.default_code}]</>
                  )}
                  {" "}
                  {paint.name}
                </div>
                <div className="tk-dropdown-field__meta">
                  <div><Badge variant="info">{paint.categ_id.name}</Badge></div>
                  <div className="ml-auto">Vendor: {paint.tk_vendor_name}</div>
                </div>
              </Nav.Link>
            ))}
          </Nav>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default PaintProductDropdown
