import { Demodal } from "demodal";
import { useEffect, useState, MouseEvent } from "react"
import { Dropdown, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import { CheckCircle } from "react-bootstrap-icons";
import { workCenterGroupP1Id } from "../../common/config";
import { getWorkCentreGroupLocation, toggleWorkCentreLocationAssignment } from "../../data/api-requests";
import { currentLocation, workCenterGroup, workOrder } from "../../data/types/work-order"
import useCheckPermission from "../../hooks/use-check-permission";
import { triggerGlobalRefresh } from "../../utils/helpers";
import createNotification from "../../utils/notification";
import ConfirmModal from "../shared/modal/modal-confirm";

interface CurrentLocationWidgetProps {
  workOrder: workOrder;
  workCenterGroups: workCenterGroup[];
  onWorkCenterGroupsChange: Function;
}

const CurrentLocationWidget = (props: CurrentLocationWidgetProps) => {
  const hasUpdatePermission = useCheckPermission('Manage WO Locations')
  const [locationGroupIds, setLocationGroupIds] = useState<number[]>([])
  const [assignedGroupIds, setAssignedGroupIds] = useState<number[]>([])
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  // Set initial work center IDs from work order
  useEffect(() => {
    if (props.workOrder && props.workCenterGroups) {
      const matchingLocations = props.workOrder.current_locations
        .map((location: currentLocation) => location.work_center_group_id)

      const matchingAssignments = props.workCenterGroups
        .filter((workCenterGroup: workCenterGroup) => workCenterGroup.work_center_group_name.includes(props.workOrder.odoo_stage_name))
        .map((workCenterGroup: workCenterGroup) => workCenterGroup.id)

      setLocationGroupIds([...matchingLocations])
      setAssignedGroupIds([...matchingAssignments])
    }
  }, [props.workCenterGroups, props.workOrder])

  const getVariant = (group_id: number) => {
    let variant = 'outline-secondary'
    const matchingLocation = props.workOrder.current_locations.find((currentLocation: currentLocation) => currentLocation.work_center_group_id === group_id)
    if (matchingLocation) {
      if (matchingLocation.is_active === true && matchingLocation.is_completed === false) {
        variant = 'outline-warning'
      } else if (matchingLocation.is_active === true && matchingLocation.is_completed === true) {
        variant = 'outline-success'
      }
    }
    return variant
  }

  const getDescription = (group_id: number) => {
    let description = 'Inactive'
    const matchingLocation = props.workOrder.current_locations.find((currentLocation: currentLocation) => currentLocation.work_center_group_id === group_id)
    if (matchingLocation) {
      if (matchingLocation.is_active === true && matchingLocation.is_completed === false) {
        description = 'In Progress'
      } else if (matchingLocation.is_active === true && matchingLocation.is_completed === true) {
        description = 'Completed'
      }
    }
    return description
  }

  const getShowP1TimeTrackingWarning = (workCentreLocation: currentLocation, workCenterGroupId: number) => {
    let showP1TimeTrackingWarning: boolean = false

    if (workCenterGroupId === workCenterGroupP1Id && workCentreLocation && workCentreLocation.has_time_tracked === false) {
      showP1TimeTrackingWarning = true
    }

    return showP1TimeTrackingWarning
  }

  const onGroupChange = async (newLocationGroupIds: any) => {
    // Check permission
    if (!hasUpdatePermission) {
      createNotification('Error', 'You do not have permission to assign locations.', 'error')
      return false
    }

    // Since this change handler receives a list of all active IDs,
    // we have to determine which ID has changed, and submit only that changed ID to the API.
    const removedItems = locationGroupIds.filter((id: number) => !newLocationGroupIds.includes(id))
    const addedItems = newLocationGroupIds.filter((id: number) => !locationGroupIds.includes(id))
    const changedItems = [...addedItems, ...removedItems]

    // The API needs the DB row ID to update. If there's none yet, feed it 0
    const changedItem: currentLocation | undefined = props.workOrder.current_locations
      .find((location: currentLocation) => location.work_center_group_id === changedItems[0])
    const changedItemDatabaseId: number = changedItem ? changedItem.id : 0

    const groupDescription = getDescription(changedItems[0])

    const workCentreLocation: currentLocation = await getWorkCentreGroupLocation(props.workOrder.id, changedItems[0])
    const showP1TimeTrackingWarning = getShowP1TimeTrackingWarning(workCentreLocation, changedItems[0])

    let allowGroupUpdate = true

    if (groupDescription === 'In Progress' && showP1TimeTrackingWarning) {
      await Demodal.open(ConfirmModal, {
        title: 'Warning',
        description: "No Blasting time recorded. Ensure Blasters have recorded their time before proceeding.",
        confirmLabel: 'Confirm',
        showCancel: false,
      })
    }

    if (groupDescription === 'Completed') {
      // Open confirmation modal if row ID is already completed
      const modalResult: any = await Demodal.open(ConfirmModal, {
        title: 'Unassign Location',
        description: "The work order has already been marked as completed at this plant. Do you want to reset its status?",
        confirmLabel: 'Yes',
        cancelLabel: 'No',
        showCancel: true,
      })

      if (!modalResult) {
        allowGroupUpdate = false
      }
    }

    if (allowGroupUpdate && changedItems.length > 0) {
      setLocationGroupIds(newLocationGroupIds)
      await toggleWorkCentreLocationAssignment(
        props.workOrder.odoo_work_order_id,
        props.workOrder.id,
        changedItems[0],
        changedItemDatabaseId,
      )
      triggerGlobalRefresh()
    }
  }

  const onGroupClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation() // Prevent row from receiving click event
  }

  const onDropdownClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation() // Prevent row from receiving click event
    setShowDropdown(!showDropdown)
  }

  const getToggleButtons = (isVertical: boolean) => (
    <ToggleButtonGroup
      vertical={isVertical}
      type="checkbox"
      value={locationGroupIds}
      onChange={onGroupChange}
      className={`current-location-toggle ${isVertical ? 'd-block' : ''}`}
    >
      {props.workCenterGroups
        .map((group: workCenterGroup) => (
          <ToggleButton
            key={group.id}
            value={group.id}
            variant={getVariant(group.id)}
            title={getDescription(group.id)}
            onClick={onGroupClick}
          >
            {assignedGroupIds.includes(group.id) && (
              <span title="Assigned" className="mr-2">
                <CheckCircle />
              </span>
            )}
            <span className="d-inline-block align-middle">{group.work_center_group_name}</span>
          </ToggleButton>
        ))
      }
    </ToggleButtonGroup>
  )

  return (
    <>
      <div className="d-none d-md-block">{getToggleButtons(false)}</div>
      <div className="d-block d-md-none">
        <Dropdown alignRight={true} show={showDropdown}>
          <Dropdown.Toggle variant="primary" className="dropdown-caret-only" onClick={onDropdownClick} />
          <Dropdown.Menu className="auto-dropdown-menu">
            {getToggleButtons(true)}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  )
}

export default CurrentLocationWidget