import { useState as useGlobalState } from '@hookstate/core'
import globalState from '../../common/context'
import { ArrowRepeat } from 'react-bootstrap-icons'
import { Button } from 'react-bootstrap'

const OdooDowntimeBlockUi = () => {
  const globals = useGlobalState(globalState)

  const handleRefreshClick = () => {
    window.location.reload()
  }

  return (
    <>
      {globals.odooIsDown.get() && (
        <div className="odoo-downtime-block-ui">
          <h1 className="odoo-downtime-heading">Could not connect to Odoo.</h1>
          <p>Please wait a minute then try refreshing the page. If downtime persists, contact technical support.</p>
          <Button size="lg" onClick={handleRefreshClick}>
            <ArrowRepeat className="d-inline-block align-middle mr-2"/>
            <span className="d-inline-block align-middle">Refresh</span>
          </Button>
        </div>
      )}
    </>
  )
}

export default OdooDowntimeBlockUi
