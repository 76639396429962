export interface shopFloorTab {
  id: number;
  odoo_work_order_id: number;
  odoo_work_order_name: string;
  tracking_time: boolean;
}

export const shopFloorTabsMockData: shopFloorTab[] = [
  {
    id: 1,
    odoo_work_order_id: 12345,
    odoo_work_order_name: 'TKW00007',
    tracking_time: false,
  },
  {
    id: 2,
    odoo_work_order_id: 23456,
    odoo_work_order_name: 'TKW00008',
    tracking_time: true,
  },
]
