import { Demodal } from "demodal";
import { BaseSyntheticEvent, useEffect } from "react";
import { Badge, Button } from "react-bootstrap";
import { Plus, Printer } from "react-bootstrap-icons";
import BootstrapTable from "react-bootstrap-table-next";
import { getDeliveries, getPackingSlip, postDelivery, printPackingSlipPdf } from "../../data/api-requests";
import { delivery, packingSlipLines, packingSlipProduct } from "../../data/types/deliveries";
import useGetData from "../../hooks/use-get-data";
import { getDateStringFromDate } from "../../utils/helpers";
import DeliveryItemsModal from "../shared/modal/modal-delivery-items";
import PdfViewerModal from "../shared/modal/modal-pdf-viewer";

interface WorkOrderDeliveriesProps {
  workOrderId: number;
  odooWorkOrderId: number;
  readOnly?: boolean;
}

const WorkOrderDeliveries = (props: WorkOrderDeliveriesProps) => {
  const fetchData = async () => {
    if (props.workOrderId > 0) {
      const result = await getDeliveries(props.workOrderId)
      if (result) return result
    }
  }

  const [deliveries, refresh, setDeliveries] = useGetData<delivery>({
    fetchDataFn: fetchData,
    autoLoad: false,
  })

  useEffect(() => {
    if (props.workOrderId) refresh()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workOrderId])

  const onAddItemClick = async () => {
    const newDelivery = await postDelivery(props.workOrderId)
    // Reload data, open delivery for editing
    fetchData().then((result: delivery[]) => {
      setDeliveries(result)
      const matchingRow = result.find((delivery: delivery) => delivery.id === newDelivery.id)
      if (matchingRow) openDeliveryItemsModal(matchingRow)
    })
  }

  const nameFormatter = (cell: any, row: delivery) => (
    cell ? cell :`CUSTOM/OUT/${String(row.id).padStart(5, '0')}`
  )

  const dateFormatter = (cell: any, row: delivery) => (
    row.date ? getDateStringFromDate(row.date, 'd MMM yyyy') : '-'
  )

  const stateFormatter = (cell: any, row: delivery) => {
    const name = row.state ? row.state.charAt(0).toUpperCase() + row.state.slice(1) : '-'
    const variants = {
      'draft': 'secondary',
      'waiting': 'info',
      'confirmed': 'info',
      'done': 'success',
      'assigned': 'info',
      'cancel': 'secondary',
    }
    return (<Badge variant={variants[row.state]}>{name}</Badge>)
  }

  const packingSlipPrintButtonFormatter = (cell: any, row: delivery) => {
    return (
      <Button variant="outline-primary" value="print" onClick={
        () => packingSlipPrint({
          odoo_work_order_id: row.odoo_work_order_id,
          odoo_stock_picking_id: row.odoo_id,
          delivery_id: row.id,
        })
      }>
        <Printer className="disable-pointer-events"/>
      </Button>
    )
  }

  const rowEvents = {
    onClick: (e: BaseSyntheticEvent, row: delivery, rowIndex: number) => {
      if (!props.readOnly && e.target.value !== 'print') {
        openDeliveryItemsModal(row)
      }
    }
  }

  const openDeliveryItemsModal = async (deliveryOrder: delivery) => {
    const deliveryOrderId = deliveryOrder.id ?? 0
    const odooStockPickingId = deliveryOrder.odoo_id ?? 0
    getPackingSlip(deliveryOrderId, odooStockPickingId)
    .then((result: packingSlipLines) => {
      Demodal.open(DeliveryItemsModal, {
        deliveryOrderId: deliveryOrderId,
        workOrderId: deliveryOrder.work_order_id,
        odooStockPickingId: odooStockPickingId,
        odooWorkOrderId: deliveryOrder.odoo_work_order_id,
        deliveryState: deliveryOrder.state,
        deliveryName: deliveryOrder.name,
        deliveryNote: result.note,
        productsList: result.products
      })
      .then((modalResult: any) => {
        if (modalResult === true) {
          refresh()
        }
      })
    })
  }

  const packingSlipPrint = async (data: any) => {
    printPackingSlipPdf(data)
      .then((pdfString: string) => {
        Demodal.open(PdfViewerModal, {
          pdfData: pdfString
        })
      })
  }

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: nameFormatter,
      sort: true,
    },
    {
      dataField: 'date',
      text: 'Date',
      formatter: dateFormatter,
    },
    {
      dataField: 'state',
      text: 'State',
      formatter: stateFormatter,
      headerStyle: {width: 100},
    },
    {
      dataField: 'none',
      text: '',
      formatter: packingSlipPrintButtonFormatter,
      headerStyle: {width: 70},
    }
  ]

  return (
    <div className="work-order-section work-order-deliveries">
      <header className="work-order-section-header work-order-deliveries-header">
        <h2>Deliveries</h2>
        {!props.readOnly && (<Button onClick={onAddItemClick}><Plus/></Button>)}
      </header>

      <BootstrapTable
        keyField="odoo_id"
        data={ deliveries }
        columns={ tableColumns }
        defaultSorted={[{dataField: 'odoo_id', order: 'asc'}]}
        hover
        rowEvents={ rowEvents }
        bootstrap4
      />
    </div>
  )
}

export default WorkOrderDeliveries