import { Demodal } from "demodal";
import { BaseSyntheticEvent, MouseEvent, useEffect, useState } from "react";
import { useState as useGlobalState } from '@hookstate/core'
import globalState from '../../common/context'
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ExclamationTriangleFill, Plus, Trash } from "react-bootstrap-icons";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import { deleteApplicablePaint, getApplicablePaints, postApplicablePaintUsage } from "../../data/api-requests";
import { applicablePaint } from "../../data/types/applicable-paint";
import createNotification from "../../utils/notification";
import { applicablePaintFormFields, applicablePaintFormSchema } from "../shared/form/form-applicable-paint";
import ConfirmModal from "../shared/modal/modal-confirm";
import ItemModal from "../shared/modal/modal-item";
import useGetData from "../../hooks/use-get-data";
import WorkOrderApplicablePaintTableGroup from "./work-order-applicable-paint-table-group";

interface WorkOrderApplicablePaintsProps {
  workOrderId: number;
  readOnly?: boolean;
  groupByPaintCategory?: boolean;
}

const WorkOrderApplicablePaints = (props: WorkOrderApplicablePaintsProps) => {
  const globals = useGlobalState(globalState)
  const [tableColumns, setTableColumns] = useState<ColumnDescription<any, any>[]>()

  const fetchData = async () => {
    const result = await getApplicablePaints(props.workOrderId)
    if (result) return result
  }

  const [applicablePaints, refresh] = useGetData<applicablePaint>({
    fetchDataFn: fetchData
  })

  useEffect(() => {
    let columns = [
      {
        dataField: 'product_name',
        text: 'Name',
        sort: true,
        formatter: productFormatter,
      },
      {
        dataField: 'vendor_name',
        text: 'Vendor',
      },
      {
        dataField: 'category',
        text: 'Category',
        formatter: badgeFormatter,
      },
      {
        dataField: 'id',
        text: '',
        formatter: deleteFormatter,
        headerStyle: {width: 75},
      },
    ]

    if (props.readOnly === true) {
      columns = columns.filter((column: ColumnDescription) => column.dataField !== 'id')
    }

    setTableColumns(columns)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.readOnly])

  // Set available applicable paint IDs for this work order
  useEffect(() => {
    if (applicablePaints) {
      globals.paintProductIds.set(applicablePaints.map((paint: applicablePaint) => paint.odoo_product_product_id))
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicablePaints])

  const onAddItemClick = async (event: BaseSyntheticEvent) => {
    const modalResult: any = await Demodal.open(ItemModal, {
      itemName: "Applicable Paint",
      formData: {'odoo_product_product_id': 0},
      formFields: applicablePaintFormFields,
      formSchema: applicablePaintFormSchema,
    })

    if (modalResult) {
      const result = await postApplicablePaintUsage(props.workOrderId, modalResult)
      if (result) {
        createNotification('Submitted', `Added applicable paint entry.`)
        refresh()
      }
    }
  }

  const onDeleteRow = async (event: MouseEvent<HTMLElement>, row: applicablePaint) => {
    event.stopPropagation()
    const modalResult = await Demodal.open(ConfirmModal, {
      title: "Delete Applicable Paint?",
      description: <>Please confirm deletion.</>,
    })

    if (modalResult) {
      const result = await deleteApplicablePaint(row.id)
      if (result) {
        createNotification('Deleted', `Deleted applicable paint entry.`)
        refresh()
      }
    }
  }

  const productFormatter = (cell: any, row: applicablePaint) => (
    <span className="d-block">
      {(row.product_name === 'N/A') ? (
        <OverlayTrigger delay={{ show: 500, hide: 0 }} overlay={<Tooltip id="invalid-paint-tooltip">This applicable paint could not be found in Odoo. Please delete this row and correct any related paint usages.</Tooltip>}>
          <span>
            <ExclamationTriangleFill className="d-inline-block align-middle mr-2 text-danger"/>
            {row.product_name}
          </span>
        </OverlayTrigger>
      ) : (
        <>
          {row.product_name}
          {" "}
          {row.default_code && (
            <small>[{row.default_code}]</small>
          )}
        </>
      )}
    </span>
  )

  const badgeFormatter = (cell: any, row: applicablePaint) => {
    return <Badge variant="info">{cell}</Badge>
  }

  const deleteFormatter = (cell: any, row: applicablePaint) => {
    return (
      <Button variant="outline-danger" onClick={(event) => {onDeleteRow(event, row)}}><Trash /></Button>
    )
  }

  const showApplicablePaintHeaders = (category: string) => {
    return applicablePaints.filter((paint: applicablePaint) => paint.category === category).length > 0
  }

  return (
    <div className="work-order-section work-order-applicable-paints">
      <header className="work-order-section-header work-order-applicable-paints-header">
        <h2>Applicable Paints</h2>
        {!props.readOnly && (<Button onClick={onAddItemClick}><Plus/></Button>)}
      </header>

      <div className="mt-3">
        {tableColumns && !props.groupByPaintCategory ? (
          <BootstrapTable
            keyField="id"
            data={ applicablePaints }
            columns={ tableColumns }
            defaultSorted={[{dataField: 'product_name', order: 'asc'}]}
            bootstrap4
          />
        ) : (
          <table className="table table-bordered m-0 mt-3">
            <tbody>
              <WorkOrderApplicablePaintTableGroup
                category='Primer'
                applicablePaints={applicablePaints}
                showHeaders={showApplicablePaintHeaders('Primer')}
              />
              <WorkOrderApplicablePaintTableGroup
                category='Clear Coat'
                applicablePaints={applicablePaints}
                showHeaders={
                  !showApplicablePaintHeaders('Primer') &&
                  showApplicablePaintHeaders('Clear Coat')
                }
              />
              <WorkOrderApplicablePaintTableGroup
                category='Top Coat'
                applicablePaints={applicablePaints}
                showHeaders={
                  !showApplicablePaintHeaders('Primer') &&
                  !showApplicablePaintHeaders('Clear Coat') &&
                  showApplicablePaintHeaders('Top Coat')}
              />
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

export default WorkOrderApplicablePaints