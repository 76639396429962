import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { completeWorkOrderReview } from "../../data/api-requests";
import { triggerGlobalRefresh } from "../../utils/helpers";

interface WorkOrderReviewCompletedProps {
  workOrderId: number;
  reviewCompleted: boolean;
}

const WorkOrderReviewCompleted = (props: WorkOrderReviewCompletedProps) => {
  const [workOrderReviewCompleted, setWorkOrderReviewCompleted] = useState<boolean>(false)

  useEffect(() => {
    setWorkOrderReviewCompleted(props.reviewCompleted)
  }, [props])

  const onReviewCompletedClick = () => {
    completeWorkOrderReview(props.workOrderId)
    .then(() => {
      setWorkOrderReviewCompleted(true)
      triggerGlobalRefresh()
    })
  }

  return (
    <div className="work-order-section work-order-review-completed">
      <Form>
        <Form.Check
          custom
          type='checkbox'
          id='review-completed-checkbox'
          label='Review Completed'
          onChange={onReviewCompletedClick}
          checked={workOrderReviewCompleted}
          disabled={workOrderReviewCompleted}
        />
      </Form>
    </div>
  )
}

export default WorkOrderReviewCompleted