import { getReceivedParts } from "../../data/api-requests"
import { receivedPart } from "../../data/types/received-parts"
import WorkOrderReceivedPart from "./work-order-received-part"
import useGetData from "../../hooks/use-get-data"
import { useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { workOrder } from "../../data/types/work-order";


interface WorkOrderReceivedPartsProps {
  workOrder: workOrder;
  readOnly?: boolean;
}

const WorkOrderReceivedParts = (props: WorkOrderReceivedPartsProps) => {
  const [show, setShow] = useState<boolean>(false)

  const fetchData = async () => {
    const result = await getReceivedParts(props.workOrder.id)
    if (result) {
      return result.sort((a: receivedPart, b: receivedPart) => b.id - a.id) // Sort by ID, desc
    }
  }

  const [receivedParts, refresh, setReceivedParts] = useGetData<receivedPart>({
    autoLoad: false,
    fetchDataFn: fetchData,
  })

  const onToggleClick = () => {
    if (!show && receivedParts.length === 0) refresh()
    setShow(!show)
  }

  const onEdit = () => refresh()

  const onDelete = async (id: number) => {
    // Remove item from the list without refreshing all data
    const filteredParts = [...receivedParts].filter((receivedPart: receivedPart) => receivedPart.id !== id)
    setReceivedParts(filteredParts)
  }

  const onUnassignWorkOrder = () => refresh()
  const onTagWorkOrder = () => refresh()
  const onUntagWorkOrder = () => refresh()

  return (
    <>
      {props.workOrder.received_part_count > 0 && (
        <div className="work-order-section work-order-parts">
          <header className="work-order-section-header work-order-received-parts-header">
            <h2>Received Parts</h2>
            <Badge variant="secondary" className="ml-2">{props.workOrder.received_part_count}</Badge>
            <Button className="ml-auto" onClick={onToggleClick}>
              {show ? <ChevronUp/> : <ChevronDown/>}
            </Button>
          </header>

          <div className={`work-order-received-parts-list ${show ? 'is-visible' : ''}`}>
            {receivedParts.map((receivedPart: receivedPart) => (
              <WorkOrderReceivedPart
                key={receivedPart.id}
                receivedPart={receivedPart}
                onEdit={onEdit}
                onDelete={onDelete}
                onUnassignWorkOrder={onUnassignWorkOrder}
                onTagWorkOrder={onTagWorkOrder}
                onUntagWorkOrder={onUntagWorkOrder}
                readOnly={props.readOnly}
              />
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default WorkOrderReceivedParts