import { useEffect, useState } from "react"
import { applicableWorkCenter } from "../data/types/applicable-work-center"
import { workOrder } from "../data/types/work-order"

const useApplicableWorkCenterIds = (workOrder: workOrder) => {
  const [applicableWorkCenterIds, setApplicableWorkCenterIds] = useState<number[]>([])

  useEffect(() => {
    if (workOrder) {
      setApplicableWorkCenterIds(
        workOrder.applicable_work_centers
          .filter((value: applicableWorkCenter) => value.is_active === true)
          .map((value: applicableWorkCenter) => value.work_center_id)
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workOrder])

  return applicableWorkCenterIds
}

export default useApplicableWorkCenterIds
