import { Switch, Route, Redirect, } from 'react-router-dom'
import { Demodal } from 'demodal'

import Notifications from './components/shared/notifications'
import LoadingIndicator from './components/shared/loading-indicator'
import Header from './components/shared/header'
import CheckLogin from './components/shared/check-login'
import OdooDowntimeBlockUi from './components/shared/odoo-down-block-ui'
import PermissionError from './components/shared/permission-error'
import RouteNotFound from './components/shared/route-not-found'
import WorkOrderOverview from './components/work-order-overview/work-order-overview'
import WorkOrder from './components/work-order/work-order'
import WorkOrderShopFloor from './components/work-order/work-order-shop-floor'
import Receiving from './components/receiving/receiving'
import SyncData from './components/shared/sync'
import Users from './components/users/users'
import WorkOrderOdooRedirect from './components/work-order/work-order-odoo-redirect'
import LogoutComponent from './components/shared/logout'
import TimeTracking from './components/time-tracking/time-tracking'
import Settings from './components/settings/settings'
import Scrap from './components/scrap/scrap'
import Queue from './components/queue/queue'

const App = () => {
  return (
    <>
      <Demodal.Container />
      <Notifications />
      <LoadingIndicator />
      <OdooDowntimeBlockUi />
      <SyncData />
      <Header />
      <CheckLogin>
        <Switch>
          <Redirect exact from="/" to="/work_order_overview/paint" />
          <Redirect exact from="/work_order_overview" to="/work_order_overview/paint" />
          <Redirect exact from="/work_order/shop_floor/" to="/work_order/shop_floor/0" />
          <Redirect exact from="/work_order/:workOrderId" to="/work_order/:workOrderId/review" />
          <Route path="/settings">
            <Settings />
          </Route>
          <Route path="/users">
            <Users />
          </Route>
          <Route path="/queue">
            <Queue />
          </Route>
          <Route path="/receiving">
            <Receiving />
          </Route>
          <Route path="/time_tracking">
            <TimeTracking />
          </Route>
          <Route path="/work_order/shop_floor/:workOrderId">
            <WorkOrderShopFloor />
          </Route>
          <Route path="/work_order_overview/:tab">
            <WorkOrderOverview />
          </Route>
          <Route path="/odoo_work_order_redirect/:odooWorkOrderId">
            <WorkOrderOdooRedirect />
          </Route>
          <Route path="/work_order/:workOrderId/:tab?">
            <WorkOrder />
          </Route>
          <Route path="/scrap">
            <Scrap />
          </Route>
          <Route path="/logout" component={LogoutComponent} />
          <Route path="/permission_error" component={PermissionError} />
          <Route component={RouteNotFound} />
        </Switch>
      </CheckLogin>
    </>
  )
}

export default App;
