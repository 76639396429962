import { Button, ListGroup } from "react-bootstrap";
import { FileImage, FilePdf, Trash } from "react-bootstrap-icons";

interface AttachmentFieldsListNewProps {
  files: any[];
  onFilesChange: Function;
  formikSetFieldValue?: Function;
}

const AttachmentsFieldListNew = (props: AttachmentFieldsListNewProps) => {
  const getFileSize = (file: any) => {
    // Returns size in megabytes
    return file.size > 0 ? file.size / 1024 / 1024 : 0
  }

  const removeFile = (file: any) => () => {
    const newFiles = [...props.files]
    newFiles.splice(newFiles.indexOf(file), 1)
    props.onFilesChange([...newFiles])
    if (props.formikSetFieldValue) props.formikSetFieldValue('new_attachments', [...newFiles])
  }

  const getPreview = (file: any) => {
    let preview = <FileImage className="attachment-icon text-info" size={64} />

    if (file.type && file.type.includes('pdf')) {
      preview = <FilePdf className="attachment-icon text-danger" size={64} />
    } else if (file.preview) {
        preview = <img
          alt=""
          className="attachments-field-uploaded-item__image"
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
    }

    return preview
  }

  return (
    <>
      {props.files.length > 0 && (
        <ListGroup className="attachments-field-thumbs">
          {props.files.map((file: any) => (
            <ListGroup.Item key={file.name} className="attachments-field-uploaded-item">
              <div className="attachments-field-uploaded-item__image-container">
                {getPreview(file)}
              </div>
              <div className="attachments-field-uploaded-item__info text-truncate mx-3">
                <div className="text-truncate">{file.path}</div>
                <small className="text-muted">{getFileSize(file).toFixed(2)} MB</small></div>
              <Button variant="danger" className="attachments-field-uploaded-item__delete" onClick={removeFile(file)}><Trash /></Button>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </>
  )
}

export default AttachmentsFieldListNew
