import * as Yup from 'yup';
import { formField } from '../modal/modal-item';
import { formFieldTypes } from '../../../data/types/form-field-type';

// Form Fields
export const paintCuringFormFields: formField[][] = [
    [
        {
            name: 'temperature',
            label: 'Temp (F)',
            type: formFieldTypes.text,
        },
        {
            name: 'time_in_minutes',
            label: 'Time (min)',
            type: formFieldTypes.text,
        },
        {
            name: 'description',
            label: '',
            type: formFieldTypes.description,
        }
    ]
]

// Validation Rules
export const paintCuringFormSchema = Yup.object().shape({
    id: Yup.number(),
    temperature: Yup.number().positive('Required').required('Required'),
    time_in_minutes: Yup.number().positive('Required').required('Required'),
})
