import { useEffect, useState } from "react";
import { Button, ListGroup } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { workOrderAttachment } from "../../../data/types/work-order";
import AttachmentImage from "../attachment-image";

interface AttachmentFieldsListEditProps {
  attachments: workOrderAttachment[];
  formikSetFieldValue: Function;
}

const AttachmentsFieldListEdit = (props: AttachmentFieldsListEditProps) => {
  const [attachments, setAttachments] = useState<workOrderAttachment[]>([])
  const [attachmentIdsToDelete, setAttachmentIdsToDelete] = useState<number[]>([])

  const deleteExistingFile = async (attachmentId: number) => {
    const matchingAttachments = attachments.find((attachment: workOrderAttachment) => attachment.id === attachmentId)
    if (matchingAttachments) {
      setAttachments([...attachments.filter((attachment: workOrderAttachment) => attachment.id !== attachmentId)])
      setAttachmentIdsToDelete([matchingAttachments.id, ...attachmentIdsToDelete])
      props.formikSetFieldValue('deleted_attachments', [matchingAttachments.id, ...attachmentIdsToDelete])
    }
  }

  useEffect(() =>  {
    setAttachments(props.attachments)
  }, [props.attachments])

  return (
    <>
      {attachments.length > 0 && (
        <ListGroup className="attachments-field-thumbs">
          {attachments.map((attachment: workOrderAttachment) => (
            <ListGroup.Item key={attachment.id} className="attachments-field-uploaded-item">
              <div className="attachments-field-uploaded-item__image-container">
                <div className="attachments-field-uploaded-item__image">
                  <AttachmentImage attachmentId={attachment.id} />
                </div>
              </div>
              <div className="mx-3 text-truncate" title={attachment.file_name}>{attachment.file_name}</div>
              <Button variant="danger" className="attachments-field-uploaded-item__delete ml-auto" onClick={() => {deleteExistingFile(attachment.id)}}><Trash /></Button>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </>
  )
}

export default AttachmentsFieldListEdit
