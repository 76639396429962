import * as Yup from 'yup';
import { formField } from '../modal/modal-item';
import { formFieldTypes } from '../../../data/types/form-field-type';

// Form Fields
export const positectorFormFields: formField[][] = [
    [
        {
            name: 'batch',
            label: 'Batch',
            type: formFieldTypes.text,
        },
        {
            name: 'min',
            label: 'Min',
            type: formFieldTypes.text,
        },
        {
            name: 'max',
            label: 'Max',
            type: formFieldTypes.text,
        },
        {
            name: 'avg',
            label: 'Avg',
            type: formFieldTypes.text,
        },
        {
            name: 'description',
            label: '',
            type: formFieldTypes.description,
        }
    ]
]

// Validation Rules
export const positectorFormSchema = Yup.object().shape({
    id: Yup.number().integer(),
    batch: Yup.string()
    .required('Required'),
    min: Yup.number()
    .positive('Number must be positive')
    .required('Required'),
    max: Yup.number()
    .positive('Number must be positive')
    .required('Required'),
    avg: Yup.number()
    .positive('Number must be positive')
    .required('Required'),
})
