import { BaseSyntheticEvent, useEffect, useState } from "react"
import { Demodal } from "demodal"
import { Button, Container } from "react-bootstrap"
import { deleteReceivedPart, getReceivedParts, postReceivedPart, putReceivedPart } from "../../data/api-requests"
import { newReceivedPart, receivedPart } from "../../data/types/received-parts"
import createNotification from "../../utils/notification"
import { receivedPartFormFields, receivedPartFormSchema } from "../shared/form/form-received-part"
import ItemModal from "../shared/modal/modal-item"
import WorkOrderReceivedPart from "../work-order/work-order-received-part"
import { getRecievedPartName, triggerReceivedPartsCountsRefresh } from "../../utils/helpers"
import { uploadFiles } from "../../utils/attachments"
import ReceivingNav from "./receiving-nav"
import useCheckPermission from "../../hooks/use-check-permission"
import useGetData from "../../hooks/use-get-data"
import { Plus } from "react-bootstrap-icons"

export const Receiving = () => {
  const hasReceivePermission = useCheckPermission('Receive Parts', true)

  const [filteredParts, setFilteredParts] = useState<receivedPart[]>([])
  const [unassignedCount, setUnassignedCount] = useState<number>(0)
  const [untaggedCount, setUntaggedCount] = useState<number>(0)
  const [tab, setTab] = useState<string>('unassigned')
  const [filterValue, setFilterValue] = useState<string>('')

  const onAddItemClick = async (event: BaseSyntheticEvent) => {
    const newReceivedPartResponse = await postReceivedPart()
    if (newReceivedPartResponse) {
      const modalResult: any = await Demodal.open(ItemModal, {
        titleVerb: "Add",
        itemName: getRecievedPartName(newReceivedPartResponse.id),
        formData: {...newReceivedPart, id: newReceivedPartResponse.id},
        formFields: receivedPartFormFields,
        formSchema: receivedPartFormSchema,
      })

      if (modalResult) {
        const files = modalResult['new_attachments']
        if ('new_attachments' in modalResult) delete modalResult['new_attachments']
        if ('id' in modalResult) delete modalResult['id']

        uploadFiles(files, undefined, newReceivedPartResponse.id)
        await putReceivedPart(newReceivedPartResponse.id, modalResult)
        createNotification('Submitted', `Added received part.`)
        refresh()
      } else {
        // They clicked "Cancel", so delete the unfinished item
        deleteReceivedPart(newReceivedPartResponse.id)
      }
    }
  }

  const onEdit = () => refresh()
  const onAssignWorkOrder = () => refresh()
  const onUnassignWorkOrder = () => refresh()

  const onDelete = async (id: number) => {
    // Remove item from the list without refreshing all data
    const filteredParts = [...receivedParts].filter((receivedPart: receivedPart) => receivedPart.id !== id)
    setReceivedParts(filteredParts)
  }

  const onTagWorkOrder = async (receivedPart: receivedPart) => {
    // Update item in the list without refreshing all data
    const partToUpdate = receivedParts.find((receivedPart2: receivedPart) => receivedPart2.id === receivedPart.id)
    if (partToUpdate) partToUpdate.is_tagged = true
    filterParts()
  }

  const onUntagWorkOrder = async (receivedPart: receivedPart) => {
    // Update item in the list without refreshing all data
    const partToUpdate = receivedParts.find((receivedPart2: receivedPart) => receivedPart2.id === receivedPart.id)
    if (partToUpdate) partToUpdate.is_tagged = false
    filterParts()
  }

  const fetchData = async () => {
    if (hasReceivePermission) {
      const result = await getReceivedParts()
      if (result) {
        return result.sort((a: receivedPart, b: receivedPart) => b.id - a.id) // Sort by ID, desc
      }
    }
  }

  const [receivedParts, refresh, setReceivedParts] = useGetData<receivedPart>({
    fetchDataFn: fetchData
  })

  const filterParts = () => {
    // Filter by text input
    const filteredParts = [...receivedParts].filter((receivedPart: receivedPart) => (
      String(receivedPart.customer_name).toLowerCase().includes(filterValue.toLowerCase())
      || String(receivedPart.customer_po_number).toLowerCase().includes(filterValue.toLowerCase())
      || String(receivedPart.odoo_work_order_name).toLowerCase().includes(filterValue.toLowerCase())
      || String(receivedPart.id).toLowerCase().includes(filterValue.toLowerCase())
    ))

    // Filter by tab
    const unassignedParts = [...filteredParts].filter((receivedPart: receivedPart) => receivedPart.work_order_id === null)
    const untaggedParts = [...filteredParts].filter((receivedPart: receivedPart) => receivedPart.work_order_id && receivedPart.is_tagged === false)

    setUnassignedCount(unassignedParts.length)
    setUntaggedCount(untaggedParts.length)

    setFilteredParts((tab === 'unassigned') ? unassignedParts : untaggedParts)
  }

  // Apply filtering & sorting
  useEffect(() => {
    filterParts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, receivedParts, filterValue])

  // The header's "Receiving" button needs to be refreshed whenever this page's data changes
  useEffect(() => {
    triggerReceivedPartsCountsRefresh()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivedParts])

  useEffect(() => {
    if (hasReceivePermission) refresh()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasReceivePermission])

  return (
    <>
      <header className="section-header received-parts-header d-flex align-items-center p-3">
        <h1 className="m-0">Received Parts</h1>
        <Button variant="primary" onClick={onAddItemClick} className="ml-3"><Plus/></Button>
      </header>

      <Container fluid className="w-100 p-3 mb-4 bg-white">
        <ReceivingNav
          currentTab={tab}
          onTabChange={setTab}
          currentFilterValue={filterValue}
          onFilterValueChange={setFilterValue}
          unassignedCount={unassignedCount}
          untaggedCount={untaggedCount}
        />
        <div className="work-order-received-parts-list is-visible three-col-layout">
          {filteredParts.map((receivedPart: receivedPart) => (
            <WorkOrderReceivedPart
              key={receivedPart.id}
              receivedPart={receivedPart}
              showWorkOrderNum={true}
              showAssignButton={receivedPart.work_order_id === null && tab === 'unassigned'}
              showTagButton={receivedPart.is_tagged === false && tab === 'untagged'}
              onEdit={onEdit}
              onDelete={onDelete}
              onAssignWorkOrder={onAssignWorkOrder}
              onUnassignWorkOrder={onUnassignWorkOrder}
              onTagWorkOrder={onTagWorkOrder}
              onUntagWorkOrder={onUntagWorkOrder}
            />
          ))}
        </div>
      </Container>
    </>
  )
}

export default Receiving