import { useHistory, useParams } from "react-router-dom"
import { Form, Nav, Tab } from "react-bootstrap"
import { workCenterIds } from "../../common/config"
import { workOrder } from "../../data/types/work-order"
import useApplicableWorkCenterIds from "../../hooks/use-applicable-work-center-ids"
import { BaseSyntheticEvent, useEffect, useState } from "react"
import useCheckPermission from "../../hooks/use-check-permission"
import { ClipboardData } from "react-bootstrap-icons"

interface navItem {
  key: string;
  label: string;
}

interface WorkOrderNavProps {
  workOrder: workOrder;
}

const WorkOrderNav = (props: WorkOrderNavProps) => {
  const history = useHistory()
  const hasReportPermission = useCheckPermission('Reporting')
  const hasReviewWorkOrderPermission = useCheckPermission('Review WO')
  const { workOrderId, tab } = useParams<{workOrderId: string, tab: string}>()
  const applicableWorkCenterIds = useApplicableWorkCenterIds(props.workOrder)
  const [navItems, setNavItems] = useState<navItem[]>([])

  const onTabChange = (newTab: string|null) => {
    if (newTab) {
      history.push(`/work_order/${workOrderId}/${newTab}`)
    }
  }

  const onSelectChange = (event: BaseSyntheticEvent) => {
    const newTab = event.target.value
    if (newTab) {
      history.push(`/work_order/${workOrderId}/${newTab}`)
    }
  }

  useEffect(() => {
    let newNavItems = []

    if (hasReviewWorkOrderPermission) {
      newNavItems.push({
        'key': 'review',
        'label': 'Review',
      })
    }

    if (applicableWorkCenterIds.includes(workCenterIds.welding)) {
      newNavItems.push({
        'key': 'welding',
        'label': 'Welding',
      })
    }

    if (applicableWorkCenterIds.includes(workCenterIds.blast)) {
      newNavItems.push({
        'key': 'blast',
        'label': 'Blast',
      })
    }

    if (applicableWorkCenterIds.includes(workCenterIds.setup)) {
      newNavItems.push({
        'key': 'setup',
        'label': 'Setup',
      })
    }

    if (applicableWorkCenterIds.includes(workCenterIds.wash)) {
      newNavItems.push({
        'key': 'wash',
        'label': 'Wash',
      })
    }

    if (applicableWorkCenterIds.includes(workCenterIds.prep)) {
      newNavItems.push({
        'key': 'prep',
        'label': 'Prep',
      })
    }

    if (applicableWorkCenterIds.includes(workCenterIds.paint)) {
      newNavItems.push({
        'key': 'paint',
        'label': 'Paint',
      })
    }

    if (applicableWorkCenterIds.includes(workCenterIds.assembly)) {
      newNavItems.push({
        'key': 'assembly',
        'label': 'Assembly',
      })
    }

    if (applicableWorkCenterIds.includes(workCenterIds.packaging)) {
      newNavItems.push({
        'key': 'packaging',
        'label': 'Packaging',
      })
    }

    newNavItems.push({
      'key': 'delivery',
      'label': 'Delivery',
    })

    if (hasReportPermission) {
      newNavItems.push({
        'key': 'divider',
        'label': '',
      })
      newNavItems.push({
        'key': 'report',
        'label': 'Report',
      })
    }

    setNavItems(newNavItems)
  }, [applicableWorkCenterIds, hasReviewWorkOrderPermission, hasReportPermission])

  useEffect(() => {
    // Don't allow users without permission to access the review tab, even if they manually enter it
    if (
      navItems.length > 0
      && tab && tab === 'review'
      && !hasReviewWorkOrderPermission
    ) {
      onTabChange(navItems[0].key)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, hasReviewWorkOrderPermission, navItems])

  const getNavItem = (navItem: navItem) => {
    if (navItem.label === '') {
      return (
        <div key="divider" className="dropdown-divider" role="separator"></div>
      )
    } else {
      return (
        <Nav.Item key={navItem.key}>
          <Nav.Link eventKey={navItem.key}>
            {(navItem.key === 'report') && (
              <ClipboardData className="mr-2 d-inline-block align-middle"/>
            )}
            <span className="d-inline-block align-middle">{navItem.label}</span>
          </Nav.Link>
        </Nav.Item>
      )
    }
  }

  return (
    <>
      <Form.Control
        className="d-md-none mb-4"
        as="select"
        custom
        name="tab"
        value={tab}
        onChange={onSelectChange}
        disabled={navItems.length === 0}
      >
        <option key='select' value={''}>Select...</option>
        {navItems
          .filter((navItem: navItem) => navItem.key !== 'divider')
          .map((navItem: navItem) => (
            <option key={navItem.key} value={navItem.key}>{navItem.label}</option>
          ))
        }
      </Form.Control>
      <Tab.Container
        id="wo-tabs"
        activeKey={tab}
        onSelect={(newTab) => onTabChange(newTab)}
      >
        <Nav variant="pills" className="flex-column d-none d-md-flex">
          {navItems.map((navItem: navItem) => (
            getNavItem(navItem)
          ))}
        </Nav>
      </Tab.Container>
    </>
  )
}

export default WorkOrderNav
