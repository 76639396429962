import { useEffect, useState } from "react";
import { useState as useGlobalState } from '@hookstate/core'
import { useHistory, useParams } from "react-router-dom";
import { getParts, getWorkOrder } from "../../data/api-requests";
import { workCenterGroup, workOrder, emptyWorkOrder, workCenter } from "../../data/types/work-order";
import { part } from "../../data/types/parts";
import WorkOrderNav from "./work-order-nav";
import WorkOrderHeader from "./work-order-header";
import { Container, Row, Col } from "react-bootstrap";
import useGetData from "../../hooks/use-get-data";
import createNotification from "../../utils/notification";
import WorkOrderSections from "./work-order-sections";
import useGetStaticData from "../../hooks/use-get-static-data";
import { globalRefreshTriggers } from "../../common/context";

const WorkOrder = () => {
  const history = useHistory()
  const staticResources = useGetStaticData()
  const refreshTriggers = useGlobalState(globalRefreshTriggers)
  const triggerPartsRefresh = refreshTriggers.parts.get()
  const { workOrderId, tab } = useParams<{workOrderId: string, tab: string}>()
  const [currentTab, setCurrentTab] = useState<string>('')
  const [workCenterId, setWorkCenterId] = useState<number>(0)
  const [workCenterGroups, setWorkCenterGroups] = useState<workCenterGroup[]>([])
  const [workCenters, setWorkCenters] = useState<workCenter[]>([])

  const fetchWorkOrderData = async () => {
    const castWorkOrderId = Number(workOrderId)
    if (!isNaN(castWorkOrderId) && castWorkOrderId > 0) {
      const result = await getWorkOrder(Number(workOrderId))
      if (result) return [result]
    } else {
      // Invalid Work Order ID, return to list view
      history.push(`/work_order_overview/`)
      createNotification('Invalid Work Order', 'You must supply a number as the work order ID.')
    }
  }

  const fetchPartsData = async () => {
    const castWorkOrderId = Number(workOrderId)
    if (!isNaN(castWorkOrderId) && castWorkOrderId > 0) {
      const result = await getParts(Number(workOrderId))
      if (result) return result
    }
  }

  const [workOrder] = useGetData<workOrder>({
    fetchDataFn: fetchWorkOrderData,
    initialValue: [emptyWorkOrder],
  })

  const [parts, refreshParts] = useGetData<part>({
    fetchDataFn: fetchPartsData,
    initialValue: [],
  })

  useEffect(() => {
    if (triggerPartsRefresh > 0) refreshParts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerPartsRefresh])

  // Set the current tab when all dependencies are ready
  // Otherwise the layout will use outdated IDs when rendering tabs after the first load
  useEffect(() => {
    if ((tab && workCenterId) || (workCenterId === 0)) {
      setCurrentTab(tab)
    }
  }, [tab, workCenterId])

  // Set WCG and WC when available
  useEffect(() => {
    if (staticResources && staticResources.work_center_groups) {
      setWorkCenterGroups(staticResources.work_center_groups)
    }
    if (staticResources && staticResources.work_centers) {
      setWorkCenters(staticResources.work_centers)
    }
  }, [staticResources])

  // Set workCenterId on tab change
  useEffect(() => {
    if (tab && workCenters && workCenters.length > 0) {
      const newWorkCenterId = workCenters.find((value: workCenter) =>
        value.work_center_name.toLowerCase() === tab.toLowerCase()
      )
      if (newWorkCenterId) setWorkCenterId(newWorkCenterId.id)
    }
  }, [tab, workCenters])

  return (
    <>
      {(workOrder.length > 0) && (
        <>
          <WorkOrderHeader workOrder={workOrder[0]} workCenterGroups={workCenterGroups} />
          <Container fluid className="py-4 bg-light work-order-container">
            <Row className="work-order-row">
              <Col className="work-order-tabs-col">
                <WorkOrderNav workOrder={workOrder[0]} />
              </Col>
              <Col>
                <Row className="work-order-row">
                  <WorkOrderSections
                    currentTab={currentTab}
                    staticResources={staticResources}
                    parts={parts}
                    workOrderId={Number(workOrderId)}
                    workOrder={workOrder[0]}
                    workCenterId={Number(workCenterId)}
                    workCenterGroupId={0}
                    workCenters={workCenters}
                  />
                </Row>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  )
}

export default WorkOrder
