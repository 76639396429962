import { deleteAttachment, postAttachment } from "../data/api-requests";

export const toBase64 = (file: File) => new Promise<string | ArrayBuffer | null>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const uploadFiles = async (files: File[], workOrderId?: number, receivedPartId?: number) => {
  let promises: Promise<any>[] = []

  if (files && files.length > 0) {
    files.forEach(async (file: File) => {
      const fileData: string = String(await toBase64(file))
      const fileExtension = file.name.split('.').pop()?.toLowerCase()
      const fileName = file.name
      if (fileData && fileExtension) {
        promises.push(postAttachment(fileData, fileName, fileExtension, workOrderId, receivedPartId))
      } else {
        console.error('Invalid file data or extension, skipped uploading file.', fileData, fileExtension)
      }
    })
  }

  return Promise.all(promises)
}

export const deleteFiles = (attachmentIds: number[]) => {
  let promises: Promise<any>[] = []

  if (attachmentIds && attachmentIds.length > 0) {
    attachmentIds.forEach((id: number) => {
      promises.push(deleteAttachment(id))
    })
  }

  return Promise.all(promises)
}