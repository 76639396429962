export interface positectorTest {
  id: number;
  work_order_id: number;
  batch: string;
  min: number;
  max: number;
  avg: number;
  user_name: string;
  created_on: string;
  user_id: number;
}

export const emptyPositectorTest: positectorTest = {
  id: 0,
  work_order_id: 0,
  batch: '',
  min: 0,
  max: 0,
  avg: 0,
  user_name: '',
  created_on: '',
  user_id: 0,
}

export const positectorTestsMockData: positectorTest[] = [
  {
    id: 1,
    work_order_id: 1,
    batch: '255532',
    min: 1.82,
    max: 6.38,
    avg: 3.47,
    user_name: 'Peter Smith',
    created_on: '2022-06-22 13:10:23.000000',
    user_id: 1,
  },
]
