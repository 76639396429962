import { useStopwatch } from "../../lib/react-timer-hook";
import { timeEntry } from "../../data/types/time-tracking"
import { ChevronRight } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import Cookies from "universal-cookie";

interface HeaderTimeTrackingRowProps {
  timeEntry: timeEntry
}

const HeaderTimeTrackingRow = (props: HeaderTimeTrackingRowProps) => {
  const cookies = new Cookies()

  const getOffsetTimestamp = () => {
    const startDate = props.timeEntry.start_time
    if (startDate) {
      const offsetTimestamp = new Date()
      const offsetSeconds = (offsetTimestamp.getTime() - startDate.getTime()) / 1000
      // The start time must be in the past
      if (offsetSeconds > 0) {
        offsetTimestamp.setSeconds(offsetSeconds)
        return offsetTimestamp
      }
    }
  }

  const {
    minutes,
    hours,
  } = useStopwatch({
    autoStart: false,
    offsetTimestamp: getOffsetTimestamp()
  });

  const getFormattedTimeElement = () => {
    return <>
      {String(hours).padStart(2, '0')}
      <span className="time-separator">:</span>
      {String(minutes).padStart(2, '0')}
    </>
  }

  const getButtonUrl = () => {
    const workOrderId = props.timeEntry.work_order_id
    const workCenterId = props.timeEntry.work_center_id
    const workCenterGroupId = props.timeEntry.work_center_group_id
    cookies.set('tk_shop_floor_work_center_id', workCenterId, {path: '/'})
    cookies.set('tk_shop_floor_work_center_group_id', workCenterGroupId, {path: '/'})
    return (workOrderId && workCenterId)
      ? `/work_order/shop_floor/${workOrderId}`
      : `/time_tracking`
  }

  return (
    <Button
      variant="custom"
      className="header-time-tracking-row"
      href={getButtonUrl()}
    >
      <span className="header-time-tracking-time-display">
        {getFormattedTimeElement()}
      </span>
      <small className="text-muted ml-3">
        {props.timeEntry.odoo_work_order_name} - {props.timeEntry.work_center_group_name}/{props.timeEntry.work_center_name}
      </small>
      <ChevronRight className="text-muted ml-auto"/>
    </Button>
  )
}
export default HeaderTimeTrackingRow