import { useHistory, useParams } from "react-router-dom"
import { Tab, Tabs } from "react-bootstrap"

const WorkOrderOverviewTabs = () => {
  const history = useHistory()
  const { tab } = useParams<{tab: any}>()

  const onTabChange = (newTab: string|null) => {
    if (newTab) {
      history.push(`/work_order_overview/${newTab}`)
    }
  }

  return (
    <Tabs
      activeKey={tab}
      onSelect={(newTab) => onTabChange(newTab)}
      variant="pills"
    >
      <Tab eventKey="queue" title="Queue" />
      <Tab eventKey="paint" title="Paint" />
      <Tab eventKey="shipping" title="Shipping" />
      <Tab eventKey="closed" title="Closed" />
    </Tabs>
  )
}

export default WorkOrderOverviewTabs