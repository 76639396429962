import axios, { AxiosPromise, ResponseType } from 'axios'
import Cookies from 'universal-cookie';
import { apiBaseUrl, useMockData } from '../common/config'
import globalState from '../common/context'
import createNotification from './notification'

axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
}

const client = axios.create({
  baseURL: apiBaseUrl,
  timeout: 30000,
})

/**
 * Intercept 503 errors to show when Odoo is down
 */
client.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  globalState.odooIsDown.set(false)
  return response
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  if (error?.response?.status === 503) {
    globalState.notifications.set([]) // Clear all other messages
    globalState.odooIsDown.set(true)
  } else {
    globalState.odooIsDown.set(false)
  }
  return Promise.reject(error)
})

const addPendingRequestName = (url: string) => {
  const pendingRequestNames = globalState.pendingRequestNames.get()
  if (!pendingRequestNames.includes(url)) {
    globalState.pendingRequestNames.set([...pendingRequestNames, url])
  }
}

const removePendingRequestName = (url: string) => {
  const pendingRequestNames = globalState.pendingRequestNames.get()
  if (pendingRequestNames.includes(url)) {
    globalState.pendingRequestNames.set(
      pendingRequestNames.filter((value: string) => value !== url)
    )
  }
}


interface requestParams {
  method: 'get' | 'post' | 'put' | 'delete';
  url: string;
  data?: any;
  params?: any;
  responseType?: ResponseType;
  mockData?: any;
  blockUi?: boolean;
  loadingMessage?: string;
  errorMessage?: string | null | boolean;
  errorDuration?: number | undefined;
  silenceError?: boolean;
  timeout?: number;
  loginMode?: boolean;
}

const apiRequest = async (
  { method, url, data, params, responseType, mockData, errorMessage, errorDuration = 5000, blockUi, loadingMessage, silenceError, timeout, loginMode }: requestParams,
): Promise<AxiosPromise<any> | any> => {
  if (blockUi) globalState.isLoading.set(true)
  if (loadingMessage) globalState.loadingMessage.set(loadingMessage)
  addPendingRequestName(url)

  if (useMockData) {
    if (blockUi) globalState.isLoading.set(false)
    if (loadingMessage) globalState.loadingMessage.set(null)
    if (mockData) {
      return Promise.resolve(mockData)
    } else {
      return Promise.resolve(true)
    }
  }

  const onSuccess = (response: any) => {
    if (blockUi) globalState.isLoading.set(false)
    if (loadingMessage) globalState.loadingMessage.set(null)
    return response.data
  }

  const onError = (error: any) => {
    if (blockUi) globalState.isLoading.set(false)
    if (loadingMessage) globalState.loadingMessage.set(null)
    const defaultErrorMessage = 'Could not complete the request. Please try again or contact technical support.'

    if (typeof errorMessage === "string" || errorMessage === null || errorMessage === undefined) {
      let errorDescription = errorMessage || defaultErrorMessage

      if (!errorMessage) {
        if (
          error.response
          && error.response.hasOwnProperty('data')
          && error.response.data.hasOwnProperty('detail')
        ) {
          if (typeof error.response.data.detail === 'string') {
            errorDescription = error.response.data.detail
          }
        }
      }

      if (!silenceError) createNotification('Error', errorDescription, 'error', errorDuration)
    }

    return Promise.reject(error.response || error.message)
  }

  let requestParams: requestParams = { method: method, url: url }
  if (data) requestParams['data'] = data
  if (params) requestParams['params'] = params
  if (responseType) requestParams['responseType'] = responseType
  if (timeout) requestParams['timeout'] = timeout

  // Only perform request if an auth token is present
  const cookies = new Cookies();
  const authCookie = cookies.get('tk_auth_token')
  if (authCookie) client.defaults.headers['Authorization'] = `Bearer ${authCookie}`

  if (authCookie || loginMode) {
    try {
      const response = await client(requestParams);
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    } finally {
      removePendingRequestName(url)
    }
  }
}

export default apiRequest
