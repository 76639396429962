export interface titration extends Record<string, any> {
    id: number;
    work_order_id: number;
    created_by: number;
	created_by_name: string;
    created_on: Date;
    updated_by?: number | null;
	updated_by_name?: string | null;
    updated_on?: Date | null;
    pre_cleaner_concentration?: number | null;
    pre_cleaner_temp?: number | null;
	rust_inhib_concentration?: number | null;
	rust_inhib_temp?: number | null;
	rust_inhib_ph?: number | null;
	phosphate_concentration?: number | null;
	phosphate_temp?: number | null;
	phosphate_ph?: number | null;
	rinse_tds?: number | null;
	rinse_temp?: number | null;
	is_active: true;
}

export const emptyTitration: titration = {
    "id": 0,
    "work_order_id": 0,
    "created_by": 0,
	"created_by_name": "",
    "created_on": new Date(),
    "updated_by": null,
	"updated_by_name": null,
    "updated_on": null,
    "pre_cleaner_concentration": null,
    "pre_cleaner_temp": null,
	"rust_inhib_concentration": null,
	"rust_inhib_temp": null,
	"rust_inhib_ph": null,
	"phosphate_concentration": null,
	"phosphate_temp": null,
	"phosphate_ph": null,
	"rinse_tds": null,
	"rinse_temp": null,
	"is_active": true,
}

// export const titrationMockData: titration[] = [
//     {
//         "id": 1,
//         "work_order_id": 1,
//         "created_by": 0,
//         "created_on": "",
//         "updated_by": 0,
//         "updated_on": "",
//         "pre_cleaner_concentration": 0,
//         "pre_cleaner_temp": 0,
//         "rust_inhib_concentration": 0,
//         "rust_inhib_temp": 0,
//         "rust_inhib_ph": 0,
//         "phosphate_concentration": 0,
//         "phosphate_temp": 0,
//         "phosphate_ph": 0,
//         "rinse_tds": 0,
//         "rinse_temp": 0,
//         "is_active": true,
//     }, {
//         "id": 0,
//         "work_order_id": 0,
//         "created_by": 0,
//         "created_on": "",
//         "updated_by": 0,
//         "updated_on": "",
//         "pre_cleaner_concentration": 0,
//         "pre_cleaner_temp": 0,
//         "rust_inhib_concentration": 0,
//         "rust_inhib_temp": 0,
//         "rust_inhib_ph": 0,
//         "phosphate_concentration": 0,
//         "phosphate_temp": 0,
//         "phosphate_ph": 0,
//         "rinse_tds": 0,
//         "rinse_temp": 0,
//         "is_active": true,
//     }, {
//         "id": 0,
//         "work_order_id": 0,
//         "created_by": 0,
//         "created_on": "",
//         "updated_by": 0,
//         "updated_on": "",
//         "pre_cleaner_concentration": 0,
//         "pre_cleaner_temp": 0,
//         "rust_inhib_concentration": 0,
//         "rust_inhib_temp": 0,
//         "rust_inhib_ph": 0,
//         "phosphate_concentration": 0,
//         "phosphate_temp": 0,
//         "phosphate_ph": 0,
//         "rinse_tds": 0,
//         "rinse_temp": 0,
//         "is_active": true,
//     },
// ]
