import { Demodal } from "demodal"
import { useEffect, useState } from "react"
import { useState as useGlobalState } from '@hookstate/core'
import globalState from '../../common/context'
import { Button, Card, Dropdown } from "react-bootstrap"
import { TagFill, ThreeDotsVertical } from "react-bootstrap-icons"
import { deleteReceivedPart, putReceivedPart } from "../../data/api-requests"
import { receivedPart } from "../../data/types/received-parts"
import useCheckPermission from "../../hooks/use-check-permission"
import { deleteFiles, uploadFiles } from "../../utils/attachments"
import { CustomToggle } from "../../utils/custom-toggle"
import createNotification from "../../utils/notification"
import AttachmentImage from "../shared/attachment-image"
import { assignWorkOrderFormFields, assignWorkOrderSchema, receivedPartFormFields, receivedPartFormSchema } from "../shared/form/form-received-part"
import ConfirmModal from "../shared/modal/modal-confirm"
import ItemModal from "../shared/modal/modal-item"
import AttachmentsModal from "../shared/modal/modal-view-attachments"
import { getRecievedPartName } from "../../utils/helpers"

interface WorkOrderReceivedPartProps {
  receivedPart: receivedPart;
  showWorkOrderNum?: boolean;
  showAssignButton?: boolean;
  showTagButton?: boolean;
  onEdit: Function;
  onDelete: Function;
  onAssignWorkOrder?: Function;
  onTagWorkOrder: Function;
  onUnassignWorkOrder?: Function;
  onUntagWorkOrder?: Function;
  readOnly?: boolean;
}

const WorkOrderReceivedPart = (props: WorkOrderReceivedPartProps) => {
  const globals = useGlobalState(globalState)
  const hasAdminPermission = useCheckPermission('WO Administrator')
  const hasReceivePermission = useCheckPermission('Receive Parts')
  const [menuItems, setMenuItems] = useState<JSX.Element[]>([])

  const onSelectAction = (action: string|null) => {
    switch (action) {
      case 'edit':
        onEdit()
        break;
      case 'delete':
        onDelete()
        break;
      case 'unassign':
        onUnassign()
        break;
      case 'untag':
        onUntag()
        break;
    }
  }

  const onEdit = async () => {
    const modalResult: any = await Demodal.open(ItemModal, {
      titleVerb: "Edit",
      saveLabel: "Save",
      itemName: getRecievedPartName(props.receivedPart.id),
      formData: receivedPartFormSchema.cast(props.receivedPart, {stripUnknown: true}),
      formFields: receivedPartFormFields,
      formSchema: receivedPartFormSchema,
    })

    if (modalResult) {
      const files = modalResult['new_attachments']
      const attachmentIdsToDelete = modalResult['deleted_attachments']
      if ('new_attachments' in modalResult) delete modalResult['new_attachments']
      if ('deleted_attachments' in modalResult) delete modalResult['deleted_attachments']
      if ('attachments' in modalResult) delete modalResult['attachments']
      if ('id' in modalResult) delete modalResult['id']

      let promises: Promise<any>[] = []
      promises.push(uploadFiles(files, undefined, props.receivedPart.id))
      promises.push(deleteFiles(attachmentIdsToDelete))
      promises.push(putReceivedPart(props.receivedPart.id, modalResult))

      Promise.all(promises).then(async (values) => {
        // There's a strange bug where the fetchData triggered by the following onEdit returns old data when uploading files.
        // Maybe the uploads take a while to be available in the filesystem.
        // For now, we'll add a bit of delay to prevent that.
        if (files && files.length > 0) {
          globals.isLoading.set(true)
          globals.loadingMessage.set('Processing Uploads...')
          await new Promise(resolve => setTimeout(resolve, 5000));
          globals.isLoading.set(false)
          globals.loadingMessage.set(null)
        }

        props.onEdit()
        createNotification('Edited', `Edited received part.`)
      })
    }
  }

  const onDelete = async () => {
    const modalResult = await Demodal.open(ConfirmModal, {
      title: `Delete ${getRecievedPartName(props.receivedPart.id)}?`,
      description: <>Please confirm deletion.</>,
    })

    if (modalResult) {
      deleteReceivedPart(props.receivedPart.id).then(() => {
        props.onDelete(props.receivedPart.id)
      })
    }
  }

  const onAssignWorkOrder = async () => {
    const modalResult: any = await Demodal.open(ItemModal, {
      titleVerb: "Assign",
      itemName: "Work Order",
      formData: props.receivedPart,
      formFields: assignWorkOrderFormFields,
      formSchema: assignWorkOrderSchema,
    })

    if (modalResult) {
      await putReceivedPart(props.receivedPart.id, modalResult)
      createNotification('Assigned', `Assigned work order to received part.`)

      if (props.onAssignWorkOrder) props.onAssignWorkOrder()
    }
  }

  const onUnassign = async () => {
    const modalResult = await Demodal.open(ConfirmModal, {
      title: "Unassign Received Part?",
      description: <>Please confirm removal.</>,
    })

    if (modalResult) {
      putReceivedPart(props.receivedPart.id, {
        'work_order_id': null,
      }).then(() => {
        createNotification('Unassigned', `The received part has been removed from this work order.`)
        if (props.onUnassignWorkOrder) props.onUnassignWorkOrder(props.receivedPart)
      })
    }
  }
  const onUntag = async () => {
    const modalResult = await Demodal.open(ConfirmModal, {
      title: "Untag Received Part?",
      description: <>Please confirm removal.</>,
    })

    if (modalResult) {
      putReceivedPart(props.receivedPart.id, {'is_tagged': false}).then(() => {
        createNotification('Untagged', `The received part has been untagged.`)
        if (props.onUntagWorkOrder) props.onUntagWorkOrder(props.receivedPart)
      })
    }
  }

  const onTagWorkOrder = () => {
    putReceivedPart(props.receivedPart.id, {'is_tagged': true}).then(() => {
      createNotification('Tagged', `Marked received part as tagged.`)
      props.onTagWorkOrder(props.receivedPart)
    })
  }

  const openAttachments = () => {
    Demodal.open(AttachmentsModal, {attachments: props.receivedPart.attachments})
  }

  // Set dropdown menu items
  useEffect(() => {
    let menuItemsArray = []

    // Edit
    if (
      hasAdminPermission
      || (hasReceivePermission && props.receivedPart.work_order_id === null)
    ) {
      menuItemsArray.push(<Dropdown.Item key="edit" eventKey="edit">Edit</Dropdown.Item>)
    }

    // Unassign
    if (hasAdminPermission && props.receivedPart.work_order_id) {
      menuItemsArray.push(<Dropdown.Item key="unassign" eventKey="unassign">Unassign</Dropdown.Item>)
    }

    // Untag
    if (hasReceivePermission && props.receivedPart.is_tagged) {
      menuItemsArray.push(<Dropdown.Item key="untag" eventKey="untag">Untag</Dropdown.Item>)
    }

    // Delete
    if (
      hasAdminPermission
      || (hasReceivePermission && props.receivedPart.work_order_id === null)
    ) {
      menuItemsArray.push(
        <Dropdown.Divider key="div" />,
        <Dropdown.Item key="delete" eventKey="delete" className="text-danger">Delete</Dropdown.Item>
      )
    }

    setMenuItems(menuItemsArray)
  }, [props.receivedPart, hasAdminPermission, hasReceivePermission])

  return (
    <Card className="work-order-received-part">
      <Card.Header className="d-flex align-items-center">
        {props.receivedPart.is_tagged && (
          <TagFill className="text-muted mr-3" />
        )}

        <strong className="received-part-title">{getRecievedPartName(props.receivedPart.id)}</strong>

        {props.showAssignButton && hasAdminPermission && (
          <Button
            variant="outline-primary"
            onClick={() => {onAssignWorkOrder()}}
            className="ml-3"
          >
            Assign WO
          </Button>
        )}

        {props.showTagButton && (
          <Button
            variant="outline-primary"
            onClick={() => {onTagWorkOrder()}}
            className="ml-3"
          >
            Tag WO
          </Button>
        )}

        {!props.readOnly && (
          <Dropdown
            alignRight={true}
            onSelect={(action) => onSelectAction(action)}
            className="ml-auto"
          >
            <Dropdown.Toggle as={CustomToggle} id={`received-part-actions-${props.receivedPart.id}`} customClasses="action-menu-button ml-2 px-1">
              <ThreeDotsVertical/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {menuItems.length > 0
                ? menuItems
                : <Dropdown.Item key="none" disabled={true}>No actions available.</Dropdown.Item>
              }
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Card.Header>
      <Card.Body>
        <div className="d-flex align-items-stretch">
          <div className="received-part-info">
            <p>
              {props.showWorkOrderNum && props.receivedPart.odoo_work_order_name && (
                <>
                  <strong className="text-muted received-part-info__label">WO #</strong>
                  {props.receivedPart.odoo_work_order_name}<br />
                </>
              )}
              <strong className="text-muted received-part-info__label">Name</strong>
              {props.receivedPart.customer_name ? props.receivedPart.customer_name : '–'}<br />
              <strong className="text-muted received-part-info__label">PO #</strong>
              {props.receivedPart.customer_po_number ? props.receivedPart.customer_po_number : '–'}
            </p>
            <p className="received-part-info__notes">{props.receivedPart.note}</p>
          </div>
          {props.receivedPart.attachments.length > 0 && (
            <Button onClick={() => {openAttachments()}} variant="custom" className="received-part-image-button ml-3 bg-light">
              <AttachmentImage attachmentId={props.receivedPart.attachments[0].id} />
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  )
}

export default WorkOrderReceivedPart
