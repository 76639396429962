import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Badge, Dropdown, Form, Nav } from "react-bootstrap";
import { getApplicablePaints } from '../../../data/api-requests';
import { applicablePaint } from '../../../data/types/applicable-paint';


interface ApplicablePaintDropdownProps {
  workOrderId: number;
  initialIdValue: number;
  formikSetFieldValue: Function;
  fieldName: string;
}

const ApplicablePaintDropdown = (props: ApplicablePaintDropdownProps) => {
  const [data, setData] = useState<applicablePaint[]>([])
  const [dataFiltered, setDataFiltered] = useState<any>([])
  const [filterValue, setFilterValue] = useState<string>('')
  const [currentValue, setCurrentValue] = useState<applicablePaint>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null);

  const fetchData = async () => {
    const response = await getApplicablePaints(props.workOrderId)
    if (response) setData(response)
  }

  useLayoutEffect(() => {
    if (isOpen) {
      inputRef.current!.focus()
    }
  }, [isOpen])

  // Load data on init
  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const initialValue = data.find((paint: applicablePaint) => paint.id === props.initialIdValue)
    if (initialValue) setCurrentValue(initialValue)
    setDataFiltered(data)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() =>  {
    const filtered = data.filter((paint: applicablePaint) => {
      if (
        paint.product_name.toLowerCase().includes(filterValue.toLowerCase())
        || paint.default_code.toLowerCase().includes(filterValue.toLowerCase())
        || paint.vendor_name.toLowerCase().includes(filterValue.toLowerCase())
      ) {
        return true
      }
      return false
    })
    setDataFiltered([...filtered])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue])

  const onDropdownToggle = (isOpen:boolean) => {
    setIsOpen(isOpen)
  }

  const onFilterInputChange = (event: any) => {
    const inputValue = event.target.value
    setFilterValue(inputValue)
  }

  const onSelectItem = (value: string | null) => {
    if (typeof value === 'string') {
      const newId = Number(value)
      const matchingRow = data.find((paint: applicablePaint) => paint.id === newId)
      if (matchingRow) {
        setCurrentValue(matchingRow)
        props.formikSetFieldValue(props.fieldName, matchingRow.id)
      }
      setFilterValue('')
    }
  }

  const getNoun = () => (dataFiltered.length === 1) ? 'result' : 'results'

  return (
    <Dropdown onToggle={onDropdownToggle}>
      <Dropdown.Toggle variant="primary" className="applicable-paints-dropdown-toggle" id="applicable-paints-menu">
        {currentValue ? (
          <>[{currentValue.default_code}] {currentValue.product_name}</>
        ) : 'Select...'}
      </Dropdown.Toggle>

      <Dropdown.Menu className="tk-dropdown-field applicable-paints-dropdown">
        <div className="">
          <Form.Control
            ref={inputRef}
            type="text"
            value={filterValue}
            onChange={onFilterInputChange}
            placeholder="Filter..."
          />
          <div className="tk-dropdown-field__status-bar px-3">
            <strong>{dataFiltered.length}</strong>{" "}{getNoun()} found.
          </div>
          <Nav
            className="flex-column tk-dropdown-field__menu-items"
            onSelect={onSelectItem}
          >
            {dataFiltered.map((paint: applicablePaint) => (
              <Nav.Link key={paint.id} eventKey={paint.id}>
                <div className="tk-dropdown-field-title text-truncate">[{paint.default_code}] {paint.product_name}</div>
                <div className="tk-dropdown-field__meta">
                  <div><Badge variant="info">{paint.category}</Badge></div>
                  <div className="ml-auto">Vendor: {paint.vendor_name}</div>
                </div>
              </Nav.Link>
            ))}
          </Nav>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ApplicablePaintDropdown