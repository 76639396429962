import * as Yup from 'yup';
import { formField } from '../modal/modal-item';
import { formFieldTypes } from '../../../data/types/form-field-type';

// Form Fields
export const userFormFields: formField[][] = [
    [
        {
            name: 'display_name',
            label: 'Display Name',
            type: formFieldTypes.text,
            readonly: true,
        },
        {
            name: 'full_name',
            label: 'Full Name',
            type: formFieldTypes.text,
            readonly: true,
        },
        {
            name: 'is_active',
            label: 'Active',
            type: formFieldTypes.switch,
            readonly: true,
        },
        {
            name: 'info',
            label: '',
            type: formFieldTypes.info,
        },
    ],
    [
        {
            name: 'user_rules',
            label: 'Permissions',
            type: formFieldTypes.rule,
        },
    ]
]

// Validation Rules
export const userFormSchema = Yup.object().shape({
    display_name: Yup.string().required('Required'),
    full_name: Yup.string().required('Required'),
    user_rules: Yup.array()
    .required('Required'),
})

export const putUserFormSchema = Yup.object().shape({
    display_name: Yup.string(),
    full_name: Yup.string(),
    user_rules: Yup.array(),
})
