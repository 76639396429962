import { BaseSyntheticEvent, useState } from "react";
import { Form } from "react-bootstrap";

interface SwitchFieldProps {
  formikSetFieldValue: Function;
  fieldName: string;
  fieldLabel: string;
  initialValue: boolean;
  readonly?: boolean;
}

const SwitchField = (props: SwitchFieldProps) => {
  const [checked, setChecked] = useState<boolean>(props.initialValue);

  const onChange = (event: BaseSyntheticEvent) => {
    setChecked(!checked)
    props.formikSetFieldValue(props.fieldName, !checked)
  }

  return (
    <Form.Check
      type="switch"
      id={props.fieldName}
      name={props.fieldName}
      label={props.fieldLabel}
      onChange={onChange}
      checked={checked}
      disabled={props.readonly}
  />
  )
}

export default SwitchField
