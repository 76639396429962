import _ from "lodash";
import { Demodal } from "demodal"
import { workCenterIds } from "../../../common/config"
import { attendance, newNonBillableTimeEntry, newTimeEntry, putTimeEntryType, timeEntriesGroupedByDate, timeEntriesGroupedByWorkCenter, timeEntry } from "../../../data/types/time-tracking"
import { getDateStringFromDate, getDurationFromDates, roundDateToNearestMinute } from "../../../utils/helpers"
import { nonBillableTimeTrackingFormFieldsPost, nonBillableTimeTrackingFormFieldsPut, postNonBillableWithLocationSchema, postNonBillableWithWorkOrderSchema, postTimeEntrySchema, putNonBillableWithLocationSchema, putNonBillableWithWorkOrderSchema, putTimeEntrySchema, timeTrackingFormFieldsPost, timeTrackingFormFieldsPut } from "../form/form-time-tracking"
import ConfirmModal from "../modal/modal-confirm"
import ItemModal, { formField } from "../modal/modal-item"
import { prepTasks } from "../../../data/types/prep-task";
import { timeTrackingType } from "../../../data/types/time-tracking-type";
import { differenceInSeconds } from "date-fns";
import { workCenter } from "../../../data/types/work-order";

/**
 * Show add modal
 * @return Promise with resulting time entry data
 */
interface onAddTimeEntryParams {
  hasAdminPermission: boolean;
  workCenterId: number;
  currentUserId: number;
  title?: string;
  isBillable?: boolean;
  timeTrackingType?: timeTrackingType;
}
export const onAddTimeEntry = ({
  hasAdminPermission,
  workCenterId,
  currentUserId,
  title = 'Time Entry',
  isBillable = true,
  timeTrackingType,
}: onAddTimeEntryParams) => {
  let formFields = timeTrackingFormFieldsPost
  let formSchema = postTimeEntrySchema

  // Non-billable fields
  if (isBillable === false) {
    formFields = nonBillableTimeTrackingFormFieldsPost

    // Remove Work Order field if not required
    if (timeTrackingType && !timeTrackingType.require_work_order_ref) {
      formFields = formFields.map((fieldGroup: formField[]) => (
        fieldGroup.filter((value: formField) => value.name !== 'work_order_id')
      ))
    }
    // Remove Location field if not required
    if (timeTrackingType && !timeTrackingType.require_work_center_group_ref) {
      formFields = formFields.map((fieldGroup: formField[]) => (
        fieldGroup.filter((value: formField) => value.name !== 'work_center_group_id')
      ))
    }

    // Set required field schemas
    if (timeTrackingType && timeTrackingType.require_work_order_ref) {
      formSchema = postNonBillableWithWorkOrderSchema
    }
    if (timeTrackingType && timeTrackingType.require_work_center_group_ref) {
      formSchema = postNonBillableWithLocationSchema
    }
  }

  // Remove user_id form field if user doesn't have admin permission
  if (!hasAdminPermission) {
    formFields = formFields.map((fieldGroup: formField[]) => (
      fieldGroup.filter((value: formField) => value.name !== 'user_id')
    ))
  }

  // Remove active_prep_task_types form field if work center is not "prep"
  if (workCenterId !== workCenterIds.prep) {
    formFields = formFields.map((fieldGroup: formField[]) => (
      fieldGroup.filter((value: formField) => value.name !== 'active_prep_task_types')
    ))
  }

  const newTimeEntryData = isBillable ? newTimeEntry : newNonBillableTimeEntry

  const modalResult: Promise<timeEntry> = Demodal.open(ItemModal, {
    itemName: title,
    formData: {
      ...newTimeEntryData,
      'user_id': currentUserId,
      'start_time': roundDateToNearestMinute(new Date()),
      'end_time': undefined,
      'is_billable': isBillable,
    },
    formFields: formFields,
    formSchema: formSchema,
  })

  return modalResult
}

/**
 * Validate data & show edit modal
 * @return Promise with resulting time entry data or 'delete' string if deleting record
 */
 interface onEditTimeEntryParams {
  timeEntry: timeEntry,
  title?: string;
  timeTrackingType?: timeTrackingType;
}
export const onEditTimeEntry = async ({
  timeEntry,
  title = "Time Entry",
  timeTrackingType,
}: onEditTimeEntryParams): Promise<false | putTimeEntryType | "delete"> => {
  let formFields = timeTrackingFormFieldsPut
  let formSchema = putTimeEntrySchema

  // Change fields & schema for non-billable entries
  if (!timeEntry.is_billable) {
    formFields = nonBillableTimeTrackingFormFieldsPut

    // Remove Work Order field if not required
    if (timeTrackingType && !timeTrackingType.require_work_order_ref) {
      formFields = formFields.map((fieldGroup: formField[]) => (
        fieldGroup.filter((value: formField) => value.name !== 'work_order_id')
      ))
    }
    // Remove Location field if not required
    if (timeTrackingType && !timeTrackingType.require_work_center_group_ref) {
      formFields = formFields.map((fieldGroup: formField[]) => (
        fieldGroup.filter((value: formField) => value.name !== 'work_center_group_id')
      ))
    }

    // Set required field schemas
    if (timeTrackingType && timeTrackingType.require_work_order_ref) {
      formSchema = putNonBillableWithWorkOrderSchema
    }
    if (timeTrackingType && timeTrackingType.require_work_center_group_ref) {
      formSchema = putNonBillableWithLocationSchema
    }
  }

  // Remove active_prep_task_types form field if work center is not "prep"
  if (timeEntry.work_center_id !== workCenterIds.prep) {
    formFields = formFields.map((fieldGroup: formField[]) => (
      fieldGroup.filter((value: formField) => value.name !== 'active_prep_task_types')
    ))
  }

  const modalResult: 'delete' | timeEntry = await Demodal.open(ItemModal, {
    itemName: title,
    formData: timeEntry,
    // formData: formSchema.cast(timeEntry, {stripUnknown: true}),
    formFields: formFields,
    formSchema: formSchema,
    canDelete: true,
  })

  return modalResult
}

/**
 * Confirm deletion modal
 * @returns Promise with boolean true on user confirmation
 */
export const onDeleteTimeEntry = () => {
  const modalResult: Promise<boolean> = Demodal.open(ConfirmModal, {
    title: "Delete Time Entry?",
    description: <>Please confirm deletion.</>,
  })

  return modalResult
}

export const groupTimeEntriesByDate = (timeEntries: timeEntry[], attendances: attendance[] = []): timeEntriesGroupedByDate[] => {
  let result: timeEntriesGroupedByDate[] = []

  /*
  This creates an Object with the date as the key and an Array of timeEntries, like this:
  {'2022-07-01': [...]}
   */
  const groupedByDate = _(timeEntries)
    .groupBy((x: timeEntry) => getDateStringFromDate(x.start_time))
    .value()


  let groupedAttendances: any = {}
  if (attendances.length > 0) {
    groupedAttendances = _(attendances)
      .groupBy((x: attendance) => x.check_in ? x.check_in.split('T')[0] : undefined)
      .value()
  }

  /*
  We need to convert it to an array so it's sortable, in this format:
  [
    {
      'date': '2022-07-01',
      'rows': [...],
    }, ...
  ]
  */

  Object.keys(groupedByDate).forEach((key: string) => {
    // Sort by start time
    groupedByDate[key].sort((a: timeEntry, b: timeEntry) => (
      a.start_time > b.start_time ? -1 : 1
    ))
    // Total all minutes
    const totalMinutes = groupedByDate[key].reduce((prev: number, current: timeEntry) => (
      prev + getDurationFromDates(current.start_time, current.end_time)
    ), 0)

    let totalAttendanceMinutes = 0

    if (key in groupedAttendances) {
      totalAttendanceMinutes = groupedAttendances[key].reduce((prev: number, current: attendance) => {
        const checkIn = current.check_in ? new Date(current.check_in) : undefined
        const checkOut = current.check_out ? new Date(current.check_out) : undefined
        if (checkIn) checkIn.setMinutes(Math.ceil(checkIn.getMinutes() / 15) * 15)
        if (checkOut) checkOut.setMinutes(Math.floor(checkOut.getMinutes() / 15) * 15)
        return prev + getDurationFromDates(checkIn, checkOut)
      }, 0)
    }

    result.push({
      'date': key,
      'total_minutes': totalMinutes,
      'total_attendance_minutes': totalAttendanceMinutes,
      'rows': groupedByDate[key]
    })
  })

  // Sort by date, descending
  result.sort((a: timeEntriesGroupedByDate, b: timeEntriesGroupedByDate) => (
    a.date > b.date ? -1 : 1
  ))

  return result
}

export const groupTimeEntriesByWorkCenter = (timeEntries: timeEntry[], workCenters: workCenter[]): timeEntriesGroupedByWorkCenter[] => {
  let result: timeEntriesGroupedByWorkCenter[] = []

    /*
  This creates an Object with the date as the key and an Array of timeEntries, like this:
  {'Blast': [...]}
   */
  const groupedByWorkCenter = _(timeEntries)
    .groupBy((x: timeEntry) => x.work_center_id)
    .value()

  /*
  We need to convert it to an array so it's sortable, in this format:
  [
    {
      'work_center_name': 'Blast',
      'rows_grouped_by_date': [...],
    }, ...
  ]
  */

  Object.keys(groupedByWorkCenter).forEach((workCenterId: string) => {
    // Sort by start time
    groupedByWorkCenter[workCenterId].sort((a: timeEntry, b: timeEntry) => (
      a.start_time > b.start_time ? -1 : 1
    ))
    // Total all minutes
    const totalMinutes = groupedByWorkCenter[workCenterId].reduce((prev: number, current: timeEntry) => (
      prev + getDurationFromDates(current.start_time, current.end_time)
    ), 0)

    const matchingWorkCenter = workCenters.find((wc: workCenter) => wc.id === Number(workCenterId))
    const workCenterName = matchingWorkCenter ? matchingWorkCenter.work_center_name : '-'

    result.push({
      'work_center_id': Number(workCenterId),
      'work_center_name': workCenterName,
      'total_minutes': totalMinutes,
      'rows_grouped_by_date': groupTimeEntriesByDate(groupedByWorkCenter[workCenterId])
    })
  })

  // Sort work centers by sequence, ascending
  result.sort((a: timeEntriesGroupedByWorkCenter, b: timeEntriesGroupedByWorkCenter) => {
    const workCenterA = workCenters.find((wc: workCenter) => wc.id === a.work_center_id)
    const workCenterB = workCenters.find((wc: workCenter) => wc.id === b.work_center_id)
    if (workCenterA && workCenterB && workCenterA.sequence < workCenterB.sequence) {
      return -1
    } else {
      return 1
    }
  })

  return result
}

export const validateTimeEntryPrepTasks = (timeEntryUserId: number, workCenterId: number, prepTasks: prepTasks[]) => {
  // Skip validation if the work center is not "Prep"
  if (workCenterId !== workCenterIds.prep) {
    return true
  }

  // Check if the specified user has an empty prep task row
  if (prepTasks && prepTasks.find((value: prepTasks) =>
    value.user_id === timeEntryUserId && value.active_prep_task_types.length === 0
  )) {
    Demodal.open(ConfirmModal, {
      title: "Must Complete Task",
      description: <>There is an empty task row related to this user. You must complete at least one of these tasks before creating another time entry.</>,
      confirmLabel: "OK",
      showCancel: false,
    })
    return false
  }

  return true
}

/**
 * Confirm entries beyond 1 hour with the user
 * @returns Promise with boolean true on user confirmation
 */
export const confirmDuration = async (startTime: Date | number, endTime: Date | number, cancelLabel: string = 'Cancel') => {
  const diffMinutes = differenceInSeconds(endTime, startTime) / 60;

  if (diffMinutes > 60) {
    // 'Confirm Duration'
    const modalResult = await Demodal.open(ConfirmModal, {
      title: 'Confirm Duration',
      description: 'Time entry is beyond 1 hour. Please verify this is correct.',
      cancelLabel: cancelLabel,
    })

    if (!modalResult) return false
  }

  return true
}