import { useState as useGlobalState, State, none } from '@hookstate/core'
import globalState from '../../common/context'
import { notification } from '../../data/types/notification'
import Toast from 'react-bootstrap/Toast'
import { ExclamationTriangleFill, InfoCircleFill } from 'react-bootstrap-icons'
import classNames from 'classnames'
import { randomkey } from '../../utils/helpers'

/**
 * Displays toast-style notifications
 *
 * To add one, call createNotification('title', 'desc')
 * (you must also import createNotification from /utils)
 */
const Notifications = () => {
  const globals = useGlobalState(globalState)

  const removeNotification = (removeIndex: any) => {
    globals.notifications[removeIndex].set(none)
  }

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      className="toasts-container"
    >
      {globals.notifications.map((notification: State<notification>, index) =>
        <Toast
          key={randomkey(10)}
          onClose={() => { removeNotification(index) }}
          delay={ notification.duration.get() }
          autohide={true}
          className="w-100"
        >
          <Toast.Header className={classNames({
            'text-danger' : notification.type.get() === 'error',
            'text-info' : notification.type.get() === 'info',
            'error' : notification.type.get() === 'error'
          })
          }>
            {notification.type.get() === 'error' && (
              <ExclamationTriangleFill className="d-inline-block align-middle mr-2" />
            )}
            {notification.type.get() === 'info' && (
              <InfoCircleFill className="d-inline-block align-middle mr-2" />
            )}
            <strong className="mr-auto d-inline-block align-middle">{ notification.title.get() }</strong>
          </Toast.Header>
          <Toast.Body>{ notification.description.get() }</Toast.Body>
        </Toast>
      )}
    </div>
  )
}

export default Notifications