import { Demodal } from "demodal";
import { BaseSyntheticEvent, useEffect } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { deletePaintUsage, getApplicablePaints, getPaintUsages, postPaintUsage, putPaintUsage } from "../../data/api-requests";
import { applicablePaint } from "../../data/types/applicable-paint";
import { newPaintUsage, paintUsage } from "../../data/types/paint-usage";
import createNotification from "../../utils/notification";
import { paintUsageFormFields, paintUsageFormSchema } from "../shared/form/form-paint-usage";
import ConfirmModal from "../shared/modal/modal-confirm";
import ItemModal from "../shared/modal/modal-item";
import useGetData from "../../hooks/use-get-data";
import { ExclamationTriangleFill, Plus } from "react-bootstrap-icons";

interface WorkOrderPaintUsageProps {
  workOrderId: number;
  workOrderCompleted: boolean;
  readOnly?: boolean;
}

const WorkOrderPaintUsage = (props: WorkOrderPaintUsageProps) => {
  const fetchPaintUsagesData = async () => {
    const result = await getPaintUsages(props.workOrderId)
    if (result) return result
  }

  const fetchApplicablePaintsData = async () => {
    const result = await getApplicablePaints(props.workOrderId)
    if (result) return result
  }

  const [paintUsages, refreshPaintUsages] = useGetData<paintUsage>({
    autoLoad: false,
    fetchDataFn: fetchPaintUsagesData,
  })

  const [applicablePaints, refreshApplicablePaints] = useGetData<applicablePaint>({
    autoLoad: false,
    fetchDataFn: fetchApplicablePaintsData,
  })

  // Load on work order ID change
  useEffect(() => {
    if (props.workOrderId) {
      refreshApplicablePaints()
      refreshPaintUsages()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workOrderId])

  const onDeleteRow = async (id: number) => {
    const modalResult = await Demodal.open(ConfirmModal, {
      title: "Delete Paint Usage?",
      description: <>Please confirm deletion.</>,
    })

    if (modalResult) {
      await deletePaintUsage(id)
      createNotification('Deleted', `Deleted paint usage entry.`)
      refreshPaintUsages()
    }
  }

  const productFormatter = (cell: any, row: paintUsage) => {
    const paint = applicablePaints.find((paint: applicablePaint) => paint.id === row.applicable_paint_id)

    let template = <>-</>

    if (paint && paint.product_name === 'N/A') {
      template =
        <OverlayTrigger delay={{ show: 500, hide: 0 }} overlay={<Tooltip id="invalid-paint-tooltip">The applicable paint could not be found in Odoo. Please edit this row and assign a new paint product.</Tooltip>}>
          <span>
            <ExclamationTriangleFill className="d-inline-block align-middle mr-2 text-danger"/>
            {paint.product_name}
          </span>
        </OverlayTrigger>
    } else if (paint) {
      template = <>{paint.product_name} {paint.default_code && (<small>[{paint.default_code}]</small>)}</>
    }

    return template
  }

  const rowEvents = {
    onClick: async (e: BaseSyntheticEvent, row: paintUsage, rowIndex: number) => {
      if (props.readOnly) return false

      const modalResult: any = await Demodal.open(ItemModal, {
        itemName: "Paint Usage",
        formData: row,
        formFields: paintUsageFormFields,
        formSchema: paintUsageFormSchema,
        canDelete: true,
      })

      if (modalResult) {
        if (modalResult === 'delete') {
          onDeleteRow(row.id)
        } else {
          const editId = modalResult['id']
          if ('id' in modalResult) delete modalResult['id']
          if ('work_order_id' in modalResult) delete modalResult['work_order_id']
          if ('created_by' in modalResult) delete modalResult['created_by']
          if ('odoo_product_name' in modalResult) delete modalResult['odoo_product_name']
          if ('odoo_product_product_id' in modalResult) delete modalResult['odoo_product_product_id']
          await putPaintUsage(editId, modalResult)
          createNotification('Usage Submitted', `Edited paint usage entry.`)
          refreshPaintUsages()
        }
      }
    }
  }

  const onAddItemClick = async (event: BaseSyntheticEvent) => {
    const modalResult: any = await Demodal.open(ItemModal, {
      itemName: "Paint Usage",
      formData: {...newPaintUsage, 'work_order_id': props.workOrderId},
      formFields: paintUsageFormFields,
      formSchema: paintUsageFormSchema,
    })

    if (modalResult) {
      if ('id' in modalResult) delete modalResult['id']
      if ('work_order_id' in modalResult) delete modalResult['work_order_id']
      await postPaintUsage(props.workOrderId, modalResult)
      createNotification('Usage Submitted', `Added paint usage entry.`)
      refreshPaintUsages()
    }
  }

  const tableColumns = [
    {
      dataField: 'applicable_paint',
      text: 'Product',
      formatter: productFormatter,
    },
    {
      dataField: 'batch',
      text: 'Batch',
    },
    {
      dataField: 'start_weight_in_kg',
      text: 'Start (kg)',
    },
    {
      dataField: 'end_weight_in_kg',
      text: 'End (kg)',
    },
  ]

  return (
    <div className="work-order-section work-order-paint-usage">
      <header className="work-order-section-header work-order-paint-usage-header">
        <h2>Paint Usage</h2>
        {!props.readOnly && (<Button onClick={onAddItemClick} disabled={props.workOrderCompleted}><Plus/></Button>)}
      </header>

      {(
        paintUsages.length === 0
        || (paintUsages.length > 0 && applicablePaints.length > 0)
      ) && (
        <div className="mt-3">
          <BootstrapTable
            keyField="id"
            data={ paintUsages }
            columns={ tableColumns }
            defaultSorted={[{dataField: 'id', order: 'asc'}]}
            hover
            rowEvents={ rowEvents }
            bootstrap4
          />
        </div>
      )}
    </div>
  )
}

export default WorkOrderPaintUsage