export interface delivery {
  id: number;
  work_order_id: number;
  odoo_id: number;
  odoo_work_order_id: number;
  name: string;
  date: Date | null;
  note: string;
  state: 'draft' | 'waiting' | 'assigned' | 'done' | 'cancel';
  backorder: boolean;
}

export interface packingSlipLines {
  note: string | null;
  products: packingSlipProduct[];
}

export interface packingSlipProduct {
  id: number;
  stock_move_id: number | null;
  stock_move_line_id: number | null;
  product_id: number | null;
  name: string;
  default_code: string | null;
  serial_number: string | null;
  uom_name: string | null;
  qty: number;
  qty_total: number;
  is_active?: boolean;
}

export const emptyDelivery: delivery = {
  id: 0,
  work_order_id: 0,
  odoo_id: 0,
  odoo_work_order_id: 0,
  name: '',
  date: new Date(),
  note: '',
  state: 'draft',
  backorder: false,
}

export const emptypackingSlipProduct: packingSlipProduct = {
  id: 0,
  stock_move_id: null,
  stock_move_line_id: null,
  product_id: null,
  name: '',
  default_code: null,
  serial_number: null,
  uom_name: null,
  qty: 0,
  qty_total: 0,
}

export const deliveriesMockData: delivery[] = [
  {
    id: 0,
    work_order_id: 0,
    odoo_id: 1,
    odoo_work_order_id: 1,
    name: '425',
    date: new Date('2022-07-15 10:15:03.000000'),
    note: '',
    state: 'assigned',
    backorder: false,
  },
  {
    id: 0,
    work_order_id: 0,
    odoo_id: 2,
    odoo_work_order_id: 1,
    name: '426',
    date: new Date('2022-07-18 15:38:12.000000'),
    note: '',
    state: 'done',
    backorder: true,
  },
]

export const packingSlipProductMockData: packingSlipProduct[] = [
  {
    id: 0,
    stock_move_id: 1,
    stock_move_line_id: null,
    product_id: 1,
    name: 'Product 1',
    default_code: 'TK0000001',
    serial_number: null,
    uom_name: 'kg',
    qty: 0,
    qty_total: 1,
  },
  {
    id: 0,
    stock_move_id: 1,
    stock_move_line_id: null,
    product_id: 2,
    name: 'Product 2',
    default_code: 'TK0000002',
    serial_number: null,
    uom_name: 'kg',
    qty: 0,
    qty_total: 1,
  }
]
