import { Demodal, useModal } from "demodal"

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

interface modalConfirmProps {
  title: string;
  description: string;
  confirmLabel: string;
  cancelLabel: string;
  showCancel?: boolean;
}

const ConfirmModal = Demodal.create(({
  title = 'Confirmation',
  description = '',
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  showCancel = undefined
}: modalConfirmProps) => {
  const modal = useModal();

  const resolve = (value: boolean) => () => {
    modal.resolve(value)
    modal.close()
    modal.remove()
  }

  return (
    <>
      <Modal
        show={modal.isOpen}
        onHide={resolve(false)}
        className="confirm-modal"
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{description}</div>
        </Modal.Body>
        <Modal.Footer>
          {showCancel !== false && (
            <Button className="mr-auto" variant="secondary" onClick={resolve(false)}>
              {cancelLabel}
            </Button>
          )}
          <Button variant="primary" onClick={resolve(true)}>{confirmLabel}</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
})

export default ConfirmModal