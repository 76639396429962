import { useCallback, useEffect, useState } from "react";
import { Alert } from 'react-bootstrap';
import { FileRejection, useDropzone } from 'react-dropzone'
import { workOrderAttachment } from "../../../data/types/work-order";
import AttachmentsFieldListEdit from "./field-attachments-list-edit";
import AttachmentsFieldListNew from "./field-attachments-list-new";


interface AttachmentsFieldProps {
  receivedPartId: number; // Pass this in to the API upload request
  formikSetFieldValue: Function;
  fieldName: string;
  initialValue: workOrderAttachment[];
}

const AttachmentsField = (props: AttachmentsFieldProps) => {
  const [files, setFiles] = useState<any[]>([])
  const [existingAttachments, setExistingAttachments] = useState<workOrderAttachment[]>([])

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const newFiles = acceptedFiles.map((file: any) => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }))

    setFiles([...files, ...newFiles])
    props.formikSetFieldValue('new_attachments', [...files, ...newFiles])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    onDrop,
    accept: {'image/*': []},
    maxSize: 1024 * 1024 * 20, // 20 MB (in bytes)
    maxFiles: 30
  })

  useEffect(() => {
    if (props.initialValue) {
      setExistingAttachments(props.initialValue)
    }
  }, [props.initialValue])

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
            <p>Drag 'n' drop some files here, or click to select files</p>
        }
      </div>
      {fileRejections.length > 0 && (
        <Alert variant="warning" className="mt-3">
          <strong>The following files could not be uploaded due to incorrect file types. Accepted file types are: .jpg, .jpeg, .png, .gif, .pdf.</strong>
          <ul>
            {fileRejections.map((value: FileRejection) => (
              <li>{value.file.name}</li>
            ))}
          </ul>
        </Alert>
      )}

      <AttachmentsFieldListNew files={files} onFilesChange={setFiles} formikSetFieldValue={props.formikSetFieldValue}/>
      <AttachmentsFieldListEdit attachments={existingAttachments} formikSetFieldValue={props.formikSetFieldValue}/>
    </>
  )
}

export default AttachmentsField
