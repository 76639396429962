import { useEffect, useState } from "react"
import { FileEarmarkX, FileImage } from "react-bootstrap-icons"
import { JsxElement } from "typescript"
import { getAttachment } from "../../data/api-requests"

interface AttachmentImageProps {
  attachmentId: number
}

// TODO: implement useCallback
// TODO: solve memory leak issue

const AttachmentImage = (props: AttachmentImageProps) => {
  const [imageData, setImageData] = useState<Blob | MediaSource>()
  const [objectUrl, setObjectUrl] = useState<string>('')
  const [hasError, setHasError] = useState<boolean>(false)

  const getImage = () => {
    getAttachment(props.attachmentId).then((result: any) => {
      setImageData(result)
    }).catch((error: any) => {
      setHasError(true)
    })
  }

  useEffect(() => {
    if (imageData) setObjectUrl(URL.createObjectURL(imageData))

    return () => {
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      if (objectUrl) URL.revokeObjectURL(objectUrl)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageData])

  useEffect(() => {
    if (props.attachmentId) {
      if (objectUrl) URL.revokeObjectURL(objectUrl)
      getImage()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.attachmentId])

  const getPlaceholder = () => (
    hasError
      ? <FileEarmarkX className="attachment-icon text-danger" size={48} />
      : <FileImage className="attachment-icon text-info" size={48} />
  )

  return (
    <>
      {objectUrl ? (
        <img
          src={objectUrl}
          className="tk-thumbnail attachment-image"
          alt=""
        />
      ) : (
        getPlaceholder()
      )}
    </>
  )
}

export default AttachmentImage
