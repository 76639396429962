import { useEffect, useState } from 'react';
import LocationDropdown from './location-dropdown';
import { dropdownData } from '../../data/types/general';
import { workCenter, workCenterGroup } from '../../data/types/work-order';

interface LocationDropdownComboProps {
  initialWorkCenterGroupId: number;
  initialWorkCenterId: number;
  onWorkCenterGroupChange: Function;
  onWorkCenterChange: Function;
  workCenters: workCenter[];
  workCenterGroups: workCenterGroup[];
}

const LocationDropdownCombo = (props: LocationDropdownComboProps) => {
  const [currentWorkCenterId, setCurrentWorkCenterId] = useState<number>(0)
  const [currentWorkCenterGroupId, setCurrentWorkCenterGroupId] = useState<number>(0)

  const onWorkCenterGroupChange = (workCenterGroupId: number) => {
    setCurrentWorkCenterGroupId(workCenterGroupId)
    props.onWorkCenterGroupChange(workCenterGroupId)
  }

  const onWorkCenterChange = (workCenterId: number) => {
    setCurrentWorkCenterId(workCenterId)
    props.onWorkCenterChange(workCenterId)
  }

  const convertToDropdownData = (data: any[], nameKey: string = 'name'): dropdownData[] => {
    if (!data || data.length === 0) return []

    return data
      .sort((a: any, b: any) => a.sequence - b.sequence )
      .map((value: any) => ({
        'id': value.id,
        'name': value[nameKey],
      }))
  }

  // Set initial values for current IDs
  useEffect(() => {
    if (props.workCenterGroups && props.workCenterGroups.length > 0) {
      setCurrentWorkCenterGroupId(props.initialWorkCenterGroupId)
    }

    if (props.workCenters && props.workCenters.length > 0) {
      setCurrentWorkCenterId(props.initialWorkCenterId)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.workCenterGroups,
    props.workCenters,
    props.initialWorkCenterGroupId,
    props.initialWorkCenterId,
  ])

  return (
    <div className='location-dropdown-group'>
      <LocationDropdown
        currentValue={currentWorkCenterGroupId}
        data={convertToDropdownData(
          props.workCenterGroups.filter((wcg: workCenterGroup) => wcg.has_work_centers), 'work_center_group_name'
        )}
        callback={onWorkCenterGroupChange}
      />
      <LocationDropdown
        currentValue={currentWorkCenterId}
        data={convertToDropdownData(props.workCenters, 'work_center_name')}
        callback={onWorkCenterChange}
      />
    </div>
  )
}

export default LocationDropdownCombo