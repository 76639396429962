export interface rule {
    id: number;
    rule_name: string;
    description: string | null;
}

export const rulesMockData: rule[] = [
    {
        "id": 3,
        "rule_name": "WO Administrator",
        "description": "Create/update/delete work order detail page plus all time tracking entries"
    },{
        "id": 5,
        "rule_name": "Manage WO Locations",
        "description": "Assign a location to a work order"
    },{
        "id": 7,
        "rule_name": "Receive Parts",
        "description": null
    },{
        "id": 8,
        "rule_name": "User Administrator",
        "description": "Create/read/update/delete users including their permissions"
    },{
        "id": 6,
        "rule_name": "Review WO",
        "description": "Read/update work order review page, including notes, attachments, and applicable paints"
    }
]
