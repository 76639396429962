import classNames from "classnames";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Box, Icon123, InfoCircle, TagsFill, Trash } from "react-bootstrap-icons";
import { packingSlipProduct } from "../../../data/types/deliveries"
import createNotification from "../../../utils/notification";

interface deliveryItemsLineProps {
    product: packingSlipProduct;
    products: packingSlipProduct[];
    changedProducts: packingSlipProduct[];
    readOnly: boolean;
    setChangedProducts: Function;
    setProducts: Function;
    setHasUnsavedChanges: Function;
}

const DeliveryItemsLine = (props: deliveryItemsLineProps) => {
    const [isNewRow, setIsNewRow] = useState<boolean>(false)

    useEffect(() => {
        setIsNewRow(props.product && props.product.id < 0)
    }, [props.product])

    const handleQtyInputChange = (event: BaseSyntheticEvent, line: packingSlipProduct, field: 'qty' | 'qty_total') => {
        let formValue = parseInt(event.target.value, 10)
        let originalValue = parseInt(event.target.getAttribute('data-value'), 10)
        let qty = formValue

        originalValue = qty ? qty : 0
        event.target.value.replace(/([^0-9]+)/gi, '')

        line[field] = originalValue

        updateLineItem(line, field)
    }

    const handleNameChange = (event: BaseSyntheticEvent, line: packingSlipProduct) => {
        const formValue = event.target.value
        line.name = formValue
        updateLineItem(line, 'name')
    }

    const handleQtyClick = (event: BaseSyntheticEvent) => {
        event.target.select()
    }

    const handleQtyButtonClick = (line: packingSlipProduct) => {
        // Make the current qty equal to the total quantity
        line.qty = line ? line.qty_total : 0
        updateLineItem(line, 'qty')
    }

    const updateLineItem = (line: packingSlipProduct, field: 'qty' | 'qty_total' | 'name' | 'is_active') => {
        // Validate qty input
        if (field === 'qty' || field === 'qty_total') {
            // Must be no greater than total quantity
            // OR the completed MO quantity, whichever is less
            if (!isNewRow && line[field] > line.qty_total) {
                const uom_name = line.uom_name ?? 'Units'
                const warningMessage = `The maximum quantity is ${line.qty_total} ${uom_name}/s.`
                createNotification('Quantity Error', warningMessage, 'error')
                line[field] = line.qty_total
            }

            // Must be positive
            if (line[field] < 0) line[field] = 0
        }

        // Update changedProducts
        const changedLineIndex = props.changedProducts.findIndex((changedLine: packingSlipProduct) => {
            return (
                (changedLine.stock_move_id && (changedLine.stock_move_id === line.stock_move_id))
                || (changedLine.id && (changedLine.id === line.id))
            ) ? true : false
        })
        changedLineIndex === -1
            ? props.changedProducts.push(line)
            : props.changedProducts[changedLineIndex] = line

        // Apply changes
        // ------------------------------------------
        props.setChangedProducts([...props.changedProducts])
        props.setProducts([...props.products])
        props.setHasUnsavedChanges(true)
    }

    /**
     * Remove a manually added line
     */
    const removeLineItem = (line: packingSlipProduct) => {
        if (line.id < 0) {
            // New lines (they don't have a database ID yet)
            const filteredChangedProducts = props.changedProducts.filter((product: packingSlipProduct) => product.id !== line.id)
            const filteredProducts = props.products.filter((product: packingSlipProduct) => product.id !== line.id)
            props.setChangedProducts([...filteredChangedProducts])
            props.setProducts([...filteredProducts])
        } else {
            // Existing lines
            line.is_active = false
            updateLineItem(line, 'is_active')
        }
    }

    return (
        <>
        <div className={classNames({
            "delivery-order-line": true,
            "justify-content-between": true,
            "d-flex": props.product && props.product.is_active !== false,
            "alert-info": isNewRow,
            "d-none": props.product && props.product.is_active === false
        })}>
            <div className="mr-2 flex-grow-1">
                <div className="delivery-order-line-item d-flex align-items-center">
                    <TagsFill className="mr-2 text-muted" />
                    {!isNewRow ? (
                        <>
                            {props.product.default_code && props.product.default_code !== '-' && ( <>[{props.product.default_code}] </> )}{props.product.name}
                        </>
                    ) : (
                        <>
                            <Form.Control
                                type="text"
                                data-line-id={props.product.id}
                                value={props.product.name}
                                onChange={(event) => {handleNameChange(event, props.product)}}
                                onClick={handleQtyClick}
                                className="order-line-name mr-2"
                                disabled={props.readOnly}
                                data-value={props.product.name}
                                placeholder="Product Name"
                            />
                        </>
                    )}
                </div>
                {!isNewRow && (
                    <>
                    {props.product.serial_number && (
                        <div className="delivery-order-line-item">
                            <Icon123 className="mr-2 text-muted"/>
                            <span>
                                <code>
                                    {props.product.serial_number}
                                </code>
                            </span>
                        </div>
                    )}
                        <div className="delivery-order-line-item">
                            <Box className="mr-2 text-muted" />
                            <span>
                                <code>
                                    {props.product.qty}/{props.product.qty_total}
                                </code> {props.product.uom_name}
                            </span>
                        </div>
                    </>
                )}
                {(props.product.id > 0) && (
                    <div className="text-info">
                        <small>
                            <InfoCircle className="d-inline-block align-middle mr-2"/>
                            <span className="d-inline-block align-middle">Manually added.</span>
                        </small>
                    </div>
                )}
            </div>
            <div id="packing-slip-product-form" className="">
                <Form>
                    <InputGroup>
                        <Form.Control
                            type="text"
                            value={props.product.qty}
                            onChange={(event) => {handleQtyInputChange(event, props.product, 'qty')}}
                            onClick={handleQtyClick}
                            className="order-line-qty"
                            disabled={props.readOnly}
                            data-value={props.product.qty}
                        />
                        {isNewRow && (
                            <div className="d-flex align-items-center">
                                <div className="mx-2">/</div>
                                <Form.Control
                                    type="text"
                                    value={props.product.qty_total}
                                    onChange={(event) => {handleQtyInputChange(event, props.product, 'qty_total')}}
                                    onClick={handleQtyClick}
                                    className="order-line-qty-total"
                                    disabled={props.readOnly}
                                    data-value={props.product.qty_total}
                                />
                            </div>
                        )}
                        {!isNewRow && (
                            <InputGroup.Append>
                                <Button
                                    className="order-line-qty-button"
                                    data-line-id={props.product.stock_move_id ?? props.product.id}
                                    onClick={() => {handleQtyButtonClick(props.product)}}
                                    disabled={props.readOnly || ((props.product.qty_total - props.product.qty) <= 0 )}
                                >
                                    +{ (props.product.qty < props.product.qty_total) ?
                                        props.product.qty_total - props.product.qty
                                        : ''
                                    }
                                </Button>
                            </InputGroup.Append>
                        )}
                    </InputGroup>
                    <Form.Text className="order-line-subtext">{props.product.uom_name ?? 'Units'}</Form.Text>
                </Form>
            </div>
            {(props.product.id !== null && !props.readOnly) && (
                <div className="ml-3">
                    <Button variant="danger" onClick={() => {removeLineItem(props.product)}}><Trash/></Button>
                </div>
            )}
        </div>
        </>
    )
}

export default DeliveryItemsLine