import { useState as useGlobalState } from '@hookstate/core'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {globalDataCache, globalRefreshTriggers} from '../common/context'
import { paintProductsMockData } from '../data/types/paint-product'
import { prepTaskTypesMockData } from '../data/types/prep-task-type'
import { timeTrackingTypesMockData } from '../data/types/time-tracking-type'
import { rulesMockData } from '../data/types/rule'
import { workCenterGroupMockData, workCenterMockData, workOrderMockData } from "../data/types/work-order"
import apiRequest from "../utils/api"

interface useGetDataOptions {
  autoLoad?: boolean;
  sortByField?: string;
  interfaceName?: string;
  fetchDataFn?: Function;
  initialValue?: any;
}

const useGetData = <T>(
  options: useGetDataOptions = {
    autoLoad: true,
    sortByField: 'id',
    interfaceName: '',
  },
): [T[], (() => void), Dispatch<SetStateAction<T[]>>, boolean] => {
  const refreshTriggers = useGlobalState(globalRefreshTriggers)
  const dataCache = useGlobalState(globalDataCache)
  const triggerGlobalRefresh = refreshTriggers.all.get()
  const [triggerRefresh, setTriggerRefresh] = useState<number>(0)
  const initialValue = options.initialValue ? options.initialValue : []
  const [data, setData] = useState<Array<T>>(initialValue)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getDataFromApi = async (interfaceName: string) => {
    let url: string = ''
    let mockData: Array<any> = []
    let blockUi: boolean = true

    switch (interfaceName) {
      case 'workOrder':
        url = 'work_order/?stage=open'
        mockData = workOrderMockData
        blockUi = false
        break;
      case 'allWorkOrders':
        url = 'work_order'
        mockData = workOrderMockData
        break;
      case 'workCenterGroup':
        url = 'static/work_center_group'
        mockData = workCenterGroupMockData
        break;
      case 'workCenter':
        url = 'static/work_center'
        mockData = workCenterMockData
        break;
      case 'user':
        url = 'admin/users'
        mockData = workCenterMockData
        break;
      case 'rule':
        url ='admin/rules'
        mockData = rulesMockData
        break;
      case 'paintProduct':
        url = 'work_order/applicable_paint/paint_products'
        mockData = paintProductsMockData
        break;
      case 'prepTaskType':
        url = 'static/prep_task_type'
        mockData = prepTaskTypesMockData
        break;
      case 'timeTrackingType':
        url = 'static/time_tracking_type'
        mockData = timeTrackingTypesMockData
        break;

      default:
        console.warn('No interface name provided to getDataFromApi. Returning empty array.')
        Promise.resolve([])
        break;
    }

    return await apiRequest(
      {
        method: 'get',
        url: url,
        mockData: mockData,
        blockUi: blockUi,
      }
    ).then((result: Array<any>) => {
      // Sort by specified field, ascending
      result.sort((a: any, b: any) => {
        if (options.sortByField) {
          return a[options.sortByField] < b[options.sortByField] ? -1 : 1
        } else {
          return 0
        }
      })

      dataCache[interfaceName].set(result)
      return result
    })
    .catch((error) => {
      console.error('request error', error)
    })
  }

  const getData = async (noCache: boolean = false) => {
    // first check cache
    if (noCache === false && options.interfaceName) {
      const cacheData = dataCache[options.interfaceName].get()
      if (cacheData) {
        setData(cacheData)
        return true
      }
    }

    setIsLoading(true)

    // fetch data if not yet available
    if (!options.fetchDataFn && options.interfaceName) {
      // by interfaceName
      const result = await getDataFromApi(options.interfaceName)
      if (result) setData(result)
    } else if (options.fetchDataFn) {
      // by fetchDataFn
      const result = await options.fetchDataFn()
      if (result) setData(result)
    }

    setIsLoading(false)
  }

  // Calback function to refresh data
  const refresh = () => {
    setTriggerRefresh(Math.floor(Math.random() * 1000000))
  }

  useEffect(() => {
    if (typeof options.autoLoad === 'undefined' || options.autoLoad === true) {
      getData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Watch for refresh event
  useEffect(() => {
    if (triggerGlobalRefresh > 0 || triggerRefresh > 0) getData(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerGlobalRefresh, triggerRefresh])

  return [data, refresh, setData, isLoading]
}

export default useGetData