import Cookies from 'universal-cookie';
import { useEffect, useState } from 'react';
import { useState as useGlobalState } from '@hookstate/core'
import globalState from '../../common/context'
import { workCenter, workCenterGroup } from '../../data/types/work-order';
import LocationDropdownCombo from './location-dropdown-combo';

interface LocationDropdownComboNavProps {
  workCenters: workCenter[];
  workCenterGroups: workCenterGroup[];
}

const LocationDropdownComboNav = (props: LocationDropdownComboNavProps) => {
  const cookies = new Cookies()
  const globals = useGlobalState(globalState)
  const [initialWorkCenterId, setInitialWorkCenterId] = useState<number>(0)
  const [initialWorkCenterGroupId, setInitialWorkCenterGroupId] = useState<number>(0)

  const onWorkCenterGroupChange = (workCenterGroupId: number) => {
    cookies.set('tk_shop_floor_work_center_group_id', workCenterGroupId, {path: '/'})
    globals.shopFloorWorkCenterGroupId.set(workCenterGroupId)
  }

  const onWorkCenterChange = (workCenterId: number) => {
    cookies.set('tk_shop_floor_work_center_id', workCenterId, {path: '/'})
    globals.shopFloorWorkCenterId.set(workCenterId)
  }

  // Set initial values for current IDs
  // Either pick the default for this workstation
  // a previously selected item if no defaults are set,
  // or the first item in the list.
  useEffect(() => {
    const lastWorkCenterId = cookies.get('tk_shop_floor_work_center_id')
    const lastWorkCenterGroupId = cookies.get('tk_shop_floor_work_center_group_id')
    let newWorkCenterGroupId = Number(lastWorkCenterGroupId)

    if (props.workCenterGroups && props.workCenterGroups.length > 0) {
      newWorkCenterGroupId = props.workCenterGroups[0].id

      if (lastWorkCenterGroupId) {
        newWorkCenterGroupId = Number(lastWorkCenterGroupId)
      }

      globals.shopFloorWorkCenterGroupId.set(newWorkCenterGroupId)
      setInitialWorkCenterGroupId(newWorkCenterGroupId)
    }

    if (
      (props.workCenters && props.workCenters.length > 0)
      && (props.workCenterGroups && props.workCenterGroups.length > 0)
    ) {
      let newWorkCenterId = props.workCenters[0].id

      if (
        lastWorkCenterId
        && props.workCenterGroups
            .filter((wcg: workCenterGroup) => wcg.id === newWorkCenterGroupId)
            .find((wcg: workCenterGroup) =>
              wcg.work_centers
                .flatMap((wc: workCenter) => wc.id)
                .includes(Number(lastWorkCenterId))
            )
      ) {
        newWorkCenterId = Number(lastWorkCenterId)
      }
      globals.shopFloorWorkCenterId.set(newWorkCenterId)
      setInitialWorkCenterId(newWorkCenterId)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workCenterGroups, props.workCenters])

  return (
    <LocationDropdownCombo
      initialWorkCenterGroupId={initialWorkCenterGroupId}
      initialWorkCenterId={initialWorkCenterId}
      onWorkCenterChange={onWorkCenterChange}
      onWorkCenterGroupChange={onWorkCenterGroupChange}
      workCenters={props.workCenters}
      workCenterGroups={props.workCenterGroups}
    />
  )
}

export default LocationDropdownComboNav