import { isEqual, uniq } from "lodash";
import { format, parse, differenceInSeconds, parseJSON } from 'date-fns'
import { globalRefreshTriggers } from '../common/context'
import { instruction } from '../data/types/instruction'

export const getRandomNumber = () => Math.floor(Math.random() * 1000000)

/**
 * Update the triggerRefresh global variable with a random number.
 * Components may watch this global variable and refresh their data when it changes.
 */
export const triggerGlobalRefresh = () => {
  globalRefreshTriggers.all.set(getRandomNumber())
}
export const triggerShopFloorTabsRefresh = () => {
  globalRefreshTriggers.shopFloorTabs.set(getRandomNumber())
}
export const triggerReceivedPartsCountsRefresh = () => {
  globalRefreshTriggers.receivedPartsCounts.set(getRandomNumber())
}
export const triggerRunningTimersRefresh = () => {
  globalRefreshTriggers.runningTimers.set(getRandomNumber())
}
export const triggerPartsRefresh = () => {
  globalRefreshTriggers.parts.set(getRandomNumber())
}

/**
 * Append to a url, adding a slash between the two if needed
 * @param base_url
 * @param addition
 * @returns string
 */
export const appendToUrl = (base_url: string, addition: string) => {
  let url: string = ''

  if (base_url !== '' && addition !== '') {
    // Remove trailing slash from base_url, and starting slash from addition
    if (base_url.charAt(base_url.length - 1) === '/') {
      base_url = base_url.slice(0, -1)
    }
    if (addition.charAt(0) === '/') {
      addition = addition.slice(1)
    }

    url = `${base_url}/${addition}`
  }

  return url
}

export const filterTableRowsByString = (rows: any[], filter: string) => {
  return rows.filter((row: any) => {
    let foundMatch = false
    Object.values(row).forEach((value: any) => {
      if (String(value).toLowerCase().includes(filter.toLowerCase())) foundMatch = true
    })
    return foundMatch
  })
}

export const getTextColor = (backgroundColor: string) => {
  let color = 'black'

  if (backgroundColor) {
    let red = 0
    let green = 0
    let blue = 0
    let brightness = 0

    var rgb = backgroundColor.substring(backgroundColor.indexOf('(') + 1, backgroundColor.lastIndexOf(')')).split(/,\s*/) // Calculate the brightness of the element
    red = parseInt(rgb[0], 0)
    green = parseInt(rgb[1], 0)
    blue = parseInt(rgb[2], 0)
    brightness = Math.sqrt((.241 * (red * red)) + (.671 * (green * green)) + (.068 * (blue * blue)));

    if (brightness > 128) { //Set the text colour based on the brightness
      color = 'black'
    } else {
      color = 'white'
    }
  } else {
     color = 'black'
  }

  return color
}

/**
 * @returns YYYY-MM-DD formatted string with today's date
 */
export const getToday = () => {
  return new Date().toLocaleDateString('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
}

/**
 * @param timestamp ISO 8601 timestamp, example: 2022-06-27T14:57:20
 * @returns HH:MM formatted string in local timezone
 */
export const getLocalTimeStringFromDate = (date: Date | undefined) => {
  return date ? format(date, 'h:mm a') : date
}

/**
 * @param timestamp ISO 8601 timestamp, example: 2022-06-27T14:57:20
 * @returns Date | undefined
 */
export const getDateFromTimestamp = (timestamp: string | undefined) => {
  return timestamp ? parseJSON(timestamp) : undefined
}

/**
 * @param date Date object
 * @returns YYYY-MM-DD formatted string in local timezone
 */
 export const getDateStringFromDate = (date: Date | undefined, outputFormat: string = 'yyyy-MM-dd') => {
  return date ? format(date, outputFormat) : date
}

/**
 * @param date Date object
 * @returns HH:MM formatted string in local timezone
 */
 export const getTimeStringFromDate = (date: Date | undefined) => {
  return date ? format(date, 'h:mm a') : date
}

/**
 * @param date1 Date
 * @param date2 Date
 * @returns HH:MM formatted string
 */
export const getTimeDurationStringFromDates = (date1: Date | undefined, date2: Date | undefined) => {
  if (!date1 || !date2) return '-'
  return getDurationStringFromDates(date1, date2)
}

/**
* calculates the duration between two dates, returning a hh:mm formatted string
* @param start Date
* @param finish Date
* @return a string of the duration with format 'hh:mm'
*/
export const getDurationStringFromDates = (start: Date, finish: Date): string => {
  const diffTime = differenceInSeconds(finish, start);
  if (!diffTime) return '00:00'; // divide by 0 protection
  const minutes = Math.abs(Math.floor(diffTime / 60) % 60).toString();
  const hours = Math.abs(Math.floor(diffTime / 60 / 60)).toString();
  return `${hours.length < 2 ? 0 + hours : hours}:${minutes.length < 2 ? 0 + minutes : minutes}`;
};

/**
* calculates the duration between two dates, returning the number of minutes
* @param start Date
* @param finish Date
* @return the number of minutes between the dates
*/
export const getDurationFromDates = (start: Date | undefined, finish: Date | undefined) => {
  if (!start || !finish) return 0

  const diffTime = differenceInSeconds(finish, start);
  if (!diffTime) return 0; // divide by 0 protection
  const minutes = Math.abs(diffTime / 60);

  return minutes
};

/**
 * Convert a string containing hours and minutes to a Date object
 * @param time examples: 5 PM, 5 pm, 5:15 pm, 05:15 pm, 5p, 515p, 515 pm
 * @returns Date object
 */
export const transformTimeStringToDate = (time: string) => {
  const format1 = parse(time, 'h:mm a', new Date())
  const format2 = parse(time, 'h a', new Date())
  const format3 = parse(time, 'h:mma', new Date())
  const format4 = parse(time, 'ha', new Date())
  const format5 = parse(time, 'hmma', new Date())
  const format6 = parse(time, 'hmm a', new Date())
  const format7 = parse(time, 'H', new Date()) // 24 hr
  const format8 = parse(time, 'Hmm', new Date()) // 24 hr
  let parsedDate

  if (format1.getTime()) {
    parsedDate = format1
  } else if (format2.getTime()) {
    parsedDate = format2
  } else if (format3.getTime()) {
    parsedDate = format3
  } else if (format4.getTime()) {
    parsedDate = format4
  } else if (format5.getTime()) {
    parsedDate = format5
  } else if (format6.getTime()) {
    parsedDate = format6
  } else if (format7.getTime()) {
    parsedDate = format7
  } else if (format8.getTime()) {
    parsedDate = format8
  } else {
    // Invalid format
    return false
  }

  return parsedDate
}

/**
 * Sanitize a string containing hours and minutes
 * @param time examples: 5 PM, 5 pm, 5:15 pm, 05:15 pm, 5p, 515p, 515 pm
 * @returns string in HH:MM AM/PM format
 */
export const sanitizeTimeString = (time: string) => {
  const parsedDate = transformTimeStringToDate(time)
  return parsedDate ? format(parsedDate, 'h:mm a') : ''
}

export const roundDateToNearestMinute = (date = new Date()) => {
  const ms = 1000 * 60
  return new Date(Math.round(date.getTime() / ms) * ms)
}

export const convertArrayToObject = (array: Array<any>, key: string, instructions: instruction[]) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    const instruction = instructions.find((instruction) => instruction.work_center_id === item.id)
    const instructionText = instruction ? instruction.instruction_text : ''
    return {
      ...obj,
      [item[key]]: {lastValue: instructionText, currentValue: instructionText},
    };
  }, initialValue);
};

export const getRecievedPartName = (id: number = 0) => {
  return `RP${String(id).padStart(5, '0')}`
}

export const randomkey = (length: number) => {
  for (var s=''; s.length < length; s += 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.charAt(Math.random()*62|0));
  return s;
}

/**
 * Compare two objects, returing any key/value pairs that are different
 * @param oldData
 * @param newData
 * @returns
 */
export const getChangedData = (oldData: any, newData: any) => {
  const data = uniq([...Object.keys(oldData), ...Object.keys(newData)])
  const changedData: any = {}
  for(const key of data){
    if(!isEqual(oldData[key], newData[key]) && newData[key] !== undefined){
      changedData[key] = newData[key]
    }
  }

  return changedData
}

/**
 * Converts multi-level Object/s timestamp strings into JS Date Objects
 * @param data Object or Array of Objects
 * @returns Object or Array
 */
export const convertTimestampsToDates = (data: any) => {
  const isArray = Array.isArray(data)

  if (! isArray) {
    data = convertObjectTimestampsToDates(data)
  } else {
    data.forEach((element: any) => {
      element = convertObjectTimestampsToDates(element)
    })
  }

  return data
}

/**
 * Converts timestamp strings within an Object into JS Date Objects
 * @returns mutated Object
 */
export const convertObjectTimestampsToDates = (data: any) => {
  for (const key in data) {
    const value = data[key];
    if (Array.isArray(value)) {
      value.forEach((element: any) => {
        element = convertObjectTimestampsToDates(element)
      })
    } else if (String(value).includes('+00:00') || String(value).includes(':00.000000')) {
      data[key] = new Date(value)
    }
  }

  return data
}

// Yup Helpers
// ============================================================================================================

/**
 * Transform function for Yup, returns a Date object given a UTC timestamp
 */
 export const yupTransformDate = (value: any, originalValue: any) => {
  return getDateFromTimestamp(originalValue)
}

/**
 * @param value
 * @param ref the Yup.object.shape
 * @returns
 */
export const yupTestDateIsAfter = (value: Date | undefined, ref: any) => {
  if (ref.parent.start_time && ref.parent.end_time) {
    return ref.parent.start_time.getTime() < ref.parent.end_time.getTime()
  } else {
    return true
  }
}

/**
 * @param value
 * @param ref the Yup.object.shape
 * @returns
 */
export const yupTestDateIsPast = (value: Date | undefined, ref: any) => {
  if (ref.parent.start_time && ref.parent.end_time) {
    const now = new Date().getTime()
    return ref.parent.end_time.getTime() < now
  } else {
    return true
  }
}

/**
 * @param value
 * @param ref the Yup.object.shape
 * @returns
 */
export const yupTestEndTimeRequired = (value: Date | undefined, ref: any) => {
  if (!ref.parent.end_time) {
    return false
  } else {
    return true
  }
}

/**
 * @param value
 * @param ref the Yup.object.shape
 * @returns
 */
export const yupTestWorkCenterRequired = (value: number | undefined, ref: any) => {
  if (!ref.parent.work_center_id) {
    return false
  } else {
    return true
  }
}
