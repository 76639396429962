export interface paintCure {
  id: number;
  work_order_id: number;
  temperature: number;
  time_in_minutes: number;
  user_name: string;
  created_by: number;
  created_on: Date;
}

export const emptypaintCure: paintCure = {
  id: 0,
  work_order_id: 0,
  temperature: 0,
  time_in_minutes: 0,
  user_name: '',
  created_by: 0,
  created_on: new Date(),
}

export const paintCuresMockData: paintCure[] = [
  {
    id: 1,
    work_order_id: 1,
    temperature: 425,
    time_in_minutes: 25,
    user_name: 'Joe Smith',
    created_on: new Date('2022-06-22 13:10:23.000000'),
    created_by: 1,
  },
]
