import { createState } from '@hookstate/core'
import { confirmDialogueContent, emptyConfirmDialogue } from '../data/types/confirm-dialogue'
import { notification } from '../data/types/notification'
import { prepTasks } from '../data/types/prep-task';

// General Settings
// ----------------------------------------------------------------------
interface context {
  userRules: Array<String>;
  isLoading: boolean;
  odooIsDown: boolean;
  loadingMessage: string|null;
  pendingRequestNames: string[];
  errorMessage: string;
  triggerSync: boolean;
  notifications: Array<notification>;
  currentTab: string;
  confirmDialogueContent: confirmDialogueContent;
  shopFloorWorkCenterGroupId: number;
  shopFloorWorkCenterId: number;
  prepTasks: prepTasks[];
  paintProductIds: number[];
}

const contextDefaults: context = {
  userRules: [],
  isLoading: false,
  odooIsDown: false,
  loadingMessage: null,
  pendingRequestNames: [],
  errorMessage: '',
  triggerSync: false,
  notifications: [],
  currentTab: '',
  confirmDialogueContent: emptyConfirmDialogue,
  shopFloorWorkCenterGroupId: 0,
  shopFloorWorkCenterId: 0,
  prepTasks: [],
  paintProductIds: [],
}

export const globalState = createState(contextDefaults)

// Refresh triggers
// ----------------------------------------------------------------------
interface refreshTriggers {
  all: number;
  parts: number;
  shopFloorTabs: number;
  receivedPartsCounts: number;
  runningTimers: number;
}

const refreshTriggersDefaults: refreshTriggers = {
  all: 0,
  parts: 0,
  shopFloorTabs: 0,
  receivedPartsCounts: 0,
  runningTimers: 0,
}

export const globalRefreshTriggers = createState(refreshTriggersDefaults)

// Cached data
// ----------------------------------------------------------------------
const dataCacheDefaults: Record<string, any> = {}
export const globalDataCache = createState(dataCacheDefaults)

export default globalState
