import React, { BaseSyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useState as useGlobalState } from '@hookstate/core'
import globalState from '../../common/context'
import { Alert, Button } from 'react-bootstrap';
import { FileError, FileRejection, useDropzone } from 'react-dropzone'
import { uploadFiles } from '../../utils/attachments';
import { triggerGlobalRefresh } from '../../utils/helpers';
import createNotification from '../../utils/notification';
import AttachmentsFieldListNew from '../shared/field/field-attachments-list-new';
import { ExclamationTriangleFill, InfoCircle } from 'react-bootstrap-icons';

interface WorkOrderUploadAttachmentsProps {
  workOrderId: number;
}

const WorkOrderUploadAttachments = (props: WorkOrderUploadAttachmentsProps) => {
  const globals = useGlobalState(globalState)
  const [files, setFiles] = useState<any[]>([])
  const [rejections, setRejections] = useState<FileRejection[]>([])
  const acceptedTypes = ['application/pdf', 'image/jpeg', 'image/gif', 'image/png']

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const invalidFiles = acceptedFiles
      .filter((file: File) => acceptedTypes.includes(file.type) === false)
      .map((file: File) => ({
        file: file,
        errors: [{message: 'Invalid type', code: "file-invalid-type"}]
      }))

    const newFiles = acceptedFiles
      .filter((file: File) => acceptedTypes.includes(file.type))
      .map((file: any) => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))

    setRejections([...invalidFiles, ...fileRejections])
    setFiles([...files, ...newFiles])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    onDrop,
    // Disabled file filters -- Chrome doesn't support it properly
    // accept: {
    //   'application/pdf': ['.pdf'],
    //   'image/*': ['.jpg', '.png', '.gif', '.jpeg'],
    // },
    maxSize: 1024 * 1024 * 20, // 20 MB (in bytes)
    maxFiles: 30
  })

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = async (event: BaseSyntheticEvent) => {
    globals.isLoading.set(true)
    globals.loadingMessage.set('Processing Uploads...')

    const result = await uploadFiles(files, props.workOrderId, undefined)

    if (result) {
      files.forEach((file: any) => URL.revokeObjectURL(file.preview))
      setFiles([])

      // Give the uploads some extra time to be ready
      await new Promise(resolve => setTimeout(resolve, 5000));

      createNotification('Submitted', `Attachments uploaded.`)
      triggerGlobalRefresh()
    }

    globals.isLoading.set(false)
    globals.loadingMessage.set(null)
  }

  return (
    <div className="work-order-section work-order-attachments">
      <h2>Upload Attachments</h2>

      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
            <p>Drag 'n' drop some files here, or click to select files</p>
        }
      </div>
      {rejections.length > 0 && (
        <Alert variant="warning" className="mt-3">
          <h3><ExclamationTriangleFill /> The following files could not be uploaded:</h3>
          <hr/>
          <p><small>Accepted file types are: <strong>.jpg, .jpeg, .png, .gif, .pdf</strong>. Maximum size is <strong>20 MB</strong></small>.</p>
          <hr/>
          <ul>
            {rejections.map((value: FileRejection) => (
              <li key={value.file.name}>
                {value.file.name}<br/>
                <small>{value.errors.map((error: FileError) => (
                  <>{error.message}</>
                ))}</small>
              </li>
            ))}
          </ul>
        </Alert>
      )}

      <AttachmentsFieldListNew files={files} onFilesChange={setFiles} />

      {(files.length > 0) && (
        <Button className="mt-3" onClick={onSave}>Save</Button>
      )}
    </div>
  )
}

export default WorkOrderUploadAttachments