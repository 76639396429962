import { useEffect, useState } from "react";
import { workCenter, workCenterGroup } from "../../../data/types/work-order";
import LocationDropdownCombo from "../location-dropdown-combo";
import useGetStaticData from "../../../hooks/use-get-static-data";


interface LocationDropdownComboFieldProps {
  initialWorkCenterGroupIdValue: number;
  initialWorkCenterIdValue: number;
  formikSetFieldValue: Function;
}

const LocationDropdownComboField = (props: LocationDropdownComboFieldProps) => {
  const staticResources = useGetStaticData()
  const [workCenterGroups, setWorkCenterGroups] = useState<workCenterGroup[]>([])
  const [workCenters, setWorkCenters] = useState<workCenter[]>([])
  const [workCenterGroupsWithPlaceholder, setWorkCenterGroupsWithPlaceholder] = useState<workCenterGroup[]>([])
  const [workCentersWithPlaceholder, setWorkCentersWithPlaceholder] = useState<workCenter[]>([])
  const [currentWorkCenterGroupId, setCurrentWorkCenterGroupId] = useState<number>(0)

  // Set WCG when available
  useEffect(() => {
    if (staticResources && staticResources.work_center_groups) {
      setWorkCenterGroups(staticResources.work_center_groups)
    }
  }, [staticResources])

  // Set WC when current WCG changes
  useEffect(() => {
    if (workCenterGroups) {
      const relatedWorkCenters = workCenterGroups.find((wcg: workCenterGroup) => wcg.id === currentWorkCenterGroupId)
      if (relatedWorkCenters) setWorkCenters(relatedWorkCenters.work_centers)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkCenterGroupId])

  // Add "Select..." option to work center groups and work centers
  useEffect(() => {
    if (workCenterGroups && workCenterGroups.length > 0) {
      // The work center is no longer valid when the group changes
      props.formikSetFieldValue('work_center_id', null)

      setWorkCenterGroupsWithPlaceholder([
        {
          id: 0,
          work_center_group_name: 'Select...',
          has_work_centers: false,
          has_paint_booth: false,
          paint_booth_name: '',
          sequence: 0,
          work_centers: [],
        },
        ...workCenterGroups
      ])
    }

    if (workCenters && workCenters.length > 0) {
      setWorkCentersWithPlaceholder([
        {
          id: 0,
          work_center_name: 'Select...',
          sequence: 0,
        },
        ...workCenters
      ])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workCenterGroups, workCenters])

  const onWorkCenterGroupChange = (workCenterGroupId: number) => {
    props.formikSetFieldValue('work_center_group_id', workCenterGroupId)
    setCurrentWorkCenterGroupId(workCenterGroupId)
  }

  const onWorkCenterChange = (workCenterId: number) => {
    props.formikSetFieldValue('work_center_id', workCenterId)
  }

  return (
    <LocationDropdownCombo
      initialWorkCenterGroupId={props.initialWorkCenterGroupIdValue}
      initialWorkCenterId={props.initialWorkCenterIdValue}
      onWorkCenterChange={onWorkCenterChange}
      onWorkCenterGroupChange={onWorkCenterGroupChange}
      workCenters={workCentersWithPlaceholder}
      workCenterGroups={workCenterGroupsWithPlaceholder}
    />
  )
}

export default LocationDropdownComboField
