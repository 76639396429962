import { Demodal } from "demodal";
import { BaseSyntheticEvent } from "react";
import { Button } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import BootstrapTable from "react-bootstrap-table-next";
import { deletePositector, getPositector, postPositector, putPositector } from "../../data/api-requests";
import { emptyPositectorTest, positectorTest } from "../../data/types/positector";
import useGetData from "../../hooks/use-get-data";
import createNotification from "../../utils/notification";
import { positectorFormFields, positectorFormSchema } from "../shared/form/form-positector";
import ConfirmModal from "../shared/modal/modal-confirm";
import ItemModal from "../shared/modal/modal-item";

interface WorkOrderPositectorProps {
  workOrderId: number;
  readOnly?: boolean;
}

const WorkOrderPositector = (props: WorkOrderPositectorProps) => {
  const fetchData = async () => {
    if (props.workOrderId > 0) {
      const result = await getPositector(props.workOrderId)
      if (result) return result
    }
  }

  const [positectorTests, refresh] = useGetData<positectorTest>({fetchDataFn: fetchData})

  const onAddItemClick = async () => {
    const modalResult: any = await Demodal.open(ItemModal, {
      itemName: 'Positector',
      formData: positectorFormSchema.cast(emptyPositectorTest, {stripUnknown: true}),
      formFields: positectorFormFields,
      formSchema: positectorFormSchema,
    })

    if (modalResult) {
      await postPositector(props.workOrderId, modalResult)
      createNotification('Positector Data Submitted', 'Added positector entry.')
      refresh()
    }
  }

  const onDeleteItemClick = async (positectorId: number) => {
    const modalResult: any = await Demodal.open(ConfirmModal, {
      title: 'Delete Positector Entry?',
      description: <>Please confirm deletion.</>,
    })

    if (modalResult) {
      await deletePositector(positectorId)
      createNotification('Deleted', 'Deleted positector entry.')
      refresh()
    }
  }

  const onRowClick = async (row: positectorTest) => {
    const formData = {
      ...positectorFormSchema.cast(row, {stripUnknown: true}),
      'description': `Created by ${row.user_name} on ${row.created_on}`
    }

    const modalResult: any = await Demodal.open(ItemModal, {
      itemName: 'Positector',
      formData: formData,
      formFields: positectorFormFields,
      formSchema: positectorFormSchema,
      canDelete: true,
    })

    if (modalResult) {
      if (modalResult === 'delete') {
        onDeleteItemClick(row.id)
      } else  {
        const editId = modalResult['id']
        if ('id' in modalResult) delete modalResult['id']
        await putPositector(editId, modalResult)
        createNotification('Edited', 'Edited positector data.')
        refresh()
      }

    }
  }

  const rowEvents = {
    onClick: (e: BaseSyntheticEvent, row: positectorTest, rowIndex: number) => {
      if (!props.readOnly) onRowClick(row)
    }
  }

  const tableColumns = [
    {
      dataField: 'batch',
      text: 'Batch',
      sort: true,
      className: 'data-tip',
      attrs: {'data-tip': true, 'data-for':'tooltipname'}
    },
    {
      dataField: 'min',
      text: 'Min',
    },
    {
      dataField: 'max',
      text: 'Max',
    },
    {
      dataField: 'avg',
      text: 'Avg',
    },
  ]

  return (
    <div className="work-order-section work-order-positector">
      <header className="work-order-section-header work-order-positector-header">
        <h2>Positector</h2>
        {!props.readOnly && (<Button onClick={onAddItemClick}><Plus/></Button>)}
      </header>

      <div className="mt-3">
        <BootstrapTable
          keyField="id"
          data={ positectorTests }
          columns={ tableColumns }
          defaultSorted={[{dataField: 'id', order: 'asc'}]}
          hover
          rowEvents={ rowEvents }
          bootstrap4
        />
      </div>
    </div>
  )
}

export default WorkOrderPositector