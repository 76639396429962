
import globalState from '../common/context'
import { notificationType } from '../data/types/notification'
import { randomkey } from './helpers'

const createNotification = (
    title: string,
    description: string,
    type: notificationType = 'info',
    duration: number = 5000
) => {
    globalState.notifications.merge([{
        key: randomkey(10),
        title: title,
        description: description,
        type: type,
        duration: duration
    }])
}

export default createNotification