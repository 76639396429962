import { useEffect, useState } from "react"
import { useHistory } from 'react-router-dom'
import { useState as useGlobalState } from '@hookstate/core'
import globalState from '../common/context'
import { useMockData } from "../common/config"

const useCheckPermission = (ruleName: string = '', redirect: boolean = false) => {
  const history = useHistory()
  const globals = useGlobalState(globalState)
  const userRules = globals.userRules.get()
  const [hasPermission, setHasPermission] = useState<boolean>(false)

  // Check permission for the specified rule
  useEffect(() => {
    if (useMockData) {
      setHasPermission(true)
    } else if ( ! userRules.includes(ruleName)) {
      setHasPermission(false)
      if (redirect) history.push(`/permission_error`)
    } else {
      setHasPermission(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRules])

  return hasPermission
}

export default useCheckPermission