import Cookies from 'universal-cookie';
import { Badge, Button, ButtonGroup, Container, Dropdown, DropdownButton, Form } from "react-bootstrap"
import { Check, Clock, Dash } from "react-bootstrap-icons"
import { workCenterIds } from "../../common/config"
import { deleteTimeEntry, getAttendance, getTimeEntries, postTimeEntry, putTimeEntry } from "../../data/api-requests"
import { prepTaskType } from "../../data/types/prep-task-type"
import { timeEntriesGroupedByDate, timeEntry } from "../../data/types/time-tracking"
import useCheckPermission from "../../hooks/use-check-permission"
import useGetData from "../../hooks/use-get-data"
import { getLocalTimeStringFromDate, getTimeDurationStringFromDates, triggerRunningTimersRefresh } from "../../utils/helpers"
import createNotification from "../../utils/notification"
import { confirmDuration, groupTimeEntriesByDate, onAddTimeEntry, onDeleteTimeEntry, onEditTimeEntry } from "../shared/time-tracking/time-tracking-utils"
import TimeTrackingPrepTaskBadges from "./time-tracking-prep-task-badges"
import { user } from '../../data/types/user';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { timeTrackingType } from '../../data/types/time-tracking-type';
import useGetStaticData from '../../hooks/use-get-static-data';

const TimeTracking = () => {
  const cookies = new Cookies()
  const currentUserId = cookies.get('tk_user_id') ? Number(cookies.get('tk_user_id')) : 0
  const hasAdminPermission = useCheckPermission('WO Administrator')

  const staticResources = useGetStaticData()
  const [selectedUserId, setSelectedUserId] = useState<number>(currentUserId);
  const [prepTaskTypes, setPrepTaskTypes] = useState<prepTaskType[]>([])
  const [timeTrackingTypes, setTimeTrackingTypes] = useState<timeTrackingType[]>([])
  const [users, refreshUsers] = useGetData<user>({interfaceName: 'user', sortByField: 'full_name', autoLoad: false})
  const [collapseAllHeaders, setCollapseAllHeaders] = useState<boolean>(false)
  const [userDataFiltered, setUserDataFiltered] = useState<user[]>([])
  const [showInactiveUsers, setShowInactiveUsers] = useState<boolean>(false)

  const fetchData = async () => {
    const result = await getTimeEntries(0, 0, selectedUserId, 7)
    const attendanceResult = await getAttendance(selectedUserId, 7)
    if (result) return groupTimeEntriesByDate(result, attendanceResult)
  }

  const [timeEntriesByDate, refresh] = useGetData<timeEntriesGroupedByDate>({
    fetchDataFn: fetchData,
    autoLoad: false,
  })

  // Set prep task and time tracking types when available
  useEffect(() => {
    if (staticResources && staticResources.prep_task_types) {
      setPrepTaskTypes(staticResources.prep_task_types)
    }
    if (staticResources && staticResources.time_tracking_types) {
      setTimeTrackingTypes(staticResources.time_tracking_types
        .sort((a: timeTrackingType, b: timeTrackingType) =>
          a.time_tracking_type_name < b.time_tracking_type_name ? -1 : 1
      ))
    }
  }, [staticResources])

  useEffect(() => {
    if (selectedUserId > 0) {
      refresh()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserId])

  useEffect(() => {
    if (hasAdminPermission) refreshUsers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAdminPermission])

  useEffect(() => {
    const filtered = getFilteredUsers(users)
    setUserDataFiltered([...filtered])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, showInactiveUsers])

  const getFilteredUsers = (users: user[]) => {
    const filtered = users.filter((user: user) => {
      if (showInactiveUsers) {
          return true
      } else {
          if (user.is_active) {
              return true
          } else {
              return false
          }
      }
    })
    return filtered
  }

  const formatMinutes = (minutes: number) => {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = Math.floor(minutes % 60)
    return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`
  }

  const formatPercent = (totalMinutes: number, totalAttendanceMinutes: number) => {
    const percent = Math.round((totalMinutes / totalAttendanceMinutes) * 100)
    return `${percent}%`
  }

  const getAbbreviatedWeekday = (dateString: string) => {
    const weekday = ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"]
    const date = new Date(dateString)
    return weekday[date.getDay()]
  }

  const onAddTimeEntryClick = async (eventKey: any, event: Object) => {
    if (!eventKey) return false
    const matchingType = timeTrackingTypes.find((type: timeTrackingType) => type.id === Number(eventKey))
    if (!matchingType) return false

    let workOrderId = 0

    const modalResult = await onAddTimeEntry({
      hasAdminPermission: hasAdminPermission,
      workCenterId: 0,
      currentUserId: currentUserId,
      title: `${matchingType.time_tracking_type_name} Time Entry`,
      isBillable: false,
      timeTrackingType: matchingType,
    })

    if (modalResult) {
      if (modalResult.end_time) {
        const confirmModalResult = await confirmDuration(modalResult.start_time, modalResult.end_time)
        if (!confirmModalResult) return false
      }

      if ('id' in modalResult) modalResult['id'] = 0
      if ('work_order_id' in modalResult && modalResult['work_order_id']) {
        workOrderId = modalResult['work_order_id']
        delete modalResult['work_order_id']
      }
      const result = await postTimeEntry(workOrderId, {...modalResult, 'type_id': matchingType.id})
      if (result) {
        createNotification('Submitted', `Added time entry.`)
        refresh()
        triggerRunningTimersRefresh()
      }
    }
  }

  const onRowClick = async (row: timeEntry) => {
    const matchingType = timeTrackingTypes.find((type: timeTrackingType) => type.id === Number(row.type_id))

    const modalResult = await onEditTimeEntry({timeEntry: row, timeTrackingType: matchingType})
    if (!modalResult) return false

    if (modalResult === 'delete') {
      onDeleteRow(row.id)
    } else {
      const editId = modalResult['id']
      if ('id' in modalResult) modalResult['id'] = 0
      const result = await putTimeEntry(editId, modalResult)
      if (result) createNotification('Edited', `Edited time entry.`)
      refresh()
      triggerRunningTimersRefresh()
    }
  }

  const onDeleteRow = async (id: number) => {
    const modalResult = await onDeleteTimeEntry()
    if (modalResult) {
      await deleteTimeEntry(id)
      createNotification('Deleted', `Deleted time entry.`)
      refresh()
      triggerRunningTimersRefresh()
    }
  }

  const onUserChange = (event: BaseSyntheticEvent) => {
    setSelectedUserId(Number(event.target.value))
  }

  const handleShowInactiveUsersClick = (event: BaseSyntheticEvent) => {
    setShowInactiveUsers(!showInactiveUsers)
  }

  return (
    <Container className="p-3">
      <div className="d-flex align-items-center time-tracking-header">
          <h1 className="m-0 time-tracking-title">Time Tracking</h1>
          <div className="ml-auto d-flex align-items-center">
            {hasAdminPermission && (
              <>
                <Form.Check
                  type="switch"
                  className="mr-4 inactive-users-switch text-nowrap"
                  id="inactiveUsersSwitch"
                  label="Show Inactive Users"
                  onChange={handleShowInactiveUsersClick}
                  checked={showInactiveUsers}
                />
                <Form.Control
                  as="select"
                  custom
                  name='user'
                  className="time-tracking-user-dropdown"
                  value={selectedUserId}
                  onChange={onUserChange}
                  disabled={users.length === 0}
                >
                  <option key='select' value={0}>Select User...</option>
                  {userDataFiltered.map((user: user) => (
                    <option key={user.id} value={user.id}>{user.full_name}</option>
                  ))}
                </Form.Control>
              </>
            )}

            <Button
              variant="primary"
              className="ml-2 flex-shrink-0"
              onClick={() => setCollapseAllHeaders(!collapseAllHeaders)}
            >
              {!collapseAllHeaders ? 'Collapse' : (
                <>&nbsp;Expand&nbsp;</>
              )}
            </Button>

            <DropdownButton
              as={ButtonGroup}
              id='non-billable-dropdown-button'
              className="ml-2"
              variant='primary'
              title='Add Non-billable Time'
              onSelect={onAddTimeEntryClick}
            >
              {timeTrackingTypes.map((type: timeTrackingType) =>
                <Dropdown.Item key={type.id} eventKey={type.id}>{type.time_tracking_type_name}</Dropdown.Item>
              )}
            </DropdownButton>
          </div>
      </div>

      <div className="mt-3">
        <table className="table table-hover table-bordered time-entries m-0">
          <tbody>
            {timeEntriesByDate.map((group: timeEntriesGroupedByDate) => (
              [
                <tr key={`${group.date}-heading1`} className="time-entry-heading-row time-entry-heading-row--primary">
                    <td colSpan={5}>
                      <div className="d-flex align-items-center">
                        <div className="d-inline-block align-middle mr-2">{group.date} ({getAbbreviatedWeekday(group.date)})</div>
                        <div className="ml-auto d-inline-flex align-items-center">
                          {group.total_attendance_minutes > 0 && (
                            <Badge variant="secondary" className="ml-auto d-inline-flex align-items-center tabular-nums">
                              {formatPercent(group.total_minutes, group.total_attendance_minutes)}
                            </Badge>
                          )}
                          <Badge variant="secondary" className="ml-2 d-inline-flex align-items-center tabular-nums">
                            <Clock className="mr-1"/>
                            {formatMinutes(group.total_minutes)}
                          </Badge>
                        </div>
                      </div>
                    </td>
                </tr>,
                !collapseAllHeaders && (
                  <>
                    <tr key={`${group.date}-heading2`} className="time-entry-heading-row">
                      <td>Time</td>
                      <td>Work Order</td>
                      <td>Location</td>
                      <td className="text-center">Qty</td>
                      <td className="text-center">Billable</td>
                    </tr>
                    {group.rows.map((row: timeEntry) => (
                      <tr key={row.id} className="time-entry-row" onClick={() => {onRowClick(row)}}>
                        <td className="time-entry-times-cell">
                          <div className="d-flex">
                            <div>
                              {row.start_time && getLocalTimeStringFromDate(row.start_time)}
                              <span> &ndash; </span>
                              {row.end_time && getLocalTimeStringFromDate(row.end_time)}
                            </div>
                            {row.start_time && row.end_time && (
                              <div className="ml-auto text-muted">({getTimeDurationStringFromDates(row.start_time, row.end_time)})</div>
                            )}
                          </div>
                          {row.description && (
                            <p className="time-entry-description">{row.description}</p>
                          )}
                          {row.work_center_id === workCenterIds.prep && (
                            <TimeTrackingPrepTaskBadges prepTaskTypes={prepTaskTypes} activePrepTaskTypes={row.active_prep_task_types}/>
                          )}
                        </td>
                        <td>
                          {row.odoo_work_order_name ? (
                            <span>{row.odoo_work_order_name}</span>
                          ): (
                            <span className="text-muted"><Dash size={30}/></span>
                          )}
                        </td>
                        <td>
                          {(row.work_center_name && row.work_center_group_name) ? (
                            <span>{row.work_center_group_name}/{row.work_center_name}</span>
                          ): (
                            <span className="text-muted"><Dash size={30}/></span>
                          )}
                        </td>
                        <td className="time-entry-qty-cell">{row.quantity}</td>
                        <td className="time-entry-billable-cell">
                          {row.is_billable && (
                            <span className="text-success"><Check size={30}/></span>
                          )}
                          {(!row.is_billable && row.type_name) && (
                            <Badge variant="light">{row.type_name}</Badge>
                          )}
                          {(!row.is_billable && !row.type_name) && (
                            <span className="text-muted"><Dash size={30}/></span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                )
              ]
            ))}
          </tbody>
        </table>
      </div>
    </Container>
  )
}

export default TimeTracking
