import { Alert, Form } from "react-bootstrap";
import { workOrder } from "../../data/types/work-order";

interface WorkOrderCompletionReportProps {
  workOrder: workOrder;
}

const WorkOrderCompletionReport = (props: WorkOrderCompletionReportProps) => {
  return (
    <div className="work-order-section work-order-completion-report">
      <header className="work-order-section-header work-order-completion-header">
        <h2>Completion</h2>
      </header>

      <Form>
        <Form.Check
          custom
          type='checkbox'
          id='review-completed'
          className='mb-2'
          label='Review Completed'
          checked={props.workOrder.odoo_review_completed}
          disabled={true}
        />
        <Form.Check
          custom
          type='checkbox'
          id='mek-passed'
          className='mb-2'
          label='MEK Test Passed'
          checked={Boolean(props.workOrder.mek_test_passed)}
          disabled={true}
        />
        <Form.Check
          custom
          type='checkbox'
          id='shop-floor-completed'
          className='mb-2'
          label='Shop Floor Completed'
          checked={props.workOrder.shop_floor_completed}
          disabled={true}
        />
        <Form.Check
          custom
          type='checkbox'
          id='completed-checkbox'
          label='Work Order Completed'
          checked={props.workOrder.odoo_work_order_completed}
          disabled={true}
        />
      </Form>

      <div className="mt-3">
        <strong className="mr-2">{props.workOrder.pallet_count ? props.workOrder.pallet_count : '0'}</strong>
        Pallets Used
      </div>

      {(props.workOrder.work_order_completed && props.workOrder.odoo_work_order_completed === false) && (
        <Alert variant="warning" className="mt-4 mb-0">
          This work order is marked as completed in the apps database but is not in the correct Odoo stage. Please move the Odoo work order into the Shipping or Closed stages.
        </Alert>
      )}
    </div>
  )
}

export default WorkOrderCompletionReport