import { Demodal, useModal } from "demodal";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { paintSpecification } from "../../../data/types/paint-specification";
import { Row, Col, Card, Alert } from "react-bootstrap";
import { odooDocument, productDrawing } from "../../../data/types/product-drawing";
import { getProductDrawing } from "../../../data/api-requests";
import { FileImage, FilePdf } from "react-bootstrap-icons";
import { emptyPart, part } from "../../../data/types/parts";
import ProductDrawingViewerModal from "./modal-product-drawing-viewer";

interface specificationSectionField {
    title: string;
    value: any;
    type?: 'text' | 'textarea';
}
interface modalPaintSpecificationProps {
    part: part,
    paintSpecifications: paintSpecification[],
    productDrawings: productDrawing[],
}

const PaintSpecificationModal = Demodal.create(({
    part = emptyPart,
    paintSpecifications = [],
    productDrawings = [],
}: modalPaintSpecificationProps) => {
    const modal = useModal();

    const resolve = (value: boolean) => () => {
        modal.resolve(value)
        modal.close()
        modal.remove()
    }

    const paintSpecificationSection = (title: string, fields: specificationSectionField[]) => {
        return (
            <>
                {fields.some((field: specificationSectionField) => (field.value)) && ( // At least one field must have a value set
                    <Card className="mb-4">
                        <Card.Header>
                            <strong>{title}</strong>
                        </Card.Header>
                        <Card.Body>
                            <div className="card-info">
                                {fields.map((field: specificationSectionField) => (
                                    field.value ? (
                                        <div key={field.title} className={`spec-field-${field.type ? field.type : 'text'}`}>
                                            {(!field.type || (field.type && field.type === 'text')) && (
                                                <span>
                                                    <strong className="text-muted card-info__label">{field.title}</strong>
                                                    {String(field.value)}
                                                </span>
                                            )}
                                            {(field.type && field.type === 'textarea') && (
                                                <span>{String(field.value)}</span>
                                            )}
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                ))}
                            </div>
                        </Card.Body>
                    </Card>
                )}
            </>
        )
    }

    const openProductDrawingViewerModal = (documentId: number) => {
        getProductDrawing(documentId)
        .then((result: odooDocument) => {
            Demodal.open(ProductDrawingViewerModal, {
                productDrawingData: result
            })
        })
    }

    const getDrawingPreview = (drawing: productDrawing) => {
        let preview = <FileImage className="product-drawing__icon text-info" size={48}/>

        if (drawing.mimetype.includes('pdf')) {
            preview = <FilePdf className="product-drawing__icon text-danger" size={48} />
        } else if ( drawing.tk_image_256) {
            preview = <img alt="" className="product-drawing__image" src={`data:image/*;base64,${drawing.tk_image_256}`}/>
        }

        return preview
    }

    return (
        <Modal
            show={modal.isOpen}
            onHide={resolve(false)}
            className="paint-specification-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Paint Specification for {part.default_code ? `[${part.default_code}]` : ''} {part.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(productDrawings.length === 0 && paintSpecifications.length === 0) && (
                    <Alert variant="info">No paint specification data available.</Alert>
                )}
                {productDrawings.length > 0 && (
                    <Row>
                        <Col>
                            <div className="product-drawings-list bg-light p-3 mb-3">
                                {productDrawings.map((drawing: productDrawing) => (
                                    <Button
                                        className="product-drawing__button"
                                        variant="custom"
                                        onClick={() => {openProductDrawingViewerModal(drawing.id)}}
                                    >
                                        <div className="product-drawing__preview">
                                            {getDrawingPreview(drawing)}
                                        </div>

                                        <div className="product-drawing__name">{drawing.name}</div>
                                    </Button>
                                ))}
                            </div>
                        </Col>
                    </Row>
                )}
                {paintSpecifications.map((paintSpecification: paintSpecification) => (
                    <>
                        <Row>
                            <Col md={6}>
                                {paintSpecificationSection('Blast', [
                                    {
                                        title: 'Grade',
                                        value: paintSpecification.tk_blast_grade,
                                    },
                                    {
                                        title: 'Special Instructions',
                                        value: paintSpecification.tk_blast_spec_instructions,
                                        type: 'textarea',
                                    }
                                ])}
                                {paintSpecificationSection('Setup', [
                                    {
                                        title: 'Special Instructions',
                                        value: paintSpecification.tk_setup_spec_instructions,
                                        type: 'textarea',
                                    }
                                ])}
                                {paintSpecificationSection('Prep', [
                                    {
                                        title: 'Special Instructions',
                                        value: paintSpecification.tk_prep_spec_instructions,
                                        type: 'textarea',
                                    }
                                ])}
                                {paintSpecificationSection('Wash', [
                                    {
                                        title: 'Stage',
                                        value: paintSpecification.tk_wash_stage,
                                    },
                                    {
                                        title: 'Special Instructions',
                                        value: paintSpecification.tk_wash_spec_instructions,
                                        type: 'textarea',
                                    }
                                ])}
                                {paintSpecificationSection('Packaging', [
                                    {
                                        title: 'Special Instructions',
                                        value: paintSpecification.tk_packaging_spec_instructions,
                                        type: 'textarea',
                                    }
                                ])}
                            </Col>
                            <Col md={6}>
                                {
                                    paintSpecification.tk_has_primer
                                    && paintSpecification.tk_primer_product.name
                                    && paintSpecificationSection('Primer', [
                                    {
                                        title: 'Product',
                                        value: `[${paintSpecification.tk_primer_product.default_code}] ${paintSpecification.tk_primer_product.name}`,
                                    },
                                    {
                                        title: 'Vendor',
                                        value: paintSpecification.tk_primer_product.tk_vendor_name,
                                    },
                                    {
                                        title: 'Target mm',
                                        value: paintSpecification.tk_primer_target_mm,
                                    },
                                    {
                                        title: 'Special Instructions',
                                        value: paintSpecification.tk_primer_instructions,
                                        type: 'textarea',
                                    },
                                ])}
                                {
                                    paintSpecification.tk_has_clear_coat
                                    && paintSpecification.tk_clear_coat_product.name
                                    && paintSpecificationSection('Clear Coat', [
                                    {
                                        title: 'Product',
                                        value: `[${paintSpecification.tk_clear_coat_product.default_code}] ${paintSpecification.tk_clear_coat_product.name}`,
                                    },
                                    {
                                        title: 'Vendor',
                                        value: paintSpecification.tk_clear_coat_product.tk_vendor_name,
                                    },
                                    {
                                        title: 'Target mm',
                                        value: paintSpecification.tk_clear_coat_target_mm,
                                    },
                                    {
                                        title: 'Special Instructions',
                                        value: paintSpecification.tk_clear_coat_instructions,
                                        type: 'textarea',
                                    },
                                ])}
                                {
                                    paintSpecification.tk_has_top_coat
                                    && paintSpecification.tk_top_coat_product.name
                                    && paintSpecificationSection('Top Coat', [
                                    {
                                        title: 'Product',
                                        value: `[${paintSpecification.tk_top_coat_product.default_code}] ${paintSpecification.tk_top_coat_product.name}`,
                                    },
                                    {
                                        title: 'Vendor',
                                        value: paintSpecification.tk_top_coat_product.tk_vendor_name,
                                    },
                                    {
                                        title: 'Target mm',
                                        value: paintSpecification.tk_top_coat_target_mm,
                                    },
                                    {
                                        title: 'Special Instructions',
                                        value: paintSpecification.tk_top_coat_instructions,
                                        type: 'textarea',
                                    },
                                ])}
                            </Col>
                        </Row>
                    </>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={resolve(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
})

export default PaintSpecificationModal
