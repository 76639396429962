export interface timeTrackingType {
  id: number;
  time_tracking_type_name: string;
  require_work_order_ref: boolean;
  require_work_center_group_ref: boolean;
  require_work_center_ref: boolean;
}

export const timeTrackingTypesMockData: timeTrackingType[] = [
  {
    "id": 1,
    "time_tracking_type_name": "Cleaning",
    "require_work_order_ref": false,
    "require_work_center_group_ref": false,
    "require_work_center_ref": false
  },
  {
    "id": 4,
    "time_tracking_type_name": "Colour Change",
    "require_work_order_ref": false,
    "require_work_center_group_ref": true,
    "require_work_center_ref": true
  },
  {
    "id": 5,
    "time_tracking_type_name": "Hooks",
    "require_work_order_ref": false,
    "require_work_center_group_ref": false,
    "require_work_center_ref": false
  },
  {
    "id": 2,
    "time_tracking_type_name": "Maintenance",
    "require_work_order_ref": false,
    "require_work_center_group_ref": false,
    "require_work_center_ref": false
  },
  {
    "id": 3,
    "time_tracking_type_name": "Repackaging",
    "require_work_order_ref": true,
    "require_work_center_group_ref": false,
    "require_work_center_ref": false
  },
  {
    "id": 6,
    "time_tracking_type_name": "Yard Maintenance",
    "require_work_order_ref": false,
    "require_work_center_group_ref": false,
    "require_work_center_ref": false
  }
]
