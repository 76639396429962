import { Button, Modal } from "react-bootstrap";
import { Printer } from "react-bootstrap-icons";
import print from 'print-js'
import { useEffect, useState } from "react";
import { Demodal, useModal } from "demodal";
import PdfViewer from "../pdf-viewer";

interface pdfViewerProps {
  pdfData: string;
}

const PdfViewerModal = Demodal.create((props: pdfViewerProps) => {
  const [pdfData, setPdfData] = useState<string>('')
  const modal = useModal()

  const resolve = (value: any) => () => {
    modal.resolve(value)
    modal.close()
    modal.remove()
  }

  const printHandler = (base64: string) => {
    print({printable: base64, type: 'pdf', base64: true})
  }

  useEffect(() => {
    setPdfData(props.pdfData)
  }, [props])

  return (
    <Modal
        show={modal.isOpen}
        onHide={resolve(false)}
        backdrop="static"
        className="pdf-viewer-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Print Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PdfViewer pdfData={pdfData} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="mr-auto" variant="secondary" onClick={resolve(false)}>
            Close
          </Button>
          <Button className="ml-auto" onClick={() => {printHandler(pdfData)}}>
            <Printer className="d-inline-block align-middle mr-2" />
            <span className="d-inline-block align-middle">Print</span>
          </Button>
        </Modal.Footer>
      </Modal>
  )
})

export default PdfViewerModal